import { createEvent, createStore } from 'effector'

export const openNewPasswordModal = createEvent()
export const closeNewPasswordModal = createEvent()

export const $isNewPasswordModalOpened = createStore(false)

$isNewPasswordModalOpened
  .on(openNewPasswordModal, () => true)
  .reset(closeNewPasswordModal)
