import { Box, Grid, type SxProps } from '@mui/material'
import { type ReactNode, useEffect, useRef } from 'react'
import { type Breakpoint } from '@mui/material/styles'
import { GameCard } from '../game-card'
import { type Game } from '@/__generated__/graphql'

export type GridConfig = Record<string, Record<Breakpoint, [number, number]>>

interface GamesListProps {
  games: Game[]
  gridConfig: GridConfig
  infinite?:
    | true
    | {
        wrapper: HTMLElement | null
      }
  numberOfShowedGamesOnClick?: number
  sx?: SxProps
  onShowMore?: () => void
}

export const GamesList = ({
  games,
  gridConfig,
  infinite,
  onShowMore,
  sx = {}
}: GamesListProps): ReactNode => {
  const endListRef = useRef<HTMLHRElement | null>(null)

  useEffect(() => {
    const wrapperElement =
      Boolean(infinite) && typeof infinite !== 'boolean'
        ? infinite?.wrapper
        : null

    const scroll = () => {
      const offsetTop = endListRef.current?.getBoundingClientRect().top ?? 0
      const autoShowMorePosition = wrapperElement
        ? wrapperElement.offsetHeight
        : window.innerHeight

      if (offsetTop < autoShowMorePosition && onShowMore) {
        onShowMore()
      }
    }

    if (infinite) {
      if (wrapperElement) {
        wrapperElement.addEventListener('scroll', scroll, { passive: true })
      } else {
        document.addEventListener('scroll', scroll, { passive: true })
      }
    }

    return () => {
      if (infinite) {
        if (wrapperElement) {
          wrapperElement.removeEventListener('scroll', scroll)
        } else {
          document.removeEventListener('scroll', scroll)
        }
      }
    }
  }, [infinite, onShowMore])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        gap: '20px',
        maxWidth: '1200px',
        mx: 'auto',
        ...sx
      }}
    >
      {games.length ? (
        <>
          <Grid
            container
            spacing={{ xs: '6px', lmd: '10px' }}
            columns={{
              xs: gridConfig.main.xs[0],
              sm: gridConfig.main.sm[0],
              md: gridConfig.main.md[0],
              lmd: gridConfig.main.lmd[0],
              lg: gridConfig.main.lg[0],
              xl: gridConfig.main.xl[0],
              xxl: gridConfig.main.xxl[0]
            }}
          >
            {Array.isArray(games) &&
              games.map((game) => {
                return <GameCard key={`game-${game.id}`} {...game} />
              })}
          </Grid>
          <Box ref={endListRef} />
        </>
      ) : (
        <Box sx={{ textAlign: 'center', my: '50px' }}>
          No games found with this criteria.
        </Box>
      )}
    </Box>
  )
}
