export const ContainedArrow = ({
  direction = 'left',
  rotate
}: {
  direction?: 'left' | 'right'
  rotate?: string
}) => {
  return (
    <svg
      width="7"
      height="14"
      viewBox="0 0 7 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: rotate
          ? `rotate(${rotate})`
          : direction === 'right'
            ? 'rotate(180deg)'
            : ''
      }}
    >
      <path
        d="M0.333496 13.6666L7.00016 6.99992L0.333496 0.333252V13.6666Z"
        fill="#71767A"
      />
    </svg>
  )
}
