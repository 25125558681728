export const PlayNetwork = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.75 25C18.75 24.6685 18.6183 24.3505 18.3839 24.1161C18.1495 23.8817 17.8315 23.75 17.5 23.75H16.25V21.25H21.25C21.913 21.25 22.5489 20.9866 23.0178 20.5178C23.4866 20.0489 23.75 19.413 23.75 18.75V6.25C23.75 5.58696 23.4866 4.95107 23.0178 4.48223C22.5489 4.01339 21.913 3.75 21.25 3.75H8.75C8.08696 3.75 7.45107 4.01339 6.98223 4.48223C6.51339 4.95107 6.25 5.58696 6.25 6.25V18.75C6.25 19.413 6.51339 20.0489 6.98223 20.5178C7.45107 20.9866 8.08696 21.25 8.75 21.25H13.75V23.75H12.5C12.1685 23.75 11.8505 23.8817 11.6161 24.1161C11.3817 24.3505 11.25 24.6685 11.25 25H2.5V27.5H11.25C11.25 27.8315 11.3817 28.1495 11.6161 28.3839C11.8505 28.6183 12.1685 28.75 12.5 28.75H17.5C17.8315 28.75 18.1495 28.6183 18.3839 28.3839C18.6183 28.1495 18.75 27.8315 18.75 27.5H27.5V25H18.75ZM12.5 17.5V7.5L18.75 12.5L12.5 17.5Z"
        fill="#95FE2C"
      />
    </svg>
  )
}
