export const Gift = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.795 1.44754C5.3775 1.44004 3.9975 2.80504 4.6275 4.50004H2.25C1.85218 4.50004 1.47064 4.65808 1.18934 4.93938C0.908035 5.22068 0.75 5.60222 0.75 6.00004V7.50004C0.75 7.69895 0.829018 7.88972 0.96967 8.03037C1.11032 8.17102 1.30109 8.25004 1.5 8.25004H8.25V6.00004H9.75V8.25004H16.5C16.6989 8.25004 16.8897 8.17102 17.0303 8.03037C17.171 7.88972 17.25 7.69895 17.25 7.50004V6.00004C17.25 5.60222 17.092 5.22068 16.8107 4.93938C16.5294 4.65808 16.1478 4.50004 15.75 4.50004H13.3725C14.25 2.04754 10.95 0.315041 9.4275 2.43004L9 3.00004L8.5725 2.41504C8.1 1.74754 7.4475 1.45504 6.795 1.44754ZM6.75 3.00004C7.4175 3.00004 7.755 3.81004 7.2825 4.28254C6.81 4.75504 6 4.41754 6 3.75004C6 3.55113 6.07902 3.36036 6.21967 3.21971C6.36032 3.07906 6.55109 3.00004 6.75 3.00004ZM11.25 3.00004C11.9175 3.00004 12.255 3.81004 11.7825 4.28254C11.31 4.75504 10.5 4.41754 10.5 3.75004C10.5 3.55113 10.579 3.36036 10.7197 3.21971C10.8603 3.07906 11.0511 3.00004 11.25 3.00004ZM1.5 9.00004V15C1.5 15.3979 1.65804 15.7794 1.93934 16.0607C2.22064 16.342 2.60218 16.5 3 16.5H15C15.3978 16.5 15.7794 16.342 16.0607 16.0607C16.342 15.7794 16.5 15.3979 16.5 15V9.00004H9.75V15H8.25V9.00004H1.5Z"
        fill="#71767A"
      />
    </svg>
  )
}
