import { type ReactNode } from 'react'

export const Arrow = ({
  rotate,
  color
}: {
  rotate?: string
  color?: string
}): ReactNode => {
  return (
    <svg
      width="10"
      height="18"
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ rotate }}
    >
      <path d="M1 1L9 9L1 17" stroke={color ?? '#EBEEF5'} />
    </svg>
  )
}
