// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

export const initTawk = (email?: string) => {
  if (!email) return
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const tawk_src = 'https://embed.tawk.to/668299db9d7f358570d5c772/1i1n2i0nb'

  if (window.Tawk_API === undefined) {
    window.Tawk_API = {
      visitor: {
        name: email
      }
    }
    ;(function () {
      const s1 = document.createElement('script')
      const s0 = document.getElementsByTagName('script')[0]
      s1.async = true
      s1.src = tawk_src
      s1.charset = 'UTF-8'
      s1.setAttribute('crossorigin', '*')
      s0.parentNode.insertBefore(s1, s0)
    })()
  } else {
    window.Tawk_API.setAttributes(
      {
        name: email
      },
      function () {}
    )
  }
}
