import { IconButton, type SxProps, useTheme } from '@mui/material'
import { type MouseEventHandler, type ReactNode } from 'react'
import { Container } from '@/layouts/container'
import { Arrow } from '@assets/icons/svg/arrow'
import { GameCard } from '@components/game-card'
import { CategoryTitle } from '@/content/home/category-title'
import { StyledSlider } from '@/content/home/games-category/styled-slyder'
import { SwiperSlide } from 'swiper/react'
import { useTranslation } from 'next-i18next'
import { type Game } from '@/__generated__/graphql'

const getButtonClassTitle = (title: string) =>
  title.toLowerCase().replace(' ', '-')

export const GamesCategory = ({
  icon,
  title,
  games,
  onClickMore,
  sx
}: {
  icon: ReactNode
  title: string
  games: Game[]
  onClickMore: MouseEventHandler
  sx?: SxProps
}): ReactNode => {
  const theme = useTheme()
  const { t } = useTranslation('home')

  const buttonStyle = {
    background: theme.colors.color300,
    '& path': {
      stroke: theme.colors.color200,
      transition: 'all 0.3s'
    },
    '&:disabled': {
      background: theme.colors.color400,
      opacity: 0.5
    },
    '&:hover': {
      '& path': {
        stroke: '#EBEEF5'
      }
    }
  }

  return (
    <Container sx={{ mb: '14px', ...sx }}>
      <CategoryTitle
        title={title}
        onClickMore={onClickMore}
        icon={icon}
        buttonText={t('more')}
        extraButtons={
          <>
            <IconButton
              className={`${getButtonClassTitle(title)}-slider-prev-button`}
              sx={{
                ml: 'auto',
                width: '32px',
                height: '32px',
                pr: '10px',
                ...buttonStyle
              }}
            >
              <Arrow rotate="180deg" />
            </IconButton>
            <IconButton
              className={`${getButtonClassTitle(title)}-slider-next-button`}
              sx={{
                pl: '10px',
                ml: '4px',
                width: '32px',
                height: '32px',
                ...buttonStyle
              }}
            >
              <Arrow />
            </IconButton>
          </>
        }
      />
      <StyledSlider
        nextEl={`.${getButtonClassTitle(title)}-slider-next-button`}
        prevEl={`.${getButtonClassTitle(title)}-slider-prev-button`}
      >
        {games.map((game, index, array) => {
          if ((index !== 0 && index % 2 === 0) || index === 1) return null

          return (
            <SwiperSlide
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '8px'
              }}
              key={game.id}
            >
              <GameCard {...game} maxWidth={177} />
              {array[index + 1] && (
                <GameCard {...array[index + 1]} maxWidth={177} />
              )}
            </SwiperSlide>
          )
        })}
      </StyledSlider>
    </Container>
  )
}
