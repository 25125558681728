export const PersonalInformation = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 5.5V7.5H3V5.5H21ZM3 17.5H12V15.5H3V17.5ZM3 12.5H21V10.5H3V12.5ZM18 14.5C19.11 14.5 20 15.4 20 16.5C20 17.6 19.11 18.5 18 18.5C16.89 18.5 16 17.61 16 16.5C16 15.39 16.9 14.5 18 14.5ZM14 22.5V21.5C14 20.4 15.79 19.5 18 19.5C20.21 19.5 22 20.4 22 21.5V22.5H14Z"
        fill="#71767A"
      />
    </svg>
  )
}
