import { createEvent } from 'effector'
import { type StartGameDto } from '@/__generated__/graphql'

export interface PagesParams {
  cookie: Record<string, string>
  headers: Record<string, string>
  userAgent: string
}

export const homePageSSREvent = createEvent<PagesParams>()
export const gamesPageSSREvent = createEvent<PagesParams>()
export const dashboardPageSSREvent = createEvent<PagesParams>()
export const bonusesPageSSREvent = createEvent<PagesParams>()
export const profilePageSSREvent = createEvent<PagesParams>()
export const termsPageSSREvent = createEvent<PagesParams>()
export const promotionsPageSSREvent = createEvent<PagesParams>()
export const promotionPageSSREvent = createEvent<PagesParams>()
export const tournamentsPageSSREvent = createEvent<PagesParams>()
export const notFoundPageSSREvent = createEvent<PagesParams>()
export const tournamentPageSSREvent = createEvent<
  PagesParams & {
    tournamentInstanceId: number
  }
>()
export const gamePageSSREvent = createEvent<
  {
    startGameDto: StartGameDto
  } & PagesParams
>()
