import { sample } from 'effector'
import {
  $allGames,
  $brands,
  $categories,
  $favoriteGames,
  $filteredGames,
  $gameData,
  $gameError,
  $gamesForMainPage,
  $gamesPageFilter,
  $lastPlayedGames,
  $tags,
  addFavoriteGameFx,
  changeGamesPageFilter,
  clearGameData,
  filterGamesFx,
  getBrandsFx,
  getCategoriesFx,
  getFavoriteGamesFx,
  getGamesForMainPage,
  getGamesForMainPageFx,
  getGamesFx,
  getGameTagsFx,
  getLastPlayedGamesFx,
  removeFavoriteGameFx,
  resetGamesPageFilter,
  startGameFx
} from '@models/games/model'
import { $headers, $userAgent } from '@models/app/model'
import { gamePageSSREvent } from '@models/pages/model'

$gameData.on(clearGameData, () => null)
$gameError.on(gamePageSSREvent, () => null)

sample({
  clock: getGamesFx.doneData,
  target: $allGames
})

sample({
  clock: getBrandsFx.doneData,
  target: $brands
})

sample({
  clock: getCategoriesFx.doneData,
  target: $categories
})

sample({
  clock: startGameFx.doneData,
  target: $gameData
})

sample({
  clock: startGameFx.failData,
  fn: (error) => {
    return error.message
  },
  target: [$gameError, clearGameData]
})

sample({
  clock: getGameTagsFx.doneData,
  target: $tags
})

sample({
  clock: $gamesPageFilter,
  source: $userAgent,
  fn: (userAgent, filter) => ({ ...filter, userAgent }),
  target: filterGamesFx
})

sample({
  source: { $filteredGames, $gamesPageFilter },
  clock: filterGamesFx.doneData,
  fn: ({ $filteredGames, $gamesPageFilter }, result) =>
    $gamesPageFilter.offset ? [...$filteredGames, ...result] : result,
  target: $filteredGames
})

$gamesPageFilter
  .on(changeGamesPageFilter, (state, { isWagering, ...payload }) => {
    const newState = {
      ...state,
      ...payload
    }

    delete newState.isWagering

    return {
      ...newState,
      ...(isWagering ? { isWagering } : {})
    }
  })
  .reset(resetGamesPageFilter)

$lastPlayedGames.on(getLastPlayedGamesFx.doneData, (_, payload) => payload)

sample({
  clock: getGamesForMainPage,
  source: { $headers, $userAgent },
  fn: ({ $headers, $userAgent }) => {
    return {
      headers: $headers ?? undefined,
      userAgent: $userAgent
    }
  },
  target: getGamesForMainPageFx
})

$gamesForMainPage.on(getGamesForMainPageFx.doneData, (_, v) => v)

sample({
  clock: [addFavoriteGameFx.doneData, removeFavoriteGameFx.doneData],
  fn: () => undefined,
  target: getFavoriteGamesFx
})

$favoriteGames.on(getFavoriteGamesFx.doneData, (_, data) => data)
