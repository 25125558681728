import getId from '@/helpers/get-id'

export const NAV_LINKS = [
  {
    id: getId(),
    title: 'games',
    links: [
      { id: getId(), title: 'slots', link: '/games' },
      { id: getId(), title: 'live', link: '/games?categories=38' },
      { id: getId(), title: 'popular', link: '/games?categories=35' }
    ]
  },
  {
    id: getId(),
    title: 'casino',
    links: [
      { id: getId(), title: 'promotions', link: '/promotions' },
      { id: getId(), title: 'payments', link: '/' },
      { id: getId(), title: 'termsAndConditions', link: '/terms' }
    ]
  },
  {
    id: getId(),
    title: 'help',
    links: [
      { id: getId(), title: 'support', link: 'mailto:support@spingreen.casino' }
    ]
  },
  {
    id: getId(),
    title: 'info',
    links: [
      { id: getId(), title: 'responsibleGaming', link: '/responsible-gaming' },
      { id: getId(), title: 'selfExclusion', link: '/self-exclusion' },
      // { id: getId(), title: 'disputeResolution', link: '/dispute-resolution' },
      // { id: getId(), title: 'aml', link: '/aml' },
      { id: getId(), title: 'kyc', link: '/kyc' },
      {
        id: getId(),
        title: 'fairness',
        link: '/fairness'
      },
      {
        id: getId(),
        title: 'privacyPolicy',
        link: '/privacy'
      }
    ]
  }
]
