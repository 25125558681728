export const AccountBox = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 12.75C4.5 11.25 7.5 10.425 9 10.425C10.5 10.425 13.5 11.25 13.5 12.75V13.5H4.5V12.75ZM11.25 6.75C11.25 7.34674 11.0129 7.91903 10.591 8.34099C10.169 8.76295 9.59674 9 9 9C8.40326 9 7.83097 8.76295 7.40901 8.34099C6.98705 7.91903 6.75 7.34674 6.75 6.75C6.75 6.15326 6.98705 5.58097 7.40901 5.15901C7.83097 4.73705 8.40326 4.5 9 4.5C9.59674 4.5 10.169 4.73705 10.591 5.15901C11.0129 5.58097 11.25 6.15326 11.25 6.75ZM2.25 3.75V14.25C2.25 14.6478 2.40804 15.0294 2.68934 15.3107C2.97064 15.592 3.35218 15.75 3.75 15.75H14.25C14.6478 15.75 15.0294 15.592 15.3107 15.3107C15.592 15.0294 15.75 14.6478 15.75 14.25V3.75C15.75 3.35218 15.592 2.97064 15.3107 2.68934C15.0294 2.40804 14.6478 2.25 14.25 2.25H3.75C2.9175 2.25 2.25 2.925 2.25 3.75Z"
        fill="#71767A"
      />
    </svg>
  )
}
