export const New = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.3771 8.81559L28.5 9L28.7865 9.57295C28.9269 9.85379 29 10.1635 29 10.4775C29 11.5945 28.0945 12.5 26.9775 12.5H26.802C25.7542 12.5 24.8521 11.7604 24.6466 10.733L24.6089 10.5447C24.5368 10.1842 24.5266 9.81404 24.5786 9.45007L24.6189 9.16766C24.8458 7.57925 26.0754 6.32077 27.6581 6.05699L28 6C27.6845 6.94643 27.8237 7.98551 28.3771 8.81559ZM26.5491 17.782C26.8746 18.1031 27.2075 18.4314 27.4901 18.8C27.4901 18.8 28.3301 20 28.6451 20.69L28.8401 21.08C30.2501 24.5 29.2901 28.49 26.5451 30.98C24.0851 33.2 20.6501 33.785 17.4401 33.38C14.4251 33.005 11.6201 31.145 10.0001 28.55C9.55007 27.755 9.14507 26.855 8.93507 25.955C8.68007 25.205 8.59007 24.455 8.50007 23.705C8.30507 20.45 9.67007 17 12.1751 14.96C11.0351 17.42 11.3051 20.495 13.0001 22.64C13.0601 22.745 13.1201 22.82 13.2101 22.895C13.4951 23.135 13.8551 23.225 14.2001 23.075C14.5001 22.955 14.7251 22.655 14.7251 22.325C14.7251 22.13 14.6651 21.98 14.6051 21.83C12.7751 17.105 14.3051 11.6 18.1901 8.48C19.2551 7.625 20.5001 6.845 21.9251 6.5C20.5001 9.275 20.9951 12.89 23.2301 15.08C23.7577 15.5994 24.3398 16.0282 24.9239 16.4585C25.4025 16.8111 25.8825 17.1647 26.3351 17.57C26.4056 17.6406 26.4772 17.7111 26.5491 17.782ZM21.1001 29.15C21.6401 29 22.3301 28.61 22.7501 28.25C23.6501 27.425 24.1901 26.075 24.1451 24.845C24.1451 24.62 24.1151 24.41 24.0551 24.2C23.8018 22.8752 22.9475 22.1324 22.0634 21.3636C21.586 20.9485 21.1 20.5259 20.6951 20C20.3351 19.55 20.0351 18.98 19.7501 18.41C19.3451 19.445 19.3151 20.39 19.4951 21.5C19.5522 21.8384 19.6338 22.1627 19.7142 22.4822C19.9083 23.2532 20.0954 23.9966 19.9151 24.845C19.6001 26.18 18.5351 27.5 16.7501 27.92C17.7401 28.91 19.4201 29.75 21.1001 29.15Z"
        fill="#71767A"
      />
    </svg>
  )
}
