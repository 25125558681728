import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'next-i18next'

export const VerifiedText = () => {
  const theme = useTheme()
  const { t } = useTranslation('profile')

  return (
    <Typography
      sx={{
        border: `1px solid ${theme.colors.primary500}`,
        borderRadius: '4px',
        padding: '8px',
        width: '100%',
        textAlign: 'center',
        textTransform: 'uppercase',
        mt: '12px'
      }}
    >
      {t('verified')}
    </Typography>
  )
}
