import { createEvent, createStore } from 'effector'

export const toggleSiteMenu = createEvent<boolean>()

export const $isSiteMenuOpened = createStore(false).on(
  toggleSiteMenu,
  (_, value) => {
    return value
  }
)
