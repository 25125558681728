import { type ReactNode } from 'react'

export const Heart = ({ active }: { active?: boolean }): ReactNode => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6 18.55L12.5 18.65L12.39 18.55C7.64 14.24 4.5 11.39 4.5 8.5C4.5 6.5 6 5 8 5C9.54 5 11.04 6 11.57 7.36H13.43C13.96 6 15.46 5 17 5C19 5 20.5 6.5 20.5 8.5C20.5 11.39 17.36 14.24 12.6 18.55ZM17 3C15.26 3 13.59 3.81 12.5 5.08C11.41 3.81 9.74 3 8 3C4.92 3 2.5 5.41 2.5 8.5C2.5 12.27 5.9 15.36 11.05 20.03L12.5 21.35L13.95 20.03C19.1 15.36 22.5 12.27 22.5 8.5C22.5 5.41 20.08 3 17 3Z"
        fill={active ? '#FF0000' : '#71767A'}
      />
    </svg>
  )
}
