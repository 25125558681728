import { Box, TextField, useTheme } from '@mui/material'
import { SearchIcon } from '@/assets/icons/svg/search-icon'
import { useTranslation } from 'next-i18next'
import { type ReactNode } from 'react'
import { useUnit } from 'effector-react'
import { openSearchGamesModal } from '@models/search-modal/model'

export const SearchInput = (): ReactNode => {
  const theme = useTheme()
  const { t } = useTranslation('common')
  const openSearchGamesModalFunction = useUnit(openSearchGamesModal)

  return (
    <Box
      onClick={openSearchGamesModalFunction}
      sx={{
        borderTopLeftRadius: '24px',
        borderBottomLeftRadius: '24px',
        overflow: 'hidden',
        background:
          'linear-gradient(90deg, #2F3033 0%, rgba(47, 48, 51, 0.00) 100%);',
        width: '100%',
        ml: '12px',
        [theme.breakpoints.down(768)]: {
          display: 'none'
        }
      }}
    >
      <TextField
        size="small"
        sx={{
          width: '90%',
          fieldSet: {
            border: 'none!important'
          },
          '&:hover': {
            fieldSet: {
              border: 'none!important',
              background: 'none!important'
            },
            '.MuiInputBase-root': {
              background: 'none!important',
              border: 'none'
            },
            '.MuiInputBase-root.Mui-focused': {
              background: 'none'
            }
          },
          '.MuiInputBase-root.Mui-focused': {
            background: 'none'
          },
          '.MuiInputBase-input': {
            p: '7px 14px 10px 0'
          }
        }}
        placeholder={t('search').toUpperCase()}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <SearchIcon style={{ marginRight: '10px', marginTop: '-2px' }} />
          )
        }}
        variant="outlined"
      />
    </Box>
  )
}
