import { useTranslation } from 'next-i18next'
import { useTheme } from '@mui/material/styles'
import { useUnit } from 'effector-react'
import { $isSiteMenuOpened, toggleSiteMenu } from '@/layouts/menu/model'
import { Box, Drawer, useMediaQuery } from '@mui/material'
import { MenuButton } from './menu-button'
import { Bullhorn } from '@assets/icons/svg/bullhorn'
import { Gamepad } from '@assets/icons/svg/gamepad'
// import { Cup } from '@assets/icons/svg/cup'
// import { Ticket } from '@assets/icons/svg/ticket'
import {
  type MouseEventHandler,
  type ReactNode,
  useCallback,
  useEffect,
  useRef
} from 'react'
// import { LanguageSelect } from '@/layouts/menu/language-select'
import { DESKTOP_LAYOUT_BREAKPOINT } from '@/config/common'
import { BullhornBig } from '@assets/icons/svg/bullhorn-big'
import { GamepadBig } from '@assets/icons/svg/gamepad-big'
import { $user } from '@models/user/model'
import { useRouter } from 'next/router'
import { GAME_PAGES } from '@/layouts/content'
// import { CupBig } from '@assets/icons/svg/cup-big'
// import { TicketBig } from '@assets/icons/svg/ticket-big'

export interface MenuItem {
  title: string
  path?: string
  onClick?: MouseEventHandler
  icon?: ReactNode
  endIcon?: ReactNode
  type: 'primary' | 'secondary'
}

export const SiteMenu = () => {
  const { pathname } = useRouter()
  const theme = useTheme()
  const toggleSiteMenuFunction = useUnit(toggleSiteMenu)
  const isOpened = useUnit($isSiteMenuOpened)
  const isSmallDesktopMenu = useMediaQuery(
    theme.breakpoints.between(DESKTOP_LAYOUT_BREAKPOINT, 1600)
  )
  const isMobile = useMediaQuery(
    theme.breakpoints.down(DESKTOP_LAYOUT_BREAKPOINT)
  )

  // Такое же условие в хедере, править и там и там
  const isAlwaysOpened =
    useMediaQuery(theme.breakpoints.up(1400)) && !GAME_PAGES.includes(pathname)

  const toggleDrawer = (open: boolean) => () => {
    toggleSiteMenuFunction(open)
  }

  const backdropRef = useRef<HTMLDivElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)

  const onClickBackdropRef = useCallback(
    (event: MouseEvent) => {
      if (isOpened && !menuRef.current?.contains(event.target as Node)) {
        toggleSiteMenuFunction(false)
      }
    },
    [toggleSiteMenuFunction, isOpened]
  )

  useEffect(() => {
    const ref = backdropRef.current

    ref?.addEventListener('click', onClickBackdropRef)

    return () => {
      ref?.removeEventListener('click', onClickBackdropRef)
    }
  }, [backdropRef, onClickBackdropRef])

  return (
    <>
      <Drawer
        ref={backdropRef}
        anchor="left"
        open={isAlwaysOpened ? false : isOpened}
        onClose={toggleDrawer(false)}
        keepMounted={isSmallDesktopMenu}
        hideBackdrop={!isMobile}
        PaperProps={{ ref: menuRef }}
        sx={{
          position: !isMobile ? 'relative' : 'fixed',
          zIndex: 1099,
          '.MuiPaper-root': {
            overflow: 'visible',
            visibility: 'visible!important',
            borderRight: '1px solid #1A1C1F'
          },
          '.MuiDrawer-paper': {
            boxSizing: 'border-box',
            maxWidth: '200px',
            mt: '60px',
            background: theme.colors.color1000
          }
        }}
      >
        <Content isDesktop={isSmallDesktopMenu} toggleDrawer={toggleDrawer} />
      </Drawer>
      <Box
        sx={{
          display: isAlwaysOpened ? 'block' : 'none',
          zIndex: 1099,
          position: 'fixed',
          left: 0,
          maxWidth: '200px',
          top: 0,
          pt: '60px',
          borderRight: '1px solid #1A1C1F',
          height: '100%'
        }}
      >
        <Content isDesktop={isSmallDesktopMenu} toggleDrawer={toggleDrawer} />
      </Box>
    </>
  )
}

const Content = ({
  isDesktop,
  toggleDrawer
}: {
  isDesktop: boolean
  toggleDrawer: (value: boolean) => () => void
}) => {
  const { t } = useTranslation('common')
  const user = useUnit($user)
  const theme = useTheme()

  const MENU_ITEMS_PRIMARY: MenuItem[] = [
    {
      title: t('sideMenu.promotions'),
      path: '/promotions',
      icon: isDesktop ? <BullhornBig /> : <Bullhorn />,
      type: 'primary'
    },
    {
      title: t('sideMenu.games'),
      path: '/games',
      icon: isDesktop ? (
        <GamepadBig style={{ marginBottom: '5px' }} />
      ) : (
        <Gamepad />
      ),
      type: 'primary'
    }
    // {
    //   title: t('sideMenu.tournaments'),
    //   path: '/tournaments',
    //   icon: isDesktop ? <CupBig /> : <Cup />,
    //   type: 'primary'
    // },
    // {
    //   title: t('sideMenu.loyalty'),
    //   path: '/loyalty',
    //   icon: isDesktop ? <CupBig /> : <Cup />,
    //   type: 'primary'
    // }
    // {
    //   title: t('sideMenu.lottery'),
    //   path: '/lottery',
    //   icon: isDesktop ? <TicketBig /> : <Ticket />,
    //   type: 'primary'
    // }
  ]

  const MENU_ITEMS_SECONDARY: MenuItem[] = [
    // {
    //   title: t('sideMenu.payments'),
    //   path: '/paymemts',
    //   type: 'secondary'
    // },
    {
      title: t('sideMenu.terms'),
      path: '/terms',
      type: 'secondary'
    },
    ...(user?.email
      ? [
          {
            title: t('sideMenu.liveChat'),
            endIcon: (
              <Box
                sx={{
                  ml: '3px',
                  width: '4px',
                  height: '4px',
                  borderRadius: '50%',
                  background: theme.colors.primary500
                }}
              />
            ),
            onClick: () => {
              if (window.Tawk_API.toggle !== undefined) {
                close()
                window.Tawk_API.toggle()
              }
            },
            type: 'secondary' as const
          }
        ]
      : [])
  ]

  return (
    <Box
      sx={{
        px: '12px',
        position: 'relative',
        [theme.breakpoints.between(DESKTOP_LAYOUT_BREAKPOINT, 1600)]: {
          px: 0
        }
      }}
    >
      <Box
        component="nav"
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        mt="12px"
        gap={isDesktop ? 0 : '4px'}
      >
        {MENU_ITEMS_PRIMARY.map((item, index) => (
          <MenuButton
            onClick={toggleDrawer(false)}
            key={index}
            {...item}
            variant={isDesktop ? 'desktop_main' : 'mobile'}
          />
        ))}
        <Box />
        {MENU_ITEMS_SECONDARY.map((item, index) => (
          <MenuButton
            onClick={toggleDrawer(false)}
            key={index}
            {...item}
            variant={isDesktop ? 'desktop_second' : 'mobile'}
          />
        ))}
      </Box>
      {/* <LanguageSelect /> */}
    </Box>
  )
}
