// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  Box,
  Button,
  type InputProps,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'next-i18next'
import { Input } from '@/content/profile/input'
import { Cellphone } from '@assets/icons/svg/cellphone'
import { useUnit } from 'effector-react'
import { $user } from '@models/user/model'
import { forwardRef, useEffect, useState } from 'react'
import {
  changePhoneNumber,
  changePhoneSendCode,
  phoneConfirmationProcess
} from '@/graphql/auth/auth.api'
import PhoneInput from 'react-phone-number-input/input'
import { type CountryCode, type E164Number } from 'libphonenumber-js'

import {
  getCountries,
  getCountryCallingCode,
  isValidPhoneNumber
} from 'react-phone-number-input'

import en from 'react-phone-number-input/locale/en'
import { notify } from '@/config/notify'
import { VerifiedText } from '@/content/profile/verification/verified-text'

export const PhoneVerification = () => {
  const theme = useTheme()
  const { t } = useTranslation('profile')
  const user = useUnit($user)
  const [isAvailable, setIsAvailable] = useState(false)

  const isNoDepositer = !!user?.tags.find((tag) => tag.name === 'no_depositer')
  const [country, setCountry] = useState<CountryCode | undefined>(
    user?.country?.code.toUpperCase()
  )
  const [phone, setPhone] = useState<E164Number | undefined>(user?.phone)
  const [code, setCode] = useState('')

  useEffect(() => {
    phoneConfirmationProcess()
      .then((data) => {
        setIsAvailable(data.timeoutTo === 0)
      })
      .catch(() => {
        setIsAvailable(true)
      })
  }, [])

  const onClickSendCode = () => {
    if (!isValidPhoneNumber(phone)) {
      notify.error({ description: t('invalidPhoneNumber') })
      return
    }
    changePhoneNumber(phone)
      .then(() => {
        phoneConfirmationProcess()
          .then((data) => {
            setIsAvailable(data.timeoutTo === 0)
          })
          .catch(() => undefined)
      })
      .catch((error) => {
        if (error instanceof Error) {
          notify.error({ description: t(error.message) })
        }
      })
  }

  const onClickConfirm = () => {
    changePhoneSendCode(code)
      .then(() => {
        notify.success({ description: t('phoneConfirmed') })
      })
      .catch(() => notify.error({ description: t('invalidCode') }))
  }

  const isPhoneConfirmAvailable =
    isAvailable && !isNoDepositer && !user?.phoneVerified

  return (
    <Box
      sx={{
        background: theme.colors.color350,
        p: '10px'
      }}
    >
      <Box sx={{ display: 'flex', gap: '5px' }}>
        <Cellphone
          color={user?.phoneVerified ? theme.colors.primary500 : undefined}
        />
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 500,
            textTransform: 'uppercase',
            alignSelf: 'center'
          }}
        >
          {t('phoneVerification')}
        </Typography>
      </Box>
      <Box
        sx={{
          mt: '12px',
          display: 'grid',
          gridTemplateColumns: '1fr 4fr',
          alignItems: 'flex-end',
          gap: '8px',
          [theme.breakpoints.down(600)]: {
            gridTemplateColumns: '1fr 4fr'
          }
        }}
      >
        <CountrySelect
          labels={en}
          value={country}
          onChange={setCountry}
          disabled={!isPhoneConfirmAvailable}
        />
        <PhoneInput
          country={country}
          value={phone}
          onChange={(value) => {
            setPhone(value)
          }}
          inputComponent={CustomPhoneInput}
          disabled={!isPhoneConfirmAvailable}
        />

        <Button
          sx={{ borderRadius: '4px' }}
          onClick={onClickSendCode}
          disabled={!phone || !isPhoneConfirmAvailable}
        >
          {t('sendCode')}
        </Button>

        <Input
          placeholder={t('enterTheCode')}
          value={code}
          inputOnChange={(event) => {
            setCode(event.target.value)
          }}
          type="input"
          disabled={!isPhoneConfirmAvailable}
        />
      </Box>
      {user?.phoneVerified && <VerifiedText />}
      {!isPhoneConfirmAvailable && (
        <Button
          variant="outlined"
          color="secondary"
          disabled={!code}
          sx={{ mt: '12px' }}
          fullWidth
          onClick={onClickConfirm}
        >
          {t('confirm')}
        </Button>
      )}
    </Box>
  )
}

export const CountrySelect = ({
  value,
  onChange,
  labels,
  disabled,
  ...rest
}: InputProps & { labels }) => {
  return (
    <Select
      {...rest}
      value={value}
      onChange={(event) => {
        onChange(event.target.value || undefined)
      }}
      disabled={disabled}
    >
      <MenuItem value="">{labels.ZZ}</MenuItem>
      {getCountries().map((country) => (
        <MenuItem key={country} value={country}>
          {labels[country]} +{getCountryCallingCode(country)}
        </MenuItem>
      ))}
    </Select>
  )
}

// eslint-disable-next-line react/display-name
export const CustomPhoneInput = forwardRef((props, ref) => {
  const { t } = useTranslation('profile')
  const theme = useTheme()
  return (
    <TextField
      sx={{
        input: {
          zIndex: 1
        },
        fieldset: {
          background: theme.colors.color400
        },
        '&:hover fieldset': {
          background: `${theme.colors.color400}!important`
        }
      }}
      {...props}
      inputRef={ref}
      fullWidth
      placeholder={t('enterThePhoneNumber')}
    />
  )
})
