import { Box, TextField } from '@mui/material'
import { Cross } from '@assets/icons/svg/cross'
import { useTranslation } from 'next-i18next'

interface OwnPromocodeProps {
  value: string
  onChange: (value: string) => void
  onClickClose: () => void
}

export const OwnPromocode = ({
  value,
  onChange,
  onClickClose
}: OwnPromocodeProps) => {
  const { t } = useTranslation('common')

  return (
    <Box sx={{ display: 'flex', gap: '8px' }}>
      <TextField
        value={value}
        onChange={(e) => {
          onChange(e.target.value)
        }}
        fullWidth
        placeholder={t('paymentForm.enterPromocode')}
      />
      <Box
        onClick={onClickClose}
        sx={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Cross />
      </Box>
    </Box>
  )
}
