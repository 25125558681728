import { type CSSProperties } from 'react'

export const GamepadBig = ({
  color,
  style
}: {
  color?: string
  style?: CSSProperties
}) => {
  return (
    <svg
      width="22"
      height="12"
      viewBox="0 0 22 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M6 0H16C17.5913 0 19.1174 0.632141 20.2426 1.75736C21.3679 2.88258 22 4.4087 22 6C22 7.5913 21.3679 9.11742 20.2426 10.2426C19.1174 11.3679 17.5913 12 16 12C14.22 12 12.63 11.23 11.53 10H10.47C9.37 11.23 7.78 12 6 12C4.4087 12 2.88258 11.3679 1.75736 10.2426C0.632141 9.11742 0 7.5913 0 6C0 4.4087 0.632141 2.88258 1.75736 1.75736C2.88258 0.632141 4.4087 0 6 0ZM5 3V5H3V7H5V9H7V7H9V5H7V3H5ZM14.5 6C14.1022 6 13.7206 6.15804 13.4393 6.43934C13.158 6.72064 13 7.10218 13 7.5C13 7.89782 13.158 8.27936 13.4393 8.56066C13.7206 8.84196 14.1022 9 14.5 9C14.8978 9 15.2794 8.84196 15.5607 8.56066C15.842 8.27936 16 7.89782 16 7.5C16 7.10218 15.842 6.72064 15.5607 6.43934C15.2794 6.15804 14.8978 6 14.5 6ZM17.5 3C17.1022 3 16.7206 3.15804 16.4393 3.43934C16.158 3.72064 16 4.10218 16 4.5C16 4.89782 16.158 5.27936 16.4393 5.56066C16.7206 5.84196 17.1022 6 17.5 6C17.8978 6 18.2794 5.84196 18.5607 5.56066C18.842 5.27936 19 4.89782 19 4.5C19 4.10218 18.842 3.72064 18.5607 3.43934C18.2794 3.15804 17.8978 3 17.5 3Z"
        fill={color ?? '#71767A'}
      />
    </svg>
  )
}
