import { sample } from 'effector'
import {
  $balance,
  $countries,
  $currencies,
  $languages,
  $user,
  $wallets,
  $withdrawableAmount,
  checkAuthFx,
  getCountriesFx,
  getCurrenciesFx,
  getLanguagesFx,
  getWalletsFx,
  initTawkFx,
  logoutFx,
  setWithdrawableAmount,
  signInFx,
  signInInTelegramFx,
  signUpFx
} from '@models/user/model'
import { getUserBonusesFx } from '@models/bonuses/model'
import { $balanceChangeMessage } from '@models/socket/model'
import { $cookie, $headers, $userAgent, appStarted } from '@models/app/model'
import { EWalletType } from '@/__generated__/graphql'

$user.on(logoutFx.doneData, () => null)
$balance.on($wallets, (_, wallets) =>
  wallets.reduce((acc, value) => {
    if (value.type === EWalletType.Cash || value.type === EWalletType.Bonus) {
      return Number(acc) + Number(value.value)
    }

    return acc
  }, 0)
)

sample({
  clock: [
    checkAuthFx.doneData,
    signInFx.doneData,
    signUpFx.doneData,
    signInInTelegramFx.doneData
  ],
  fn: (data) => {
    return data
  },
  target: $user
})

sample({
  clock: [checkAuthFx.failData],
  fn: () => {
    return null
  },
  target: $user
})

sample({
  clock: getWalletsFx.doneData,
  target: $wallets
})

// TODO Move getUserBonusesFx to page
sample({
  clock: $user,
  source: { $headers, $cookie, $userAgent },
  filter: (_, user) => !!user?.id,
  fn: ({ $headers, $cookie, $userAgent }, user) => ({
    userId: user?.id ?? 0,
    headers: $headers ?? {},
    cookie: $cookie ?? {},
    userAgent: $userAgent
  }),
  target: [getWalletsFx, getUserBonusesFx]
})

sample({
  clock: getCountriesFx.doneData,
  target: $countries
})

sample({
  clock: getCurrenciesFx.doneData,
  target: $currencies
})

sample({
  clock: getLanguagesFx.doneData,
  target: $languages
})

sample({
  clock: $balanceChangeMessage,
  source: $wallets,
  fn: (wallets, message) => {
    const changedIndex = wallets.findIndex(
      (wallet) => wallet.type === message?.type
    )

    if (changedIndex > -1) {
      const newWallets = [...wallets]
      const changedWallet = { ...newWallets[changedIndex] }
      changedWallet.value = message?.value

      newWallets[changedIndex] = changedWallet

      return newWallets
    }

    return wallets
  },
  target: $wallets
})

$withdrawableAmount
  .on($user, (_, user) => user?.withdrawableAmount ?? 0)
  .on(setWithdrawableAmount, (_, amount) => amount)

sample({
  clock: [appStarted, $user],
  source: $user,
  fn: (user) => user?.email,
  filter: (user) => !!user?.email,
  target: initTawkFx
})
