import { useUnit } from 'effector-react'
import {
  Box,
  Dialog,
  IconButton,
  Tab,
  Tabs,
  TextField,
  useMediaQuery
} from '@mui/material'
import {
  $filter,
  $filteredGames,
  $isSearchGamesModalOpened,
  changeFilter,
  closeSearchGamesModal
} from '@models/search-modal/model'
import { GamesList, type GridConfig } from '@components/games-list'
import { SearchIcon } from '@assets/icons/svg/search-icon'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'next-i18next'
import { Cross } from '@assets/icons/svg/cross'
import { coloristics } from '@styles/theme/foundations/coloristics'
import {
  type SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { TabPanel } from '@components/modals/search-games-modal/tab-panel'
import debounce from 'lodash.debounce'
import {
  $lastPlayedGames,
  getFavoriteGamesFx,
  getLastPlayedGamesFx
} from '@models/games/model'

const GRID_CONFIG: GridConfig = {
  main: {
    xxl: [6, 30], // 8 - кол-во столбцов, 40 - элементов на странице
    xl: [6, 30],
    lg: [6, 30],
    lmd: [5, 25],
    md: [4, 16],
    sm: [3, 18],
    xs: [2, 10]
  }
}

export const SearchGamesModal = () => {
  const { t } = useTranslation('common')
  const theme = useTheme()
  const open = useUnit($isSearchGamesModalOpened)
  const filteredGames = useUnit($filteredGames)
  const filter = useUnit($filter)
  const lastPlayedGames = useUnit($lastPlayedGames)
  const getFavoriteGames = useUnit(getFavoriteGamesFx)

  const changeFilterEvent = useUnit(changeFilter)
  const closeSearchGamesModalFunction = useUnit(closeSearchGamesModal)

  const getLastPlayedGamesFunction = useUnit(getLastPlayedGamesFx)

  const [tab, setTab] = useState(0)
  const [gameListWrapper, setGameListWrapper] = useState<HTMLDivElement | null>(
    null
  )
  const [searchValue, setSearchValue] = useState<string>('')

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    debouncedChangeGamesPageFilterEvent('')
    setSearchValue('')
    setTab(newValue)
  }

  const xs = useMediaQuery(theme.breakpoints.up('xs'))
  const sm = useMediaQuery(theme.breakpoints.up('sm'))
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const lmd = useMediaQuery(theme.breakpoints.up('lmd'))
  const lg = useMediaQuery(theme.breakpoints.up('lg'))
  const xl = useMediaQuery(theme.breakpoints.up('xl'))
  const xxl = useMediaQuery(theme.breakpoints.up('xxl'))

  const gamesPerPage = useMemo(() => {
    if (xxl) return GRID_CONFIG.main.xxl[1]
    if (xl) return GRID_CONFIG.main.xl[1]
    if (lg) return GRID_CONFIG.main.lg[1]
    if (lmd) return GRID_CONFIG.main.lmd[1]
    if (md) return GRID_CONFIG.main.md[1]
    if (sm) return GRID_CONFIG.main.sm[1]
    if (xs) return GRID_CONFIG.main.xs[1]
    return GRID_CONFIG.main.xxl[1]
  }, [
    GRID_CONFIG.main.xxl,
    GRID_CONFIG.main.xl,
    GRID_CONFIG.main.lg,
    GRID_CONFIG.main.lmd,
    GRID_CONFIG.main.md,
    GRID_CONFIG.main.sm,
    GRID_CONFIG.main.xs,
    xxl,
    xl,
    lg,
    lmd,
    md,
    sm,
    xs
  ])

  const onScrollGamesList = () => {
    changeFilterEvent({
      name: searchValue,
      limit: gamesPerPage,
      offset: (filter?.offset ?? 0) + gamesPerPage,
      ...(filter?.gameCategories
        ? { gameCategories: filter.gameCategories }
        : {})
    })
  }

  const debouncedChangeGamesPageFilterEvent = useCallback(
    debounce(
      (value) =>
        changeFilterEvent({
          name: value,
          limit: gamesPerPage,
          offset: 0,
          ...(value ? {} : { gameCategories: [34] })
        }),
      300
    ),
    [changeFilterEvent, gamesPerPage]
  )

  const onSearch = useCallback(
    (value: string) => {
      debouncedChangeGamesPageFilterEvent(value)
    },
    [debouncedChangeGamesPageFilterEvent]
  )

  useEffect(() => {
    onSearch(searchValue)
    void getFavoriteGames()
  }, [searchValue, onSearch, getFavoriteGames])

  useEffect(() => {
    if (!open) {
      onSearch('')
      setSearchValue('')
    } else if (lastPlayedGames.length === 0) {
      onSearch(searchValue)
      getLastPlayedGamesFunction(gamesPerPage).catch(console.error)
    }
  }, [gamesPerPage, getLastPlayedGamesFunction, lastPlayedGames.length, open])

  return (
    <Dialog
      open={open}
      onClose={closeSearchGamesModalFunction}
      sx={{
        zIndex: 2100
      }}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: '100%',
          height: '100%',
          maxHeight: '100%',
          m: 0,
          p: 0
        }
      }}
    >
      <div
        ref={(ref) => {
          setGameListWrapper(ref)
        }}
        style={{
          padding: '16px',
          width: '100%',
          maxWidth: '100%',
          height: '100%',
          maxHeight: '100%',
          overflowY: 'scroll'
        }}
      >
        <Box
          sx={{
            maxWidth: 1100,
            mx: 'auto',
            width: '100%',
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            mb: '20px'
          }}
        >
          <SearchIcon
            color={theme.colors.primary500}
            style={{ width: '32px', height: '32px' }}
          />
          <TextField
            autoFocus
            autoComplete="off"
            placeholder={t('enterGameName')}
            value={searchValue}
            onChange={(event) => {
              setTab(0)
              setSearchValue(event.target.value)
            }}
            sx={{
              maxWidth: 1100,
              width: '100%',
              mx: 'auto',
              borderRadius: '24px',
              overflow: 'hidden',
              fieldSet: {
                border: `1px solid ${coloristics.colors.primary500}`,
                borderRadius: '24px'
              },
              '&:hover': {
                fieldSet: {
                  border: `1px solid ${coloristics.colors.primary500}!important`
                }
              },
              '.MuiInputBase-root.Mui-focused': {
                fieldSet: {
                  border: `1px solid ${coloristics.colors.primary500}`
                }
              }
            }}
          />
          <IconButton
            sx={{ width: '32px', height: '32px', ml: 'auto' }}
            onClick={closeSearchGamesModalFunction}
          >
            <Cross />
          </IconButton>
        </Box>
        <Tabs
          value={tab}
          onChange={handleChange}
          sx={{
            width: '100%',
            maxWidth: 1100,
            mx: 'auto',
            mb: '20px',
            '.MuiTabs-flexContainer': {
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: '8px'
            }
          }}
        >
          <Tab
            label={searchValue ? t('search') : t('new')}
            sx={{
              width: '100%',
              maxWidth: '100%',
              borderBottom: `2px solid ${theme.colors.color250}`
            }}
          />
          <Tab
            label={t('lastPlayed')}
            sx={{
              width: '100%',
              maxWidth: '100%',
              borderBottom: `2px solid ${theme.colors.color250}`
            }}
          />
        </Tabs>
        <>
          <TabPanel index={0} value={tab}>
            <GamesList
              games={filteredGames}
              gridConfig={GRID_CONFIG}
              sx={{ maxWidth: '1100px' }}
              onShowMore={onScrollGamesList}
              infinite={{ wrapper: gameListWrapper }}
            />
          </TabPanel>
          <TabPanel index={1} value={tab}>
            <GamesList
              games={lastPlayedGames}
              sx={{ maxWidth: '1100px' }}
              gridConfig={GRID_CONFIG}
              onShowMore={onScrollGamesList}
              infinite={{ wrapper: gameListWrapper }}
            />
          </TabPanel>
        </>
      </div>
    </Dialog>
  )
}
