import { isClient } from '@/helpers/isClient'

export const isInIframe = () => {
  if (!isClient) return false

  try {
    return window.self !== window.top
  } catch {
    return true
  }
}
