import { Container } from '@/layouts/container'
import { Box } from '@mui/material'
import { CategoryTitle } from '@/content/home/category-title'
import { useTranslation } from 'next-i18next'
import { PlaySmallGreen } from '@assets/icons/svg/play-small-green'
import Image from 'next/image'
import mobile from '@src/assets/images/440x300-2_x2.jpg'
import desktop from '@src/assets/images/1160x250_x2.jpg'
import { useRouter } from 'next/router'
import { useTheme } from '@mui/material/styles'

export const Banner = () => {
  const { t } = useTranslation('home')
  const router = useRouter()
  const theme = useTheme()

  return (
    <Container mt="12px">
      <CategoryTitle
        icon={<PlaySmallGreen />}
        title={t('playAndAchieve')}
        onClickMore={async () => await router.push('/promotions')}
        buttonText={t('letsGoToPromo')}
      />
      <Box
        sx={{
          height: '200px',
          position: 'relative',
          gap: '5px'
        }}
      >
        <Box
          component={Image}
          src={desktop}
          alt={'frame'}
          fill
          sizes="100vw"
          sx={{
            objectFit: 'cover',
            [theme.breakpoints.down(600)]: {
              display: 'none'
            }
          }}
          quality={100}
        />

        <Box
          component={Image}
          src={mobile}
          alt={'frame'}
          fill
          sizes="100vw"
          sx={{
            objectFit: 'cover',
            [theme.breakpoints.up(600)]: {
              display: 'none'
            }
          }}
          quality={100}
        />
      </Box>
    </Container>
  )
}
