import { type ReactNode } from 'react'

export const Gamepad = ({ selected }: { selected?: boolean }): ReactNode => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="gamepad-variant">
        <path
          id="Vector"
          d="M5.25 5H12.75C13.9435 5 15.0881 5.47411 15.932 6.31802C16.7759 7.16193 17.25 8.30653 17.25 9.5C17.25 10.6935 16.7759 11.8381 15.932 12.682C15.0881 13.5259 13.9435 14 12.75 14C11.415 14 10.2225 13.4225 9.3975 12.5H8.6025C7.7775 13.4225 6.585 14 5.25 14C4.05653 14 2.91193 13.5259 2.06802 12.682C1.22411 11.8381 0.75 10.6935 0.75 9.5C0.75 8.30653 1.22411 7.16193 2.06802 6.31802C2.91193 5.47411 4.05653 5 5.25 5ZM4.5 7.25V8.75H3V10.25H4.5V11.75H6V10.25H7.5V8.75H6V7.25H4.5ZM11.625 9.5C11.3266 9.5 11.0405 9.61853 10.8295 9.8295C10.6185 10.0405 10.5 10.3266 10.5 10.625C10.5 10.9234 10.6185 11.2095 10.8295 11.4205C11.0405 11.6315 11.3266 11.75 11.625 11.75C11.9234 11.75 12.2095 11.6315 12.4205 11.4205C12.6315 11.2095 12.75 10.9234 12.75 10.625C12.75 10.3266 12.6315 10.0405 12.4205 9.8295C12.2095 9.61853 11.9234 9.5 11.625 9.5ZM13.875 7.25C13.5766 7.25 13.2905 7.36853 13.0795 7.5795C12.8685 7.79048 12.75 8.07663 12.75 8.375C12.75 8.67337 12.8685 8.95952 13.0795 9.1705C13.2905 9.38147 13.5766 9.5 13.875 9.5C14.1734 9.5 14.4595 9.38147 14.6705 9.1705C14.8815 8.95952 15 8.67337 15 8.375C15 8.07663 14.8815 7.79048 14.6705 7.5795C14.4595 7.36853 14.1734 7.25 13.875 7.25Z"
          fill={selected ? '#95FE2C' : '#71767A'}
        />
      </g>
    </svg>
  )
}
