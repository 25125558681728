export const BonusBuy = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5066 18.5019L20.1722 24.0541L15.4409 18.5H18.4999L18.5066 18.5019ZM20.0629 16.7296L23.0998 26.8527L25.95 16.65L24.2932 11H21.748L20.0629 16.7296ZM19.6633 11H17.6806L15.0174 16.5H18.0458L19.6633 11ZM11 17.913L15.8 8H30.2L35 17.913L23 32L11 17.913Z"
        fill="#71767A"
      />
      <path
        d="M6.15 9.15L7.5 6L8.85 9.15L12 10.5L8.85 11.85L7.5 15L6.15 11.85L3 10.5L6.15 9.15Z"
        fill="#71767A"
      />
    </svg>
  )
}
