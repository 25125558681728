import type { NextPage } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import Head from 'next/head'
import { allSettled, serialize } from 'effector'

import {
  type GetServerSidePropsResult,
  type ServerSidePropsContext
} from '@/types/app'
import { Slider } from '@/content/home/slider'
import { CategoryLinks } from '@/content/home/category-links'
import { GamesCategory } from '@/content/home/games-category'
import { FireGreen } from '@/assets/icons/svg/fire-green'
import { Trophy } from '@assets/icons/svg/trophy'
import { SlotMachine } from '@assets/icons/svg/slot-machine'
import { Live } from '@assets/icons/svg/live'
// import { Providers } from '@/content/home/providers'
import { Banner } from '@/content/home/banner'
import { WelcomeText } from '@/content/home/welcome-text'
import { Crypto } from '@/content/home/crypto'
import { homePageSSREvent } from '@models/pages/model'
import { $gamesForMainPage } from '@models/games/model'
import { useUnit } from 'effector-react'
import { requestParser } from '@/config/request-parser'
import { New } from '@assets/icons/svg/new'
import { Popular } from '@assets/icons/svg/popular'
import { BonusBuy } from '@assets/icons/svg/bonus-buy'
import { Live2 } from '@assets/icons/svg/live-2'
import { Rocket } from '@assets/icons/svg/rocket'
import { useRouter } from 'next/router'
import { Box } from '@mui/material'

export interface Category {
  tKey: string
  icon: JSX.Element
  serverId: number
  iconForSlider: JSX.Element
}

export const CATEGORIES_MAIN_PAGE: Category[] = [
  {
    tKey: 'new',
    icon: <New />,
    iconForSlider: <FireGreen />,
    serverId: 34
  },
  {
    tKey: 'popular',
    icon: <Popular />,
    iconForSlider: <Trophy />,
    serverId: 35
  },
  {
    tKey: 'bonusBuy',
    icon: <BonusBuy />,
    iconForSlider: <SlotMachine />,
    serverId: 40
  },
  {
    tKey: 'liveGames',
    icon: <Live2 />,
    iconForSlider: (
      <Box
        height={32}
        width={32}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Live />
      </Box>
    ),
    serverId: 38
  },
  {
    tKey: 'instantWin',
    icon: <Rocket />,
    iconForSlider: <Rocket color="#95FE2C" />,
    serverId: 41
  }
]

const Home: NextPage = () => {
  const { t } = useTranslation(['common', 'home'])
  const router = useRouter()
  const gameForMainPage = useUnit($gamesForMainPage)

  return (
    <>
      <Head>
        <title>{t('pageHeads.homeTitle')}</title>
        <meta
          name="description"
          content={t('pageHeads.homeDescr')}
          key="title"
        />
      </Head>
      <Slider />
      <CategoryLinks categories={CATEGORIES_MAIN_PAGE} />
      {CATEGORIES_MAIN_PAGE.map((category) => (
        <GamesCategory
          key={category.tKey}
          icon={category.iconForSlider}
          title={t(category.tKey, { ns: 'home' })}
          games={gameForMainPage[category.serverId] || []}
          onClickMore={() => {
            router
              .push(`/games?filter=categories:${category.serverId}`)
              .catch(console.error)
          }}
        />
      ))}
      <Crypto />

      {/* <Providers /> */}
      {/* <Winners /> */}
      <Banner />
      <WelcomeText />
    </>
  )
}

export async function getServerSideProps({
  locale,
  req
}: ServerSidePropsContext): Promise<GetServerSidePropsResult> {
  const { scope, cookie, userAgent } = requestParser(req)

  await allSettled(homePageSSREvent, {
    scope,
    params: { cookie, headers: req.headers, userAgent }
  })

  return {
    props: {
      initialState: serialize(scope),
      locale,
      ...(await serverSideTranslations(locale, [
        'common',
        'profile',
        'home',
        'profile'
      ]))
    }
  }
}

export default Home
