import { attach, createEffect, createEvent, sample } from 'effector'
import { toast, type ToastContent, type ToastOptions } from 'react-toastify'
import * as React from 'react'

import { type ReactNode } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { Box, styled, type SxProps, Typography } from '@mui/material'
import { theme } from '@styles/theme'

interface ToastProps {
  title?: ReactNode
  description: ReactNode
}

const sharedTitleTypographyStyle: SxProps = {
  color: theme.colors.text1,
  fontWeight: 600
}

const sharedTypographyStyle: SxProps = {
  color: theme.colors.text1
}

const Wrapper = styled(Box)`
  padding: 5px;
`

export const SuccessToastUi = ({ title, description }: ToastProps) => {
  return (
    <Wrapper>
      {title && (
        <Typography sx={sharedTitleTypographyStyle}>{title}</Typography>
      )}
      {description && (
        <Typography sx={sharedTypographyStyle}>{description}</Typography>
      )}
    </Wrapper>
  )
}

export const WarningToastUi = ({ title, description }: ToastProps) => {
  return (
    <Wrapper>
      {title && (
        <Typography sx={sharedTitleTypographyStyle}>{title}</Typography>
      )}
      {description && (
        <Typography sx={sharedTypographyStyle}>{description}</Typography>
      )}
    </Wrapper>
  )
}

export const InfoToastUi = ({ title, description }: ToastProps) => {
  return (
    <Wrapper>
      {title && (
        <Typography sx={sharedTitleTypographyStyle}>{title}</Typography>
      )}
      {description && (
        <Typography sx={sharedTypographyStyle}>{description}</Typography>
      )}
    </Wrapper>
  )
}

export const ErrorToastUi = ({ title, description }: ToastProps) => {
  return (
    <Wrapper>
      {title && (
        <Typography sx={sharedTitleTypographyStyle}>{title}</Typography>
      )}
      {description && (
        <Typography sx={sharedTypographyStyle}>{description}</Typography>
      )}
    </Wrapper>
  )
}

interface ToastParams extends ToastOptions {
  title?: string
  description?: string
}

interface MapToNotifyFxParams {
  title?: string
  description?: string
  params?: ToastOptions
}

type Type = 'success' | 'warning' | 'info' | 'error'

interface ToastHandlerParams {
  element: ToastContent
  type: Type
  toastId: string
  onClick?: (event: React.MouseEvent) => Omit<ToastOptions, 'onClick'>
}

const progressColorSwitcher = (type: Type) => {
  switch (type) {
    case 'success':
      return theme.palette.success.main
    case 'warning':
      return theme.palette.warning.main
    case 'info':
      return theme.palette.info.main
    case 'error':
      return theme.palette.error.main
    default:
      return '#000'
  }
}

const notifyFx = createEffect(
  ({ element, type, toastId, onClick, ...params }: ToastHandlerParams) =>
    toast(element, {
      position: 'top-right',
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      progress: undefined,
      bodyStyle: {
        padding: 0
      },
      style: {
        cursor: onClick ? 'pointer' : 'auto',
        padding: 0,
        background: theme.colors.color350
      },
      progressStyle: {
        background: progressColorSwitcher(type)
      },
      toastId,
      onClick,
      ...params
    })
)

const successNotifyFx = attach({
  effect: notifyFx,
  mapParams: ({ title, description, ...params }: MapToNotifyFxParams) => ({
    element: <SuccessToastUi title={title} description={description} />,
    type: 'success' as const,
    toastId: `${title}${description}`,
    ...params
  })
})

const warningNotifyFx = attach({
  effect: notifyFx,
  mapParams: ({ title, description, ...params }: MapToNotifyFxParams) => ({
    element: <WarningToastUi title={title} description={description} />,
    type: 'warning' as const,
    toastId: `${title}${description}`,
    ...params
  })
})

const infoNotifyFx = attach({
  effect: notifyFx,
  mapParams: ({ title, description, ...params }: MapToNotifyFxParams) => ({
    element: <InfoToastUi title={title} description={description} />,
    type: 'info' as const,
    toastId: `${title}${description}`,
    ...params
  })
})

const errorNotifyFx = attach({
  effect: notifyFx,
  mapParams: ({ title, description, ...params }: MapToNotifyFxParams) => ({
    element: <ErrorToastUi title={title} description={description} />,
    type: 'error' as const,
    toastId: `${title}${description}`,
    ...params
  })
})

export const notifySuccess = createEvent<ToastParams>()
export const notifyWarning = createEvent<ToastParams>()
export const notifyInfo = createEvent<ToastParams>()
export const notifyError = createEvent<ToastParams>()

sample({
  clock: notifySuccess,
  target: successNotifyFx
})

sample({
  clock: notifyWarning,
  target: warningNotifyFx
})

sample({
  clock: notifyInfo,
  target: infoNotifyFx
})

sample({
  clock: notifyError,
  target: errorNotifyFx
})

export const notify = {
  success: notifySuccess,
  warning: notifyWarning,
  info: notifyInfo,
  error: notifyError
}
