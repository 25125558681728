import { gql, type QueryOptions } from '@apollo/client'
import { mutate, query } from '@/graphql/base-api'
import {
  type CreatePromocodeInstanceDto,
  type GiftConfig,
  type GiftInstance,
  type Promocode,
  type UserBonus
} from '@/__generated__/graphql'
import type { PagesParams } from '@models/pages/model'

export async function getUserBonuses(
  params?: PagesParams
): Promise<GiftConfig[]> {
  const request: QueryOptions = {
    query: gql`
      query GiftConfigs {
        giftConfigs {
          id
          createdAt
          updatedAt
          ttl
          activationTtl
          promoCodeId
          comment
          source
          bonusConfigs {
            id
            createdAt
            updatedAt
            type
            value
            wager
            onlyCashWagering
            gamesIds
            freespinsParams {
              id
              createdAt
              updatedAt
              count
              currencyId
              gameId
              denomination
              bet
              betLevel
            }
            bonuses {
              id
              createdAt
              updatedAt
              wagered
              giftId
              configId
              config {
                id
                createdAt
                updatedAt
                type
                value
                wager
                onlyCashWagering
                gamesIds
              }
            }
            rates {
              id
              createdAt
              updatedAt
              currencyId
              maxValue
              maxWinValue
              minDepValue
              maxBet
              bonusConfigId
            }
          }
          promocode {
            id
            createdAt
            updatedAt
            name
            type
            stock
            stockPerUser
            ratelimitPerUser
            ratelimitPeriodPerUser
            isActive
            comment
            promocodes {
              id
              createdAt
              updatedAt
              isCompleted
              transactionId
              userId
              configId
            }
          }
          hide
          name
          depCount
          image
        }
      }
    `,
    context: {
      headers: {
        cookie: params?.cookie
      }
    }
  }

  const result = await query<{ giftConfigs: GiftConfig[] }>(request)

  return result.giftConfigs
}

export async function getActiveUserBonuses(
  params?: PagesParams
): Promise<UserBonus[]> {
  const request: QueryOptions = {
    query: gql`
      query ActiveUserBonuses {
        activeUserBonuses {
          id
          value
          valueType
          ttl
          image
          wager
          wagerSum
          wagered
          type
          game {
            id
            name
            previewImage
          }
        }
      }
    `,
    context: {
      headers: {
        cookie: params?.cookie
      }
    }
  }

  const result = await query<{
    activeUserBonuses: UserBonus[]
  }>(request)

  return result.activeUserBonuses
}

export const grabGift = async (giftId: number) => {
  const mutation = {
    mutation: gql`
      mutation ActivateGift($activateGiftId: Float!) {
        activateGift(id: $activateGiftId) {
          id
          createdAt
          updatedAt
          userId
          status
          expiredActivationAt
          expiredAt
          config {
            id
            createdAt
            updatedAt
            ttl
            activationTtl
            promoCodeId
            comment
            source
            bonusConfigs {
              id
              createdAt
              updatedAt
              type
              value
              wager
              onlyCashWagering
              gamesIds
              freespinsParams {
                id
                createdAt
                updatedAt
                count
                currencyId
                gameId
                denomination
                bet
                betLevel
              }
              bonuses {
                id
                createdAt
                updatedAt
                wagered
                giftId
                configId
              }
              rates {
                id
                createdAt
                updatedAt
                currencyId
                bonusConfigId
                maxValue
                maxWinValue
                minDepValue
                maxBet
              }
            }
            hide
            name
            depCount
            image
          }
          bonuses {
            id
            createdAt
            updatedAt
            wagered
            giftId
            configId
            config {
              id
              createdAt
              updatedAt
              type
              value
              wager
              onlyCashWagering
              gamesIds
            }
          }
          activatedAt
        }
      }
    `,
    variables: {
      activateGiftId: giftId
    }
  }

  const result = await mutate<{ activateGift: GiftInstance }>(mutation)

  return result.activateGift
}

export const activateGift = async (dto: CreatePromocodeInstanceDto) => {
  await mutate({
    mutation: gql`
      mutation ActivatePromocode($dto: CreatePromocodeInstanceDto!) {
        activatePromocode(dto: $dto) {
          id
        }
      }
    `,
    variables: {
      dto
    }
  })
}

export const convertCompPoints = async (amount: number) => {
  const mutation = {
    mutation: gql`
      mutation ConvertCompoints($amount: Float!) {
        convertCompPoints(amount: $amount)
      }
    `,
    variables: {
      amount
    }
  }

  return await mutate<boolean>(mutation)
}

export const getActiveUserPromocode = async (params?: PagesParams) => {
  const request = {
    query: gql`
      query GetActiveUserPromocode {
        getActiveUserPromocode {
          config {
            comment
            createdAt
            id
            isActive
            name
            ratelimitPerUser
            ratelimitPeriodPerUser
            stock
            type
            updatedAt
          }
          configId
          createdAt
          id
          isCompleted
          transactionId
          updatedAt
          userId
        }
      }
    `,
    context: {
      headers: {
        cookie: params?.cookie
      }
    }
  }

  const result = await query<{ getActiveUserPromocode: Promocode }>(request)

  return result.getActiveUserPromocode
}

export const deactivatePromocode = async () => {
  const request = {
    mutation: gql`
      mutation DeleteActiveUserPromocode {
        deleteActiveUserPromocode
      }
    `
  }

  await mutate(request)
}

export const activateStandalone = async (promocode: string) => {
  const request = {
    mutation: gql`
      mutation ActivateStandalonePromocode($dto: CreatePromocodeInstanceDto!) {
        activateStandalonePromocode(dto: $dto) {
          id
        }
      }
    `,
    variables: {
      dto: {
        name: promocode
      }
    }
  }

  await mutate(request)
}
