export const Hamburger = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.75 7.0625H17.25V8.9375H6.75V7.0625Z" fill="#71767A" />
      <path d="M6.75 11.5625H17.25V13.4375H6.75V11.5625Z" fill="#71767A" />
      <path d="M17.25 16.0625H6.75V17.9375H17.25V16.0625Z" fill="#71767A" />
    </svg>
  )
}
