export const Info = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14" cy="14" r="10" stroke="#71767A" />
      <path
        d="M12.9641 20.3999C13.5875 20.3999 14.2822 20.1111 15.1372 18.9381L16.0387 17.7149C16.065 17.6791 16.084 17.6384 16.0946 17.5951C16.1052 17.5518 16.1072 17.5067 16.1004 17.4626C16.0935 17.4186 16.0781 17.3763 16.0549 17.3383C16.0317 17.3004 16.0013 17.2675 15.9654 17.2416C15.8895 17.1873 15.7964 17.1634 15.7042 17.1745C15.6119 17.1856 15.5269 17.231 15.4657 17.3018L14.0061 18.9852C13.9725 19.0239 13.9277 19.0507 13.878 19.0617C13.8283 19.0727 13.7765 19.0673 13.73 19.0464C13.685 19.0263 13.6483 18.9908 13.6266 18.946C13.6049 18.9012 13.5994 18.8501 13.6113 18.8017L15.4499 11.3362C15.4702 11.2548 15.47 11.1696 15.4493 11.0883C15.4286 11.0071 15.388 10.9324 15.3313 10.8712C15.2747 10.81 15.2037 10.7642 15.125 10.738C15.0463 10.7119 14.9623 10.7062 14.8809 10.7216L11.5659 11.3362C11.5007 11.3483 11.4403 11.3791 11.3919 11.425C11.3435 11.4708 11.3091 11.5298 11.2928 11.5949L11.2077 11.9297C11.1983 11.9668 11.1974 12.0056 11.2052 12.0431C11.2129 12.0805 11.2289 12.1157 11.2522 12.1459C11.2754 12.1761 11.3051 12.2005 11.3391 12.2173C11.3731 12.234 11.4104 12.2427 11.4481 12.2426H13.1601L11.6995 18.1801C11.646 18.4147 11.557 18.8117 11.557 19.1005C11.557 19.7682 11.9489 20.3999 12.9641 20.3999ZM14.775 9.83627C15.4697 9.83627 16.004 9.42119 16.1465 8.78954C16.1821 8.64516 16.2 8.48273 16.2 8.37445C16.2 7.86913 15.8081 7.3999 15.0778 7.3999C14.3831 7.3999 13.8488 7.81499 13.7063 8.44664C13.6732 8.58254 13.6553 8.72178 13.6529 8.86173C13.6529 9.36705 14.0447 9.83627 14.775 9.83627Z"
        fill="#71767A"
      />
    </svg>
  )
}
