import { useEffect, useMemo, useState } from 'react'
import { createCryptoWallet } from '@/graphql/payments/payments.api'
import {
  type CreateTransactionNewDto,
  type CryptoWallet
} from '@/__generated__/graphql'
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography
} from '@mui/material'
import { useFormContext, useWatch } from 'react-hook-form'
import Image from 'next/image'
import { useTheme } from '@mui/material/styles'
import { notify } from '@/config/notify'
import { useTranslation } from 'next-i18next'

export const CryptoWalletData = () => {
  const theme = useTheme()
  const { t } = useTranslation('common')
  const [cryptoPaymentData, setCryptoPaymentData] =
    useState<CryptoWallet | null>(null)

  const { control } = useFormContext<CreateTransactionNewDto>()

  const [paymentSystemId] = useWatch<CreateTransactionNewDto>({
    control,
    name: ['paymentSystemId']
  })

  const onClickWallet = () => {
    if (cryptoPaymentData) {
      navigator.clipboard
        .writeText(cryptoPaymentData?.addressWallet)
        .catch(console.error)
      notify.success({ description: t('cryptoDeposit.copied') })
    }
  }

  const onClickTag = () => {
    if (cryptoPaymentData?.tag) {
      navigator.clipboard.writeText(cryptoPaymentData?.tag).catch(console.error)
      notify.success({ description: t('cryptoDeposit.copied') })
    }
  }

  const showedWalletAddress = useMemo(() => {
    if (cryptoPaymentData?.addressWallet) {
      return cryptoPaymentData?.addressWallet.length > 20
        ? `${cryptoPaymentData?.addressWallet.slice(
            0,
            10
          )}...${cryptoPaymentData?.addressWallet.slice(-10)}`
        : cryptoPaymentData?.addressWallet
    }

    return ''
  }, [cryptoPaymentData])

  useEffect(() => {
    setCryptoPaymentData(null)
    createCryptoWallet(paymentSystemId)
      .then((wallet) => {
        setCryptoPaymentData(wallet)
      })
      .catch(console.error)
  }, [paymentSystemId])

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: 'auto min-content',
          p: '10px 0',
          gap: '10px',
          [theme.breakpoints.down(530)]: {
            gridTemplateColumns: 'auto',
            gridTemplateRows: 'auto auto'
          }
        }}
      >
        <Box sx={{ minWidth: '240px' }}>
          <Typography fontSize="13px">{t('cryptoDeposit.wallet')}</Typography>
          <TextField
            onClick={onClickWallet}
            sx={{
              cursor: 'pointer',
              '.MuiInputBase-input': { cursor: 'pointer' }
            }}
            fullWidth
            value={showedWalletAddress || ''}
            InputProps={{
              readOnly: true,
              endAdornment: cryptoPaymentData ? (
                <Button variant="text" size="small" sx={{ color: '#fff' }}>
                  {t('cryptoDeposit.copy')}
                </Button>
              ) : (
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress size={25} />
                </Box>
              )
            }}
          />
          {cryptoPaymentData?.tag && (
            <>
              <Typography
                fontSize="13px"
                sx={{
                  mt: '5px'
                }}
              >
                {t('cryptoDeposit.tag')}
              </Typography>
              <TextField
                sx={{
                  cursor: 'pointer',
                  '.MuiInputBase-input': { cursor: 'pointer' }
                }}
                onClick={onClickTag}
                fullWidth
                value={cryptoPaymentData?.tag || ''}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <Button variant="text" size="small" sx={{ color: '#fff' }}>
                      {t('cryptoDeposit.copy')}
                    </Button>
                  )
                }}
              />
            </>
          )}
        </Box>
        {cryptoPaymentData?.qrCodeImage ? (
          <Image
            style={{ justifySelf: 'center' }}
            src={cryptoPaymentData.qrCodeImage}
            alt="image"
            width={150}
            height={150}
          />
        ) : (
          <Box
            width={150}
            height={150}
            style={{
              margin: '15px 0 0 10px',
              justifySelf: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#575555'
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </>
  )
}
