import { AppBar, Button, IconButton } from '@mui/material'

import { type ReactNode } from 'react'
import { SearchIcon } from '@assets/icons/svg/search-icon'
import { useUnit } from 'effector-react'
import { openSearchGamesModal } from '@models/search-modal/model'
import { Arrow } from '@assets/icons/svg/arrow'
import { CheckMarkRoundedIcon } from '@assets/icons/svg/check-mark-rounded-icon'
import { openPaymentModal } from '@components/modals/payment-modal/model'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

export const MobileGameHeader = (): ReactNode => {
  const { t } = useTranslation('common')
  const router = useRouter()

  const openSearchGamesModalFunction = useUnit(openSearchGamesModal)
  const openPaymentModalFunction = useUnit(openPaymentModal)

  return (
    <AppBar
      sx={{
        display: 'flex',
        flexDirection: 'row',
        zIndex: 2000,
        gap: '10px'
      }}
    >
      <IconButton
        onClick={async () =>
          await router
            .push(router.query.backTo?.toString() ?? '/')
            .catch(() => undefined)
        }
        sx={{ height: '40px', width: '40px', p: '0 2px 0 0' }}
      >
        <Arrow rotate={'180deg'} color="#fff" />
      </IconButton>
      <IconButton
        sx={{ height: '40px', width: '40px' }}
        onClick={openSearchGamesModalFunction}
      >
        <SearchIcon color="#fff" />
      </IconButton>
      <Button
        sx={{ ml: 'auto' }}
        color="secondary"
        startIcon={<CheckMarkRoundedIcon />}
        onClick={() => openPaymentModalFunction({})}
      >
        {t('deposit')}
      </Button>
    </AppBar>
  )
}
