export const PlaySmallGreen = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.0408 15.0747L12.8964 8.88879C11.8339 8.17002 10.3999 8.93123 10.3999 10.214L10.3999 22.5859C10.3999 23.8687 11.8339 24.63 12.8964 23.9112L22.0408 17.7252C22.9788 17.0907 22.9788 15.7093 22.0408 15.0747Z"
        fill="#95FE2C"
      />
    </svg>
  )
}
