import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { NAV_LINKS } from '@/layouts/footer/config'
import { useTranslation } from 'next-i18next'
import { DESKTOP_LAYOUT_BREAKPOINT } from '@/config/common'
import { useRouter } from 'next/router'

export const NavigationDesktop = () => {
  const theme = useTheme()
  const { t } = useTranslation('common')
  const router = useRouter()

  return (
    <Box
      sx={{
        display: 'grid',
        width: '100%',
        gridTemplateColumns: `repeat(${NAV_LINKS.length}, 1fr)`,
        [theme.breakpoints.down(DESKTOP_LAYOUT_BREAKPOINT)]: {
          display: 'none'
        }
      }}
    >
      {NAV_LINKS.map((column, id) => (
        <Box key={id} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            sx={{
              textTransform: 'uppercase',
              color: theme.colors.text4,
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: 1
            }}
          >
            {column.title}
          </Typography>
          {column.links.map((subLink, id) => (
            <Box
              key={id}
              sx={{
                fontSize: '16px',
                color: theme.colors.text3,
                mt: '16px',
                cursor: 'pointer'
              }}
              onClick={async () => await router.push(subLink.link)}
            >
              {t(`footer.${subLink.title}`)}
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  )
}
