const createCounter = (): (() => string) => {
  let id = BigInt(0)

  return () => {
    const currentId = id.toString()
    id += BigInt(1)
    return currentId
  }
}

const getId = createCounter()

export default getId
