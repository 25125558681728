import { createEffect, createEvent, createStore } from 'effector'
import {
  addFavoriteGame,
  getBrands,
  getFavoriteGames,
  getGameCategories,
  getGames,
  getGameTags,
  getLastPlayedGames,
  removeFavoriteGame,
  startGame
} from '@/graphql'
import {
  type Brand,
  type Game,
  type GameCategory,
  type GameStartUrlEntity,
  type GameTag,
  type GetGamesDto
} from '@/__generated__/graphql'
import { CATEGORIES_MAIN_PAGE } from '@/pages'
import { UAParser } from 'ua-parser-js'

export const $allGames = createStore<Game[]>([])
export const $brands = createStore<Brand[]>([])
export const $categories = createStore<GameCategory[]>([])
export const $tags = createStore<GameTag[]>([])
export const $gameData = createStore<GameStartUrlEntity | null>(null)
export const $filteredGames = createStore<Game[]>([])
export const $gamesPageFilter = createStore<Partial<GetGamesDto>>({
  gameTags: [],
  gameBrands: []
})
export const $gameError = createStore<string | null>(null)
export const $gamesForMainPage = createStore<Record<number, Game[]>>({})
export const $lastPlayedGames = createStore<Game[]>([])
export const $favoriteGames = createStore<Game[]>([])

export const clearGameData = createEvent()
export const changeGamesPageFilter = createEvent<Partial<GetGamesDto>>()
export const resetGamesPageFilter = createEvent()
export const getGamesForMainPage = createEvent<number[]>()

export const filterGamesFx = createEffect(
  async ({
    gameBrands,
    gameTags,
    gameCategories,
    userAgent,
    ...params
  }: Partial<GetGamesDto> & { userAgent: string }) => {
    const parser = new UAParser(userAgent)

    return await getGames({
      getGamesDto: {
        ...params,
        ...(Number(gameBrands?.length) > 0 ? { gameBrands } : {}),
        ...(Number(gameTags?.length) > 0 ? { gameTags } : {}),
        ...(Number(gameCategories?.length) > 0 ? { gameCategories } : {}),
        devices: parser.getDevice().type === 'mobile' ? 'MOBILE' : 'PC'
      }
    })
  }
)

export const getGamesFx = createEffect(
  async ({
    headers,
    userAgent
  }: {
    headers: Record<string, string> | undefined
    userAgent: string
  }) => {
    const parser = new UAParser(userAgent)

    return await getGames({
      getGamesDto: {
        limit: 50,
        offset: 0,
        devices: parser.getDevice().type === 'mobile' ? 'MOBILE' : 'PC'
      },
      headers
    })
  }
)

export const getCategoriesFx = createEffect(
  async () => await getGameCategories()
)

export const getBrandsFx = createEffect(getBrands)

export const getGameTagsFx = createEffect(getGameTags)

export const startGameFx = createEffect(startGame)

export const getLastPlayedGamesFx = createEffect(getLastPlayedGames)

export const getGamesByCategoryId = createEffect(
  async ({
    headers,
    userAgent,
    ids
  }: {
    headers?: Record<string, string> | undefined
    userAgent?: string
    ids: number[]
  }) => {
    const parser = new UAParser(userAgent)

    return await getGames({
      getGamesDto: {
        limit: 40,
        offset: 0,
        gameCategories: ids,
        devices: parser.getDevice().type === 'mobile' ? 'MOBILE' : 'PC'
      },
      headers
    })
  }
)

export const getGamesForMainPageFx = createEffect(
  async ({
    headers,
    userAgent
  }: {
    headers: Record<string, string> | undefined
    userAgent: string
  }) => {
    // eslint-disable-next-line
    const games = {} as Record<number, Game[]>
    const parser = new UAParser(userAgent)

    try {
      await Promise.all(
        CATEGORIES_MAIN_PAGE.map(async ({ serverId }) => {
          games[serverId] = await getGames({
            getGamesDto: {
              limit: 40,
              offset: 0,
              gameCategories: [serverId],
              devices: parser.getDevice().type === 'mobile' ? 'MOBILE' : 'PC'
            },
            headers
          })
        })
      )
    } catch (e) {
      console.log(e)
    }

    return games
  }
)

export const getFavoriteGamesFx = createEffect(
  async (params?: { headers?: Record<string, string> | undefined }) =>
    await getFavoriteGames({
      headers: params?.headers
    })
)

export const addFavoriteGameFx = createEffect(addFavoriteGame)
export const removeFavoriteGameFx = createEffect(removeFavoriteGame)
