import { createEvent, createStore } from 'effector'

export const $userAgent = createStore<string>('')
export const $headers = createStore<Record<string, string> | null>(null)
export const $cookie = createStore<Record<string, string> | null>(null)
export const $endSession = createStore<string | null>(null)

export const setHeaders = createEvent<{ headers: Record<string, string> }>()
export const setCookie = createEvent<{ cookie: Record<string, string> }>()
export const appStarted = createEvent()

$headers.on(setHeaders, (_, v) => v.headers)
$cookie.on(setCookie, (_, v) => v.cookie)
