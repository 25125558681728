import { TabPanel } from '@/content/profile/tab-panel'
import { useTheme } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { AccountDetails } from '@assets/icons/svg/account-details'
import { useTranslation } from 'next-i18next'
import { Input } from '@/content/profile/input'
import { PersonalInformation } from '@assets/icons/svg/personal-information'
// import { MailboxUp } from '@assets/icons/svg/mailbox-up'
import { useUnit } from 'effector-react'
import { $countries, $user, checkAuthFx } from '@models/user/model'
import dayjs from 'dayjs'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { updateUser } from '@/graphql/auth/auth.api'
import { LoadingButton } from '@components/loading-button'
import { joiResolver } from '@hookform/resolvers/joi'
import Joi from 'joi'
import { notify } from '@/config/notify'
import { EUserSex, type User } from '@/__generated__/graphql'
import { BirthdayInput } from '@/content/profile/birthday-input'

type FormUserData = Omit<User, 'birthday'> & {
  birthday: [number, number, number]
}

export const GeneralTab = ({
  currentValue,
  value,
  onSubmitCallback
}: {
  currentValue: string
  value: string
  onSubmitCallback?: () => void
}) => {
  const theme = useTheme()
  const { t } = useTranslation(['profile', 'common'])
  const profile = useUnit($user)
  const isProfileFilled = !!profile?.firstName
  const countries = useUnit($countries)
  const checkAuthFunction = useUnit(checkAuthFx)

  const methods = useForm<FormUserData>({
    defaultValues: {
      email: profile?.email ?? '',
      firstName: profile?.firstName ?? '',
      lastName: profile?.lastName ?? '',
      address: profile?.address ?? '',
      countryId: profile?.countryId ?? 0,
      city: profile?.city ?? '',
      zip: profile?.zip ?? '',
      sex: profile?.sex ?? EUserSex.Male,
      birthday: [
        profile?.birthday ? dayjs(profile?.birthday).date() : 1,
        profile?.birthday ? dayjs(profile?.birthday).month() : 0,
        profile?.birthday ? dayjs(profile?.birthday).year() : 2000
      ]
    },
    resolver: joiResolver(
      Joi.object({
        email: Joi.string().email({ tlds: false }).required(),
        firstName: Joi.string().required(),
        lastName: Joi.string().required(),
        address: Joi.string().required(),
        countryId: Joi.number().required(),
        city: Joi.string().required(),
        zip: Joi.string().required(),
        sex: Joi.string().required(),
        birthday: Joi.array().items(Joi.number()).length(3).required()
      })
    )
  })

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    setValue,
    setError
  } = methods

  const [firstName, lastName, birthday, sex, countryId, city, address, zip] =
    useWatch({
      control,
      name: [
        'firstName',
        'lastName',
        'birthday',
        'sex',
        'countryId',
        'city',
        'address',
        'zip'
      ]
    })

  const onSubmit = async (data: FormUserData) => {
    const birthdayDate = dayjs()
      .date(data.birthday[0])
      .month(data.birthday[1])
      .year(data.birthday[2])

    if (!(birthdayDate.isValid() && birthdayDate.isBefore(dayjs()))) {
      notify.error({
        description: t('invalidDate')
      })

      setError?.('birthday', {
        message: t('invalidDate')
      })

      return
    }

    if (birthdayDate.isAfter(dayjs().subtract(18, 'year'))) {
      notify.error({
        description: t('youMustBeAtLeast18YearsOld')
      })

      setError?.('birthday', {
        message: t('youMustBeAtLeast18YearsOld')
      })
    }

    try {
      await updateUser({
        firstName: data?.firstName ?? '',
        lastName: data?.lastName ?? '',
        birthday: birthdayDate.toString(),
        sex: data?.sex ?? EUserSex.Female,
        address: data?.address ?? '',
        countryId: data?.countryId ?? 0,
        city: data.city ?? '',
        zip: data?.zip ?? ''
      })
      notify.success({
        description: t('profileSavedSuccessfully')
      })

      await checkAuthFunction()
      onSubmitCallback?.()
    } catch (error) {
      if (error instanceof Error) {
        notify.error({
          description: error.message
        })
      }
    }
  }

  return (
    <TabPanel currentValue={currentValue} value={value}>
      <FormProvider {...methods}>
        <form>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: '12px',
              mt: '12px',
              [theme.breakpoints.down(800)]: {
                gridTemplateColumns: '1fr'
              }
            }}
          >
            <Box
              sx={{
                background: theme.colors.color350,
                p: '10px',
                borderRadius: '4px'
              }}
            >
              <Box sx={{ display: 'flex', gap: '5px' }}>
                <AccountDetails />
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    textTransform: 'uppercase',
                    alignSelf: 'center'
                  }}
                >
                  {t('userInformation')}
                </Typography>
              </Box>
              <Input
                error={!!errors.firstName}
                value={firstName ?? ''}
                disabled={isProfileFilled}
                label={t('firstName')}
                placeholder={t('enterTheFirstName')}
                inputOnChange={(event) => {
                  setValue('firstName', event.target.value)
                }}
                type="input"
              />
              <Input
                error={!!errors.lastName}
                value={lastName ?? ''}
                label={t('lastName')}
                disabled={isProfileFilled}
                placeholder={t('enterTheLastName')}
                inputOnChange={(event) => {
                  setValue('lastName', event.target.value)
                }}
                type="input"
              />
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '12px',
                  mt: '12px',
                  color: theme.colors.text3
                }}
              >
                {t('dayOfBirth')}
              </Typography>
              <BirthdayInput
                value={birthday}
                error={!!errors.birthday}
                onChange={(v) => {
                  setValue('birthday', v)
                }}
              />
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '12px',
                  mt: '12px',
                  color: theme.colors.text3
                }}
              >
                {t('gender')}
              </Typography>
              <Input
                error={!!errors.sex}
                placeholder={t('chooseAGender')}
                disabled={isProfileFilled}
                value={sex ?? ''}
                selectOnChange={(event) => {
                  setValue('sex', event.target.value as EUserSex)
                }}
                type={'select'}
                selectItems={[
                  { value: 'MALE', label: t('male') },
                  { value: 'FEMALE', label: t('female') }
                ]}
              />
            </Box>
            <Box
              sx={{
                background: theme.colors.color350,
                p: '10px',
                borderRadius: '4px'
              }}
            >
              <Box sx={{ display: 'flex', gap: '5px' }}>
                <PersonalInformation />
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    textTransform: 'uppercase',
                    alignSelf: 'center'
                  }}
                >
                  {t('personalInformation')}
                </Typography>
              </Box>
              <Input
                error={!!errors.countryId}
                label={t('country')}
                placeholder={t('chooseACountry')}
                value={countryId?.toString() ?? ''}
                type={'select'}
                disabled={isProfileFilled}
                selectItems={countries.map((country) => ({
                  value: country.id.toString(),
                  label: country.name
                }))}
                selectOnChange={(event) => {
                  setValue('countryId', Number(event.target.value))
                }}
              />
              <Input
                error={!!errors.city}
                placeholder={t('enterTheCity')}
                label={t('city')}
                value={city ?? ''}
                disabled={isProfileFilled}
                inputOnChange={(event) => {
                  setValue('city', event.target.value)
                }}
                type="input"
              />
              <Input
                error={!!errors.address}
                placeholder={t('enterTheAddress')}
                label={t('address')}
                value={address ?? ''}
                disabled={isProfileFilled}
                inputOnChange={(event) => {
                  setValue('address', event.target.value)
                }}
                type="input"
              />
              <Input
                error={!!errors.zip}
                placeholder={t('enterThePostalCode')}
                label={t('postalCode')}
                value={zip ?? ''}
                disabled={isProfileFilled}
                inputOnChange={(event) => {
                  setValue('zip', event.target.value)
                }}
                type="input"
              />
            </Box>
          </Box>
          {/* <Box */}
          {/*  sx={{ */}
          {/*    background: theme.colors.color350, */}
          {/*    display: 'flex', */}
          {/*    gap: '12px', */}
          {/*    mt: '12px', */}
          {/*    p: '10px', */}
          {/*    borderRadius: '4px', */}
          {/*    alignItems: 'center', */}
          {/*    [theme.breakpoints.down(1085)]: { */}
          {/*      flexDirection: 'column', */}
          {/*      alignItems: 'flex-start' */}
          {/*    } */}
          {/*  }} */}
          {/* > */}
          {/*  <Box sx={{ display: 'flex', gap: '12px', alignItems: 'center' }}> */}
          {/*    <MailboxUp /> */}
          {/*    <Typography */}
          {/*      sx={{ */}
          {/*        fontSize: '16px', */}
          {/*        fontWeight: 500, */}
          {/*        textTransform: 'uppercase' */}
          {/*      }} */}
          {/*    > */}
          {/*      {t('subscriptions')} */}
          {/*    </Typography> */}
          {/*  </Box> */}
          {/*  <Divider */}
          {/*    orientation="vertical" */}
          {/*    sx={{ */}
          {/*      height: '29px', */}
          {/*      borderColor: theme.colors.color250, */}
          {/*      [theme.breakpoints.down(1085)]: { */}
          {/*        display: 'none' */}
          {/*      } */}
          {/*    }} */}
          {/*  /> */}
          {/*  <Box */}
          {/*    sx={{ */}
          {/*      display: 'flex', */}
          {/*      alignItems: 'center', */}
          {/*      [theme.breakpoints.down(1085)]: { */}
          {/*        ml: '-10px' */}
          {/*      } */}
          {/*    }} */}
          {/*  > */}
          {/*    <Switch /> */}
          {/*    <Typography sx={{ fontSize: '12px', mt: '3px' }}> */}
          {/*      {t('privacy')} */}
          {/*    </Typography> */}
          {/*  </Box> */}
          {/*  <Box */}
          {/*    sx={{ */}
          {/*      display: 'flex', */}
          {/*      alignItems: 'center', */}
          {/*      [theme.breakpoints.down(1085)]: { */}
          {/*        ml: '-10px' */}
          {/*      } */}
          {/*    }} */}
          {/*  > */}
          {/*    <Switch /> */}
          {/*    <Typography sx={{ fontSize: '12px', mt: '3px' }}> */}
          {/*      {t('privacy')} */}
          {/*    </Typography> */}
          {/*  </Box> */}
          {/* </Box> */}
          {!isProfileFilled && (
            <LoadingButton
              sx={{ mt: '12px' }}
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={handleSubmit(onSubmit)}
              disabled={isProfileFilled}
              loading={isSubmitting}
            >
              {t('saveEnteredData')}
            </LoadingButton>
          )}
        </form>
      </FormProvider>
    </TabPanel>
  )
}
