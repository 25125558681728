export const Cross = ({ color }: { color?: string }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 7.41423L10.2929 11.7071L11.7071 10.2929L7.41421 6.00001L11.7071 1.70712L10.2929 0.292908L6 4.5858L1.70711 0.292908L0.292892 1.70712L4.58579 6.00001L0.292892 10.2929L1.70711 11.7071L6 7.41423Z"
        fill={color ?? '#71767A'}
      />
    </svg>
  )
}
