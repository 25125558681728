import { Dialog, TextField, Typography } from '@mui/material'

import { TitleWithBackAndClose } from '@components/modals/title-with-back-and-close'

import { useUnit } from 'effector-react'
import { useTranslation } from 'next-i18next'
import React, { useState } from 'react'
import { restorePasswordCommit } from '@/graphql/auth/auth.api'
import { LoadingButton } from '@components/loading-button'
import { notify } from '@/config/notify'
import {
  $isNewPasswordModalOpened,
  closeNewPasswordModal
} from '@components/modals/new-password/model'
import { useRouter } from 'next/router'
import { useForm, useWatch } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import Joi from 'joi'
import { getPasswordValidation } from '@components/modals/registration-modal'
import { useTheme } from '@mui/material/styles'

interface FormValues {
  password: string
  repeat: string
}

export const NewPassword = () => {
  const { t } = useTranslation('common')
  const isOpened = useUnit($isNewPasswordModalOpened)
  const closeNewPasswordFunction = useUnit(closeNewPasswordModal)
  const router = useRouter()
  const theme = useTheme()

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, submitCount }
  } = useForm<FormValues>({
    defaultValues: {
      password: '',
      repeat: ''
    },
    resolver: joiResolver(
      Joi.object({
        password: getPasswordValidation(t),
        repeat: Joi.string().required()
      })
    )
  })

  const [password, repeat] = useWatch({
    control,
    name: ['password', 'repeat']
  })

  const [loading, setLoading] = useState(false)

  const onClickRestore = async (values: FormValues) => {
    if (typeof router.query.passwordrestoration === 'string') {
      try {
        setLoading(true)
        await restorePasswordCommit({
          password: values.password,
          token: router.query.passwordrestoration
        })

        notify.success({ description: t('passwordChanged') })
        closeNewPasswordFunction()
      } catch (e) {
        if (e instanceof Error) {
          notify.error({ description: e.message })
        }
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <Dialog
      open={isOpened}
      sx={{
        zIndex: 5000
      }}
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit(onClickRestore),
        sx: {
          minWidth: '375px'
        }
      }}
    >
      <TitleWithBackAndClose
        title={t('setNewPassword')}
        onClickClose={closeNewPasswordFunction}
      />
      <Typography sx={{ fontWeight: 400, fontSize: '12px', mt: '12px' }}>
        {t('password')}
      </Typography>
      <TextField
        {...register('password')}
        error={!!errors.password}
        type="password"
      />
      {errors.password?.message && (
        <Typography
          sx={{
            fontSize: '12px',
            lineHeight: 1,
            color: theme.palette.error.main
          }}
        >
          {errors.password?.message}
        </Typography>
      )}
      <Typography sx={{ fontWeight: 400, fontSize: '12px', mt: '12px' }}>
        {t('repeatPassword')}
      </Typography>
      <TextField
        {...register('repeat')}
        error={password !== repeat && submitCount > 0}
        value={repeat}
        type="password"
      />
      {password !== repeat && submitCount > 0 && (
        <Typography
          sx={{
            fontSize: '12px',
            lineHeight: 1,
            color: theme.palette.error.main
          }}
        >
          {t('repeatShouldMatch')}
        </Typography>
      )}
      <LoadingButton
        sx={{ borderRadius: '4px', mt: '12px' }}
        loading={loading}
        color="secondary"
        type="submit"
      >
        {t('save')}
      </LoadingButton>
    </Dialog>
  )
}
