export const Logout = () => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.75 12.75V10.5H7.5V7.5H12.75V5.25L16.5 9L12.75 12.75ZM11.25 1.5C11.6478 1.5 12.0294 1.65804 12.3107 1.93934C12.592 2.22064 12.75 2.60218 12.75 3V4.5H11.25V3H4.5V15H11.25V13.5H12.75V15C12.75 15.3978 12.592 15.7794 12.3107 16.0607C12.0294 16.342 11.6478 16.5 11.25 16.5H4.5C4.10218 16.5 3.72064 16.342 3.43934 16.0607C3.15804 15.7794 3 15.3978 3 15V3C3 2.60218 3.15804 2.22064 3.43934 1.93934C3.72064 1.65804 4.10218 1.5 4.5 1.5H11.25Z"
        fill="#71767A"
      />
    </svg>
  )
}
