import { createEffect, createStore } from 'effector'
import {
  activateGift,
  deactivatePromocode,
  getActiveUserBonuses,
  getActiveUserPromocode,
  getUserBonuses,
  grabGift
} from '@/graphql/bonuses/bonuses.api'
import {
  type GiftConfig,
  GiftSource,
  type Promocode,
  type UserBonus
} from '@/__generated__/graphql'

export const $userGifts = createStore<GiftConfig[] | null>(null)
export const $activeUserGifts = createStore<UserBonus[] | null>(null)
export const $activeUserPromocode = createStore<Promocode | null>(null)
export const $depositGifts = $userGifts.map((gifts) =>
  gifts?.filter(
    (gift) => gift.source === GiftSource.Promocode && gift.promocode
  )
)

export const getUserBonusesFx = createEffect(getUserBonuses)
export const getActiveUserBonusesFx = createEffect(getActiveUserBonuses)

export const grabGiftFx = createEffect(grabGift)
export const activateGiftFx = createEffect(activateGift)
export const getActiveUserPromocodeFx = createEffect(getActiveUserPromocode)
export const deactivateUserPromocodeFx = createEffect(deactivatePromocode)
