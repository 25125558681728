import { type Components, type Theme } from '@mui/material'
import { coloristics } from '@/styles/theme/foundations/coloristics'

export const components: Components<Omit<Theme, 'components'>> = {
  MuiTypography: {
    styleOverrides: {
      root: {
        color: coloristics.colors.text1
      }
    }
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        background: coloristics.colors.color400,
        height: '60px',
        padding: '10px 12px'
      }
    }
  },
  MuiButton: {
    defaultProps: {
      variant: 'contained',
      disableTouchRipple: true,
      disableRipple: true
    },
    styleOverrides: {
      root: {
        height: '40px',
        p: '8px 12px',
        minWidth: 'fit-content',
        lineHeight: 1
      },
      contained: {
        borderRadius: '28px',
        fontSize: '14px',
        boxShadow: 'none',
        fontWeight: 500
      },
      containedPrimary: {
        background: coloristics.colors.color300,
        color: coloristics.colors.color200,
        '&:hover': {
          background: coloristics.colors.color250,
          color: coloristics.colors.text1
        },
        '&:active': {
          background: coloristics.colors.color350,
          color: coloristics.colors.primary500
        },
        '&:disabled': {
          background: coloristics.colors.color300,
          color: coloristics.colors.color200
        }
      },
      containedSecondary: {
        background: coloristics.colors.primary500,
        color: coloristics.colors.text5,
        '&:hover': {
          background: coloristics.colors.primary400,
          color: coloristics.colors.text5
        },
        '&:active': {
          background: coloristics.colors.primary600,
          color: coloristics.colors.text5
        },
        '&:disabled': {
          background: coloristics.colors.color150,
          color: coloristics.colors.text3
        }
      },
      outlinedPrimary: {
        background: 'none',
        color: coloristics.colors.color100,
        border: `1px solid ${coloristics.colors.color250}`,
        borderRadius: '4px'
      },
      outlinedSecondary: {
        background: 'none',
        color: '#fff',
        border: `1px solid ${coloristics.colors.primary500}`,
        borderRadius: '4px'
      }
    }
  },
  MuiIconButton: {
    defaultProps: {
      disableTouchRipple: true
    },
    styleOverrides: {
      root: {
        height: '40px',
        p: '8px 12px',
        minWidth: 'fit-content',
        background: coloristics.colors.color300,
        color: coloristics.colors.color200,
        '&:hover': {
          background: coloristics.colors.color250,
          color: coloristics.colors.text1
        },
        '&:active': {
          background: coloristics.colors.color350,
          color: coloristics.colors.primary500
        },
        '&:disabled': {
          background: coloristics.colors.color300,
          color: coloristics.colors.color200
        }
      }
    }
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        '& .MuiTabs-indicator': {
          background: coloristics.colors.primary500
        }
      }
    }
  },
  MuiTab: {
    defaultProps: {
      disableRipple: true
    },
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          color: coloristics.colors.primary500
        },
        '&.Mui-disabled': {
          color: '#2c2d2e',
          borderColor: '#2c2d2e'
        }
      }
    }
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: coloristics.colors.color1000
      }
    }
  },
  MuiDialog: {
    styleOverrides: {
      root: {
        zIndex: 5000
      },
      paper: {
        background: coloristics.colors.color400,
        borderRadius: '4px',
        padding: '16px',
        maxHeight: '100%',
        overflow: 'hidden',
        overflowY: 'auto'
      }
    }
  },
  MuiTextField: {
    defaultProps: { size: 'small' },
    styleOverrides: {
      root: {
        borderRadius: '4px',
        background: 'none',
        'input:-webkit-autofill': {
          boxShadow: `inset 0 0 0 44px ${coloristics.colors.color300}`,
          '-webkit-text-fill-color': coloristics.colors.text1,
          color: coloristics.colors.text1
        },

        '.MuiInputBase-root': {
          borderRadius: '4px',
          background: 'none',
          overflow: 'hidden'
        },

        '.MuiInputBase-input.Mui-disabled': {
          textFillColor: coloristics.colors.text3
        },

        fieldSet: {
          border: `1px solid ${coloristics.colors.color250}`
        },

        '.MuiFormLabel-root': {
          color: coloristics.colors.text1
        },

        '.MuiInputLabel-root.Mui-focused': {
          color: coloristics.colors.text1
        },

        '.MuiFormLabel-root.Mui-error': {
          color: '#d32f2f'
        },

        '.MuiFilledInput-root': {
          '&:before': {
            display: 'none'
          },
          '&:after': {
            display: 'none'
          }
        },
        '&:hover': {
          fieldSet: {
            border: `1px solid ${coloristics.colors.color200}!important`,
            background: 'none!important'
          },
          '.MuiInputBase-root': {
            border: 'none'
          }
        },

        '.MuiInputBase-root.Mui-focused': {
          background: coloristics.colors.color300
        }
      }
    }
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        '.MuiInputLabel-root.Mui-focused': {
          color: coloristics.colors.text1
        }
      }
    }
  },
  MuiSelect: {
    defaultProps: {
      size: 'small',
      MenuProps: {
        sx: {
          '.MuiPaper-root': {
            border: `1px solid ${coloristics.colors.color250}`,
            borderTop: 'none',
            borderRadius: '0 0 4px 4px!important',
            background: coloristics.colors.color500
          }
        }
      }
    },
    styleOverrides: {
      root: {
        borderRadius: '4px',
        background: 'none',
        path: {
          color: coloristics.colors.color200
        },

        '&.Mui-focused': {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0
        },

        '.MuiInputBase-root': {
          borderRadius: '4px',
          background: 'none'
        },

        '.MuiSelect-select': {
          zIndex: 1
        },

        '.MuiInputBase-input.Mui-disabled': {
          textFillColor: coloristics.colors.text3
        },

        fieldSet: {
          border: `1px solid ${coloristics.colors.color250}`
        },

        '.MuiFormLabel-root': {
          color: coloristics.colors.text1
        },

        '.MuiInputLabel-root.Mui-focused': {
          color: coloristics.colors.text1
        },

        '.MuiFormLabel-root.Mui-error': {
          color: '#d32f2f'
        },

        '.MuiFilledInput-root': {
          '&:before': {
            display: 'none'
          },
          '&:after': {
            display: 'none'
          }
        },

        '&:hover': {
          fieldSet: {
            border: `1px solid ${coloristics.colors.color200}!important`,
            background: 'none!important'
          },
          '.MuiInputBase-root': {
            background: `${coloristics.colors.color250}`,
            border: 'none'
          }
        },

        '.MuiInputBase-root.Mui-focused': {
          background: coloristics.colors.color300,
          fieldSet: {
            border: `1px solid ${coloristics.colors.color200}`
          }
        }
      }
    }
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        borderRadius: '4px',
        background: 'none'
      }
    }
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        background: coloristics.colors.color500
      },
      list: {
        padding: 0
      },
      root: {
        zIndex: 5000
      }
    }
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        background: coloristics.colors.color300
      },
      bar: {
        background: coloristics.colors.primary500
      }
    }
  },
  MuiSwitch: {
    styleOverrides: {
      track: {
        height: '17.5px',
        background: coloristics.colors.color250,
        borderRadius: '4px'
      },
      thumb: {
        width: '9.6px',
        height: '12.2px',
        borderRadius: '6px'
      },
      root: {
        '.MuiButtonBase-root': {
          top: 6,
          left: 5
        },
        '.Mui-checked': {
          '.MuiSwitch-thumb': {
            background: coloristics.colors.primary500
          }
        },
        '.MuiButtonBase-root.Mui-checked + .MuiSwitch-track': {
          background: coloristics.colors.color250
        }
      }
    }
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        marginBottom: '16px',
        borderRadius: '4px!important',
        background: coloristics.colors.color350,
        '&:before': {
          height: 0
        },
        '.MuiAccordionSummary-root': {
          minHeight: 0,
          '&.Mui-expanded': {
            minHeight: 0
          }
        },
        '.MuiAccordionSummary-content': {
          margin: '10px 0!important',
          '.MuiTypography-root': {
            fontSize: '14px',
            fontWeight: 500
          }
        },
        '.MuiAccordionDetails-root': {
          padding: '0 8px',
          p: { fontSize: '14px' }
        },
        '&.Mui-expanded': {
          background: coloristics.colors.color350,
          '.MuiAccordionSummary-expandIconWrapper': {
            div: {
              background: '#28143C'
            }
          }
        }
      }
    }
  }
}
