import { Box, Button, Dialog, TextField, Typography } from '@mui/material'
import { useUnit } from 'effector-react'
import { TitleWithBackAndClose } from '@components/modals/title-with-back-and-close'
import { useTranslation } from 'next-i18next'
import { useTheme } from '@mui/material/styles'
import {
  $isLoginModalOpened,
  closeLoginModal
} from '@components/modals/login-modal/model'
import { signInFx } from '@models/user/model'
import { openRegisterModal } from '@components/modals/registration-modal/model'
import { useForm } from 'react-hook-form'
import { notify } from '@/config/notify'
import { openForgotPasswordModal } from '@components/modals/forgot-password/model'
import { LoadingButton } from '@components/loading-button'
import React, { useState } from 'react'
import { Eye } from '@assets/icons/svg/eye'
import { Cross } from '@assets/icons/svg/cross'

interface LoginFormValues {
  email: string
  password: string
}

export const LoginModal = () => {
  const theme = useTheme()
  const { t } = useTranslation('common')
  const open = useUnit($isLoginModalOpened)
  const closeLoginModalFunction = useUnit(closeLoginModal)
  const openRegisterModalFunction = useUnit(openRegisterModal)
  const signInFxFunction = useUnit(signInFx)
  const openForgotModalFunction = useUnit(openForgotPasswordModal)

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isSubmitting }
  } = useForm<LoginFormValues>({
    defaultValues: {
      email: '',
      password: ''
    }
  })

  const onClickSignUp = () => {
    closeLoginModalFunction()
    openRegisterModalFunction()
  }

  const onSubmit = async (values: LoginFormValues) => {
    try {
      await signInFxFunction(values)
      closeLoginModalFunction()
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === 'Unauthorized') {
          notify.error({ description: t('errors.incorrectEmailOrPassword') })
          return
        }
        notify.error({ description: t(error.message) })
      }
    }
  }

  const [isShowPassword, setIsShowPassword] = useState(false)

  return (
    <Dialog
      open={open}
      sx={{
        zIndex: 5000
      }}
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit(onSubmit),
        sx: {
          minWidth: '375px'
        }
      }}
    >
      <TitleWithBackAndClose
        title={t('logIn')}
        onClickBack={closeLoginModalFunction}
        onClickClose={closeLoginModalFunction}
      />
      <Typography sx={{ fontWeight: 400, fontSize: '14px', mt: '12px' }}>
        {t('email')}
      </Typography>
      <TextField
        {...register('email', { required: true })}
        error={!!errors.email}
        sx={{
          '& .MuiInputBase-root': {
            pr: 0
          }
        }}
        InputProps={{
          endAdornment: (
            <Box
              sx={{
                position: 'absolute',
                right: '4px',
                cursor: 'pointer',
                width: '32px',
                height: '32px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              onClick={() => {
                setValue('email', '')
              }}
            >
              <Cross />
            </Box>
          )
        }}
      />
      <Typography sx={{ fontWeight: 400, fontSize: '14px', mt: '12px' }}>
        {t('password')}
      </Typography>
      <TextField
        sx={{
          '& .MuiInputBase-root': {
            pr: 0
          }
        }}
        {...register('password', { required: true })}
        type={isShowPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <Box
              sx={{ position: 'absolute', right: '3px', pt: '5px' }}
              onClick={() => {
                setIsShowPassword(!isShowPassword)
              }}
            >
              <Eye />
            </Box>
          )
        }}
        error={!!errors.password}
      />

      <LoadingButton
        loading={isSubmitting}
        sx={{ borderRadius: '4px', mt: '12px' }}
        color="secondary"
        type="submit"
      >
        {t('logIn')}
      </LoadingButton>
      <Button
        sx={{ borderRadius: '4px', mt: '12px' }}
        variant="outlined"
        onClick={() => {
          closeLoginModalFunction()
          openForgotModalFunction()
        }}
      >
        {t('forgotYourPassword')}
      </Button>
      <Box
        sx={{ display: 'flex', alignItems: 'center', gap: '5px', pt: '12px' }}
      >
        <Typography>{t('dontHave')} </Typography>
        <Button
          onClick={onClickSignUp}
          variant="text"
          sx={{
            color: theme.colors.primary500,
            textTransform: 'none',
            height: '19px',
            p: 0,
            fontSize: '16px'
          }}
        >
          {t('signUp')}
        </Button>
      </Box>
    </Dialog>
  )
}
