import { type ReactNode } from 'react'

export const Triangle = (): ReactNode => {
  return (
    <svg
      width="11"
      height="5"
      viewBox="0 0 11 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.5 0L5.5 5L10.5 0H0.5Z" fill="#71767A" />
    </svg>
  )
}
