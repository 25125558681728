export const RoundedPlus = () => {
  return (
    <svg
      width="53"
      height="52"
      viewBox="0 0 53 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2984_4950)">
        <rect
          x="6.5"
          y="5"
          width="40"
          height="40"
          rx="20"
          fill="#95FE2C"
          shapeRendering="geometricPrecision"
        />
        <path
          d="M25.4998 26V32.0711H27.4998V26H33.5708V24H27.4998V17.929L25.4998 17.929L25.4998 24H19.4287L19.4287 26L25.4998 26Z"
          fill="#1A1C1F"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2984_4950"
          x="0.5"
          y="0"
          width="52"
          height="52"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.372549 0 0 0 0 0.866667 0 0 0 0 0.137255 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2984_4950"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2984_4950"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
