import { useTheme } from '@mui/material'
import { type ReactNode } from 'react'

export const CheckMarkRoundedIcon = ({
  disabled
}: {
  disabled?: boolean
}): ReactNode => {
  const theme = useTheme()

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM9.70711 13.7071L15.7071 7.70711L14.2929 6.29289L9 11.5858L5.70711 8.29289L4.29289 9.70711L8.29289 13.7071L9 14.4142L9.70711 13.7071Z"
        fill={disabled ? theme.colors.color200 : '#1A1C1F'}
      />
    </svg>
  )
}
