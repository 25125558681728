import {
  Box,
  CircularProgress,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material'
import { TitleWithBackAndClose } from '@components/modals/title-with-back-and-close'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'next-i18next'
import { useUnit } from 'effector-react'
import {
  $paymentModalOptions,
  closePaymentModal
} from '@components/modals/payment-modal/model'
import { type SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { type Step } from '@components/modals/payment-modal'
import { useFormContext } from 'react-hook-form'
import {
  type CreateTransactionNewDto,
  type PromocodeConfig
} from '@/__generated__/graphql'
import {
  $depositPaymentSystems,
  $withdrawPaymentSystems
} from '@models/payments/model'
import {
  $activeUserPromocode,
  $depositGifts,
  activateGiftFx,
  deactivateUserPromocodeFx,
  getUserBonusesFx
} from '@models/bonuses/model'
import { $user } from '@models/user/model'
import { Bullhorn } from '@assets/icons/svg/bullhorn'
import { LoadingButton } from '@components/loading-button'
import { PaymentCard } from '@components/modals/payment-modal/choose-system-step/payment-card'
import { notify } from '@/config/notify'
import { OwnPromocode } from '@components/modals/payment-modal/choose-system-step/own-promocode'
import { PromocodeSelect } from '@components/modals/payment-modal/choose-system-step/promocode-select'

type TabType = 'FIAT' | 'CRYPTO'

export const ChooseSystemStep = ({
  setStep,
  step,
  paymentSystemId
}: {
  setStep: (step: Step) => void
  step: Step
  paymentSystemId: number
}) => {
  const theme = useTheme()
  const { t } = useTranslation('common')

  const { setValue, reset } = useFormContext<CreateTransactionNewDto>()

  const [withBonus, setWithBonus] = useState(false)
  const [isOwnPromocode, setIsOwnPromocode] = useState(false)
  const [ownEnteredPromocode, setOwnEnteredPromocode] = useState('')
  const [promocode, setPromocode] = useState<PromocodeConfig | null>(null)

  const closePaymentModalFunction = useUnit(closePaymentModal)
  const activateGift = useUnit(activateGiftFx)
  const getUserBonusesFxFunction = useUnit(getUserBonusesFx)
  const deactivatePromocodeFunction = useUnit(deactivateUserPromocodeFx)

  const activeUserPromocode = useUnit($activeUserPromocode)
  const paymentModalOptions = useUnit($paymentModalOptions)
  const depositPaymentSystems = useUnit($depositPaymentSystems)
  const withdrawPaymentSystems = useUnit($withdrawPaymentSystems)
  const isUserBonusLoading = useUnit(getUserBonusesFx.pending)
  const depositBonuses = useUnit($depositGifts)
  const activateGiftLoading = useUnit(activateGiftFx.pending)
  const user = useUnit($user)

  const [tab, setTab] = useState<TabType>('FIAT')

  const systems = paymentModalOptions?.withdraw
    ? withdrawPaymentSystems
    : depositPaymentSystems

  const handleChange = (_: SyntheticEvent, newValue: TabType) => {
    setTab(newValue)

    const newSelectedSystem = systems.find(
      (system) =>
        (system.type === 'CRYPTO' && newValue === 'CRYPTO') ||
        (system.type !== 'CRYPTO' && newValue === 'FIAT')
    )

    if (newSelectedSystem?.id) {
      setValue('paymentSystemId', newSelectedSystem.id)
    }
  }

  const selectedPaymentMethod = useMemo(
    () => systems.find((system) => system.id === Number(paymentSystemId)),
    [paymentSystemId, systems]
  )

  const onConfirm = async () => {
    try {
      if (!paymentModalOptions?.withdraw && withBonus) {
        await activateGift({
          name: ownEnteredPromocode || promocode?.name || ''
        })
      }

      setStep('amount')
    } catch (e) {
      if (e instanceof Error) {
        notify.error({ description: e.message })
      }
    }
  }

  const onClickUseBonus = () => {
    setWithBonus((prev) => !prev)
    if (withBonus) {
      deactivatePromocodeFunction().catch(console.error)
    }
  }

  const onClose = () => {
    closePaymentModalFunction()
    setTimeout(() => {
      reset()

      setStep('system')
    }, 500)
  }

  useEffect(() => {
    if (!paymentSystemId) {
      const newSelectedSystem = systems.find(
        (system) =>
          (system.type === 'CRYPTO' && tab === 'CRYPTO') ||
          (system.type !== 'CRYPTO' && tab === 'FIAT')
      )

      if (newSelectedSystem?.id) {
        setValue('paymentSystemId', newSelectedSystem.id)
      }
    }
  }, [paymentSystemId, setValue, systems, tab])

  useEffect(() => {
    setOwnEnteredPromocode('')
  }, [isOwnPromocode])

  useEffect(() => {
    if (activeUserPromocode && !paymentModalOptions?.withdraw) {
      setWithBonus(true)
    }

    setPromocode(activeUserPromocode?.config || null)
  }, [activeUserPromocode, paymentModalOptions?.withdraw])

  useEffect(() => {
    if (withBonus && !depositBonuses?.length) {
      getUserBonusesFxFunction().catch(console.error)
    }
  }, [
    deactivatePromocodeFunction,
    depositBonuses?.length,
    getUserBonusesFxFunction,
    setValue,
    user?.id,
    withBonus
  ])

  return (
    <Box role="tabpanel" hidden={step !== 'system'}>
      <TitleWithBackAndClose
        title={t('paymentForm.choosePaymentMethod')}
        onClickBack={closePaymentModalFunction}
        onClickClose={onClose}
      />
      <Tabs
        value={tab}
        onChange={handleChange}
        sx={{
          width: '100%',
          '.MuiTabs-flexContainer': {
            width: '100%',
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)'
          }
        }}
      >
        <Tab
          value={'FIAT'}
          label={'FIAT'}
          sx={{
            width: '100%',
            maxWidth: '100%',
            borderBottom: `2px solid ${theme.colors.color250}`,
            color: theme.colors.text2
          }}
        />
        <Tab
          value={'CRYPTO'}
          label={'CRYPTO'}
          sx={{
            width: '100%',
            maxWidth: '100%',
            borderBottom: `2px solid ${theme.colors.color250}`,
            color: theme.colors.text2
          }}
        />
      </Tabs>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gap: '8px',
          pt: '12px'
        }}
      >
        {systems.map((payment) => {
          if (
            (tab === 'FIAT' && payment.type !== 'CRYPTO') ||
            (tab === 'CRYPTO' && payment.type === 'CRYPTO')
          ) {
            return (
              <PaymentCard
                key={`${payment.id}_${paymentModalOptions?.withdraw ? 'withdraw' : null}`}
                payment={payment}
                paymentSystemId={paymentSystemId}
                setValue={setValue}
              />
            )
          }

          return null
        })}
      </Box>
      <Typography sx={{ maxWidth: '346px', mt: '5px' }}>
        {selectedPaymentMethod?.message}
      </Typography>
      {!paymentModalOptions?.withdraw && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Switch checked={withBonus} onChange={onClickUseBonus} />
          <Bullhorn />
          <Typography
            sx={{
              ml: '10px',
              color: withBonus ? theme.colors.text1 : theme.colors.text2
            }}
          >
            {t('paymentForm.useBonuses')}
          </Typography>
        </Box>
      )}
      {withBonus ? (
        isUserBonusLoading || depositBonuses?.length === 0 ? (
          <TextField
            sx={{ mt: '5px' }}
            fullWidth
            value={
              isUserBonusLoading
                ? t('paymentForm.loading')
                : t('paymentForm.noBonuses')
            }
            InputProps={{
              readOnly: true,
              endAdornment: isUserBonusLoading ? (
                <CircularProgress size={20} />
              ) : undefined
            }}
          />
        ) : isOwnPromocode ? (
          <OwnPromocode
            value={ownEnteredPromocode}
            onChange={setOwnEnteredPromocode}
            onClickClose={() => {
              setIsOwnPromocode(false)
            }}
          />
        ) : (
          <PromocodeSelect
            promocode={promocode}
            setPromocode={setPromocode}
            onClickOwnPromocode={() => {
              setIsOwnPromocode(true)
            }}
          />
        )
      ) : null}
      <LoadingButton
        loading={activateGiftLoading}
        color="secondary"
        sx={{ borderRadius: '4px', mt: '12px' }}
        onClick={onConfirm}
        fullWidth
        disabled={!selectedPaymentMethod?.id}
      >
        {t(
          paymentModalOptions?.withdraw
            ? 'paymentForm.withdraw'
            : 'paymentForm.deposit'
        )}
      </LoadingButton>
    </Box>
  )
}
