import { createEffect, createStore } from 'effector'
import {
  cancelTransaction,
  createTransaction,
  getDepositPaymentSystems,
  getTransactions,
  getWithdrawPaymentSystems
} from '@/graphql/payments/payments.api'
import {
  type DepositPaymentSystem,
  type Transaction,
  type WithdrawPaymentSystem
} from '@/__generated__/graphql'

export const $transactions = createStore<Transaction[]>([])
export const $depositPaymentSystems = createStore<DepositPaymentSystem[]>([])
export const $withdrawPaymentSystems = createStore<WithdrawPaymentSystem[]>([])

export const createTransactionFx = createEffect(createTransaction)
export const getTransactionsFx = createEffect(getTransactions)
export const getDepositPaymentSystemsFx = createEffect(getDepositPaymentSystems)
export const getWithdrawPaymentSystemsFx = createEffect(
  getWithdrawPaymentSystems
)
export const cancelTransactionFx = createEffect(cancelTransaction)
