import { Button, type ButtonProps, CircularProgress } from '@mui/material'

export type LoadingButtonProps = ButtonProps & { loading?: boolean }

export const LoadingButton = ({
  loading,
  children,
  disabled,
  ...props
}: LoadingButtonProps) => (
  <Button
    {...props}
    startIcon={
      <CircularProgress
        size={30}
        sx={{ display: loading ? 'block' : 'none' }}
      />
    }
    disabled={disabled ?? loading}
  >
    {children}
  </Button>
)
