export const Dashboard = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.75 2.25V6.75H15.75V2.25H9.75ZM9.75 15.75H15.75V8.25H9.75V15.75ZM2.25 15.75H8.25V11.25H2.25V15.75ZM2.25 9.75H8.25V2.25H2.25V9.75Z"
        fill="#71767A"
      />
    </svg>
  )
}
