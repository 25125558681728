import { createEvent, createStore } from 'effector'

export const $isSuccessDepositModalOpen = createStore(false)

export const setIsSuccessDepositModalOpen = createEvent<boolean>()

$isSuccessDepositModalOpen.on(
  setIsSuccessDepositModalOpen,
  (_, payload) => payload
)
