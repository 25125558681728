import { sample } from 'effector'
import { $userAgent } from '@models/app/model'
import { UAParser } from 'ua-parser-js'
import {
  $filter,
  $filteredGames,
  changeFilter,
  filterGamesFx
} from '@models/search-modal/model'

$filter.on(changeFilter, (_, payload) => payload)

sample({
  clock: $filter,
  source: $userAgent,
  fn: (userAgent, params) => {
    const parser = new UAParser(userAgent)
    return {
      ...params,
      devices: parser.getDevice().type === 'mobile' ? 'MOBILE' : 'PC'
    }
  },
  target: filterGamesFx
})

sample({
  clock: filterGamesFx.doneData,
  source: { $filter, $filteredGames },
  fn: ({ $filter, $filteredGames }, games) => {
    if ($filter.offset === 0) return games
    return [...$filteredGames, ...games]
  },
  target: $filteredGames
})
