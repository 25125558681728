import { RegistrationModal } from '@components/modals/registration-modal'
import { LoginModal } from '@components/modals/login-modal'
import { SearchGamesModal } from '@components/modals/search-games-modal'
import { PaymentModal } from '@components/modals/payment-modal'
import { ForgotPassword } from '@components/modals/forgot-password'
import { NewPassword } from '@components/modals/new-password'
import { SuccessDepositModal } from '@components/modals/success-deposit-modal'

export const Modals = () => {
  return (
    <>
      <RegistrationModal />
      <LoginModal />
      <SearchGamesModal />
      <PaymentModal />
      <ForgotPassword />
      <NewPassword />
      <SuccessDepositModal />
    </>
  )
}
