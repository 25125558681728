export const Logo = () => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0001 32.2106C25.4006 32.2106 32.2106 25.4006 32.2106 17.0001C32.2106 8.59953 25.4006 1.78955 17.0001 1.78955C8.59953 1.78955 1.78955 8.59953 1.78955 17.0001C1.78955 25.4006 8.59953 32.2106 17.0001 32.2106Z"
        fill="#95FE2C"
      />
      <path
        d="M17.0002 26.8423C16.1338 26.8423 15.2748 26.7289 14.4457 26.5067C12.7845 26.0624 11.2589 25.1796 10.0346 23.9538C8.81034 22.728 7.92902 21.201 7.48762 19.5382L10.9473 18.6196C11.2276 19.6739 11.7868 20.6447 12.5667 21.4246C13.3467 22.2046 14.316 22.7667 15.3717 23.0501C16.426 23.3334 17.5713 23.3334 18.6137 23.0531C19.6724 22.7727 20.6417 22.212 21.4231 21.4321C21.7736 21.0832 22.0793 20.6954 22.3358 20.2779L7.02832 16.1889L7.48762 14.4621C7.932 12.7919 8.8163 11.2619 10.0451 10.0346C11.2709 8.81034 12.7979 7.93052 14.4591 7.48911C16.098 7.0492 17.8949 7.04771 19.5517 7.49359C21.2129 7.93797 22.7384 8.82078 23.9627 10.0466C25.187 11.2724 26.0683 12.7994 26.5097 14.4621L23.0501 15.3807C22.7697 14.3264 22.2105 13.3556 21.4306 12.5757C20.6507 11.7958 19.6814 11.2336 18.6256 10.9503C17.5713 10.6669 16.426 10.6669 15.3837 10.9473C14.3249 11.2276 13.3556 11.7883 12.5742 12.5682C12.2238 12.9172 11.9181 13.3049 11.6616 13.7224L26.969 17.8114L26.5097 19.5382C26.0653 21.2084 25.181 22.7384 23.9523 23.9657C22.7265 25.19 21.1995 26.0698 19.5382 26.5112C18.7195 26.7304 17.8636 26.8423 17.0002 26.8423Z"
        fill="#1A1C1F"
      />
    </svg>
  )
}
