import { Box, Button, Typography } from '@mui/material'
import { type MouseEventHandler, type ReactNode } from 'react'

export const CategoryTitle = ({
  icon,
  title,
  onClickMore,
  extraButtons,
  buttonText
}: {
  icon: ReactNode
  title: string
  onClickMore: MouseEventHandler
  extraButtons?: ReactNode
  buttonText?: string
}): ReactNode => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: '12px' }}>
      {icon}
      <Typography sx={{ ml: '5px', textTransform: 'uppercase' }}>
        {title}
      </Typography>
      <Button
        onClick={onClickMore}
        sx={{
          borderRadius: '4px',
          py: 0,
          height: '28px',
          ml: '10px'
        }}
      >
        {buttonText}
      </Button>
      {extraButtons}
    </Box>
  )
}
