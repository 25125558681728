import { type ReactNode } from 'react'
import { Box, type SxProps } from '@mui/material'

interface TabPanelProps {
  children?: ReactNode
  currentValue: string
  value: string
  sx?: SxProps
}

export const TabPanel = (props: TabPanelProps): ReactNode => {
  const { children, value, currentValue, sx } = props

  return (
    <Box role="tabpanel" hidden={value !== currentValue} sx={sx}>
      {value === currentValue && children}
    </Box>
  )
}
