import { createBreakpoints } from '@mui/system'

export const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 480,
    md: 675,
    lmd: 976,
    lg: 1367,
    xl: 1700,
    xxl: 1920
  }
})
