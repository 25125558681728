import { Box, Typography } from '@mui/material'
import {
  type CreateTransactionNewDto,
  type DepositPaymentSystem,
  type WithdrawPaymentSystem
} from '@/__generated__/graphql'
import { useTheme } from '@mui/material/styles'
import { type UseFormSetValue } from 'react-hook-form'

export const PaymentCard = ({
  paymentSystemId,
  payment,
  setValue
}: {
  payment: WithdrawPaymentSystem | DepositPaymentSystem
  paymentSystemId: number
  setValue: UseFormSetValue<CreateTransactionNewDto>
}) => {
  const theme = useTheme()
  return (
    <Box key={payment.id}>
      <Box
        sx={{
          width: '110px',
          height: '53px',
          cursor: 'pointer',
          borderRadius: '4px',
          p: '5px',
          background: '#fff',
          filter:
            Number(paymentSystemId) === payment.id ? 'brightness(0.7)' : ''
        }}
        onClick={() => {
          setValue('paymentSystemId', payment.id)
        }}
      >
        <Box
          sx={{
            height: '40px',
            backgroundImage: `url(${payment?.icons?.[0]?.icon})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            color: 'black'
          }}
        />
      </Box>
      <Typography
        sx={{
          fontSize: '12px',
          textAlign: 'center',
          color: theme.colors.text3
        }}
      >
        {payment.name}
      </Typography>
    </Box>
  )
}
