import { Box, Button, Menu, MenuItem } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { useTheme } from '@mui/material/styles'
import { NAV_LINKS } from './config'
import { DESKTOP_LAYOUT_BREAKPOINT } from '@/config/common'
import { useState } from 'react'
import { useRouter } from 'next/router'

export const NavigationMobile = () => {
  const { t } = useTranslation('common')
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [menuItems, setMenuItems] = useState<
    Array<{ id: string; title: string; link: string }>
  >([])
  const router = useRouter()

  return (
    <Box
      component="nav"
      sx={{
        mt: '12px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        [theme.breakpoints.up(DESKTOP_LAYOUT_BREAKPOINT)]: {
          display: 'none'
        }
      }}
    >
      {NAV_LINKS.map(({ id, title, links }) => (
        <Button
          sx={{ borderRadius: '4px' }}
          key={id}
          fullWidth
          onClick={(event) => {
            setAnchorEl(event.currentTarget)
            setMenuItems(links)
          }}
        >
          {t(`footer.${title}`)}
        </Button>
      ))}
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null)
          setMenuItems([])
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        disableScrollLock={true}
        slotProps={{
          paper: {
            sx: {
              maxHeight: '250px',
              overflowY: 'auto',
              width: 'calc(100% - 55px)',
              borderRadius: '4px'
            }
          }
        }}
      >
        {menuItems.map(({ title, link, id }) => (
          <MenuItem
            sx={{ display: 'flex', justifyContent: 'center' }}
            key={id}
            onClick={async () => await router.push(link)}
          >
            {t(`footer.${title}`)}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}
