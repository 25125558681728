import {
  Box,
  Button,
  Dialog,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import { useUnit } from 'effector-react'
import {
  $isRegisterModalOpened,
  closeRegisterModal
} from '@components/modals/registration-modal/model'
import { TitleWithBackAndClose } from '@components/modals/title-with-back-and-close'
import { Trans, useTranslation } from 'next-i18next'
import Image from 'next/image'
import registerBanner from '@assets/images/register_banner.jpg'
import { useTheme } from '@mui/material/styles'
import { Cross } from '@assets/icons/svg/cross'
import { useForm, useWatch } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import Joi from 'joi'
import { PASSWORD_REGEX, REF_CAMP_KEY, REFERAL_ID_KEY } from '@/config/common'

import React, { useState } from 'react'
import { $currencies, $languages, signUpFx } from '@models/user/model'
import { notify } from '@/config/notify'
import { LoadingButton } from '@components/loading-button'
import { openLoginModal } from '@components/modals/login-modal/model'
import { type TFunction } from 'i18next'
import { type RegistrationDto } from '@/__generated__/graphql'
import { Eye } from '@assets/icons/svg/eye'
import Link from 'next/link'

export const getPasswordValidation = (t: TFunction) =>
  Joi.string()
    .required()
    .pattern(PASSWORD_REGEX)
    .min(8)
    .max(50)
    .messages({
      'string.empty': t('auth.passwordRequired'),
      'string.pattern.base': t('auth.incorrectPassword'),
      'string.max': t('auth.passwordMaxLength'),
      'string.min': t('auth.passwordMinLength')
    })

export const RegistrationModal = () => {
  const theme = useTheme()
  const { t, i18n } = useTranslation('common')
  const open = useUnit($isRegisterModalOpened)
  const closeRegisterModalFunction = useUnit(closeRegisterModal)
  const currencies = useUnit($currencies)
  const languages = useUnit($languages)
  const openLoginModalFunction = useUnit(openLoginModal)
  const signUpFxFunction = useUnit(signUpFx)
  const [isAgeConfirmed, setIsAgeConfirmed] = useState(false)

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting }
  } = useForm<RegistrationDto>({
    defaultValues: {
      email: '',
      password: '',
      currencyId:
        currencies.find((currency) => currency.name === 'USD')?.id ?? 2,
      languageId:
        languages.find((lang) => lang.code === i18n.language)?.id ?? 3,
      emailSubscribed: true
    },
    resolver: joiResolver(
      Joi.object({
        email: Joi.string()
          .email({ tlds: { allow: false } })
          .required()
          .messages({
            'string.email': t('auth.incorrectEmail'),
            'string.empty': t('auth.emailRequired')
          }),
        password: getPasswordValidation(t),
        currencyId: Joi.number().required(),
        languageId: Joi.number(),
        emailSubscribed: Joi.boolean()
      })
    )
  })

  const onClickLogin = () => {
    closeRegisterModalFunction()
    openLoginModalFunction()
  }

  const onSubmit = async (data: RegistrationDto) => {
    const refCamp = localStorage.getItem(REF_CAMP_KEY)
    const referalId = localStorage.getItem(REFERAL_ID_KEY)

    try {
      await signUpFxFunction({
        ...data,
        ...(refCamp ? { refCamp } : {}),
        ...(referalId ? { referalId } : {})
      })
      notify.info({
        description: t('verifyEmail')
      })
      closeRegisterModalFunction()
    } catch (e) {
      if (e instanceof Error) {
        notify.error({ description: e.message })
      }
      console.log('Register error:', e)
    }
  }

  const currency = useWatch({ name: 'currencyId', control })
  const emailSubscribed = useWatch({ name: 'emailSubscribed', control })

  const [isShowPassword, setIsShowPassword] = useState(false)

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          minWidth: '375px'
        },
        component: 'form',
        onSubmit: handleSubmit(onSubmit)
      }}
    >
      <TitleWithBackAndClose
        title={t('registration')}
        onClickBack={closeRegisterModalFunction}
        onClickClose={closeRegisterModalFunction}
      />
      <Box
        sx={{
          mt: '12px',
          border: `1px solid ${theme.colors.color250}`,
          borderRadius: '4px',
          overflow: 'hidden',
          minHeight: '198px'
        }}
      >
        <Box sx={{ position: 'relative', height: '125px' }}>
          <Image src={registerBanner} alt="Promo" fill />
        </Box>
        <Box
          sx={{
            p: '13px',
            textAlign: 'center',
            fontSize: '16px',
            fontWeight: 700,
            maxWidth: '200px',
            margin: 'auto'
          }}
        >
          {t('registerPromoText')}
        </Box>
      </Box>
      <Typography sx={{ fontWeight: 400, fontSize: '14px', mt: '12px' }}>
        {t('email')}
      </Typography>
      <TextField
        {...register('email')}
        sx={{
          '& .MuiInputBase-root': {
            pr: 0
          }
        }}
        error={!!errors.email}
        InputProps={{
          endAdornment: (
            <Box
              sx={{
                position: 'absolute',
                right: '4px',
                cursor: 'pointer',
                width: '32px',
                height: '32px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              onClick={() => {
                setValue('email', '')
              }}
            >
              <Cross />
            </Box>
          )
        }}
      />
      {errors.email?.message && (
        <Typography
          sx={{
            fontSize: '12px',
            lineHeight: 1,
            color: theme.palette.error.main
          }}
        >
          {errors.email?.message}
        </Typography>
      )}
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: '14px',
          mt: errors.email ? 0 : '12px'
        }}
      >
        {t('password')}
      </Typography>
      <TextField
        {...register('password')}
        error={!!errors.password}
        sx={{
          '& .MuiInputBase-root': {
            pr: 0
          }
        }}
        type={isShowPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <Box
              sx={{
                position: 'absolute',
                right: '5px',
                cursor: 'pointer',
                width: '32px',
                height: '32px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              onClick={() => {
                setIsShowPassword(!isShowPassword)
              }}
            >
              <Eye />
            </Box>
          )
        }}
      />
      {errors.password?.message && (
        <Typography
          sx={{
            fontSize: '12px',
            lineHeight: 1,
            color: theme.palette.error.main
          }}
        >
          {errors.password?.message}
        </Typography>
      )}
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: '14px',
          mt: errors.password?.message ? 0 : '12px'
        }}
      >
        {t('currency')}
      </Typography>
      <Select
        {...register('currencyId')}
        onChange={(e) => {
          setValue('currencyId', Number(e.target.value))
        }}
        value={currency}
        error={!!errors.currencyId}
      >
        {currencies.map((currency) => (
          <Button
            component={MenuItem}
            sx={{
              width: 'calc(100% - 8px)',
              borderRadius: '4px',
              mx: '4px',
              mt: '4px',
              '&:last-child': {
                mb: '4px'
              }
            }}
            value={currency.id}
            key={`${currency.id}_currency`}
          >
            {currency.name}
          </Button>
        ))}
      </Select>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: '5px' }}>
        <Switch
          {...register('emailSubscribed')}
          checked={!!emailSubscribed}
          onChange={(event) => {
            setValue('emailSubscribed', event.target.checked)
          }}
        />
        <Typography
          fontSize="14px"
          sx={{
            ml: '10px',
            mt: '4px'
          }}
        >
          {t('auth.getPromo')}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Switch
          checked={isAgeConfirmed}
          onChange={(event) => {
            setIsAgeConfirmed(event.target.checked)
          }}
        />
        <Typography
          fontSize="14px"
          lineHeight={1}
          sx={{
            ml: '10px',
            mt: '4px'
          }}
        >
          <Trans
            i18nKey="auth.ageApproval"
            ns="common"
            components={{
              1: (
                <Link
                  href="/terms"
                  onClick={() => {
                    closeRegisterModalFunction()
                  }}
                />
              )
            }}
          />
        </Typography>
      </Box>
      <LoadingButton
        color={'secondary'}
        sx={{ borderRadius: '4px', mt: '12px' }}
        onClick={handleSubmit(onSubmit)}
        loading={isSubmitting}
        disabled={!isAgeConfirmed}
      >
        {t('signUp')}
      </LoadingButton>
      <Box
        sx={{ display: 'flex', alignItems: 'center', gap: '5px', pt: '12px' }}
      >
        <Typography>{t('alreadyHaveAnAccount')} </Typography>
        <Button
          variant="text"
          onClick={onClickLogin}
          sx={{
            color: theme.colors.primary500,
            textTransform: 'none',
            height: '19px',
            p: 0,
            fontSize: '16px'
          }}
        >
          {t('logIn')}
        </Button>
      </Box>
    </Dialog>
  )
}
