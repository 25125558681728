import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { Cross } from '@assets/icons/svg/cross'
import { useTranslation } from 'next-i18next'
import { type PromocodeConfig } from '@/__generated__/graphql'
import { useTheme } from '@mui/material/styles'
import { useUnit } from 'effector-react'
import { $depositGifts } from '@models/bonuses/model'

interface PromocodeSelectProps {
  promocode: PromocodeConfig | null
  setPromocode: (promocode: PromocodeConfig | null) => void
  onClickOwnPromocode: () => void
}

export const PromocodeSelect = ({
  promocode,
  setPromocode,
  onClickOwnPromocode
}: PromocodeSelectProps) => {
  const { t } = useTranslation('common')
  const theme = useTheme()

  const depositBonuses = useUnit($depositGifts)

  return (
    <FormControl
      fullWidth
      sx={{
        '& .MuiInputBase-root': {
          pr: 0
        }
      }}
    >
      {!promocode && <InputLabel>{t('paymentForm.chooseBonus')}</InputLabel>}
      <Select
        defaultOpen={!promocode}
        endAdornment={
          <Box
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              right: '35px',
              zIndex: 1
            }}
            onClick={() => {
              setPromocode(null)
            }}
          >
            <Cross />
          </Box>
        }
        sx={{ mt: '5px' }}
        label={promocode ? undefined : t('paymentForm.chooseBonus')}
        value={promocode?.id ?? 0}
        MenuProps={{
          sx: {
            zIndex: 6000,
            '.MuiPaper-root': {
              borderRadius: '4px',
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              border: `1px solid ${theme.colors.color300}`,
              borderTop: 'none'
            }
          }
        }}
      >
        {depositBonuses?.map((bonus) => {
          return (
            <MenuItem
              key={bonus.promocode?.id}
              value={bonus.promocode?.id}
              onClick={() => {
                if (bonus.promocode) {
                  setPromocode(bonus.promocode)
                }
              }}
            >
              {bonus.name ?? bonus.promocode?.name ?? bonus.id}
            </MenuItem>
          )
        })}
        <MenuItem value={'own_promocode_option'} onClick={onClickOwnPromocode}>
          {t('paymentForm.selectToEnterPromocode')}
        </MenuItem>
      </Select>
    </FormControl>
  )
}
