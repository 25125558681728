import { Container } from '@/layouts/container'
import { Box, useTheme } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation, Pagination, EffectFade } from 'swiper/modules'
import { useRef, type ReactNode, useState, useEffect } from 'react'
import { Slide } from '@/content/home/slider/slide'
import { Arrow } from '@assets/icons/svg/arrow'

export const Slider = (): ReactNode => {
  const theme = useTheme()
  const moreThanOneSlide = true
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [buttonTop, setButtonTop] = useState('0')

  useEffect(() => {
    setButtonTop(`${(wrapperRef?.current?.offsetHeight ?? 0) / 2 - 25}px`)
  }, [])

  return (
    <Container sx={{ pt: '12px', pb: '25px' }}>
      <Box
        ref={wrapperRef}
        sx={{
          position: 'relative',
          '&:hover': {
            '.promo-banner-slider-next-button, .promo-banner-slider-prev-button':
              {
                opacity: 1
              }
          },
          '.promo-banner-slider': {
            '&:has(.swiper-slide)': {
              minHeight: 'initial'
            }
          }
        }}
      >
        <Swiper
          className="promo-banner-slider"
          watchOverflow
          simulateTouch
          effect="fade"
          modules={[Autoplay, Pagination, Navigation, EffectFade]}
          loop={moreThanOneSlide}
          {...(moreThanOneSlide
            ? {
                navigation: {
                  prevEl: '.promo-banner-slider-prev-button',
                  nextEl: '.promo-banner-slider-next-button'
                },
                pagination: {
                  el: '.promo-banner-slider__pagination',
                  clickable: true
                },
                autoplay: {
                  delay: 5000,
                  disableOnInteraction: true
                }
              }
            : {})}
        >
          {[1].map((key) => (
            <SwiperSlide key={key}>
              <Slide />
            </SwiperSlide>
          ))}
        </Swiper>
        <Box
          className="promo-banner-slider__pagination"
          sx={{
            zIndex: 1,
            display: 'flex',
            gap: '5px',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            position: 'absolute',
            bottom: '-22px',
            transition: 'opacity 0.4s ease-in-out',
            '& .swiper-pagination-bullet': {
              background: theme.colors.color300,
              cursor: 'pointer',
              width: '100%',
              height: '4px',
              borderRadius: '12px',
              opacity: 1,
              '&.swiper-pagination-bullet-active': {
                background: theme.colors.color200,
                boxShadow: 'none',
                height: '6px'
              }
            }
          }}
        />
        <Box
          className="promo-banner-slider-prev-button"
          sx={{
            cursor: 'pointer',
            opacity: 0,
            transition: 'opacity 0.4s ease-in-out',
            background: theme.colors.color1000,
            borderRadius: '25px',
            width: '50px',
            height: '50px',
            position: 'absolute',
            zIndex: 1,
            top: buttonTop,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0
          }}
        >
          <Box
            sx={{
              width: '32px',
              height: '32px',
              borderRadius: '50%',
              background: theme.colors.color300,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Arrow rotate="180deg" />
          </Box>
        </Box>
        <Box
          className="promo-banner-slider-next-button"
          sx={{
            cursor: 'pointer',
            opacity: 0,
            transition: 'opacity 0.4s ease-in-out',
            background: theme.colors.color1000,
            borderRadius: '25px',
            width: '50px',
            height: '50px',
            position: 'absolute',
            zIndex: 1,
            top: buttonTop,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
          }}
        >
          <Box
            sx={{
              width: '32px',
              height: '32px',
              borderRadius: '50%',
              background: theme.colors.color300,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Arrow />
          </Box>
        </Box>
      </Box>
    </Container>
  )
}
