export const AccountDetails = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 9.5C11 11.16 9.66 12.5 8 12.5C6.34 12.5 5 11.16 5 9.5C5 7.84 6.34 6.5 8 6.5C9.66 6.5 11 7.84 11 9.5ZM14 20.5H2V18.5C2 16.29 4.69 14.5 8 14.5C11.31 14.5 14 16.29 14 18.5V20.5ZM22 12.5V14.5H13V12.5H22ZM22 8.5V10.5H13V8.5H22ZM22 4.5V6.5H13V4.5H22Z"
        fill="#71767A"
      />
    </svg>
  )
}
