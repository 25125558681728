import { createEvent, createStore } from 'effector'

interface PaymentModalOptions {
  withdraw?: boolean
}

export const $paymentModalOptions = createStore<PaymentModalOptions | null>(
  null
)

export const openPaymentModal = createEvent<PaymentModalOptions>()
export const closePaymentModal = createEvent()

$paymentModalOptions
  .on(openPaymentModal, (_, value) => value)
  .reset(closePaymentModal)
