export const coloristics = {
  colors: {
    color000: '#FFFFFF',
    color100: '#EBEEF5',
    color150: '#BCC1CC',
    color200: '#71767A',
    color250: '#454952',
    color300: '#2B2E33',
    color350: '#222629',
    color400: '#1A1C1F',
    color500: '#121314',
    color1000: '#0D1014',
    text1: '#FFFFFF',
    text2: '#C4C8CC',
    text3: '#7A7E85',
    text4: '#484D52',
    text5: '#0D1014',
    new: '#882CFE',
    hot: '#FE2C78',
    yellow: '#FFC700',
    blue: '#167BFF',
    error: '#FF4D21',
    link: '#95FE2C',
    primary400: '#B7FE60',
    primary500: '#95FE2C',
    primary600: '#56B616'
  },
  gradient: {
    green: 'linear-gradient(0deg, #5fdd23 0%, #dcfd3e 100%)',
    red: 'linear-gradient(0deg, #FF8C21 0%, #FF4D21 100%)',
    grey: 'linear-gradient(180deg, #454952 0%, #BCC1CC 100%)',
    yellow: 'linear-gradient(0deg, #FFC700 0%, #FFEE33 100%)'
  },
  palette: {
    common: {
      black: '#0D1014',
      white: '#FFFFFF'
    },
    primary: {
      light: '#454952',
      main: '#2B2E33',
      dark: '#222629'
    },
    secondary: {
      light: '#B7FE60',
      main: '#95FE2C',
      dark: '#56B616'
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#7A7E85',
      disabled: '#0D1014'
    }
  }
}
