import { type ReactNode } from 'react'

export const Bullhorn = ({ selected }: { selected?: boolean }): ReactNode => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="bullhorn-variant">
        <path
          id="Vector"
          d="M15.5 2V3.5L3.5 6.5V5H2V14H3.5V12.5L5 12.875V14.375C5 15.8 6.2 17 7.625 17C9.05 17 10.25 15.8 10.25 14.375V14.225L15.5 15.5V17H17V2H15.5ZM8.75 14.375C8.75 14.975 8.225 15.5 7.625 15.5C7.025 15.5 6.5 14.975 6.5 14.375V13.25L8.75 13.85V14.375Z"
          fill={selected ? '#95FE2C' : '#71767A'}
        />
      </g>
    </svg>
  )
}
