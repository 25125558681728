import { Box, Button, Typography } from '@mui/material'
import { Container } from '@/layouts/container'
import { useTheme } from '@mui/material/styles'
import { Triangle } from '@assets/icons/svg/triangle'
import { type ReactNode, useState } from 'react'

export const WelcomeText = (): ReactNode => {
  const theme = useTheme()
  const [isTextExtended, setIsTextExtended] = useState(false)

  return (
    <Container
      sx={{
        mt: '24px'
      }}
    >
      <Box
        sx={{
          overflow: 'hidden',
          ...(!isTextExtended && {
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '8',
            lineClamp: '8',
            WebkitBoxOrient: 'vertical'
          })
        }}
      >
        <Typography color={theme.colors.text3} fontSize="20px" fontWeight={700}>
          Welcome to the Best Online Casino - Spin Green
        </Typography>
        <Typography color={theme.colors.text3} fontSize="16px" fontWeight={400}>
          To start playing for real money at Spin Green Casino, you’ll need to
          register an account – a quick process, taking only a few minutes. The
          Online Casino Registration is available via email on the official
          website. Users fill out a form entering a valid email, password, and
          account currency. After agreeing to the terms and conditions, complete
          the process by clicking the &quot;Register&quot; button.
        </Typography>
        <Typography
          color={theme.colors.text3}
          fontSize="20px"
          fontWeight={700}
          mt="10px"
        >
          Licensed slot machines at Spin Green Casino
        </Typography>
        <Typography
          color={theme.colors.text3}
          fontSize="16px"
          fontWeight={400}
          mt="10px"
        >
          The Spin Green Casino boasts a diverse collection of original slot
          machines developed by licensed gaming software providers. Slots, being
          the most popular and extensive category of gambling entertainment,
          offer several thousand game variants with distinctions in
          functionality, genres, themes, and the potential to win bonuses or hit
          the jackpot. Players can expect fair and secure gameplay thanks to the
          Casino only being partnered with trusted suppliers and the inclusion
          of original slot machines. When users open a slot, they need not
          concern themselves with the safety of their data or funds.
          Additionally, the Live section allows visitors to immerse themselves
          in the atmosphere of an offline casino and enjoy popular games
          reminiscent of land-based establishments.
        </Typography>
        <Typography
          color={theme.colors.text3}
          fontSize="20px"
          fontWeight={700}
          mt="10px"
        >
          Spin Green – a safe online casino
        </Typography>
        <Typography color={theme.colors.text3} fontSize="16px" fontWeight={400}>
          Spin Green Casino is a safe online gambling project launched by the
          WoT NV company that’s registered in Curacao. Gambling fun available
          24/7 on both desktop and mobile devices.
        </Typography>
        <Typography
          color={theme.colors.text3}
          fontSize="20px"
          fontWeight={700}
          mt="10px"
        >
          Spin Green Casino mobile version
        </Typography>
        <Typography color={theme.colors.text3} fontSize="16px" fontWeight={400}>
          Ensuring a seamless user experience is a top priority for the Casino
          Spin Green administration. The gaming platform is accessible on all
          devices, including PCs for the desktop version and the Mobile Casino
          for smartphones, regardless of the OS. The Mobile Casino Registration
          process mirrors the web version, requiring users to provide a valid
          email address, select a currency, and come up with a password. If an
          account already exists, there&apos;s no need to re-register when
          logging in from mobile devices. Players logging in from smartphones
          enjoy full access to their personal accounts and all its features. In
          the mobile version, users can deposit funds, claim bonuses, place
          real-money bets, and launch slot machines in test mode. The mobile
          casino offers a streamlined experience, ensuring shorter loading
          times.
        </Typography>
        <Typography
          color={theme.colors.text3}
          fontSize="20px"
          fontWeight={700}
          mt="10px"
        >
          Spin Green Casino customer support
        </Typography>
        <Typography color={theme.colors.text3} fontSize="16px" fontWeight={400}>
          Online Casino Spin Green ensures that users can address any queries
          with ease, thanks to the prompt assistance of professional technical
          support managers. For quick answers, visitors can reach out via
          support@spingreen.casino or use the online chat on the project’s
          website. Additionally, users have the option to contact customer
          support to set daily limits for themselves, preventing impulsive
          decisions. You can also use the “Self-Limit” feature, which allows
          players to restrict account access for a specified period or
          indefinitely. It&apos;s crucial to carefully consider the decision,
          since a temporary block will automatically expire after the set
          period, whereas a permanent block includes no way to bypass this
          restriction.
        </Typography>
      </Box>
      <Button
        onClick={() => {
          setIsTextExtended((prev) => !prev)
        }}
        sx={{
          background: theme.colors.color350,
          borderRadius: '20px',
          height: '20px',
          mt: '12px'
        }}
        fullWidth
      >
        <Triangle />
      </Button>
    </Container>
  )
}
