import { createEffect, createStore } from 'effector'
import {
  getTournament,
  getTournaments
} from '@/graphql/tournaments/tournaments.api'
import { type TournamentInstance } from '@/__generated__/graphql'

export const $tournaments = createStore<TournamentInstance[]>([])
export const $tournament = createStore<TournamentInstance | null>(null)

export const getTournamentsFx = createEffect(getTournaments)
export const getTournamentFx = createEffect(getTournament)
