import { LinearProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { Router } from 'next/router'

export const RouterLoader = () => {
  const [pageLoading, setPageLoading] = useState(false)

  useEffect(() => {
    const start = () => {
      setPageLoading(true)
    }
    const end = () => {
      setPageLoading(false)
    }
    Router.events.on('routeChangeStart', start)
    Router.events.on('routeChangeComplete', end)
    Router.events.on('routeChangeError', end)
    return () => {
      Router.events.off('routeChangeStart', start)
      Router.events.off('routeChangeComplete', end)
      Router.events.off('routeChangeError', end)
    }
  }, [])

  return pageLoading ? (
    <LinearProgress
      color="primary"
      sx={{
        position: 'fixed',
        width: '100%',
        height: '3px',
        top: 0,
        zIndex: 10000,
        borderRadius: '32px'
      }}
    />
  ) : null
}
