import { type ReactNode } from 'react'

export const Magnify = ({ selected }: { selected: boolean }): ReactNode => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="magnify">
        <path
          id="Vector"
          d="M7.125 2.75C8.41793 2.75 9.65791 3.26361 10.5721 4.17785C11.4864 5.09209 12 6.33207 12 7.625C12 8.8325 11.5575 9.9425 10.83 10.7975L11.0325 11H11.625L15.375 14.75L14.25 15.875L10.5 12.125V11.5325L10.2975 11.33C9.4425 12.0575 8.3325 12.5 7.125 12.5C5.83207 12.5 4.59209 11.9864 3.67785 11.0721C2.76361 10.1579 2.25 8.91793 2.25 7.625C2.25 6.33207 2.76361 5.09209 3.67785 4.17785C4.59209 3.26361 5.83207 2.75 7.125 2.75ZM7.125 4.25C5.25 4.25 3.75 5.75 3.75 7.625C3.75 9.5 5.25 11 7.125 11C9 11 10.5 9.5 10.5 7.625C10.5 5.75 9 4.25 7.125 4.25Z"
          fill={selected ? '#95FE2C' : '#71767A'}
        />
      </g>
    </svg>
  )
}
