// noinspection GraphQLUnresolvedReference

import { subscribe } from '@/graphql/base-api'
import { type FetchResult, gql, type Observable } from '@apollo/client'

export interface SocketChangeMessage {
  userId: number
  value: number
  type: string
  updateInitiator: string
}

export interface TransactionCompletedMessage {
  amountInUserCurrency: number
  status: string
  type: string
  userId: number
  withdrawableAmount: number
}

export const getObserverBalanceChange = async (): Promise<
  Observable<FetchResult<{ balanceChanged: SocketChangeMessage }>>
> => {
  return await subscribe({
    query: gql`
      subscription BalanceChanged {
        balanceChanged {
          userId
          value
          type
          updateInitiator
        }
      }
    `
  })
}

export const getTransactionCompletedObserver = async (): Promise<
  Observable<FetchResult<{ transactionCompleted: TransactionCompletedMessage }>>
> => {
  return await subscribe({
    query: gql`
      subscription TransactionCompleted {
        transactionCompleted {
          amountInUserCurrency
          status
          type
          userId
          withdrawableAmount
        }
      }
    `
  })
}
