export const Rocket = ({ color }: { color?: string }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.4943 7.46004C28.0744 8.15138 30.3267 9.39104 32.102 10.9706C31.0859 8.38675 28.8996 6.30194 26.0119 5.52818C23.1241 4.75441 20.1883 5.46677 18.0164 7.19644C20.3437 6.71612 22.9141 6.76868 25.4943 7.46004ZM16.0336 15.721L15.6627 17.1051C15.3546 18.2549 15.2462 19.4473 15.3406 20.6306C12.7385 20.4547 10.416 22.3214 10.0546 24.9346L9.62312 28.0541L17.1609 26.968L22.9569 28.5211L27.3061 31.9434L28.9418 33.2305L29.7257 31.3024L30.1278 30.3131C31.1214 27.8694 30.0434 25.0915 27.7019 23.9428C28.3753 22.9652 28.8776 21.8783 29.1857 20.7286L29.5566 19.3444C30.5413 15.6693 28.9705 11.7866 25.7073 9.83014C25.2296 9.54374 24.6812 9.39681 24.1243 9.406C20.3201 9.46877 17.0183 12.0458 16.0336 15.721ZM24.9158 27.5175L26.4456 25.5734L26.6745 25.6712C28.1793 26.3139 28.8914 28.044 28.2751 29.5599L28.09 30.0153L24.9158 27.5175ZM15.3842 31.6683L16.6436 28.8999L22.4392 30.4528L22.1457 33.48L20.4726 31.9964L17.9885 35.4719L17.5748 31.22L15.3842 31.6683ZM20.3618 16.9552C20.7192 15.6215 22.09 14.83 23.4237 15.1874C24.7573 15.5448 25.5488 16.9156 25.1914 18.2493C24.8341 19.5829 23.4632 20.3744 22.1296 20.017C20.7959 19.6597 20.0044 18.2888 20.3618 16.9552ZM11.3065 10.4108L9.33549 8.16467L8.7516 11.0954L6.00635 12.2758L8.61317 13.7368L8.88752 16.7124L11.0825 14.6847L13.9973 15.3433L12.7471 12.6291L14.2742 10.0605L11.3065 10.4108Z"
        fill={color ?? '#71767A'}
      />
    </svg>
  )
}
