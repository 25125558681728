export const Eye = (props: { onClick?: () => void }) => {
  return (
    <svg
      onClick={props.onClick}
      style={{ cursor: 'pointer' }}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 10C12.1139 10 8.74551 12.4384 7.09033 16.0001C8.74554 19.5617 12.1139 22 15.9999 22C19.886 22 23.2544 19.5616 24.9096 15.9999C23.2544 12.4383 19.886 10 16 10ZM16 19C17.6569 19 19 17.6569 19 16C19 14.3431 17.6569 13 16 13C14.3431 13 13 14.3431 13 16C13 17.6569 14.3431 19 16 19Z"
        fill="#71767A"
      />
    </svg>
  )
}
