import { type ReactNode } from 'react'

export const SlotMachine = (): ReactNode => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.7998 16L11.7998 12V11H8.7998V12H10.7998L8.7998 16H9.7998ZM13.7998 16L15.7998 12V11H12.7998V12H14.7998L12.7998 16H13.7998ZM17.7998 16L19.7998 12V11H16.7998V12H18.7998L16.7998 16H17.7998ZM25.7998 6C24.6998 6 23.7998 6.9 23.7998 8C23.7998 8.7 24.1998 9.4 24.7998 9.7V21H21.7998V19C22.3998 19 22.7998 18.6 22.7998 18V9C22.7998 8.4 22.3998 8 21.7998 8H17.9998C17.1998 6.8 15.7998 6 14.2998 6C12.7998 6 11.3998 6.8 10.5998 8H6.7998C6.1998 8 5.7998 8.4 5.7998 9V18C5.7998 18.6 6.1998 19 6.7998 19V26H21.7998V23H24.7998C25.8998 23 26.7998 22.1 26.7998 21V9.7C27.3998 9.4 27.7998 8.7 27.7998 8C27.7998 6.9 26.8998 6 25.7998 6ZM17.7998 23H10.7998V21H17.7998V23ZM20.7998 17H7.7998V10H20.7998V17Z"
        fill="#95FE2C"
      />
    </svg>
  )
}
