import { Box, IconButton, Typography } from '@mui/material'
import { Arrow } from '@assets/icons/svg/arrow'
import { Cross } from '@assets/icons/svg/cross'
import { useTheme } from '@mui/material/styles'
import { type MouseEventHandler } from 'react'

interface TitleWithBackAndCloseProps {
  title: string
  onClickBack?: MouseEventHandler
  onClickClose: MouseEventHandler
}

export const TitleWithBackAndClose = ({
  title,
  onClickClose,
  onClickBack
}: TitleWithBackAndCloseProps) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '8px',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      {onClickBack && (
        <IconButton
          sx={{ width: '32px', height: '32px', pr: '11px' }}
          onClick={onClickBack}
        >
          <Arrow rotate="180deg" color={theme.colors.color200} />
        </IconButton>
      )}
      <Typography
        sx={{ textTransform: 'uppercase', width: '100%', fontWeight: 500 }}
      >
        {title}
      </Typography>
      <IconButton sx={{ width: '32px', height: '32px' }} onClick={onClickClose}>
        <Cross />
      </IconButton>
    </Box>
  )
}
