import { Box, Typography, useTheme } from '@mui/material'
import { Container } from '@/layouts/container'
import Link from 'next/link'

export const License = () => {
  const theme = useTheme()

  return (
    <Container
      sx={{
        py: '12px'
      }}
    >
      <a
        href="https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJa2xCWWtsc1ZqTlBWR2RFVVVKNFNYRjJUVk5sTTNjOVBTSXNJblpoYkhWbElqb2libVJpWW5oU05XNDRVR0pDYzJkbFYzZFpSbEpaTUZaUVZYbDFVa1Z2VTJvNVkwSlRVVlJoUzB0cGF6MGlMQ0p0WVdNaU9pSXdNV1V3T0RCaE1USTNOV1F4WXpjNU9ESXhOR1ExWlRnNFpqTmtZVGsxWlRBMllUSTJOMll5TldVeFpUSXpObVV5TXpKaVptUTVabVEzWm1Sa056VTRJaXdpZEdGbklqb2lJbjA9"
        target="_blank"
        style={{ float: 'left', marginRight: '10px' }}
      >
        <img
          src="https://portal.gamingcontrolcuracao.org/uploads/documents/GCB_Temp_Seal.svg"
          width="150"
          height="85"
          alt=""
        />
      </a>
      <Box>
        <Typography sx={{ color: theme.colors.text3 }}>
          Spingreen.casino - website is owned and operated by WoT N.V. (License
          holder) reg. #129742 (Kaya Richard J. Beaujon Z/N, Curacao).
        </Typography>
        <Typography sx={{ color: theme.colors.text3, mt: '5px' }}>
          Limesco Limited (Payment processor) (Payment provider with
          registration number HE 261682), Arch. Makariou III, 155 PROTEAS HOUSE,
          5th floor 3026, Limassol, Cyprus.
        </Typography>
        <Typography sx={{ color: theme.colors.text3, mt: '5px' }}>
          Access to online money game sites is forbidden under some national
          laws. It is the responsibility of each individual player to ensure
          that they are acting within the law.
        </Typography>
        <Typography sx={{ color: theme.colors.text3, mt: '7px' }}>
          In order to register for this website, the user is required to accept
          the <Link href="/terms">General Terms and Conditions</Link>. In the
          event the General Terms and Conditions are updated, existing users may
          choose to discontinue using the products and services before the said
          update shall become effective, which is a minimum of two weeks after
          it has been announced.
        </Typography>
        <Typography sx={{ color: theme.colors.text3, mt: '10px' }}>
          18+ ©{new Date().getFullYear()} SPINGREEN.CASINO ALL RIGHTS RESERVED
        </Typography>
      </Box>
    </Container>
  )
}
