import { Box, Button, Typography, useTheme } from '@mui/material'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { useUnit } from 'effector-react'
import { $user } from '@models/user/model'
import { openPaymentModal } from '@components/modals/payment-modal/model'
import { openRegisterModal } from '@components/modals/registration-modal/model'

export const Slide = () => {
  // const { locale } = useRouter()
  const theme = useTheme()
  const { t } = useTranslation('home')
  // const localeLanguage = locale?.toUpperCase() ?? 'EN'
  const user = useUnit($user)
  const openPaymentModalFunction = useUnit(openPaymentModal)
  const openRegisterModalFunction = useUnit(openRegisterModal)

  return (
    <Link
      style={{ width: '100%', textDecoration: 'none', color: '#fffffe' }}
      href={''}
      passHref
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          cursor: 'pointer',
          aspectRatio: '683/150',
          background: 'url(/assets/images/slide.jpg)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          [theme.breakpoints.down(500)]: {
            aspectRatio: '64/35',
            backgroundImage: 'url(/assets/images/450x245.jpg)'
          },
          [theme.breakpoints.between(500, 768)]: {
            aspectRatio: '64/25'
          }
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'start',
            flexDirection: 'column',
            px: '45px',
            [theme.breakpoints.down(1280)]: {
              px: '35px'
            },
            [theme.breakpoints.down(768)]: {
              px: '20px',
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
              width: '100%'
            },
            [theme.breakpoints.down(500)]: {
              alignItems: 'center',
              justifyContent: 'flex-end',
              pb: '12px',
              width: '100%'
            }
          }}
        >
          <Typography
            fontWeight={600}
            lineHeight={1}
            sx={{
              textShadow: '4px 4px 0px 0px #000000',
              letterSpacing: 'initial',
              fontSize: '20px',
              color: theme.colors.primary500,
              [theme.breakpoints.down(768)]: {
                fontSize: '26px'
              },
              [theme.breakpoints.down(500)]: {
                fontSize: '12px'
              },
              [theme.breakpoints.down(500)]: {
                textAlign: 'center'
              }
            }}
          >
            WELCOME BONUS
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Alfa Slab One',
              textShadow: '4px 4px #000000',
              letterSpacing: 'initial',
              fontSize: '60px',
              lineHeight: 1.1,
              [theme.breakpoints.down(1366)]: {
                fontSize: '50px'
              },
              [theme.breakpoints.down(1280)]: {
                fontSize: '40px'
              },
              [theme.breakpoints.down(768)]: {
                fontSize: '30px'
              },
              [theme.breakpoints.down(500)]: {
                fontSize: '20px'
              },
              [theme.breakpoints.down(500)]: {
                textAlign: 'center'
              }
            }}
          >
            up to{' '}
            <span
              style={{
                textShadow: '4px 4px #000000',
                fontFamily: 'Alfa Slab One',
                color: theme.colors.primary500
              }}
            >
              1300
            </span>{' '}
            EUR
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Alfa Slab One',
              textShadow: '4px 4px #000000',
              letterSpacing: 'initial',
              fontSize: '60px',
              lineHeight: 1.1,
              mt: '5px',
              [theme.breakpoints.down(1366)]: {
                fontSize: '50px'
              },
              [theme.breakpoints.down(1280)]: {
                fontSize: '40px'
              },
              [theme.breakpoints.down(768)]: {
                fontSize: '30px'
              },
              [theme.breakpoints.down(500)]: {
                fontSize: '20px'
              },
              [theme.breakpoints.down(500)]: {
                textAlign: 'center'
              }
            }}
          >
            +{' '}
            <span
              style={{
                textShadow: '4px 4px #000000',
                fontFamily: 'Alfa Slab One',
                color: theme.colors.primary500
              }}
            >
              250
            </span>{' '}
            FS
          </Typography>
          <Button
            color="secondary"
            onClick={async () => {
              user?.email
                ? openPaymentModalFunction({})
                : openRegisterModalFunction()
            }}
            sx={{
              borderRadius: '4px',
              height: '40px',
              minHeight: '40px',
              fontWeight: 500,
              mt: '10px',
              width: '92px',
              [theme.breakpoints.down(500)]: {
                width: '100%'
              }
            }}
          >
            {t('get')}
          </Button>
        </Box>
      </Box>
    </Link>
  )
}
