import React from 'react'
import Script from 'next/script'
import { signInInTelegramFx } from '@models/user/model'
import { useUnit } from 'effector-react'

export const TelegramAuth = () => {
  const signInInTelegramFxFunction = useUnit(signInInTelegramFx)

  const init = () => {
    const initData = window?.Telegram?.WebApp?.initData

    if (initData) {
      signInInTelegramFxFunction(initData).catch((e) => {
        console.error(e)
      })
    }
  }

  return (
    <Script
      src="https://telegram.org/js/telegram-web-app.js"
      onLoad={init}
    ></Script>
  )
}
