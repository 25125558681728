import { createStore, createEffect, createEvent } from 'effector'
import {
  checkAuth,
  getWallets,
  logout,
  signIn,
  signInInTelegram,
  signUp
} from '@/graphql/auth/auth.api'
import { getCountries, getCurrencies, getLanguages } from '@/graphql'
import {
  type Country,
  type CurrencyEntity,
  type Language,
  type User,
  type Wallet
} from '@/__generated__/graphql'
import { initTawk } from '@/config/tawkTo'

export const $user = createStore<null | User>(null)
export const $wallets = createStore<Wallet[]>([])
export const $balance = createStore(0)
export const $countries = createStore<Country[]>([])
export const $currencies = createStore<CurrencyEntity[]>([])
export const $languages = createStore<Language[]>([])
export const $withdrawableAmount = createStore(0)

export const getWalletsFx = createEffect(getWallets)
export const getCountriesFx = createEffect(getCountries)
export const getCurrenciesFx = createEffect(getCurrencies)
export const getLanguagesFx = createEffect(getLanguages)
export const checkAuthFx = createEffect(checkAuth)
export const signInFx = createEffect(signIn)
export const signInInTelegramFx = createEffect(signInInTelegram)
export const signUpFx = createEffect(signUp)
export const logoutFx = createEffect(logout)
export const initTawkFx = createEffect(initTawk)

export const setWithdrawableAmount = createEvent<number>()
