import { forwardRef, type ReactNode } from 'react'
import { Box } from '@mui/material'

interface TabPanelProps {
  children?: ReactNode
  index: number
  value: number
}

const TabPanel = forwardRef<HTMLDivElement, TabPanelProps>((props, ref) => {
  const { children, value, index } = props

  return (
    <Box ref={ref} role="tabpanel" hidden={value !== index}>
      {value === index && children}
    </Box>
  )
})

TabPanel.displayName = 'TabPanel'

export { TabPanel }
