import { Box, Button, Dialog, Typography } from '@mui/material'
import { useUnit } from 'effector-react'
import {
  $isSuccessDepositModalOpen,
  setIsSuccessDepositModalOpen
} from '@components/modals/success-deposit-modal/model'
import { useTranslation } from 'next-i18next'
import { useTheme } from '@mui/material/styles'
import { EmojiCelebrate } from '@assets/icons/svg/emoji-celebrate'
import { useRouter } from 'next/router'

export const SuccessDepositModal = () => {
  const { t } = useTranslation('common')
  const theme = useTheme()
  const open = useUnit($isSuccessDepositModalOpen)
  const setSuccessDepositModalOpenFunction = useUnit(
    setIsSuccessDepositModalOpen
  )
  const router = useRouter()

  return (
    <Dialog
      open={open}
      onClose={() => setSuccessDepositModalOpenFunction(false)}
      sx={{
        zIndex: 2100,
        '& .MuiDialog-paper': {
          overflow: 'visible'
        }
      }}
    >
      <Box
        sx={{
          width: '355px',
          background: theme.colors.color400,
          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          mx: '10px',
          paddingTop: '50px'
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '-58px'
          }}
        >
          <EmojiCelebrate />
        </Box>
        <Typography
          fontSize="12px"
          fontWeight={500}
          textTransform={'uppercase'}
          color={theme.colors.text3}
        >
          {t('appointment')}
        </Typography>
        <Typography
          fontSize="28px"
          fontWeight={700}
          mt="5px"
          color={theme.colors.primary500}
          textAlign="center"
          textTransform={'uppercase'}
        >
          {t('successfulDeposit')}
        </Typography>
        <Button
          fullWidth
          onClick={() => {
            router.push('/games').catch(console.error)
            setSuccessDepositModalOpenFunction(false)
          }}
          variant="outlined"
          color="secondary"
          sx={{ mt: '15px' }}
        >
          {t('play')}
        </Button>
      </Box>
    </Dialog>
  )
}
