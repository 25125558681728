import {
  AppBar,
  Box,
  Button,
  Menu,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { useTranslation } from 'next-i18next'
import { CheckMarkRoundedIcon } from '@/assets/icons/svg/check-mark-rounded-icon'
import { SearchInput } from '@/layouts/header/search-input'
import { LogoElement } from '@/layouts/header/logo-element'
import {
  type MouseEventHandler,
  type ReactNode,
  useState,
  useRef,
  useEffect
} from 'react'
import { openRegisterModal } from '@components/modals/registration-modal/model'
import { useStoreMap, useUnit } from 'effector-react'
import { openLoginModal } from '@components/modals/login-modal/model'
import { Menu as MenuIcon } from '@assets/icons/svg/menu'
import { $isSiteMenuOpened, toggleSiteMenu } from '@/layouts/menu/model'
import { $balance, $currencies, $user, logoutFx } from '@models/user/model'
import { BellOff } from '@assets/icons/svg/bell-off'
import { Dashboard } from '@assets/icons/svg/dashboard'
import { AccountBox } from '@assets/icons/svg/account-box'
import { Gift } from '@assets/icons/svg/gift'
import { Logout } from '@/assets/icons/svg/logout'
import { ContainedArrow } from '@assets/icons/svg/contained-arrow'
import { CURRENCIES, DESKTOP_LAYOUT_BREAKPOINT } from '@/config/common'
import { openPaymentModal } from '@components/modals/payment-modal/model'
import { useRouter } from 'next/router'
import { GAME_PAGES } from '@/layouts/content'

export const Header = (): ReactNode => {
  const { t } = useTranslation('common')
  const theme = useTheme()
  const openRegisterModalFunction = useUnit(openRegisterModal)
  const openLoginModalFunction = useUnit(openLoginModal)
  const toggleSiteMenuFunction = useUnit(toggleSiteMenu)
  const isOpened = useUnit($isSiteMenuOpened)
  const isAuthenticated = useStoreMap($user, (user) => !!user)
  const user = useUnit($user)
  const balance = useUnit($balance)
  const profileBoxRef = useRef<HTMLElement | null>(null)
  const currencies = useUnit($currencies)
  const openPaymentModalFunction = useUnit(openPaymentModal)
  const router = useRouter()
  const logoutFxFunction = useUnit(logoutFx)
  const logoutLoading = useUnit(logoutFx.pending)
  // такое же условие в меню, править и там и там
  const isAlwaysOpened =
    useMediaQuery(theme.breakpoints.up(1400)) &&
    !GAME_PAGES.includes(router.pathname)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick: MouseEventHandler = (event) => {
    setAnchorEl(event.currentTarget as HTMLElement)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const toggleDrawer = (open: boolean) => () => {
    toggleSiteMenuFunction(open)
  }

  const currencyName = currencies.find(
    (currency) => currency.id === user?.currencyId
  )?.name

  const MENU_ITEMS = [
    {
      tKey: 'dashboard',
      icon: <Dashboard />,
      route: '/dashboard'
    },
    { tKey: 'profile', icon: <AccountBox />, route: '/profile' },
    // {
    //   tKey: 'notifications',
    //   icon: <Bell />
    // },
    {
      tKey: 'bonuses',
      icon: <Gift />,
      route: '/bonuses'
    },
    {
      tKey: 'exit',
      icon: <Logout />,
      onClick: async () => {
        await logoutFxFunction().catch(console.error)
        if (router.pathname !== '/') {
          router.push('/').catch(console.error)
        }
      }
    }
  ]

  useEffect(() => {
    if (!user) {
      handleClose()
    }
  }, [user])

  const isGamePage = router.pathname.includes('game/')

  return (
    <>
      <AppBar
        sx={{
          display: 'flex',
          flexDirection: 'row',
          zIndex: 2000
        }}
      >
        {!isAlwaysOpened && (
          <Box
            onClick={toggleDrawer(!isOpened)}
            sx={{
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              [theme.breakpoints.down(DESKTOP_LAYOUT_BREAKPOINT)]: {
                display: 'none'
              }
            }}
          >
            <MenuIcon opened={isOpened} />
          </Box>
        )}

        <LogoElement />
        <SearchInput />
        {isAuthenticated ? (
          <>
            <Box
              ref={profileBoxRef}
              onClick={handleClick}
              sx={{
                background: theme.colors.color350,
                height: '40px',
                borderRadius: '4px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                pl: '12px',
                pr: '10px',
                maxWidth: '200px',
                cursor: 'pointer',
                ml: 'auto',
                minWidth: '160px'
              }}
            >
              <Box sx={{ minWidth: '20px' }}>
                <BellOff />
              </Box>
              <Box sx={{ pl: '10px', maxWidth: '152px', mr: 'auto' }}>
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: theme.colors.text2,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textWrap: 'nowrap',
                    maxWidth: '123px'
                  }}
                >
                  {user?.email}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: theme.colors.text1
                  }}
                >
                  {isGamePage ? '****' : balance.toFixed(2)}{' '}
                  {CURRENCIES[currencyName as keyof typeof CURRENCIES]
                    ? CURRENCIES[currencyName as keyof typeof CURRENCIES]
                    : currencyName}
                </Typography>
              </Box>
              <ContainedArrow rotate={open ? '-90deg' : '90deg'} />
            </Box>
            <Button
              sx={{ ml: '8px' }}
              color="secondary"
              startIcon={<CheckMarkRoundedIcon />}
              onClick={() => openPaymentModalFunction({})}
            >
              {t('deposit')}
            </Button>
          </>
        ) : (
          <>
            <Button
              sx={{
                ml: 'auto',
                color: theme.colors.text1
              }}
              onClick={openLoginModalFunction}
            >
              {t('logIn')}
            </Button>
            <Button
              sx={{ ml: '8px' }}
              color="secondary"
              startIcon={<CheckMarkRoundedIcon />}
              onClick={openRegisterModalFunction}
            >
              {t('registration')}
            </Button>
          </>
        )}
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        open={logoutLoading ? false : open}
        onClose={handleClose}
        sx={{
          zIndex: 2500,
          '.MuiMenu-paper': {
            transitionDuration: '0s !important'
          },
          '.MuiPaper-root': {
            mt: '-4px',
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            width: profileBoxRef.current?.offsetWidth,
            background: theme.colors.color350,
            p: '4px 8px 8px',
            minWidth: '160px',
            maxWidth: '200px'
          }
        }}
      >
        {MENU_ITEMS.map(({ tKey, icon, route, onClick }, index) => {
          return (
            <Button
              key={index}
              onClick={async () => {
                if (route) {
                  await router.push(route)
                }
                await onClick?.().catch(() => undefined)
                handleClose()
              }}
              startIcon={icon}
              fullWidth
              sx={{
                borderRadius: '4px',
                justifyContent: 'flex-start',
                mt: index === 0 ? '0px' : '4px'
              }}
            >
              {t(tKey)}
            </Button>
          )
        })}
      </Menu>
    </>
  )
}
