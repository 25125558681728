export const Cellphone = ({ color }: { color?: string | undefined }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.54 23.5H7C5.9 23.5 5 22.61 5 21.5V3.5C5 2.39 5.89 1.5 7 1.5H17C18.1 1.5 19 2.39 19 3.5V13.5C18.3 13.5 17.63 13.63 17 13.85V5.5H7V19.5H13C13 21.04 13.58 22.44 14.54 23.5ZM17.75 22.66L15 19.66L16.16 18.5L17.75 20.09L21.34 16.5L22.5 17.91L17.75 22.66Z"
        fill={color ?? '#71767A'}
      />
    </svg>
  )
}
