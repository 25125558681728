import { createEvent, createStore } from 'effector'
import { type SocketChangeMessage } from '@/graphql/socket/socket.api'

export const $balanceChangeMessage = createStore<SocketChangeMessage | null>(
  null
)
export const $testNotification = createStore<string | null>(null)

export const setBalanceChangeMessage = createEvent<SocketChangeMessage>()
export const setTestNotification = createEvent<string>()

$balanceChangeMessage.on(setBalanceChangeMessage, (_, message) => message)
$testNotification.on(setTestNotification, (_, message) => message)
