import { Box, Button, useTheme } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { Gamepad } from '@/assets/icons/svg/gamepad'
import { Bullhorn } from '@/assets/icons/svg/bullhorn'
import { Magnify } from '@/assets/icons/svg/magnify'
import { type MouseEventHandler, type ReactNode } from 'react'
import { $isSiteMenuOpened, toggleSiteMenu } from '@/layouts/menu/model'
import { useUnit } from 'effector-react'
import { DESKTOP_LAYOUT_BREAKPOINT } from '@/config/common'
import { useRouter } from 'next/router'
import { openSearchGamesModal } from '@models/search-modal/model'
import { Hamburger } from '@/assets/icons/svg/hamburger'
import { RoundedPlus } from '@assets/icons/svg/rounded-plus'
import { openRegisterModal } from '@components/modals/registration-modal/model'
import getId from '@/helpers/get-id'
import { $user } from '@models/user/model'
import { openPaymentModal } from '@components/modals/payment-modal/model'

interface MenuItem {
  id: string
  icon: ReactNode
  iconSize?: string
  title?: string
  onClick?: MouseEventHandler
  selected?: boolean
}

export const MOBILE_BOTTOM_BAR_HEIGHT = '60px'

export const MobileBottomBar = (): ReactNode => {
  const theme = useTheme()
  const { t } = useTranslation('common')
  const toggleSiteMenuFunction = useUnit(toggleSiteMenu)
  const isSiteMenuOpened = useUnit($isSiteMenuOpened)
  const router = useRouter()
  const openSearchGamesModalFunction = useUnit(openSearchGamesModal)
  const openRegisterModalFunction = useUnit(openRegisterModal)
  const user = useUnit($user)
  const openPaymentModalFunction = useUnit(openPaymentModal)

  const items: MenuItem[] = [
    {
      id: getId(),
      icon: <Hamburger />,
      iconSize: '24px',
      title: t('mobileBottomMenu.menu'),
      onClick: () => {
        toggleSiteMenuFunction(!isSiteMenuOpened)
      }
    },
    {
      id: getId(),
      selected: router.pathname === '/promotions',
      icon: <Bullhorn selected={router.pathname === '/promotions'} />,
      iconSize: '24px',
      title: t('mobileBottomMenu.promo'),
      onClick: () => {
        router.push('/promotions').catch((e) => {
          console.error(e)
        })
        toggleSiteMenuFunction(false)
      }
    },
    {
      id: getId(),
      icon: <RoundedPlus />,
      onClick: () => {
        user?.email ? openPaymentModalFunction({}) : openRegisterModalFunction()
        toggleSiteMenuFunction(false)
      }
    },

    {
      id: getId(),
      selected: router.pathname === '/games',
      icon: <Gamepad selected={router.pathname === '/games'} />,
      iconSize: '24px',
      title: t('mobileBottomMenu.games'),
      onClick: () => {
        router.push('/games').catch((e) => {
          console.error(e)
        })
        toggleSiteMenuFunction(false)
      }
    },
    {
      id: getId(),
      icon: <Magnify selected={false} />,
      iconSize: '24px',
      title: t('mobileBottomMenu.search'),
      onClick: () => {
        openSearchGamesModalFunction()
        toggleSiteMenuFunction(false)
      }
    }
  ]

  return (
    <Box
      sx={{
        zIndex: 2000,
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        background: theme.colors.color350,
        height: MOBILE_BOTTOM_BAR_HEIGHT,
        display: 'flex',
        px: '15px',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.up(DESKTOP_LAYOUT_BREAKPOINT)]: {
          display: 'none'
        }
      }}
    >
      {items.map(({ id, title, icon, iconSize, selected, onClick }) => (
        <Button
          onClick={onClick}
          key={id}
          sx={{
            background: 'transparent',
            textTransform: 'none',
            flexDirection: 'column',
            height: '50px',
            width: '50px',
            p: 0,
            color: selected ? theme.colors.primary500 : null,
            '&:hover': {
              background: 'transparent'
            }
          }}
        >
          <Box
            sx={{
              ...(iconSize ? { width: iconSize, height: iconSize } : {}),

              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {icon}
          </Box>
          {title}
        </Button>
      ))}
    </Box>
  )
}
