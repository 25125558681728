import { type ReactNode, useCallback, useRef, useState } from 'react'
import type Swiper from 'swiper'
import { Swiper as SwiperInstance } from 'swiper/react'
import Box from '@mui/material/Box'
import { Navigation } from 'swiper/modules'

export const StyledSlider = ({
  children,
  nextEl,
  prevEl
}: {
  children: ReactNode
  nextEl: string
  prevEl: string
}) => {
  const [opacity, setOpacity] = useState(0)
  const leftBoxShadowRef = useRef<HTMLDivElement>(null)
  const rightBoxShadowRef = useRef<HTMLDivElement>(null)

  const handleSlideChange = useCallback(
    (swiper: Swiper) => {
      if (rightBoxShadowRef.current) {
        rightBoxShadowRef.current.style.display = 'inline-block'
      }

      if (leftBoxShadowRef.current) {
        leftBoxShadowRef.current.style.display = 'none'
      }

      if (swiper.isEnd) {
        if (leftBoxShadowRef.current && rightBoxShadowRef.current) {
          leftBoxShadowRef.current.style.display = 'inline-block'
          rightBoxShadowRef.current.style.display = 'none'
        }
      }

      if (!swiper.isBeginning) {
        if (leftBoxShadowRef.current) {
          leftBoxShadowRef.current.style.display = 'inline-block'
        }
      }
    },
    [nextEl, prevEl]
  )

  const onInit = (swiper: Swiper) => {
    setOpacity(1)

    if (swiper.isBeginning) {
      if (leftBoxShadowRef.current) {
        leftBoxShadowRef.current.style.display = 'none'
      }
    }
  }

  return (
    <Box
      sx={{
        opacity,
        transition: 'all 0.1s ease',
        position: 'relative'
      }}
      onInit={onInit}
      component={SwiperInstance}
      onSlideChange={handleSlideChange}
      freeMode
      spaceBetween={10}
      speed={800}
      slidesPerView={3.4}
      slidesPerGroup={3}
      modules={[Navigation]}
      navigation={{
        nextEl,
        prevEl,
        disabledClass: '.swiper-button-disabled'
      }}
      breakpoints={{
        600: { slidesPerView: 4.3, slidesPerGroup: 4 },
        900: { slidesPerView: 5.3, slidesPerGroup: 5 },
        1150: { slidesPerView: 6.3, slidesPerGroup: 6 }
      }}
    >
      <Box
        ref={leftBoxShadowRef}
        sx={{
          top: 0,
          left: 0,
          width: '60px',
          height: '100%',
          display: 'inline-block',
          position: 'absolute',
          background:
            '-webkit-linear-gradient(left, #0D1014 2%,rgba(28, 29, 38, 0) 98%)',
          zIndex: 1
        }}
      />
      <Box
        ref={rightBoxShadowRef}
        sx={{
          top: 0,
          right: 0,
          width: '60px',
          height: '100%',
          display: 'inline-block',
          position: 'absolute',
          background:
            '-webkit-linear-gradient(right, #0D1014 2%,rgba(28, 29, 38, 0) 98%)',
          zIndex: 1
        }}
      />
      {children}
    </Box>
  )
}
