import {
  $depositPaymentSystems,
  $transactions,
  $withdrawPaymentSystems,
  cancelTransactionFx,
  getDepositPaymentSystemsFx,
  getTransactionsFx,
  getWithdrawPaymentSystemsFx
} from '@models/payments/model'
import { sample } from 'effector'

$transactions.on(getTransactionsFx.doneData, (_, transactions) => transactions)
$depositPaymentSystems.on(
  getDepositPaymentSystemsFx.doneData,
  (_, depositPaymentSystems) => depositPaymentSystems
)
$withdrawPaymentSystems.on(
  getWithdrawPaymentSystemsFx.doneData,
  (_, withdrawPaymentSystems) => withdrawPaymentSystems
)

sample({
  clock: cancelTransactionFx.doneData,
  fn: () => ({}),
  target: getTransactionsFx
})
