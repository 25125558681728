import { Box, type BoxProps } from '@mui/material'
import { type ReactNode } from 'react'
import { useTheme } from '@mui/material/styles'

export const Container = ({ children, sx, ...props }: BoxProps): ReactNode => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        px: '16px',
        maxWidth: '1200px',
        mx: 'auto',
        [theme.breakpoints.down(1232)]: {
          px: 0,
          mx: '10px'
        },
        ...sx
      }}
      {...props}
    >
      {children}
    </Box>
  )
}
