import { type CSSProperties } from 'react'

export const PlayButton = ({ style }: { style?: CSSProperties }) => {
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_4700_15094)">
        <rect
          x="18"
          y="14"
          width="60"
          height="60"
          rx="30"
          fill="url(#paint0_linear_4700_15094)"
          shapeRendering="crispEdges"
        />
        <path
          d="M56.405 42.3209L45.0865 34.9971C43.7558 34.1361 42 35.0913 42 36.6763L42 51.3237C42 52.9087 43.7558 53.8639 45.0865 53.0029L56.405 45.6791C57.623 44.891 57.623 43.109 56.405 42.3209Z"
          fill="#0D1014"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4700_15094"
          x="0"
          y="0"
          width="96"
          height="96"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="9" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.372549 0 0 0 0 0.866667 0 0 0 0 0.137255 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4700_15094"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4700_15094"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_4700_15094"
          x1="48"
          y1="14.3333"
          x2="48"
          y2="74.3333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DCFD3E" />
          <stop offset="1" stopColor="#5FDD23" />
        </linearGradient>
      </defs>
    </svg>
  )
}
