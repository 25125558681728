import { Dialog } from '@mui/material'

import { useUnit } from 'effector-react'
import {
  $paymentModalOptions,
  closePaymentModal
} from '@components/modals/payment-modal/model'
import { useEffect, useState } from 'react'
import { ChooseSystemStep } from '@components/modals/payment-modal/choose-system-step/choose-system-step'
import { AmountStep } from '@components/modals/payment-modal/amount-step'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import {
  $depositPaymentSystems,
  $withdrawPaymentSystems,
  createTransactionFx
} from '@models/payments/model'
import { notify } from '@/config/notify'
import {
  type CreateTransactionNewDto,
  ETransactionCreateType
} from '@/__generated__/graphql'
import { $user } from '@models/user/model'
import sleep from '@/helpers/sleep'
import {
  $activeUserPromocode,
  getActiveUserPromocodeFx
} from '@models/bonuses/model'
import { ProfileStep } from '@components/modals/payment-modal/profile-step'

export type Step = 'system' | 'amount' | 'profile'

export const PaymentModal = () => {
  const [step, setStep] = useState<Step>('system')

  const closePaymentModalFunction = useUnit(closePaymentModal)
  const createTransactionFxFunction = useUnit(createTransactionFx)
  const getActiveUserPromocode = useUnit(getActiveUserPromocodeFx)

  const paymentModalOptions = useUnit($paymentModalOptions)
  const user = useUnit($user)
  const activeUserPromocode = useUnit($activeUserPromocode)

  const methods = useForm<CreateTransactionNewDto>({
    defaultValues: {
      paymentSystemId: undefined,
      amount: undefined,
      inputValues: {},
      paymentDetails: ''
    }
  })

  const onClose = () => {
    setTimeout(() => {
      methods.reset()
      closePaymentModalFunction()
      setStep('system')
    }, 500)
  }

  const depositPaymentSystems = useUnit($depositPaymentSystems)
  const withdrawPaymentSystems = useUnit($withdrawPaymentSystems)

  const systems = paymentModalOptions?.withdraw
    ? withdrawPaymentSystems
    : depositPaymentSystems

  const selectedPaymentMethod = systems.find(
    (system) => system.id === Number(paymentSystemId)
  )

  const fillProfileRequired = !!selectedPaymentMethod?.fillProfileRequired

  const onSubmit = async (data: CreateTransactionNewDto) => {
    try {
      if (paymentModalOptions?.withdraw) {
        await createTransactionFxFunction({
          ...data,
          type: ETransactionCreateType.Withdrawal,
          amount: +data.amount
        })
        notify.success({ description: 'Withdraw request created' })
        onClose()
      } else {
        if (
          fillProfileRequired &&
          !user?.lastName &&
          !user?.firstName &&
          step === 'amount'
        ) {
          setStep('profile')
          return
        }

        const html = await createTransactionFxFunction({
          ...data,
          type: ETransactionCreateType.Deposit
        })
        await sleep(500)
        const newWindow = window.open('', '_blank')
        newWindow?.document.write(html)
      }
    } catch (e) {
      if (e instanceof Error) {
        console.dir(e)
        notify.error({ description: e.message })
      }
    }
  }

  useEffect(() => {
    return () => {
      setTimeout(() => {
        methods.reset()
      }, 500)
    }
  }, [methods])

  useEffect(() => {
    if (paymentModalOptions && !activeUserPromocode) {
      getActiveUserPromocode().catch(console.error)
    }
  }, [activeUserPromocode, getActiveUserPromocode, paymentModalOptions])

  const paymentSystemId = useWatch({
    control: methods.control,
    name: 'paymentSystemId'
  })

  return (
    <Dialog
      open={!!paymentModalOptions}
      onClose={onClose}
      sx={{
        zIndex: 5000
      }}
      PaperProps={{
        sx: {
          minWidth: '375px',
          maxWidth: '632px'
        }
      }}
    >
      <FormProvider {...methods}>
        <form
          name="paymentForm"
          onSubmit={methods.handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <ChooseSystemStep
            setStep={setStep}
            step={step}
            paymentSystemId={paymentSystemId}
          />
          <AmountStep
            setStep={setStep}
            step={step}
            paymentSystemId={paymentSystemId}
          />
          <ProfileStep setStep={setStep} step={step} />
        </form>
      </FormProvider>
    </Dialog>
  )
}
