export const Live2 = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 12C12.6569 12 14 10.6569 14 9C14 8.8252 13.985 8.65389 13.9564 8.48728C13.7131 7.07468 12.4821 6 11 6C9.34315 6 8 7.34315 8 9C8 10.2738 8.79394 11.3623 9.91398 11.7974C9.93207 11.8044 9.95024 11.8113 9.9685 11.818C10.2901 11.9357 10.6376 12 11 12ZM17 9C17 12.3137 14.3137 15 11 15C10.0237 15 9.10188 14.7668 8.28717 14.3531C7.46235 16.0608 7 17.9764 7 20C7 27.1797 12.8203 33 20 33C27.1797 33 33 27.1797 33 20C33 12.8203 27.1797 7 20 7C18.8902 7 17.8129 7.13907 16.7845 7.40073C16.925 7.90988 17 8.44618 17 9ZM21 30.9552C23.2868 30.7491 25.3717 29.8432 27.0391 28.4533L24.9056 26.3199C23.7991 27.18 22.4607 27.756 21 27.9381V30.9552ZM26.3199 24.9056C27.18 23.7991 27.756 22.4607 27.9381 21H30.9552C30.7491 23.2868 29.8432 25.3717 28.4533 27.0391L26.3199 24.9056ZM19 27.9381C17.5393 27.756 16.2009 27.18 15.0944 26.3199L12.9609 28.4533C14.6283 29.8432 16.7132 30.7491 19 30.9552V27.9381ZM13.6801 24.9056C12.82 23.7991 12.244 22.4607 12.0619 21H9.04484C9.25091 23.2868 10.1568 25.3717 11.5467 27.0391L13.6801 24.9056ZM21 12.0619C22.4607 12.244 23.7991 12.82 24.9056 13.6801L27.0391 11.5467C25.3717 10.1568 23.2868 9.25091 21 9.04484V12.0619ZM30.9552 19H27.9381C27.756 17.5393 27.18 16.2009 26.3199 15.0944L28.4533 12.9609C29.8432 14.6283 30.7491 16.7132 30.9552 19ZM20 14C21.1046 14 22 14.8954 22 16C22 16.8393 21.483 17.5579 20.75 17.8546V19.25H22.3879C22.6846 18.517 23.4032 18 24.2426 18C25.3471 18 26.2426 18.8954 26.2426 20C26.2426 21.1046 25.3471 22 24.2426 22C23.4032 22 22.6846 21.483 22.3879 20.75H20.75V22.1454C21.483 22.4421 22 23.1607 22 24C22 25.1046 21.1046 26 20 26C18.8954 26 18 25.1046 18 24C18 23.1607 18.517 22.4421 19.25 22.1454V20.75H17.8546C17.5579 21.483 16.8393 22 16 22C14.8954 22 14 21.1046 14 20C14 18.8954 14.8954 18 16 18C16.8393 18 17.5579 18.517 17.8546 19.25H19.25V17.8546C18.517 17.5579 18 16.8393 18 16C18 14.8954 18.8954 14 20 14Z"
        fill="#71767A"
      />
    </svg>
  )
}
