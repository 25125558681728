import { createEffect, createEvent, createStore } from 'effector'
import { type Game, type GetGamesDto } from '@/__generated__/graphql'
import { getGames } from '@/graphql'

type FilterValues = Pick<
  GetGamesDto,
  'limit' | 'offset' | 'name' | 'gameCategories'
>

export const openSearchGamesModal = createEvent()
export const closeSearchGamesModal = createEvent()

export const $isSearchGamesModalOpened = createStore(false)
  .on(openSearchGamesModal, () => true)
  .on(closeSearchGamesModal, () => false)

export const $filteredGames = createStore<Game[]>([])
export const $filter = createStore<FilterValues>({
  name: '',
  offset: 0,
  limit: 0
})

export const changeFilter = createEvent<FilterValues>()

export const filterGamesFx = createEffect(
  async (params: FilterValues & { devices: string }) => {
    return await getGames({
      getGamesDto: {
        ...params
      }
    })
  }
)
