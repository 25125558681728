import { Logo } from '@/assets/icons/svg/logo'
import { Box, Typography, useTheme } from '@mui/material'
import { type ReactNode } from 'react'
import Link from 'next/link'

export const LogoElement = (): ReactNode => {
  const theme = useTheme()

  return (
    <Box
      component={Link}
      href="/"
      sx={{
        width: 'fit-content',
        height: '40px',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        p: '5px',
        textDecoration: 'none'
      }}
    >
      <Logo />
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: 1,
          ml: '5px',
          width: 'fit-content',
          whiteSpace: 'nowrap',
          [theme.breakpoints.down(768)]: {
            display: 'none'
          }
        }}
      >
        SPIN<span style={{ color: theme.colors.primary500 }}>GREEN</span>
      </Typography>
    </Box>
  )
}
