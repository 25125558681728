export const Menu = ({ opened }: { opened: boolean }) => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: opened ? 'rotate(180deg)' : 'rotate(0deg)' }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.75H11V3.25H0V0.75ZM0 6.75H14L14 2L20 8L14 14V9.25H0V6.75ZM0 12.75H11V15.25H0V12.75Z"
        fill="#71767A"
      />
    </svg>
  )
}
