export const EmojiCelebrate = () => {
  return (
    <svg
      width="126"
      height="126"
      viewBox="0 0 126 126"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_4481_28236)">
        <circle cx="63" cy="59" r="45" fill="url(#paint0_linear_4481_28236)" />
      </g>
      <path
        d="M63.3432 93.8287C82.2787 93.8287 97.6289 78.4786 97.6289 59.543C97.6289 40.6076 82.2787 25.2573 63.3432 25.2573C44.4077 25.2573 29.0575 40.6076 29.0575 59.543C29.0575 78.4786 44.4077 93.8287 63.3432 93.8287Z"
        fill="url(#paint1_radial_4481_28236)"
      />
      <path
        opacity="0.5"
        d="M63.3432 93.8287C82.2787 93.8287 97.6289 78.4786 97.6289 59.543C97.6289 40.6076 82.2787 25.2573 63.3432 25.2573C44.4077 25.2573 29.0575 40.6076 29.0575 59.543C29.0575 78.4786 44.4077 93.8287 63.3432 93.8287Z"
        fill="url(#paint2_radial_4481_28236)"
      />
      <path
        d="M55.8646 47.0288C56.0575 45.6573 54.686 44.6073 52.1575 44.2859C49.9932 44.0074 45.1289 44.5431 41.2932 48.2931C40.586 48.9788 41.4647 49.536 42.1075 49.1073C44.336 47.6716 49.9932 46.2145 54.1074 47.2216C55.779 47.6502 55.8646 47.0288 55.8646 47.0288Z"
        fill="url(#paint3_linear_4481_28236)"
      />
      <path
        d="M70.8218 47.0288C70.629 45.6573 72.0004 44.6073 74.5289 44.2859C76.6932 44.0074 81.5576 44.5431 85.3933 48.2931C86.1003 48.9788 85.2218 49.536 84.579 49.1073C82.3504 47.6716 76.6932 46.2145 72.579 47.2216C70.8861 47.6502 70.8218 47.0288 70.8218 47.0288Z"
        fill="url(#paint4_linear_4481_28236)"
      />
      <path
        d="M63.3432 93.8287C82.2787 93.8287 97.6289 78.4786 97.6289 59.543C97.6289 40.6076 82.2787 25.2573 63.3432 25.2573C44.4077 25.2573 29.0575 40.6076 29.0575 59.543C29.0575 78.4786 44.4077 93.8287 63.3432 93.8287Z"
        fill="url(#paint5_radial_4481_28236)"
      />
      <path
        opacity="0.5"
        d="M63.3432 93.8287C82.2787 93.8287 97.6289 78.4786 97.6289 59.543C97.6289 40.6076 82.2787 25.2573 63.3432 25.2573C44.4077 25.2573 29.0575 40.6076 29.0575 59.543C29.0575 78.4786 44.4077 93.8287 63.3432 93.8287Z"
        fill="url(#paint6_radial_4481_28236)"
      />
      <path
        opacity="0.75"
        d="M63.3432 93.8287C82.2787 93.8287 97.6289 78.4786 97.6289 59.543C97.6289 40.6076 82.2787 25.2573 63.3432 25.2573C44.4077 25.2573 29.0575 40.6076 29.0575 59.543C29.0575 78.4786 44.4077 93.8287 63.3432 93.8287Z"
        fill="url(#paint7_radial_4481_28236)"
      />
      <path
        opacity="0.4"
        d="M56.443 83.5858C60.7627 83.5858 64.2645 80.084 64.2645 75.7643C64.2645 71.4447 60.7627 67.9429 56.443 67.9429C52.1234 67.9429 48.6216 71.4447 48.6216 75.7643C48.6216 80.084 52.1234 83.5858 56.443 83.5858Z"
        fill="url(#paint8_radial_4481_28236)"
      />
      <path
        d="M64.05 79.7498C65.2571 79.7498 66.2357 78.4834 66.2357 76.9212C66.2357 75.3591 65.2571 74.0928 64.05 74.0928C62.8428 74.0928 61.8643 75.3591 61.8643 76.9212C61.8643 78.4834 62.8428 79.7498 64.05 79.7498Z"
        fill="url(#paint9_linear_4481_28236)"
      />
      <path
        d="M94.5 65.9717C94.6285 67.1502 93.8571 69.2503 92.6357 70.6216C91.7143 71.6717 90.4285 72.0789 89.1429 71.8432C88.8857 71.8003 88.65 71.736 88.3929 71.6289C86.7428 70.9432 85.1785 69.7431 83.5071 70.0003L72.9 71.5431C71.4857 71.7574 70.1143 72.1217 68.7857 72.6574L63.2142 75.5503L63.5143 77.5646L69.6643 78.7431C71.1 78.8931 72.5143 78.8502 73.9285 78.6574L87.2785 76.7074L91.1142 75.4645L96.0857 71.6931C97.2214 70.7289 97.2 68.6074 96.0429 67.5145L94.5 65.9717Z"
        fill="url(#paint10_linear_4481_28236)"
      />
      <path
        d="M72.0856 71.6929C70.9714 71.9071 69.8785 72.2285 68.8072 72.6785L63.2357 75.5714L63.5356 77.5857L69.6857 78.7643C70.8214 78.8714 71.9785 78.8714 73.0929 78.7643C72.5356 78.6571 71.8928 77.2 71.6356 75.3786C71.3786 73.5142 71.5714 71.9499 72.0856 71.6929Z"
        fill="url(#paint11_linear_4481_28236)"
      />
      <path
        d="M94.5 65.9717C94.6285 67.1502 93.8571 69.2503 92.6357 70.6216C91.7143 71.6717 90.4285 72.0789 89.1428 71.8432C88.8857 71.8003 88.65 71.736 88.3929 71.6289C86.7428 70.9432 85.1785 69.7431 83.5071 70.0003L72.9 71.5431C71.4857 71.7574 70.1143 72.1217 68.7857 72.6574L63.2142 75.5503L63.5143 77.5646L69.6643 78.7431C71.1 78.8931 72.5143 78.8502 73.9285 78.6574L86.5929 76.8145L90.3214 76.0431L96.0642 71.7146C97.2 70.7502 97.1786 68.6288 96.0214 67.536L94.5 65.9717Z"
        fill="url(#paint12_linear_4481_28236)"
      />
      <path
        d="M86.6143 76.8146C93.75 75.786 100.8 73.986 99.2786 64.6002C98.1857 57.786 87.8142 57.1645 87.2358 61.9431C87.9857 61.1288 89.0785 59.4789 89.8714 59.4574C93.5785 59.3288 94.7143 63.5931 94.7786 65.886C94.9071 71.6289 90.15 76.3002 86.6143 76.8146Z"
        fill="url(#paint13_linear_4481_28236)"
      />
      <path
        d="M93.2784 62.843C94.2641 63.1645 94.5213 64.7288 94.4998 65.9502C94.4998 65.9502 94.7784 66.6574 94.5855 66.9573L94.7356 66.6787C94.7356 66.6574 94.757 65.8858 94.757 65.8645C94.6927 63.5716 93.5141 59.2644 89.8498 59.4359C86.507 59.6073 86.7642 64.4501 87.6855 65.8001L88.1999 66.6359L92.1642 65.9073C91.6498 63.2931 92.4856 62.5859 93.2784 62.843Z"
        fill="url(#paint14_linear_4481_28236)"
      />
      <path
        d="M87.1284 63.7644C87.2999 65.1144 87.857 66.4002 88.4356 66.3144C89.0141 66.2287 89.2498 64.9216 89.1427 64.1502L94.1356 64.9002C94.2427 65.6073 94.0284 66.8073 93.4927 66.893L88.4999 66.8073C87.3427 66.7859 87.1284 64.3645 87.1284 63.7644Z"
        fill="url(#paint15_linear_4481_28236)"
      />
      <path
        d="M64.093 76.836C63.4287 81.8502 59.3787 82.943 60.193 81.5073C61.2644 79.5573 61.5215 78.2717 61.7573 76.5359C61.993 74.8002 62.0787 73.493 61.5644 71.3287C61.1572 69.7217 64.7572 71.8216 64.093 76.836Z"
        fill="#643800"
      />
      <path
        d="M61.5429 71.3287C62.0786 73.493 61.9714 74.8002 61.7357 76.5358C61.5 78.2716 61.2428 79.5572 60.1714 81.5073C59.8928 82.0215 60.1929 82.2145 60.75 82.043C60.7286 82.043 60.1929 81.8929 60.6857 81.2287C61.4357 80.243 62.5928 78.7858 62.8714 76.7072C63.1499 74.6072 62.4214 72.9144 61.95 71.7573C61.6715 70.9858 62.2286 70.9858 62.2286 70.9858C61.7571 70.6644 61.4143 70.7715 61.5429 71.3287Z"
        fill="url(#paint16_linear_4481_28236)"
      />
      <path
        opacity="0.75"
        d="M94.5 65.9717C94.6285 67.1502 93.8571 69.2503 92.6357 70.6216C91.7143 71.6717 90.4285 72.0789 89.1428 71.8432C88.8857 71.8003 88.65 71.736 88.3929 71.6289C86.7428 70.9432 85.1785 69.7431 83.5071 70.0003L72.9 71.5431C71.4857 71.7574 70.1143 72.1217 68.7857 72.6574L63.2142 75.5503L63.5143 77.5646L69.6643 78.7431C71.1 78.8931 72.5143 78.8502 73.9285 78.6574L86.5929 76.8145L90.3214 76.0431L96.0642 71.7146C97.2 70.7502 97.1786 68.6288 96.0214 67.536L94.5 65.9717Z"
        fill="url(#paint17_radial_4481_28236)"
      />
      <path
        opacity="0.75"
        d="M86.6143 76.8146C93.75 75.786 100.8 73.986 99.2786 64.6002C98.1857 57.786 87.8142 57.1645 87.2358 61.9431C87.9857 61.1288 89.0785 59.4789 89.8714 59.4574C93.5785 59.3288 94.7143 63.5931 94.7786 65.886C94.9071 71.6289 90.15 76.3002 86.6143 76.8146Z"
        fill="url(#paint18_radial_4481_28236)"
      />
      <path
        d="M47.8501 31.5143C48.1716 31.3644 48.193 30.9144 47.8716 30.7429L44.7216 29.0929L25.8644 19.3001C25.4787 19.1072 23.9359 18.4215 22.2001 19.8787C20.3573 21.4858 20.7859 22.7072 20.9144 23.0929L30.7072 48.4429C30.9216 49.0215 31.7358 49.0001 31.9501 48.4215C34.3501 41.6072 44.0573 33.2715 47.8501 31.5143Z"
        fill="url(#paint19_linear_4481_28236)"
      />
      <path
        d="M44.7431 29.0929L40.843 27.0786C39.4288 26.4358 27.5573 35.4143 27.8573 40.9858L30.1288 46.8572C27.4073 39.8714 42.8359 28.1072 44.7431 29.0929Z"
        fill="url(#paint20_linear_4481_28236)"
      />
      <path
        d="M36.8786 25.0431L36.0001 24.5717L32.9787 23.0074C31.7144 22.9003 24.643 28.3217 24.3644 31.986L26.2501 36.8289C25.8215 31.7503 35.593 24.3574 36.8786 25.0431Z"
        fill="url(#paint21_linear_4481_28236)"
      />
      <path
        d="M26.1 19.45C24.9858 19.8571 22.0929 22.0642 21.2786 23.9928L23.2501 29.1142C22.05 25.9856 28.3072 21.2714 29.6358 21.2714L26.1 19.45Z"
        fill="url(#paint22_linear_4481_28236)"
      />
      <path
        opacity="0.75"
        d="M47.8501 31.5143C48.1716 31.3644 48.193 30.9144 47.8716 30.7429L44.7216 29.0929L25.8644 19.3001C25.4787 19.1072 23.9359 18.4215 22.2001 19.8787C20.3573 21.4858 20.7859 22.7072 20.9144 23.0929L30.7072 48.4429C30.9216 49.0215 31.7358 49.0001 31.9501 48.4215C34.3501 41.6072 44.0573 33.2715 47.8501 31.5143Z"
        fill="url(#paint23_radial_4481_28236)"
      />
      <path
        opacity="0.75"
        d="M47.8501 31.5143C48.1716 31.3644 48.193 30.9144 47.8716 30.7429L44.7216 29.0929L25.8644 19.3001C25.4787 19.1072 23.9359 18.4215 22.2001 19.8787C20.3573 21.4858 20.7859 22.7072 20.9144 23.0929L30.7072 48.4429C30.9216 49.0215 31.7358 49.0001 31.9501 48.4215C34.3501 41.6072 44.0573 33.2715 47.8501 31.5143Z"
        fill="url(#paint24_radial_4481_28236)"
      />
      <path
        opacity="0.75"
        d="M47.8501 31.5143C48.1716 31.3644 48.193 30.9144 47.8716 30.7429L44.7216 29.0929L25.8644 19.3001C25.4787 19.1072 23.9359 18.4215 22.2001 19.8787C20.3573 21.4858 20.7859 22.7072 20.9144 23.0929L30.7072 48.4429C30.9216 49.0215 31.7358 49.0001 31.9501 48.4215C34.3501 41.6072 44.0573 33.2715 47.8501 31.5143Z"
        fill="url(#paint25_radial_4481_28236)"
      />
      <path
        d="M54.0645 54.0142C51.6859 52.5357 48.6216 52.707 46.393 54.3786C45.2573 55.2357 44.143 56.5428 43.4788 58.5785C43.3716 58.9213 43.2858 59.2857 43.2645 59.65C43.2216 60.3785 43.7358 61.0428 44.5501 60.2713C50.893 54.2928 55.3287 60.4428 55.3287 60.4428C56.1859 61.0642 57.0645 59.7786 56.893 58.5785C56.8502 58.2356 56.8073 57.8714 56.7002 57.5499C56.2074 55.8142 55.1573 54.7214 54.0645 54.0142Z"
        fill="url(#paint26_radial_4481_28236)"
      />
      <path
        d="M56.7001 57.5502C56.4644 56.8217 56.1644 56.2217 55.8429 55.7074C55.3287 54.9788 54.7073 54.4431 54.0644 54.036C51.6858 52.5574 48.6216 52.7288 46.3929 54.4002C45.2573 55.2574 44.1429 56.5645 43.4786 58.6003C43.4144 58.7931 43.3715 59.0075 43.3287 59.2217C43.3287 59.2217 43.3287 59.2217 43.3287 59.2431C46.1144 54.8931 50.1429 54.5932 50.1429 54.5932C50.1429 54.5932 54.1287 54.2503 56.8287 58.1503C56.7858 57.936 56.7644 57.7432 56.7001 57.5502Z"
        fill="url(#paint27_linear_4481_28236)"
      />
      <path
        d="M72.6216 54.0142C75.0002 52.5357 78.0644 52.707 80.2931 54.3786C81.4288 55.2357 82.5431 56.5428 83.2074 58.5785C83.3145 58.9213 83.4002 59.2857 83.4217 59.65C83.4644 60.3785 82.9502 61.0428 82.1359 60.2713C75.7931 54.2928 71.3573 60.4428 71.3573 60.4428C70.5002 61.0642 69.6217 59.7786 69.7931 58.5785C69.836 58.2356 69.8787 57.8714 69.9859 57.5499C70.4573 55.8142 71.5074 54.7214 72.6216 54.0142Z"
        fill="url(#paint28_radial_4481_28236)"
      />
      <path
        d="M69.9645 57.5502C70.2002 56.8217 70.5002 56.2217 70.8217 55.7074C71.336 54.9788 71.9573 54.4431 72.6003 54.036C74.9789 52.5574 78.0431 52.7288 80.2717 54.4002C81.4073 55.2574 82.5217 56.5645 83.186 58.6003C83.2502 58.7931 83.2931 59.0075 83.3359 59.2217C83.3359 59.2217 83.3359 59.2217 83.3359 59.2431C80.5502 54.8931 76.5217 54.5932 76.5217 54.5932C76.5217 54.5932 72.5359 54.2503 69.8359 58.1503C69.8788 57.936 69.9217 57.7217 69.9645 57.5502Z"
        fill="url(#paint29_linear_4481_28236)"
      />
      <path
        d="M71.3573 78.8285C70.8215 78.6785 70.2644 77.2857 70.0073 75.5714C69.7716 73.9428 69.9215 72.5285 70.3287 72.1428C69.8144 72.2928 69.3001 72.4856 68.8072 72.6785L68.743 72.6999C68.4216 73.1714 68.2501 74.35 68.443 75.7642C68.6358 77.1357 69.1501 78.2714 69.5786 78.7214L69.6859 78.7427C70.243 78.8071 70.8001 78.8285 71.3573 78.8285Z"
        fill="url(#paint30_linear_4481_28236)"
      />
      <g opacity="0.25">
        <path
          opacity="0.25"
          d="M36.0856 50.3929C37.2 53.6073 34.3927 54.4429 35.3142 56.5216L38.4427 55.7072C37.5214 53.6286 39.9642 52.8359 38.9785 49.5144L36.0856 50.3929Z"
          fill="url(#paint31_radial_4481_28236)"
        />
        <path
          opacity="0.25"
          d="M89.0356 40.0002C84.7498 42.4217 83.6569 37.4288 80.7641 38.5431L81.6641 42.8502C84.5356 41.7574 85.757 46.4288 90.0427 44.0074L89.0356 40.0002Z"
          fill="url(#paint32_radial_4481_28236)"
        />
        <path
          opacity="0.25"
          d="M54.1927 35.0284C54.6779 35.0284 55.0713 34.6351 55.0713 34.1499C55.0713 33.6647 54.6779 33.2712 54.1927 33.2712C53.7075 33.2712 53.3142 33.6647 53.3142 34.1499C53.3142 34.6351 53.7075 35.0284 54.1927 35.0284Z"
          fill="url(#paint33_radial_4481_28236)"
        />
        <path
          opacity="0.25"
          d="M76.7356 34.6431C77.2208 34.6431 77.6143 34.2498 77.6143 33.7646C77.6143 33.2794 77.2208 32.886 76.7356 32.886C76.2504 32.886 75.8571 33.2794 75.8571 33.7646C75.8571 34.2498 76.2504 34.6431 76.7356 34.6431Z"
          fill="url(#paint34_radial_4481_28236)"
        />
        <path
          opacity="0.25"
          d="M92.5927 55.7284C93.0779 55.7284 93.4713 55.335 93.4713 54.8498C93.4713 54.3646 93.0779 53.9712 92.5927 53.9712C92.1075 53.9712 91.7142 54.3646 91.7142 54.8498C91.7142 55.335 92.1075 55.7284 92.5927 55.7284Z"
          fill="url(#paint35_radial_4481_28236)"
        />
        <path
          opacity="0.25"
          d="M37.607 68.4144C38.0922 68.4144 38.4856 68.0209 38.4856 67.5357C38.4856 67.0505 38.0922 66.6572 37.607 66.6572C37.1218 66.6572 36.7285 67.0505 36.7285 67.5357C36.7285 68.0209 37.1218 68.4144 37.607 68.4144Z"
          fill="url(#paint36_radial_4481_28236)"
        />
        <path
          opacity="0.25"
          d="M79.3712 86.5215C79.8565 86.5215 80.2499 86.1282 80.2499 85.643C80.2499 85.1578 79.8565 84.7644 79.3712 84.7644C78.886 84.7644 78.4926 85.1578 78.4926 85.643C78.4926 86.1282 78.886 86.5215 79.3712 86.5215Z"
          fill="url(#paint37_radial_4481_28236)"
        />
        <path
          opacity="0.25"
          d="M76.6927 65.1356C77.1779 65.1356 77.5713 64.7423 77.5713 64.257C77.5713 63.7718 77.1779 63.3784 76.6927 63.3784C76.2075 63.3784 75.8142 63.7718 75.8142 64.257C75.8142 64.7423 76.2075 65.1356 76.6927 65.1356Z"
          fill="url(#paint38_radial_4481_28236)"
        />
        <path
          opacity="0.25"
          d="M67.9285 35.4786C67.8428 34.0857 67.7142 32.6286 67.5427 31.1072C62.1427 34.4929 60.0856 38.6072 60.0856 38.6072L61.6927 42.0786C63.3856 38.8643 66.1927 36.6358 67.9285 35.4786Z"
          fill="url(#paint39_radial_4481_28236)"
        />
      </g>
      <path
        d="M35.6142 48.5075C36.7285 51.7218 33.9214 52.5575 34.8428 54.6361L37.9714 53.8218C37.05 51.7433 39.4929 50.9504 38.5072 47.6289L35.6142 48.5075Z"
        fill="url(#paint40_linear_4481_28236)"
      />
      <path
        d="M89.0361 38.2644C84.7503 40.6859 83.6575 35.693 80.7646 36.8073L81.6647 41.1145C84.5361 40.0215 85.7575 44.693 90.0432 42.2715L89.0361 38.2644Z"
        fill="url(#paint41_linear_4481_28236)"
      />
      <path
        d="M90.6214 33.3573C91.1066 33.3573 91.5001 32.9639 91.5001 32.4787C91.5001 31.9935 91.1066 31.6001 90.6214 31.6001C90.1362 31.6001 89.7429 31.9935 89.7429 32.4787C89.7429 32.9639 90.1362 33.3573 90.6214 33.3573Z"
        fill="url(#paint42_linear_4481_28236)"
      />
      <path
        d="M54.1931 33.7432C54.6783 33.7432 55.0716 33.3498 55.0716 32.8646C55.0716 32.3794 54.6783 31.9861 54.1931 31.9861C53.7079 31.9861 53.3145 32.3794 53.3145 32.8646C53.3145 33.3498 53.7079 33.7432 54.1931 33.7432Z"
        fill="url(#paint43_linear_4481_28236)"
      />
      <path
        d="M76.7359 33.3573C77.2211 33.3573 77.6144 32.9639 77.6144 32.4787C77.6144 31.9935 77.2211 31.6001 76.7359 31.6001C76.2507 31.6001 75.8573 31.9935 75.8573 32.4787C75.8573 32.9639 76.2507 33.3573 76.7359 33.3573Z"
        fill="url(#paint44_linear_4481_28236)"
      />
      <path
        d="M92.593 54.4432C93.0782 54.4432 93.4715 54.0498 93.4715 53.5645C93.4715 53.0793 93.0782 52.686 92.593 52.686C92.1078 52.686 91.7144 53.0793 91.7144 53.5645C91.7144 54.0498 92.1078 54.4432 92.593 54.4432Z"
        fill="url(#paint45_linear_4481_28236)"
      />
      <path
        d="M37.4145 67.4073C37.8997 67.4073 38.293 67.014 38.293 66.5288C38.293 66.0436 37.8997 65.6501 37.4145 65.6501C36.9292 65.6501 36.5358 66.0436 36.5358 66.5288C36.5358 67.014 36.9292 67.4073 37.4145 67.4073Z"
        fill="url(#paint46_linear_4481_28236)"
      />
      <path
        d="M76.586 63.9363C77.0712 63.9363 77.4645 63.5429 77.4645 63.0577C77.4645 62.5725 77.0712 62.1792 76.586 62.1792C76.1008 62.1792 75.7073 62.5725 75.7073 63.0577C75.7073 63.5429 76.1008 63.9363 76.586 63.9363Z"
        fill="url(#paint47_linear_4481_28236)"
      />
      <path
        d="M30.4502 79.9644C30.9355 79.9644 31.3288 79.5711 31.3288 79.0859C31.3288 78.6007 30.9355 78.2073 30.4502 78.2073C29.965 78.2073 29.5717 78.6007 29.5717 79.0859C29.5717 79.5711 29.965 79.9644 30.4502 79.9644Z"
        fill="url(#paint48_linear_4481_28236)"
      />
      <path
        d="M68.3788 32.4144C68.2931 31.0215 68.1646 29.5644 67.9932 28.043C62.5932 31.4288 60.536 35.543 60.536 35.543L62.1432 39.0144C63.836 35.8216 66.6432 33.5716 68.3788 32.4144Z"
        fill="url(#paint49_linear_4481_28236)"
      />
      <path
        d="M51.4502 73.7285C51.9431 83.2429 44.1645 85.7072 41.8289 86.2428C43.0503 87.2285 44.3359 88.1285 45.7074 88.9428C49.9502 87.1643 56.9359 82.6856 54.5788 72.8071C53.4216 73.1072 52.3717 73.4071 51.4502 73.7285Z"
        fill="url(#paint50_radial_4481_28236)"
      />
      <path
        d="M49.2216 72.1C49.8001 83.2857 38.9358 84.7428 38.9358 84.7428L40.4572 88.3214C40.4572 88.3214 55.6501 85.0857 52.3501 71.2C51.193 71.4785 50.1429 71.7785 49.2216 72.1Z"
        fill="url(#paint51_linear_4481_28236)"
      />
      <defs>
        <filter
          id="filter0_d_4481_28236"
          x="0"
          y="0"
          width="126"
          height="126"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="9" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.372549 0 0 0 0 0.866667 0 0 0 0 0.137255 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4481_28236"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4481_28236"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_4481_28236"
          x1="63.0001"
          y1="14.5"
          x2="63.0001"
          y2="104.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DCFD3E" />
          <stop offset="1" stopColor="#5FDD23" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_4481_28236"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(56.5891 45.6435) scale(41.3613 41.3613)"
        >
          <stop stopColor="#FFE030" />
          <stop offset="1" stopColor="#FFB92E" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_4481_28236"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(56.5891 45.6435) scale(32.5407 32.5407)"
        >
          <stop stopColor="#FFEA5F" />
          <stop offset="1" stopColor="#FFBC47" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint3_linear_4481_28236"
          x1="48.3821"
          y1="48.3449"
          x2="48.8626"
          y2="44.7643"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00132565" stopColor="#3C2200" />
          <stop offset="1" stopColor="#7A4400" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_4481_28236"
          x1="78.2926"
          y1="48.3439"
          x2="77.8121"
          y2="44.7633"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00132565" stopColor="#3C2200" />
          <stop offset="1" stopColor="#7A4400" />
        </linearGradient>
        <radialGradient
          id="paint5_radial_4481_28236"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(37.5899 37.3581) rotate(-45.5348) scale(26.3071 10.8078)"
        >
          <stop stopColor="#EB672A" stopOpacity="0.5" />
          <stop offset="0.3025" stopColor="#E36521" stopOpacity="0.3422" />
          <stop offset="0.7868" stopColor="#CC600A" stopOpacity="0.0897045" />
          <stop offset="0.9589" stopColor="#C25E00" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_4481_28236"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(44.6417 68.0679) rotate(-2.7703) scale(14.4214 11.4109)"
        >
          <stop stopColor="#FF4C00" />
          <stop offset="0.1995" stopColor="#F94C0E" stopOpacity="0.8005" />
          <stop offset="0.7145" stopColor="#EC4A2E" stopOpacity="0.2855" />
          <stop offset="1" stopColor="#E74A3A" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_4481_28236"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(81.6973 78.6584) rotate(-0.40159) scale(22.9296 5.20504)"
        >
          <stop stopColor="#7A4400" stopOpacity="0.5" />
          <stop offset="0.5833" stopColor="#894D00" stopOpacity="0.2069" />
          <stop offset="0.995" stopColor="#975500" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_4481_28236"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(58.9261 77.247) rotate(4.55396) scale(8.09664 5.72482)"
        >
          <stop stopColor="white" stopOpacity="0.75" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint9_linear_4481_28236"
          x1="61.8521"
          y1="76.9137"
          x2="66.232"
          y2="76.9137"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00132565" stopColor="#3C2200" />
          <stop offset="1" stopColor="#512D00" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_4481_28236"
          x1="80.0717"
          y1="72.5313"
          x2="81.5812"
          y2="82.8907"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF40BF" />
          <stop offset="0.8932" stopColor="#7000A3" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_4481_28236"
          x1="67.3439"
          y1="71.5324"
          x2="68.3788"
          y2="78.6347"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2544" stopColor="#D1F2FF" />
          <stop offset="1" stopColor="#17BBFE" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_4481_28236"
          x1="82.683"
          y1="73.0179"
          x2="98.9951"
          y2="66.0168"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7000A3" stopOpacity="0" />
          <stop offset="0.2242" stopColor="#530078" stopOpacity="0.2242" />
          <stop offset="0.5925" stopColor="#260038" stopOpacity="0.5925" />
          <stop offset="0.8613" stopColor="#0B000F" stopOpacity="0.8613" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_4481_28236"
          x1="96.7166"
          y1="83.914"
          x2="91.7673"
          y2="66.5396"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="0.1488" stopColor="#27002D" />
          <stop offset="0.4631" stopColor="#760086" />
          <stop offset="0.7193" stopColor="#B000C7" />
          <stop offset="0.9045" stopColor="#D300F0" />
          <stop offset="1" stopColor="#E100FF" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_4481_28236"
          x1="91.7152"
          y1="56.8721"
          x2="90.0918"
          y2="72.8365"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="0.1488" stopColor="#27002D" />
          <stop offset="0.4631" stopColor="#760086" />
          <stop offset="0.7193" stopColor="#B000C7" />
          <stop offset="0.9045" stopColor="#D300F0" />
          <stop offset="1" stopColor="#E100FF" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_4481_28236"
          x1="91.3445"
          y1="69.4848"
          x2="90.6619"
          y2="64.8011"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7000A3" />
          <stop offset="0.4469" stopColor="#A900D2" />
          <stop offset="0.8117" stopColor="#D200F2" />
          <stop offset="1" stopColor="#E100FF" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_4481_28236"
          x1="59.0985"
          y1="76.1811"
          x2="62.7605"
          y2="76.6705"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00132565" stopColor="#3C2200" />
          <stop offset="1" stopColor="#512D00" />
        </linearGradient>
        <radialGradient
          id="paint17_radial_4481_28236"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(77.6058 73.1289) rotate(-8.28692) scale(12.3994 1.38276)"
        >
          <stop stopColor="white" stopOpacity="0.5" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint18_radial_4481_28236"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(97.1561 63.5603) rotate(77.7176) scale(6.23942 1.3963)"
        >
          <stop stopColor="white" stopOpacity="0.5" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint19_linear_4481_28236"
          x1="42.6894"
          y1="23.278"
          x2="22.8264"
          y2="37.2135"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2544" stopColor="#D1F2FF" />
          <stop offset="1" stopColor="#17BBFE" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_4481_28236"
          x1="44.411"
          y1="28.7608"
          x2="27.0597"
          y2="43.2669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF40BF" />
          <stop offset="0.7456" stopColor="#7000A3" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_4481_28236"
          x1="36.0258"
          y1="24.075"
          x2="23.9556"
          y2="34.1659"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF40BF" />
          <stop offset="0.7456" stopColor="#7000A3" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_4481_28236"
          x1="28.919"
          y1="20.4838"
          x2="21.3073"
          y2="26.8474"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF40BF" />
          <stop offset="0.7456" stopColor="#7000A3" />
        </linearGradient>
        <radialGradient
          id="paint23_radial_4481_28236"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.5544 33.5312) rotate(65.3021) scale(14.2279 3.50405)"
        >
          <stop stopColor="#440063" stopOpacity="0.75" />
          <stop offset="1" stopColor="#420061" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint24_radial_4481_28236"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(33.7866 27.0565) rotate(31.2018) scale(14.2277 3.50451)"
        >
          <stop stopColor="white" stopOpacity="0.5" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint25_radial_4481_28236"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(38.3898 45.5097) rotate(30.2004) scale(17.5568 17.876)"
        >
          <stop stopColor="#440063" stopOpacity="0.75" />
          <stop offset="1" stopColor="#420061" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint26_radial_4481_28236"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(50.1579 57.0428) rotate(175.474) scale(5.28952 3.7507)"
        >
          <stop offset="0.00132565" stopColor="#7A4400" />
          <stop offset="1" stopColor="#643800" />
        </radialGradient>
        <linearGradient
          id="paint27_linear_4481_28236"
          x1="50.6726"
          y1="50.3857"
          x2="50.2526"
          y2="56.143"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00132565" stopColor="#3C2200" />
          <stop offset="1" stopColor="#512D00" />
        </linearGradient>
        <radialGradient
          id="paint28_radial_4481_28236"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(76.5087 57.0427) rotate(4.52557) scale(5.28951 3.7507)"
        >
          <stop offset="0.00132565" stopColor="#7A4400" />
          <stop offset="1" stopColor="#643800" />
        </radialGradient>
        <linearGradient
          id="paint29_linear_4481_28236"
          x1="75.9948"
          y1="50.3857"
          x2="76.4146"
          y2="56.1429"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00132565" stopColor="#3C2200" />
          <stop offset="1" stopColor="#512D00" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_4481_28236"
          x1="69.4496"
          y1="73.8931"
          x2="70.3018"
          y2="80.4062"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF40BF" />
          <stop offset="0.8932" stopColor="#7000A3" />
        </linearGradient>
        <radialGradient
          id="paint31_radial_4481_28236"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(46.6061 37.1342) scale(77.1878 77.1878)"
        >
          <stop stopColor="#7A4400" stopOpacity="0.5" />
          <stop offset="0.5833" stopColor="#894D00" stopOpacity="0.2069" />
          <stop offset="0.995" stopColor="#975500" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint32_radial_4481_28236"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(41.1323 29.9218) rotate(0.0419929) scale(105.022 104.952)"
        >
          <stop stopColor="#7A4400" stopOpacity="0.5" />
          <stop offset="0.5833" stopColor="#894D00" stopOpacity="0.2069" />
          <stop offset="0.995" stopColor="#975500" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint33_radial_4481_28236"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(50.2489 33.8929) scale(77.1879 77.1878)"
        >
          <stop stopColor="#7A4400" stopOpacity="0.5" />
          <stop offset="0.5833" stopColor="#894D00" stopOpacity="0.2069" />
          <stop offset="0.995" stopColor="#975500" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint34_radial_4481_28236"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(50.249 33.8933) scale(77.1878 77.1879)"
        >
          <stop stopColor="#7A4400" stopOpacity="0.5" />
          <stop offset="0.5833" stopColor="#894D00" stopOpacity="0.2069" />
          <stop offset="0.995" stopColor="#975500" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint35_radial_4481_28236"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(50.2488 33.8929) scale(77.1881 77.188)"
        >
          <stop stopColor="#7A4400" stopOpacity="0.5" />
          <stop offset="0.5833" stopColor="#894D00" stopOpacity="0.2069" />
          <stop offset="0.995" stopColor="#975500" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint36_radial_4481_28236"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(41.8704 35.0717) scale(77.188 77.1879)"
        >
          <stop stopColor="#7A4400" stopOpacity="0.5" />
          <stop offset="0.5833" stopColor="#894D00" stopOpacity="0.2069" />
          <stop offset="0.995" stopColor="#975500" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint37_radial_4481_28236"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(50.2487 33.8933) scale(77.1882 77.1878)"
        >
          <stop stopColor="#7A4400" stopOpacity="0.5" />
          <stop offset="0.5833" stopColor="#894D00" stopOpacity="0.2069" />
          <stop offset="0.995" stopColor="#975500" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint38_radial_4481_28236"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(53.0346 30.4109) scale(77.1879 77.1878)"
        >
          <stop stopColor="#7A4400" stopOpacity="0.5" />
          <stop offset="0.5833" stopColor="#894D00" stopOpacity="0.2069" />
          <stop offset="0.995" stopColor="#975500" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint39_radial_4481_28236"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(45.5195 45.2994) rotate(-28.5684) scale(106.838 106.75)"
        >
          <stop stopColor="#7A4400" stopOpacity="0.5" />
          <stop offset="0.5833" stopColor="#894D00" stopOpacity="0.2069" />
          <stop offset="0.995" stopColor="#975500" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint40_linear_4481_28236"
          x1="37.8772"
          y1="53.1241"
          x2="35.4096"
          y2="49.0088"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9214" />
          <stop offset="1" stopColor="#FF4E0D" />
        </linearGradient>
        <linearGradient
          id="paint41_linear_4481_28236"
          x1="95.2779"
          y1="41.8268"
          x2="80.9588"
          y2="39.1353"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#80DAFE" />
          <stop offset="1" stopColor="#008EE6" />
        </linearGradient>
        <linearGradient
          id="paint42_linear_4481_28236"
          x1="85.4602"
          y1="31.496"
          x2="95.6288"
          y2="33.4991"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFCB4B" />
          <stop offset="1" stopColor="#E81CA2" />
        </linearGradient>
        <linearGradient
          id="paint43_linear_4481_28236"
          x1="59.3217"
          y1="32.7534"
          x2="48.7566"
          y2="33.0131"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBE07A" />
          <stop offset="1" stopColor="#E81CA2" />
        </linearGradient>
        <linearGradient
          id="paint44_linear_4481_28236"
          x1="76.3867"
          y1="28.404"
          x2="76.7438"
          y2="32.6897"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#80DAFE" />
          <stop offset="1" stopColor="#008EE6" />
        </linearGradient>
        <linearGradient
          id="paint45_linear_4481_28236"
          x1="94.0573"
          y1="55.1636"
          x2="90.5031"
          y2="51.3067"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFCB4B" />
          <stop offset="1" stopColor="#E81CA2" />
        </linearGradient>
        <linearGradient
          id="paint46_linear_4481_28236"
          x1="35.6314"
          y1="66.2277"
          x2="38.1287"
          y2="66.6975"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2544" stopColor="#9F00E8" />
          <stop offset="1" stopColor="#7000A3" />
        </linearGradient>
        <linearGradient
          id="paint47_linear_4481_28236"
          x1="73.7775"
          y1="66.7413"
          x2="78.5632"
          y2="60.4556"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#80DAFE" />
          <stop offset="1" stopColor="#008EE6" />
        </linearGradient>
        <linearGradient
          id="paint48_linear_4481_28236"
          x1="29.2191"
          y1="78.6936"
          x2="34.9453"
          y2="80.7438"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9214" />
          <stop offset="1" stopColor="#FF4E0D" />
        </linearGradient>
        <linearGradient
          id="paint49_linear_4481_28236"
          x1="81.1459"
          y1="22.3578"
          x2="58.341"
          y2="38.296"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBE07A" />
          <stop offset="1" stopColor="#E81CA2" />
        </linearGradient>
        <radialGradient
          id="paint50_radial_4481_28236"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.3466 71.3239) scale(36.4545 36.4545)"
        >
          <stop stopColor="#7A4400" stopOpacity="0.5" />
          <stop offset="0.5833" stopColor="#894D00" stopOpacity="0.2069" />
          <stop offset="0.995" stopColor="#975500" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint51_linear_4481_28236"
          x1="49.6847"
          y1="65.2763"
          x2="40.386"
          y2="102.931"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F78174" />
          <stop offset="0.3893" stopColor="#8800C7" />
          <stop offset="1" stopColor="#3640FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}
