import { gql } from '@apollo/client'
import { mutate, query } from '@/graphql/base-api'
import {
  type CreateTransactionNewDto,
  type CryptoWallet,
  type DepositPaymentSystem,
  type QueriesUserTransactions,
  type Transaction,
  type WithdrawPaymentSystem
} from '@/__generated__/graphql'
import { type PagesParams } from '@models/pages/model'

export async function createTransaction({
  ...createTransactionsDto
}: CreateTransactionNewDto): Promise<string> {
  const query = {
    mutation: gql`
      mutation CreateTransaction(
        $createTransactionsDto: CreateTransactionNewDto!
      ) {
        createTransaction(createTransactionsDto: $createTransactionsDto)
      }
    `,
    variables: {
      createTransactionsDto: {
        ...createTransactionsDto,
        amount: Number(createTransactionsDto.amount)
      }
    }
  }

  const result = await mutate<{ createTransaction: string }>(query)

  return result.createTransaction
}

export async function getDepositPaymentSystems() {
  const request = {
    query: gql`
      query DepositPaymentSystems {
        depositPaymentSystems {
          currency {
            code
            createdAt
            id
            minValue
            maxValue
            name
            options {
              value
              paymentSystemId
              id
              currencyId
            }
            updatedAt
            paymentSystemId
          }
          id
          type
          name
          message
          icons {
            theme
            icon
          }
          inputs {
            name
            label
            paymentSystemId
            target
            regexp
          }
        }
      }
    `
  }

  const result = await query<{ depositPaymentSystems: DepositPaymentSystem[] }>(
    request
  )

  return result.depositPaymentSystems
}

export async function createCryptoWallet(paymentSystemId: number) {
  const request = {
    mutation: gql`
      mutation CreateCryptoWallet($paymentSystemId: Float!) {
        createCryptoWallet(paymentSystemId: $paymentSystemId) {
          addressWallet
          tag
          qrCodeImage
        }
      }
    `,
    variables: {
      paymentSystemId
    }
  }

  const result = await mutate<{ createCryptoWallet: CryptoWallet }>(request)

  return result.createCryptoWallet
}

export async function getWithdrawPaymentSystems() {
  const request = {
    query: gql`
      query WithdrawPaymentSystems {
        withdrawPaymentSystems {
          checkDepositPaymentDetailsForWithdraw
          icons {
            icon
            theme
          }
          currency {
            code
            createdAt
            id
            maxValue
            minValue
            name
            updatedAt
          }
          id
          message
          name
          type
        }
      }
    `
  }

  const result = await query<{
    withdrawPaymentSystems: WithdrawPaymentSystem[]
  }>(request)

  return result.withdrawPaymentSystems
}

export async function getTransactions(
  params: Partial<PagesParams> & QueriesUserTransactions
) {
  const request = {
    query: gql`
      query Transactions($input: QueriesUserTransactions!) {
        transactions(input: $input) {
          meta {
            total
            take
            skip
          }
          data {
            id
            status
            type
            amount
            currencyId
            paymentDetails
            userId
            promoCode
            paymentCountry
            feeValue
            createdAt
            updatedAt
            isWagered
            wageredSum
            wagerSum
            paymentSystem {
              name
            }
          }
        }
      }
    `,
    context: {
      headers: {
        ...params.headers,
        cookie: params?.cookie
      }
    },
    variables: {
      input: {
        pagination: params.pagination ?? { skip: 0, take: 100 },
        order: params.order,
        filter: params.filter
      }
    }
  }

  const result = await query<{
    transactions: {
      data: Transaction[]
    }
  }>(request)

  return result.transactions.data
}

export const cancelTransaction = async (cancelTransactionId: string) => {
  const request = {
    mutation: gql`
      mutation CancelTransaction($cancelTransactionId: String!) {
        cancelTransaction(id: $cancelTransactionId) {
          id
        }
      }
    `,
    variables: {
      cancelTransactionId
    }
  }

  return await mutate(request)
}
