export const DESKTOP_LAYOUT_BREAKPOINT = 1200

export const CURRENCIES = {
  USD: '$',
  EUR: '€',
  PLN: 'zł',
  AUD: 'A$',
  INR: '₹'
}

export type Currency = keyof typeof CURRENCIES

export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/

export const generateCookiesString = (cookies: Record<string, string>) => {
  return Object.entries(cookies)
    .map(([key, value]) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    })
    .join('; ')
}

export const REF_CAMP_KEY = 'refCamp'
export const REFERAL_ID_KEY = 'referalId'
