import { mutate, query } from '../base-api'
import { gql, type QueryOptions } from '@apollo/client'
import {
  type Brand,
  type FavoriteGame,
  type Game,
  type GameCategory,
  type GameStartUrlEntity,
  type GameTag,
  type GetGamesDto,
  type StartGameDto
} from '@/__generated__/graphql'
import { UAParser } from 'ua-parser-js'

export async function getGames({
  headers,
  getGamesDto
}: { getGamesDto: GetGamesDto } & {
  headers?: Record<string, string>
}): Promise<Game[]> {
  const windowUserAgent =
    typeof window !== 'undefined' ? window.navigator.userAgent : ''
  const parser = new UAParser(headers?.userAgent ?? windowUserAgent)

  const isMobile = parser.getDevice().type === 'mobile'

  const response: QueryOptions = {
    query: gql`
      query GetGames($dto: GetGamesDto!) {
        games(args: $dto) {
          id
          createdAt
          updatedAt
          name
          previewImage
          brand {
            name
          }
        }
      }
    `,
    variables: { dto: { ...getGamesDto, devices: isMobile ? 'MOBILE' : 'PC' } },
    context: {
      headers
    }
  }

  const result = await query<{ games: Game[] }>(response)

  return result.games
}

export async function getGameCategories(): Promise<GameCategory[]> {
  const response = {
    query: gql`
      query GetGameCategories {
        gameCategories {
          id
          createdAt
          updatedAt
          name
          games {
            id
            bonusEligible
            createdAt
            updatedAt
            name
          }
        }
      }
    `
  }

  const result = await query<{ gameCategories: GameCategory[] }>(response)

  return result.gameCategories
}

export async function getBrands(params?: {
  cookie: Record<string, string>
  headers: Record<string, string>
}): Promise<Brand[]> {
  const { cookie, headers } = params ?? {}

  const response: QueryOptions = {
    query: gql`
      query GetBrands {
        brands {
          id
          name
          darkPreviewImage
          lightPreviewImage
          currencies {
            id
            name
          }
          provider
          active
        }
      }
    `,
    context: {
      headers: {
        ...headers,
        cookie
      }
    }
  }

  const result = await query<{ brands: Brand[] }>(response)
  return result.brands
}

export async function getGameTags(): Promise<GameTag[]> {
  const response = {
    query: gql`
      query GetTags {
        tags {
          id
          createdAt
          updatedAt
          name
          games {
            id
            bonusEligible
            createdAt
            updatedAt
            name
          }
        }
      }
    `
  }

  const result = await query<{ tags: GameTag[] }>(response)

  return result.tags
}

export async function startGame({
  startGameDto,
  cookie
}: {
  startGameDto: StartGameDto
  cookie: Record<string, string>
}): Promise<GameStartUrlEntity> {
  const query = {
    mutation: gql`
      mutation StartGame($dto: StartGameDto!) {
        startGame(startGameDto: $dto) {
          url
          html
          game {
            name
            brand {
              name
            }
            id
          }
        }
      }
    `,
    variables: {
      dto: {
        homeUrl: startGameDto.homeUrl,
        gameId: Number(startGameDto.gameId)
      }
    },

    context: {
      headers: {
        cookie
      }
    }
  }

  const result = await mutate<{ startGame: GameStartUrlEntity }>({
    ...query,
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  })

  return result.startGame
}

export async function getLastPlayedGames(count: number) {
  const request = {
    query: gql`
      query GetLastPlayerGames($count: Float!) {
        getLastPlayerGames(take: $count) {
          id
          brand {
            name
          }
          createdAt
          updatedAt
          name
          devices
          previewImage
          demo
          countries {
            name
          }
          getGameTags {
            name
          }
          gameCategories {
            name
          }
        }
      }
    `,
    variables: {
      count
    }
  }

  const result = await query<{ getLastPlayerGames: Game[] }>(request)

  return result.getLastPlayerGames
}

export const addFavoriteGame = async (gameId: number) => {
  const query = {
    mutation: gql`
      mutation AddFavoriteGame($gameId: Float!) {
        addFavoriteGame(gameId: $gameId) {
          id
          createdAt
          updatedAt
          userId
          gameId
        }
      }
    `,
    variables: {
      gameId
    }
  }

  try {
    const result = await mutate<{ addFavoriteGame: FavoriteGame }>(query)

    return result.addFavoriteGame
  } catch (e) {
    console.log(e)
  }
}

export const removeFavoriteGame = async (gameId: number) => {
  const query = {
    mutation: gql`
      mutation RemoveFavoriteGame($gameId: Float!) {
        removeFavoriteGame(gameId: $gameId) {
          id
          createdAt
          updatedAt
          userId
          gameId
        }
      }
    `,
    variables: {
      gameId
    }
  }

  try {
    const result = await mutate<{ removeFavoriteGame: FavoriteGame }>(query)

    return result.removeFavoriteGame
  } catch (e) {
    console.log(e)
  }
}

export const getFavoriteGames = async ({
  headers
}: {
  headers?: Record<string, string>
}) => {
  const request = {
    query: gql`
      query GetFavoriteGames {
        getFavoriteGames {
          id
          name
          createdAt
          updatedAt
          remoteName
          freespinsEligible
          bonusEligible
          isWagering
          previewImage
          demo
          active
          devices
          percentRate
          getProviderJackpots {
            id
            createdAt
            updatedAt
            currencyId
            value
          }
          getGameTags {
            id
            createdAt
            updatedAt
            name
          }
          brand {
            id
            createdAt
            updatedAt
            name
            active
            provider
            darkPreviewImage
            lightPreviewImage
          }
          gameCategories {
            id
            createdAt
            updatedAt
            name
          }
        }
      }
    `,
    context: {
      headers
    }
  }

  const result = await query<{ getFavoriteGames: Game[] }>(request)

  return result.getFavoriteGames
}
