import { Box, Button, Typography } from '@mui/material'
import leftImage from '@src/assets/images/left-crypto.png'
import rightImage from '@src/assets/images/right-crypto.png'
import { Crypto1 } from '@assets/icons/svg/Crypto1'
import { Crypto4 } from '@assets/icons/svg/Crypto4'
import { Crypto2 } from '@assets/icons/svg/Crypto2'
import { Crypto3 } from '@assets/icons/svg/Crypto3'
import { Crypto5 } from '@assets/icons/svg/Crypto5'
import { Crypto6 } from '@assets/icons/svg/Crypto6'
import { Container } from '@/layouts/container'
import { Arrow } from '@assets/icons/svg/arrow'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'next-i18next'

export const Crypto = () => {
  const theme = useTheme()
  const { t } = useTranslation('home')

  return (
    <Container
      sx={{
        mb: '15px',
        [theme.breakpoints.down(939)]: {
          display: 'none'
        }
      }}
    >
      <Box
        sx={{
          border: `1px solid ${theme.colors.color200}`,
          borderRadius: '4px',
          position: 'relative',
          padding: '2px 14px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            background: `url(${leftImage.src})`,
            height: '100%',
            width: '20%',
            left: 0,
            top: 0,
            zIndex: -1
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            background: `url(${rightImage.src})`,
            height: '100%',
            width: '20%',
            right: 0,
            top: 0,
            zIndex: -1
          }}
        />
        <Box sx={{ display: 'flex', gap: '8px' }}>
          <Crypto1 />
          <Crypto2 />
          <Crypto3 />
          <Crypto4 />
          <Crypto5 />
          <Crypto6 />
        </Box>
        <Arrow color={theme.colors.primary500} />
        <Box>
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: 700,
              textTransform: 'uppercase',
              textAlign: 'center'
            }}
          >
            {t(t('makeADepositInCrypto'))}
          </Typography>
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: 700,
              color: theme.colors.primary500,
              textTransform: 'uppercase',
              textAlign: 'center'
            }}
          >
            {t(t('byCreditCard'))}
          </Typography>
        </Box>
        <Arrow color={theme.colors.primary500} />
        <Button
          variant="outlined"
          color="secondary"
          sx={{
            width: '256px',
            background: theme.colors.color400,
            '&:hover': {
              background: theme.colors.color400
            }
          }}
        >
          {t('buyCrypto')}
        </Button>
      </Box>
    </Container>
  )
}
