import { Box, Typography } from '@mui/material'
import { TitleWithBackAndClose } from '@components/modals/title-with-back-and-close'
import { useTranslation } from 'next-i18next'
import { useStoreMap, useUnit } from 'effector-react'
import {
  $paymentModalOptions,
  closePaymentModal
} from '@components/modals/payment-modal/model'
import { type Step } from '@components/modals/payment-modal/index'
import { useTheme } from '@mui/material/styles'
import { Info } from '@assets/icons/svg/info'

import { useFormContext } from 'react-hook-form'
import {
  $depositPaymentSystems,
  $withdrawPaymentSystems,
  createTransactionFx
} from '@models/payments/model'
import { LoadingButton } from '@components/loading-button'
import { type CreateTransactionNewDto } from '@/__generated__/graphql'
import { Amount } from '@components/modals/payment-modal/amount'
import { CryptoWalletData } from '@components/modals/payment-modal/crypto-wallet'
import { $user } from '@models/user/model'

export const AmountStep = ({
  setStep,
  step,
  paymentSystemId
}: {
  setStep: (step: Step) => void
  step: Step
  paymentSystemId: number
}) => {
  const { t } = useTranslation('common')
  const paymentModalOptions = useUnit($paymentModalOptions)
  const closePaymentModalFunction = useUnit(closePaymentModal)
  const theme = useTheme()

  const createTransactionFxLoading = useUnit(createTransactionFx.pending)
  const depositPaymentSystems = useUnit($depositPaymentSystems)
  const withdrawPaymentSystems = useUnit($withdrawPaymentSystems)

  const { reset } = useFormContext<CreateTransactionNewDto>()

  const systems = paymentModalOptions?.withdraw
    ? withdrawPaymentSystems
    : depositPaymentSystems

  const selectedPaymentMethod = systems.find(
    (system) => system.id === Number(paymentSystemId)
  )
  const isDeposit = !paymentModalOptions?.withdraw
  const isWithdraw = !!paymentModalOptions?.withdraw
  const minDep = Math.ceil(selectedPaymentMethod?.currency?.minValue || 0)
  const maxDep = Math.ceil(selectedPaymentMethod?.currency?.maxValue || 0)
  const userCurrency = useStoreMap($user, (user) => user?.currency?.code)

  return (
    <Box role="tabpanel" hidden={step !== 'amount'}>
      <TitleWithBackAndClose
        title={
          isWithdraw ? t('paymentForm.withdraw') : t('paymentForm.quickDeposit')
        }
        onClickBack={() => {
          setStep('system')
        }}
        onClickClose={() => {
          closePaymentModalFunction()
          setTimeout(() => {
            reset()
            setStep('system')
          }, 500)
        }}
      />
      <Box
        sx={{
          background: theme.colors.color350,
          borderRadius: '24px',
          display: 'flex',
          alignItems: 'center',
          mt: '12px',
          gap: '5px'
        }}
      >
        <Info />
        <Typography sx={{ color: theme.colors.text2, fontSize: '12px' }}>{`${
          isWithdraw ? t('paymentForm.minWithdraw') : t('paymentForm.minDep')
        } ${minDep} ${userCurrency}, ${t(paymentModalOptions?.withdraw ? 'paymentForm.maxWithdraw' : 'paymentForm.maxDep')}  ${maxDep} ${userCurrency}`}</Typography>
      </Box>
      {selectedPaymentMethod?.type !== 'CRYPTO' || isWithdraw ? (
        <Amount />
      ) : null}
      {isDeposit &&
        selectedPaymentMethod?.type === 'CRYPTO' &&
        step === 'amount' && <CryptoWalletData />}

      {selectedPaymentMethod?.message && (
        <Typography sx={{ maxWidth: '346px', mt: '5px' }}>
          {selectedPaymentMethod?.message}
        </Typography>
      )}

      {selectedPaymentMethod?.type !== 'CRYPTO' || isWithdraw ? (
        <LoadingButton
          color="secondary"
          sx={{ borderRadius: '4px', mt: '12px' }}
          type="submit"
          fullWidth
          loading={createTransactionFxLoading}
        >
          {t(
            paymentModalOptions?.withdraw
              ? 'paymentForm.withdraw'
              : 'paymentForm.deposit'
          )}
        </LoadingButton>
      ) : null}
    </Box>
  )
}
