import {
  $activeUserGifts,
  $activeUserPromocode,
  $userGifts,
  activateGiftFx,
  deactivateUserPromocodeFx,
  getActiveUserBonusesFx,
  getActiveUserPromocodeFx,
  getUserBonusesFx
} from '@models/bonuses/model'
import { sample } from 'effector'

$userGifts.on(getUserBonusesFx.doneData, (_, bonuses) => bonuses || [])
$activeUserGifts.on(getActiveUserBonusesFx.doneData, (_, bonuses) =>
  bonuses.filter((bonus) => bonus || [])
)
$activeUserPromocode.on(getActiveUserPromocodeFx.doneData, (_, data) => data)

sample({
  clock: [activateGiftFx.doneData, deactivateUserPromocodeFx.doneData],
  target: getActiveUserPromocodeFx
})
