import { Box, Button, TextField, Typography } from '@mui/material'
import { CURRENCIES, type Currency } from '@/config/common'
import { type CreateTransactionNewDto } from '@/__generated__/graphql'
import { useTranslation } from 'next-i18next'
import { useUnit } from 'effector-react'
import { $paymentModalOptions } from '@components/modals/payment-modal/model'
import { useTheme } from '@mui/material/styles'
import { $currencies, $user, $withdrawableAmount } from '@models/user/model'
import { useMemo, useState } from 'react'

import {
  $depositPaymentSystems,
  $withdrawPaymentSystems
} from '@models/payments/model'
import { useFormContext, useWatch } from 'react-hook-form'
import en from 'react-phone-number-input/locale/en.json'
import PhoneInput from 'react-phone-number-input/input'
import {
  CountrySelect,
  CustomPhoneInput
} from '@/content/profile/verification/phone-verification'
import type { CountryCode } from 'libphonenumber-js'

export const Amount = () => {
  const { t } = useTranslation(['common', 'profile'])

  const theme = useTheme()
  const currencies = useUnit($currencies)
  const user = useUnit($user)
  const depositPaymentSystems = useUnit($depositPaymentSystems)
  const withdrawPaymentSystems = useUnit($withdrawPaymentSystems)
  const paymentModalOptions = useUnit($paymentModalOptions)
  const withdrawableAmount = useUnit($withdrawableAmount)
  const isWithdraw = !!paymentModalOptions?.withdraw

  const {
    control,
    setValue,
    register,
    formState: { errors }
  } = useFormContext<CreateTransactionNewDto>()

  const [country, setCountry] = useState<CountryCode | undefined>(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    user?.country?.code?.toUpperCase()
  )

  const [paymentSystemId, amount, paymentDetails] =
    useWatch<CreateTransactionNewDto>({
      control,
      name: ['paymentSystemId', 'amount', 'paymentDetails']
    })

  const selectedPaymentMethod = useMemo(
    () =>
      (isWithdraw ? withdrawPaymentSystems : depositPaymentSystems).find(
        (system) => system.id === Number(paymentSystemId)
      ),
    [paymentSystemId, depositPaymentSystems]
  )

  const currencyName = currencies.find(
    (currency) => currency.id === user?.currencyId
  )?.name as Currency

  const minDep = Math.ceil(selectedPaymentMethod?.currency?.minValue || 0)
  const maxDep = Math.ceil(selectedPaymentMethod?.currency?.maxValue || 0)

  return (
    <>
      <Box sx={{ mt: '12px' }}>
        {isWithdraw && (
          <>
            <Typography
              sx={{ color: theme.colors.text3, fontSize: '12px', mt: '10px' }}
            >
              {t('paymentForm.withdrawable')}
            </Typography>
            <TextField
              fullWidth
              value={Number(withdrawableAmount).toFixed(2)}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <Typography sx={{ mr: '5px' }}>
                    {CURRENCIES[currencyName]}
                  </Typography>
                )
              }}
            />
          </>
        )}
        <Typography
          sx={{ color: theme.colors.text3, fontSize: '12px', mt: '10px' }}
        >
          {t('paymentForm.amount')}
        </Typography>

        <TextField
          {...register('amount', {
            required: true,
            min: {
              value: minDep,
              message: t('paymentForm.minAmount', {
                value: `${minDep} ${CURRENCIES[currencyName]}`
              })
            },
            max: {
              value: maxDep,
              message: t('paymentForm.maxAmount', {
                value: `${maxDep} ${CURRENCIES[currencyName]}`
              })
            }
          })}
          error={!!errors.amount}
          value={amount}
          sx={{
            background: theme.colors.color300,
            'fieldset, .MuiInputBase-root':
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              currencyName && selectedPaymentMethod?.currency?.options?.length
                ? {
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0
                  }
                : 0
          }}
          InputProps={{
            startAdornment: (
              <Typography sx={{ mr: '5px' }}>
                {CURRENCIES[currencyName]}
              </Typography>
            )
          }}
          onChange={(e) => {
            setValue('amount', Number(e.target.value.trim()))
          }}
          type="number"
          fullWidth
        />
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-expect-error */}
        {currencyName && selectedPaymentMethod?.currency?.options?.length ? (
          <Box
            sx={{
              display: 'flex',
              p: '8px',
              border: `1px solid ${theme.colors.color250}`,
              borderTop: 'none',
              gap: '8px'
            }}
          >
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-expect-error */}
            {selectedPaymentMethod?.currency?.options?.map((option) => (
              <Button
                key={option.id}
                fullWidth
                color={
                  option.value === Number(amount) ? 'secondary' : 'primary'
                }
                sx={{
                  borderRadius: '4px'
                }}
                onClick={() => {
                  setValue('amount', option.value)
                }}
              >
                {
                  CURRENCIES[
                    (currencies.find((cur) => cur.id === option.currencyId)
                      ?.code as Currency) ?? 'USD'
                  ]
                }{' '}
                {option.value}
              </Button>
            ))}
          </Box>
        ) : null}
      </Box>
      {errors.amount && (
        <Typography sx={{ fontSize: '10px', color: theme.colors.error }}>
          {errors.amount.message}
        </Typography>
      )}

      {isWithdraw && (
        <>
          <Typography
            sx={{ color: theme.colors.text3, fontSize: '12px', mt: '10px' }}
          >
            {t('paymentForm.paymentDetails')}
          </Typography>
          <TextField
            {...register('paymentDetails')}
            error={!!errors.paymentDetails}
            value={paymentDetails}
            sx={{
              background: theme.colors.color300
            }}
            onChange={(e) => {
              setValue('paymentDetails', e.target.value)
            }}
            fullWidth
          />
          <Typography
            sx={{ color: theme.colors.text3, fontSize: '12px', mt: '10px' }}
          >
            {t('paymentForm.comment')}
          </Typography>
          <TextField
            {...register('comment')}
            sx={{
              background: theme.colors.color300
            }}
            fullWidth
            onChange={(e) => {
              setValue('comment', e.target.value)
            }}
          />
        </>
      )}

      {selectedPaymentMethod?.inputs?.map((input) => {
        if (input.name.toLowerCase().includes('phone')) {
          return (
            <Box key={input.name}>
              <Typography
                sx={{ color: theme.colors.text3, fontSize: '12px', mt: '10px' }}
              >
                {t(input.label)}
              </Typography>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 4fr',
                  maxWidth: '346px',
                  gap: '10px'
                }}
              >
                <CountrySelect
                  labels={en}
                  value={country}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  onChange={setCountry}
                />
                <PhoneInput
                  country={country}
                  value={paymentDetails?.[input.name]}
                  onChange={(value) => {
                    setValue(`inputValues.${input.name}`, value)
                  }}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  inputComponent={CustomPhoneInput}
                />
              </Box>
            </Box>
          )
        }

        return (
          <Box key={input.name}>
            <Typography
              sx={{ color: theme.colors.text3, fontSize: '12px', mt: '10px' }}
            >
              {t(input.name)}
            </Typography>
            <TextField
              key={input.label}
              {...register(
                `inputValues.${input.name}`
                //   {
                //   validate: (value) => new RegExp(input.regexp).test(value)
                // }
              )}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              error={!!errors?.inputValues?.[input.name]}
              value={paymentDetails?.[input.name]}
              sx={{
                background: theme.colors.color300
              }}
              onChange={(e) => {
                setValue(`inputValues.${input.name}`, e.target.value)
              }}
              fullWidth
            />
          </Box>
        )
      })}
    </>
  )
}
