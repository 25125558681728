import type { Step } from '@components/modals/payment-modal/index'
import { useTranslation } from 'next-i18next'
import { useUnit } from 'effector-react'
import {
  $paymentModalOptions,
  closePaymentModal
} from '@components/modals/payment-modal/model'

import { useFormContext } from 'react-hook-form'
import type { CreateTransactionNewDto } from '@/__generated__/graphql'
import { Box } from '@mui/material'
import { TitleWithBackAndClose } from '@components/modals/title-with-back-and-close'
import { GeneralTab } from '@/content/profile/general'
import { $user } from '@models/user/model'
import { LoadingButton } from '@components/loading-button'
import { createTransactionFx } from '@models/payments/model'

export const ProfileStep = ({
  setStep,
  step
}: {
  setStep: (step: Step) => void
  step: Step
}) => {
  const { t } = useTranslation('common')
  const paymentModalOptions = useUnit($paymentModalOptions)
  const closePaymentModalFunction = useUnit(closePaymentModal)
  const user = useUnit($user)
  const createTransactionFxLoading = useUnit(createTransactionFx.pending)

  const { reset } = useFormContext<CreateTransactionNewDto>()

  return (
    <Box role="tabpanel" hidden={step !== 'profile'} sx={{}}>
      <TitleWithBackAndClose
        title={t('paymentForm.fillProfile')}
        onClickBack={() => {
          setStep('system')
        }}
        onClickClose={() => {
          closePaymentModalFunction()
          setTimeout(() => {
            reset()
            setStep('system')
          }, 500)
        }}
      />
      <GeneralTab currentValue={'1'} value={'1'} />
      {user?.firstName && (
        <LoadingButton
          color="secondary"
          sx={{ borderRadius: '4px', mt: '12px' }}
          type="submit"
          fullWidth
          loading={createTransactionFxLoading}
        >
          {t(
            paymentModalOptions?.withdraw
              ? 'paymentForm.continueWithdraw'
              : 'paymentForm.continueDeposit'
          )}
        </LoadingButton>
      )}
    </Box>
  )
}
