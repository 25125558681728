/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any }
  /** An arbitrary-precision Decimal type */
  Decimal: { input: any; output: any }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any }
}

export type ACreateBonusConfigDto = {
  gamesIds: Array<Scalars['Float']['input']>
  giftConfigId: Scalars['Float']['input']
  maxWinValue?: InputMaybe<Scalars['Float']['input']>
  onlyCashWagering?: InputMaybe<Scalars['Boolean']['input']>
  type: BonusTypes
  value?: InputMaybe<Scalars['Float']['input']>
  valueType: BonusValueType
  wager?: InputMaybe<Scalars['Float']['input']>
}

export type ACreateWalletDto = {
  type: Scalars['String']['input']
  userId: Scalars['Float']['input']
  value: Scalars['Decimal']['input']
  withdraw?: InputMaybe<Scalars['Boolean']['input']>
}

export type AGiftConfig = {
  __typename?: 'AGiftConfig'
  activationTtl: Scalars['Float']['output']
  bonusConfigs?: Maybe<Array<BonusConfig>>
  comment: Scalars['String']['output']
  countries?: Maybe<Array<GiftConfigCountry>>
  countriesIds?: Maybe<Array<Scalars['Float']['output']>>
  createdAt: Scalars['DateTime']['output']
  depCount?: Maybe<Scalars['Float']['output']>
  groupIds?: Maybe<Array<Scalars['Float']['output']>>
  hide: Scalars['Boolean']['output']
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
  promoCodeId?: Maybe<Scalars['Float']['output']>
  promocode?: Maybe<PromocodeConfig>
  refCamp?: Maybe<Scalars['String']['output']>
  referalId?: Maybe<Scalars['String']['output']>
  source: GiftSource
  tagsIds?: Maybe<Array<Scalars['Float']['output']>>
  ttl: Scalars['Float']['output']
  updatedAt: Scalars['DateTime']['output']
  userGroups?: Maybe<Array<UserGroup>>
  userTags?: Maybe<Array<UserTag>>
}

export type AResetWalletDto = {
  type: Scalars['String']['input']
  userId: Scalars['Float']['input']
}

export type AUpdateBonusConfigDto = {
  gamesIds: Array<Scalars['Float']['input']>
  id: Scalars['Float']['input']
  maxWinValue?: InputMaybe<Scalars['Float']['input']>
  onlyCashWagering?: InputMaybe<Scalars['Boolean']['input']>
  type: BonusTypes
  value?: InputMaybe<Scalars['Float']['input']>
  valueType: BonusValueType
  wager?: InputMaybe<Scalars['Float']['input']>
}

export type AdminTransaction = ITransaction & {
  __typename?: 'AdminTransaction'
  adminId?: Maybe<Scalars['String']['output']>
  amount: Scalars['Decimal']['output']
  amountInUserCurrency: Scalars['Float']['output']
  comments?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  currencyId: Scalars['Float']['output']
  externalId?: Maybe<Scalars['String']['output']>
  failMessage: Scalars['String']['output']
  feeValue: Scalars['Float']['output']
  gateway?: Maybe<Gateway>
  gatewayId?: Maybe<Scalars['Float']['output']>
  id: Scalars['String']['output']
  isWagered: Scalars['Boolean']['output']
  merchantId?: Maybe<Scalars['Float']['output']>
  paymentCountry?: Maybe<Scalars['String']['output']>
  paymentDetails?: Maybe<Scalars['String']['output']>
  processing: Scalars['Float']['output']
  promoCode?: Maybe<Scalars['Float']['output']>
  promocode?: Maybe<Promocode>
  relatedTransactionId?: Maybe<Scalars['String']['output']>
  status: ETransactionStatus
  type: ETransactionCreateType
  updatedAt: Scalars['DateTime']['output']
  user: User
  userId: Scalars['Float']['output']
  wagerSum: Scalars['Decimal']['output']
  wageredSum: Scalars['Decimal']['output']
}

export type AdminTransactionMetaType = {
  __typename?: 'AdminTransactionMetaType'
  skip: Scalars['Float']['output']
  take: Scalars['Float']['output']
  total: Scalars['Float']['output']
}

export type ApproveDocumentDto = {
  id: Scalars['Float']['input']
  type: EDocumentType
}

export type AssignManualBonusDto = {
  currencyId: Scalars['Float']['input']
  maxValue: Scalars['Float']['input']
  maxWinValue: Scalars['Float']['input']
  minDepValue?: InputMaybe<Scalars['Float']['input']>
  ttl: Scalars['Float']['input']
  userId: Scalars['Float']['input']
  value: Scalars['Float']['input']
  wager: Scalars['Float']['input']
}

export type AssignManualFreeSpinsDto = {
  bet?: InputMaybe<Scalars['Decimal']['input']>
  betLevel?: InputMaybe<Scalars['Float']['input']>
  count: Scalars['Float']['input']
  denomination?: InputMaybe<Scalars['Decimal']['input']>
  freespinTtl?: InputMaybe<Scalars['Float']['input']>
  gameId: Scalars['Float']['input']
  provider: Scalars['String']['input']
  ttl?: InputMaybe<Scalars['Float']['input']>
  userId: Scalars['Float']['input']
  wager?: InputMaybe<Scalars['Float']['input']>
}

export type BonusConfig = {
  __typename?: 'BonusConfig'
  bonuses?: Maybe<Array<BonusInstance>>
  createdAt: Scalars['DateTime']['output']
  freespinsParams?: Maybe<Array<FreespinsParams>>
  gamesIds: Array<Scalars['Float']['output']>
  id: Scalars['Int']['output']
  maxWinValue?: Maybe<Scalars['Float']['output']>
  onlyCashWagering: Scalars['Boolean']['output']
  rates?: Maybe<Array<BonusConfigRate>>
  type: BonusTypes
  updatedAt: Scalars['DateTime']['output']
  value: Scalars['Float']['output']
  valueType: BonusValueType
  wager: Scalars['Float']['output']
}

export type BonusConfigRate = {
  __typename?: 'BonusConfigRate'
  bonusConfigId: Scalars['Float']['output']
  createdAt: Scalars['DateTime']['output']
  currencyId: Scalars['Float']['output']
  fixedValue?: Maybe<Scalars['Float']['output']>
  id: Scalars['Int']['output']
  maxBet?: Maybe<Scalars['Float']['output']>
  maxValue?: Maybe<Scalars['Float']['output']>
  maxWinValue?: Maybe<Scalars['Float']['output']>
  minDepValue?: Maybe<Scalars['Float']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export type BonusInstance = {
  __typename?: 'BonusInstance'
  config: BonusConfig
  configId: Scalars['Float']['output']
  createdAt: Scalars['DateTime']['output']
  giftId: Scalars['Float']['output']
  id: Scalars['Int']['output']
  updatedAt: Scalars['DateTime']['output']
  wagerBonusSum: Scalars['Float']['output']
  wagered: Scalars['Float']['output']
}

export enum BonusTypes {
  Bonus = 'BONUS',
  Cash = 'CASH',
  Cp = 'CP',
  Freespins = 'FREESPINS',
  LockedBonus = 'LOCKED_BONUS',
  Material = 'MATERIAL'
}

export enum BonusValueType {
  Currency = 'CURRENCY',
  Percent = 'PERCENT',
  Quantity = 'QUANTITY'
}

export type Brand = {
  __typename?: 'Brand'
  active: Scalars['Boolean']['output']
  countries: Array<Country>
  createdAt: Scalars['DateTime']['output']
  currencies: Array<CurrencyEntity>
  darkPreviewImage?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  lightPreviewImage?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  provider: EProvider
  updatedAt: Scalars['DateTime']['output']
}

export type BrandAdmin = {
  __typename?: 'BrandAdmin'
  active: Scalars['Boolean']['output']
  countries: Array<Country>
  createdAt: Scalars['DateTime']['output']
  currencies: Array<CurrencyEntity>
  darkPreviewImage?: Maybe<Scalars['String']['output']>
  externalId: Scalars['Float']['output']
  externalName: Scalars['String']['output']
  hide: Scalars['Boolean']['output']
  id: Scalars['Int']['output']
  lightPreviewImage?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  provider: EProvider
  subExternalId: Scalars['Float']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type BrandAdminMetaType = {
  __typename?: 'BrandAdminMetaType'
  skip: Scalars['Float']['output']
  take: Scalars['Float']['output']
  total: Scalars['Float']['output']
}

export type BrandFilter = {
  AND?: InputMaybe<Array<BrandFilterableFieldsEnumerableNested>>
  NOT?: InputMaybe<Array<BrandFilterableFieldsEnumerableNested>>
  OR?: InputMaybe<Array<BrandFilterableFieldsEnumerableNested>>
  active?: InputMaybe<BrandFilterableFields_Active>
  createdAt?: InputMaybe<BrandFilterableFields_CreatedAt>
  devices?: InputMaybe<BrandFilterableFields_Devices>
  externalId?: InputMaybe<BrandFilterableFields_ExternalId>
  externalName?: InputMaybe<BrandFilterableFields_ExternalName>
  id?: InputMaybe<BrandFilterableFields_Id>
  name?: InputMaybe<BrandFilterableFields_Name>
  provider?: InputMaybe<BrandFilterableFields_Provider>
  subExternalId?: InputMaybe<BrandFilterableFields_SubExternalId>
}

export type BrandFilterableFields = {
  active?: InputMaybe<BrandFilterableFields_Active>
  createdAt?: InputMaybe<BrandFilterableFields_CreatedAt>
  devices?: InputMaybe<BrandFilterableFields_Devices>
  externalId?: InputMaybe<BrandFilterableFields_ExternalId>
  externalName?: InputMaybe<BrandFilterableFields_ExternalName>
  id?: InputMaybe<BrandFilterableFields_Id>
  name?: InputMaybe<BrandFilterableFields_Name>
  provider?: InputMaybe<BrandFilterableFields_Provider>
  subExternalId?: InputMaybe<BrandFilterableFields_SubExternalId>
}

export type BrandFilterableFieldsEnumerableNested = {
  AND?: InputMaybe<Array<BrandFilterableFields>>
  NOT?: InputMaybe<Array<BrandFilterableFields>>
  OR?: InputMaybe<Array<BrandFilterableFields>>
  active?: InputMaybe<BrandFilterableFields_Active>
  createdAt?: InputMaybe<BrandFilterableFields_CreatedAt>
  devices?: InputMaybe<BrandFilterableFields_Devices>
  externalId?: InputMaybe<BrandFilterableFields_ExternalId>
  externalName?: InputMaybe<BrandFilterableFields_ExternalName>
  id?: InputMaybe<BrandFilterableFields_Id>
  name?: InputMaybe<BrandFilterableFields_Name>
  provider?: InputMaybe<BrandFilterableFields_Provider>
  subExternalId?: InputMaybe<BrandFilterableFields_SubExternalId>
}

export type BrandFilterableFields_Active = {
  contains?: InputMaybe<Scalars['Boolean']['input']>
  endsWith?: InputMaybe<Scalars['Boolean']['input']>
  equals?: InputMaybe<Scalars['Boolean']['input']>
  gt?: InputMaybe<Scalars['Boolean']['input']>
  gte?: InputMaybe<Scalars['Boolean']['input']>
  in?: InputMaybe<Array<Scalars['Boolean']['input']>>
  lt?: InputMaybe<Scalars['Boolean']['input']>
  lte?: InputMaybe<Scalars['Boolean']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Boolean']['input']>
  notIn?: InputMaybe<Array<Scalars['Boolean']['input']>>
  startsWith?: InputMaybe<Scalars['Boolean']['input']>
}

export type BrandFilterableFields_CreatedAt = {
  contains?: InputMaybe<Scalars['DateTime']['input']>
  endsWith?: InputMaybe<Scalars['DateTime']['input']>
  equals?: InputMaybe<Scalars['DateTime']['input']>
  gt?: InputMaybe<Scalars['DateTime']['input']>
  gte?: InputMaybe<Scalars['DateTime']['input']>
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  lt?: InputMaybe<Scalars['DateTime']['input']>
  lte?: InputMaybe<Scalars['DateTime']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['DateTime']['input']>
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  startsWith?: InputMaybe<Scalars['DateTime']['input']>
}

export type BrandFilterableFields_Devices = {
  contains?: InputMaybe<EDeviceType>
  endsWith?: InputMaybe<EDeviceType>
  equals?: InputMaybe<EDeviceType>
  gt?: InputMaybe<EDeviceType>
  gte?: InputMaybe<EDeviceType>
  in?: InputMaybe<Array<EDeviceType>>
  lt?: InputMaybe<EDeviceType>
  lte?: InputMaybe<EDeviceType>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<EDeviceType>
  notIn?: InputMaybe<Array<EDeviceType>>
  startsWith?: InputMaybe<EDeviceType>
}

export type BrandFilterableFields_ExternalId = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type BrandFilterableFields_ExternalName = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type BrandFilterableFields_Id = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type BrandFilterableFields_Name = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type BrandFilterableFields_Provider = {
  contains?: InputMaybe<EProvider>
  endsWith?: InputMaybe<EProvider>
  equals?: InputMaybe<EProvider>
  gt?: InputMaybe<EProvider>
  gte?: InputMaybe<EProvider>
  in?: InputMaybe<Array<EProvider>>
  lt?: InputMaybe<EProvider>
  lte?: InputMaybe<EProvider>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<EProvider>
  notIn?: InputMaybe<Array<EProvider>>
  startsWith?: InputMaybe<EProvider>
}

export type BrandFilterableFields_SubExternalId = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type BrandOrderableFields = {
  id?: InputMaybe<OrderEnum>
  name?: InputMaybe<OrderEnum>
}

export type BulkCreateDocumentDto = {
  data: Array<CreateDocumentDto>
}

export type BulkDeleteDocumentDto = {
  data: Array<Scalars['Float']['input']>
}

export type ChangePasswordByAdminDto = {
  newPassword: Scalars['String']['input']
  userId: Scalars['Float']['input']
}

export type ChangePasswordDto = {
  newPassword: Scalars['String']['input']
  oldPassword: Scalars['String']['input']
}

export type ChangePhoneRequestDto = {
  phone: Scalars['String']['input']
}

export type CommitRestorationPasswordDto = {
  password: Scalars['String']['input']
  token: Scalars['String']['input']
}

export type Country = {
  __typename?: 'Country'
  code: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
  projects: Array<Project>
  updatedAt: Scalars['DateTime']['output']
}

export type CreateBonusConfigRateDto = {
  bonusConfigId: Scalars['Float']['input']
  currencyId: Scalars['Float']['input']
  fixedValue?: InputMaybe<Scalars['Float']['input']>
  maxBet?: InputMaybe<Scalars['Float']['input']>
  maxValue?: InputMaybe<Scalars['Float']['input']>
  maxWinValue?: InputMaybe<Scalars['Float']['input']>
  minDepValue?: InputMaybe<Scalars['Float']['input']>
}

export type CreateBonusInstanceDto = {
  configId: Scalars['Float']['input']
  giftId: Scalars['Float']['input']
  wagerBonusSum: Scalars['Float']['input']
  wagered: Scalars['Float']['input']
}

export type CreateBrandDto = {
  active: Scalars['Boolean']['input']
  allowAllCountries?: InputMaybe<Scalars['Boolean']['input']>
  countryIds?: Array<Scalars['Float']['input']>
  currencyIds?: InputMaybe<Array<Scalars['Float']['input']>>
  darkPreviewImage?: InputMaybe<Scalars['String']['input']>
  externalId: Scalars['Float']['input']
  externalName?: InputMaybe<Scalars['String']['input']>
  hide?: InputMaybe<Scalars['Boolean']['input']>
  lightPreviewImage?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  provider: Scalars['String']['input']
  subExternalId?: InputMaybe<Scalars['Float']['input']>
}

export type CreateCountryDto = {
  code: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type CreateDocumentDto = {
  entity: Scalars['String']['input']
  name: Scalars['String']['input']
  type: Scalars['String']['input']
}

export type CreateFreespinsParamsDto = {
  bet?: InputMaybe<Scalars['Decimal']['input']>
  betLevel?: InputMaybe<Scalars['Float']['input']>
  bonusConfigId: Scalars['Float']['input']
  count: Scalars['Float']['input']
  currencyId: Scalars['Float']['input']
  denomination?: InputMaybe<Scalars['Decimal']['input']>
  gameId: Scalars['Float']['input']
  provider: Scalars['String']['input']
  ttl?: InputMaybe<Scalars['Float']['input']>
}

export type CreateGameCategoryDto = {
  name: Scalars['String']['input']
}

export type CreateGameDto = {
  bonusEligible?: InputMaybe<Scalars['Boolean']['input']>
  brandId: Scalars['Float']['input']
  categoryIds?: Array<Scalars['Float']['input']>
  countryIds?: Array<Scalars['Float']['input']>
  demo: Scalars['Boolean']['input']
  devices?: InputMaybe<Scalars['String']['input']>
  freespinsEligible?: InputMaybe<Scalars['Boolean']['input']>
  isWagering?: InputMaybe<Scalars['Boolean']['input']>
  lines?: InputMaybe<Scalars['Float']['input']>
  name: Scalars['String']['input']
  percentRate: Scalars['Decimal']['input']
  previewImage: Scalars['String']['input']
  provider: Scalars['String']['input']
  providerJackpotIds?: Array<Scalars['Float']['input']>
  remoteName: Scalars['String']['input']
  tagIds?: Array<Scalars['Float']['input']>
}

export type CreateGameTagDto = {
  name: Scalars['String']['input']
}

export type CreateGatewayCountryDto = {
  countryId: Scalars['Float']['input']
  gatewayId: Scalars['Float']['input']
  mode: EContainMode
  target: EPaymentTarget
}

export type CreateGatewayCountryNestedDto = {
  countryId: Scalars['Float']['input']
  mode: EContainMode
  target: EPaymentTarget
}

export type CreateGatewayCurrencyDto = {
  currencyId: Scalars['Float']['input']
  gatewayId: Scalars['Float']['input']
  isDefault: Scalars['Boolean']['input']
  maxDepositValue?: InputMaybe<Scalars['Float']['input']>
  maxWithdrawalValue?: InputMaybe<Scalars['Float']['input']>
  minDepositValue?: InputMaybe<Scalars['Float']['input']>
  minWithdrawalValue?: InputMaybe<Scalars['Float']['input']>
}

export type CreateGatewayCurrencyNestedDto = {
  currencyId: Scalars['Float']['input']
  isDefault: Scalars['Boolean']['input']
  maxDepositValue?: InputMaybe<Scalars['Float']['input']>
  maxWithdrawalValue?: InputMaybe<Scalars['Float']['input']>
  minDepositValue?: InputMaybe<Scalars['Float']['input']>
  minWithdrawalValue?: InputMaybe<Scalars['Float']['input']>
}

export type CreateGatewayDto = {
  chargeBackFeeValue?: InputMaybe<Scalars['Float']['input']>
  countries?: InputMaybe<Array<CreateGatewayCountryNestedDto>>
  externalId: Scalars['String']['input']
  fee?: InputMaybe<Scalars['Float']['input']>
  feeAdditionalValue?: InputMaybe<Scalars['Float']['input']>
  feeMinimalValue?: InputMaybe<Scalars['Float']['input']>
  gatewayCurrencies?: InputMaybe<Array<CreateGatewayCurrencyNestedDto>>
  isDefault: Scalars['Boolean']['input']
  minDepositsCount: Scalars['Float']['input']
  minDepositsSum: Scalars['Float']['input']
  name: Scalars['String']['input']
  paymentSystemId: Scalars['Float']['input']
  refundFeeValue?: InputMaybe<Scalars['Float']['input']>
  userGroups?: InputMaybe<Array<CreateGatewayNestedGroupDto>>
  withInvoice?: InputMaybe<Scalars['Boolean']['input']>
  withdrawalEligible: Scalars['Boolean']['input']
}

export type CreateGatewayNestedGroupDto = {
  groupId: Scalars['Float']['input']
  mode: EContainMode
  target: EPaymentTarget
}

export type CreateGatewayUserGroupDto = {
  gatewayId: Scalars['Float']['input']
  groupId: Scalars['Float']['input']
  mode: EContainMode
  target: EPaymentTarget
}

export type CreateGiftConfigDto = {
  activationTtl?: InputMaybe<Scalars['Float']['input']>
  comment?: InputMaybe<Scalars['String']['input']>
  countriesIds?: InputMaybe<Array<Scalars['Float']['input']>>
  depCount?: InputMaybe<Scalars['Float']['input']>
  groupIds?: InputMaybe<Array<Scalars['Float']['input']>>
  hide?: InputMaybe<Scalars['Boolean']['input']>
  image?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  promoCodeId?: InputMaybe<Scalars['Float']['input']>
  refCamp?: InputMaybe<Scalars['String']['input']>
  referalId?: InputMaybe<Scalars['String']['input']>
  source: Scalars['String']['input']
  tagsIds?: InputMaybe<Array<Scalars['Float']['input']>>
  ttl?: InputMaybe<Scalars['Float']['input']>
}

export type CreateGroupDto = {
  description: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type CreateManualTransactionsDto = {
  amount: Scalars['Decimal']['input']
  comments: Scalars['String']['input']
  currencyId: Scalars['Float']['input']
  gatewayId?: InputMaybe<Scalars['Float']['input']>
  userId: Scalars['Float']['input']
}

export type CreatePaymentSystemDto = {
  checkDepositPaymentDetailsForWithdraw?: Scalars['Boolean']['input']
  defaultDarkIcon?: InputMaybe<Scalars['String']['input']>
  defaultIcon: Scalars['String']['input']
  defaultWithdrawDarkIcon?: InputMaybe<Scalars['String']['input']>
  defaultWithdrawIcon?: InputMaybe<Scalars['String']['input']>
  fillProfileRequired?: InputMaybe<Scalars['Boolean']['input']>
  isActive?: Scalars['Boolean']['input']
  name: Scalars['String']['input']
  paymentSystemInputs: Array<CreatePaymentSystemInputNestedDto>
  paymentSystemMessage: Array<CreatePaymentSystemMessageNestedDto>
  projectId: Scalars['Float']['input']
  successDepositForWithdraw?: Scalars['Boolean']['input']
  type: EPaymentSystemType
  valueOptions: Array<CreatePaymentSystemOptionNestedDto>
  visibleInFooter?: Scalars['Boolean']['input']
}

export type CreatePaymentSystemInputDto = {
  label?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  paymentSystemId: Scalars['Float']['input']
  regexp: Scalars['String']['input']
  target?: InputMaybe<EPaymentTarget>
}

export type CreatePaymentSystemInputNestedDto = {
  label?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  regexp: Scalars['String']['input']
  target?: InputMaybe<EPaymentTarget>
}

export type CreatePaymentSystemMessageDto = {
  languageId: Scalars['Float']['input']
  message: Scalars['String']['input']
  paymentSystemId: Scalars['Float']['input']
  target: EPaymentTarget
}

export type CreatePaymentSystemMessageNestedDto = {
  languageId: Scalars['Float']['input']
  message: Scalars['String']['input']
  target: EPaymentTarget
}

export type CreatePaymentSystemOptionDto = {
  currencyId: Scalars['Float']['input']
  paymentSystemId: Scalars['Float']['input']
  value: Scalars['Float']['input']
}

export type CreatePaymentSystemOptionNestedDto = {
  currencyId: Scalars['Float']['input']
  value: Scalars['Float']['input']
}

export type CreatePhoneSenderDto = {
  login: Scalars['String']['input']
  password: Scalars['String']['input']
  projectId: Scalars['Float']['input']
}

export type CreateProjectDto = {
  blockedCountries: Array<Scalars['Float']['input']>
  domain: Scalars['String']['input']
  isLicensed: Scalars['Boolean']['input']
  languages: Array<Scalars['Float']['input']>
  mirrorDomain: Scalars['String']['input']
  name: Scalars['String']['input']
  postalApiKey: Scalars['String']['input']
  wagerDep?: InputMaybe<Scalars['Float']['input']>
}

export type CreatePromocodeConfigDto = {
  comment?: InputMaybe<Scalars['String']['input']>
  giftConfigId: Scalars['Float']['input']
  isActive: Scalars['Boolean']['input']
  name: Scalars['String']['input']
  ratelimitPerUser?: InputMaybe<Scalars['Float']['input']>
  ratelimitPeriodPerUser?: InputMaybe<Scalars['String']['input']>
  stock?: InputMaybe<Scalars['Float']['input']>
  stockPerUser?: InputMaybe<Scalars['Float']['input']>
  type: Scalars['String']['input']
}

export type CreatePromocodeInstanceDto = {
  isCompleted?: Scalars['Boolean']['input']
  name: Scalars['String']['input']
  transactionId?: InputMaybe<Scalars['String']['input']>
}

export type CreateSenderDto = {
  apiKey: Scalars['String']['input']
  description: Scalars['String']['input']
  domain: Scalars['String']['input']
  from: Scalars['String']['input']
  mirrorDomain: Scalars['String']['input']
  name: Scalars['String']['input']
  projectId: Scalars['Float']['input']
  replyTo: Scalars['String']['input']
}

export type CreateTagDto = {
  name: Scalars['String']['input']
}

export type CreateTelegramTriggerInstanceDto = {
  minAfter: Scalars['Float']['input']
  telegramTemplateId: Scalars['Float']['input']
  userId: Scalars['Float']['input']
}

export type CreateTournamentConfigDto = {
  areGamesVisible?: InputMaybe<Scalars['Boolean']['input']>
  duration: Scalars['Float']['input']
  gameCategoriesIds: Array<Scalars['Float']['input']>
  gameTagsIds?: InputMaybe<Array<Scalars['Float']['input']>>
  minBet?: InputMaybe<Scalars['Float']['input']>
  minDepositCount?: InputMaybe<Scalars['Float']['input']>
  pointsFactor: Scalars['Float']['input']
  schedule: Scalars['String']['input']
  startAt: Scalars['DateTime']['input']
  stopAt: Scalars['DateTime']['input']
  type: Scalars['String']['input']
  userTagsIds?: InputMaybe<Array<Scalars['Float']['input']>>
}

export type CreateTournamentImageDto = {
  languageId: Scalars['Float']['input']
  size: Scalars['String']['input']
  tournamentConfigId: Scalars['Float']['input']
  url: Scalars['String']['input']
}

export type CreateTournamentInstanceto = {
  configId: Scalars['Float']['input']
  expiredAt: Scalars['DateTime']['input']
  isActive?: InputMaybe<Scalars['Boolean']['input']>
}

export type CreateTournamentPlaceDto = {
  place: Scalars['Float']['input']
  points: Scalars['Float']['input']
  tournamentId: Scalars['Float']['input']
  userId: Scalars['Float']['input']
}

export type CreateTournamentPrizeDto = {
  giftId: Scalars['Float']['input']
  place: Scalars['Float']['input']
  tournamentId: Scalars['Float']['input']
}

export type CreateTournamentTextDto = {
  languageId: Scalars['Float']['input']
  tournamentId: Scalars['Float']['input']
  type: Scalars['String']['input']
  value: Scalars['String']['input']
}

export type CreateTournamentWinnerDto = {
  giftConfigId: Scalars['Float']['input']
  place: Scalars['Float']['input']
  points: Scalars['Float']['input']
  tournamentId: Scalars['Float']['input']
  userId: Scalars['Float']['input']
}

export type CreateTransactionNewDto = {
  amount: Scalars['Float']['input']
  comment?: InputMaybe<Scalars['String']['input']>
  inputValues: Scalars['JSONObject']['input']
  paymentDetails?: InputMaybe<Scalars['String']['input']>
  paymentSystemId: Scalars['Float']['input']
  promoCode?: InputMaybe<Scalars['Float']['input']>
  type: ETransactionCreateType
}

export type CreateTriggerInstanceDto = {
  minAfter: Scalars['Float']['input']
  telegramTemplateId: Scalars['Float']['input']
  templateId: Scalars['Float']['input']
  userId: Scalars['Float']['input']
}

export type CryptoWallet = {
  __typename?: 'CryptoWallet'
  addressWallet: Scalars['String']['output']
  qrCodeImage: Scalars['String']['output']
  tag?: Maybe<Scalars['String']['output']>
}

export type CurrencyEntity = {
  __typename?: 'CurrencyEntity'
  code: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type DepositPaymentSystem = {
  __typename?: 'DepositPaymentSystem'
  currency?: Maybe<DepositPaymentSystemCurrency>
  fillProfileRequired: Scalars['Boolean']['output']
  icons?: Maybe<Array<Icon>>
  id: Scalars['Float']['output']
  inputs: Array<PaymentSystemInput>
  message?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  options: Array<PaymentSystemOption>
  type: EPaymentSystemType
  withInvoice: Scalars['Boolean']['output']
}

export type DepositPaymentSystemCurrency = {
  __typename?: 'DepositPaymentSystemCurrency'
  code: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['Int']['output']
  maxValue: Scalars['Float']['output']
  minValue: Scalars['Float']['output']
  name: Scalars['String']['output']
  options?: Maybe<Array<PaymentSystemOption>>
  paymentSystemId: Scalars['Float']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type DocumentFilter = {
  AND?: InputMaybe<Array<DocumentFilterableFieldsEnumerableNested>>
  NOT?: InputMaybe<Array<DocumentFilterableFieldsEnumerableNested>>
  OR?: InputMaybe<Array<DocumentFilterableFieldsEnumerableNested>>
  createdAt?: InputMaybe<DocumentFilterableFields_CreatedAt>
  fileId?: InputMaybe<DocumentFilterableFields_FileId>
  id?: InputMaybe<DocumentFilterableFields_Id>
  isAccepted?: InputMaybe<DocumentFilterableFields_IsAccepted>
  type?: InputMaybe<DocumentFilterableFields_Type>
  user?: InputMaybe<UserFilter>
  userId?: InputMaybe<DocumentFilterableFields_UserId>
}

export type DocumentFilterableFields = {
  createdAt?: InputMaybe<DocumentFilterableFields_CreatedAt>
  fileId?: InputMaybe<DocumentFilterableFields_FileId>
  id?: InputMaybe<DocumentFilterableFields_Id>
  isAccepted?: InputMaybe<DocumentFilterableFields_IsAccepted>
  type?: InputMaybe<DocumentFilterableFields_Type>
  user?: InputMaybe<UserFilter>
  userId?: InputMaybe<DocumentFilterableFields_UserId>
}

export type DocumentFilterableFieldsEnumerableNested = {
  AND?: InputMaybe<Array<DocumentFilterableFields>>
  NOT?: InputMaybe<Array<DocumentFilterableFields>>
  OR?: InputMaybe<Array<DocumentFilterableFields>>
  createdAt?: InputMaybe<DocumentFilterableFields_CreatedAt>
  fileId?: InputMaybe<DocumentFilterableFields_FileId>
  id?: InputMaybe<DocumentFilterableFields_Id>
  isAccepted?: InputMaybe<DocumentFilterableFields_IsAccepted>
  type?: InputMaybe<DocumentFilterableFields_Type>
  user?: InputMaybe<UserFilter>
  userId?: InputMaybe<DocumentFilterableFields_UserId>
}

export type DocumentFilterableFields_CreatedAt = {
  contains?: InputMaybe<Scalars['DateTime']['input']>
  endsWith?: InputMaybe<Scalars['DateTime']['input']>
  equals?: InputMaybe<Scalars['DateTime']['input']>
  gt?: InputMaybe<Scalars['DateTime']['input']>
  gte?: InputMaybe<Scalars['DateTime']['input']>
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  lt?: InputMaybe<Scalars['DateTime']['input']>
  lte?: InputMaybe<Scalars['DateTime']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['DateTime']['input']>
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  startsWith?: InputMaybe<Scalars['DateTime']['input']>
}

export type DocumentFilterableFields_FileId = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type DocumentFilterableFields_Id = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type DocumentFilterableFields_IsAccepted = {
  contains?: InputMaybe<Scalars['Boolean']['input']>
  endsWith?: InputMaybe<Scalars['Boolean']['input']>
  equals?: InputMaybe<Scalars['Boolean']['input']>
  gt?: InputMaybe<Scalars['Boolean']['input']>
  gte?: InputMaybe<Scalars['Boolean']['input']>
  in?: InputMaybe<Array<Scalars['Boolean']['input']>>
  lt?: InputMaybe<Scalars['Boolean']['input']>
  lte?: InputMaybe<Scalars['Boolean']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Boolean']['input']>
  notIn?: InputMaybe<Array<Scalars['Boolean']['input']>>
  startsWith?: InputMaybe<Scalars['Boolean']['input']>
}

export type DocumentFilterableFields_Type = {
  contains?: InputMaybe<EDocumentType>
  endsWith?: InputMaybe<EDocumentType>
  equals?: InputMaybe<EDocumentType>
  gt?: InputMaybe<EDocumentType>
  gte?: InputMaybe<EDocumentType>
  in?: InputMaybe<Array<EDocumentType>>
  lt?: InputMaybe<EDocumentType>
  lte?: InputMaybe<EDocumentType>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<EDocumentType>
  notIn?: InputMaybe<Array<EDocumentType>>
  startsWith?: InputMaybe<EDocumentType>
}

export type DocumentFilterableFields_UserId = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type DocumentOrderableFields = {
  createdAt?: InputMaybe<OrderEnum>
  fileId?: InputMaybe<OrderEnum>
  id?: InputMaybe<OrderEnum>
  isAccepted?: InputMaybe<OrderEnum>
  type?: InputMaybe<OrderEnum>
  userId?: InputMaybe<OrderEnum>
}

export enum EBonusValueType {
  Currency = 'CURRENCY',
  Percent = 'PERCENT',
  Quantity = 'QUANTITY'
}

export enum EContainMode {
  Exclude = 'EXCLUDE',
  Include = 'INCLUDE'
}

export enum EDeviceType {
  All = 'ALL',
  Mobile = 'MOBILE',
  Pc = 'PC'
}

export enum EDocumentType {
  Address = 'Address',
  Cards = 'Cards',
  DriveLicence = 'DriveLicence',
  Face = 'Face',
  Other = 'Other',
  PassportId = 'PassportId'
}

export enum EGiftStatuses {
  Active = 'ACTIVE',
  Available = 'AVAILABLE',
  CanceledByAdmin = 'CANCELED_BY_ADMIN',
  CanceledByBan = 'CANCELED_BY_BAN',
  CanceledByNewBonus = 'CANCELED_BY_NEW_BONUS',
  CanceledByWithdrawal = 'CANCELED_BY_WITHDRAWAL',
  Completed = 'COMPLETED',
  ExpiredActivation = 'EXPIRED_ACTIVATION',
  ExpiredWagering = 'EXPIRED_WAGERING',
  Lost = 'LOST'
}

export enum EIconTheme {
  Dark = 'DARK',
  Default = 'DEFAULT'
}

export enum EPaymentSystemType {
  Bank = 'BANK',
  Card = 'CARD',
  Crypto = 'CRYPTO',
  Wallet = 'WALLET'
}

export enum EPaymentTarget {
  Deposit = 'DEPOSIT',
  Withdraw = 'WITHDRAW'
}

export enum EPromocodeActivationPeriods {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

export enum EPromocodeType {
  Deposit = 'DEPOSIT',
  Registration = 'REGISTRATION',
  Standalone = 'STANDALONE'
}

export enum EProvider {
  B2B = 'B2B',
  Igromat = 'IGROMAT',
  Mascot = 'MASCOT',
  Outcomebet = 'OUTCOMEBET',
  Slotegrator = 'SLOTEGRATOR',
  Softgamings = 'SOFTGAMINGS'
}

export enum ETransactionCreateType {
  Chargeback = 'CHARGEBACK',
  Deposit = 'DEPOSIT',
  Refund = 'REFUND',
  Withdrawal = 'WITHDRAWAL'
}

export enum ETransactionStatus {
  Decline = 'DECLINE',
  Failed = 'FAILED',
  Hold = 'HOLD',
  Pending = 'PENDING',
  Success = 'SUCCESS',
  TimedOut = 'TIMED_OUT'
}

export enum ETriggerType {
  AnyLastDeposit = 'AnyLastDeposit',
  AnyLastLogginIn = 'AnyLastLogginIn',
  FirstDepositSucceed = 'FirstDepositSucceed',
  SecondDepositSucceed = 'SecondDepositSucceed',
  ThirdDepositSucceed = 'ThirdDepositSucceed',
  UserBlockedSystem = 'UserBlockedSystem',
  UserEmailVerified = 'UserEmailVerified',
  UserPasswordRestore = 'UserPasswordRestore',
  UserRegisteredSystem = 'UserRegisteredSystem'
}

export enum EUserSex {
  Female = 'FEMALE',
  Male = 'MALE'
}

export enum EUserStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  Suspended = 'SUSPENDED'
}

export enum EValueFieldCase {
  Default = 'DEFAULT',
  Insensitive = 'INSENSITIVE'
}

export enum EWalletType {
  Bonus = 'BONUS',
  Cash = 'CASH',
  Cp = 'CP',
  Freespins = 'FREESPINS',
  LockedBonus = 'LOCKED_BONUS'
}

export type FavoriteGame = {
  __typename?: 'FavoriteGame'
  createdAt: Scalars['DateTime']['output']
  gameId: Scalars['Float']['output']
  id: Scalars['Int']['output']
  updatedAt: Scalars['DateTime']['output']
  userId: Scalars['Float']['output']
}

export type FilterUserTransactions = {
  status?: InputMaybe<ETransactionStatus>
  type?: InputMaybe<ETransactionCreateType>
}

export type FreespinSetting = {
  __typename?: 'FreespinSetting'
  bet?: Maybe<Scalars['Float']['output']>
  betLevel?: Maybe<Scalars['Float']['output']>
  denomination?: Maybe<Scalars['Float']['output']>
  gameId: Scalars['Float']['output']
}

export type FreespinsParams = {
  __typename?: 'FreespinsParams'
  bet: Scalars['Float']['output']
  betLevel: Scalars['Float']['output']
  bonusConfig: BonusConfig
  count: Scalars['Float']['output']
  createdAt: Scalars['DateTime']['output']
  currency: CurrencyEntity
  currencyId: Scalars['Float']['output']
  denomination: Scalars['Float']['output']
  gameId: Scalars['Float']['output']
  id: Scalars['Int']['output']
  provider: EProvider
  ttl?: Maybe<Scalars['Float']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export type Game = {
  __typename?: 'Game'
  active: Scalars['Boolean']['output']
  bonusEligible: Scalars['Boolean']['output']
  brand: Brand
  countries: Array<Country>
  createdAt: Scalars['DateTime']['output']
  demo: Scalars['Boolean']['output']
  devices: EDeviceType
  freespinsEligible: Scalars['Boolean']['output']
  gameCategories: Array<GameCategory>
  getGameTags: Array<GameTag>
  getProviderJackpots: Array<ProviderJackpot>
  id: Scalars['Float']['output']
  isWagering: Scalars['Boolean']['output']
  lines: Scalars['Float']['output']
  name: Scalars['String']['output']
  percentRate: Scalars['Decimal']['output']
  previewImage: Scalars['String']['output']
  remoteName: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type GameCategory = {
  __typename?: 'GameCategory'
  createdAt: Scalars['DateTime']['output']
  games: Array<Game>
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type GameCategoryFilter = {
  AND?: InputMaybe<Array<GameCategoryFilterableFieldsEnumerableNested>>
  NOT?: InputMaybe<Array<GameCategoryFilterableFieldsEnumerableNested>>
  OR?: InputMaybe<Array<GameCategoryFilterableFieldsEnumerableNested>>
  id?: InputMaybe<GameCategoryFilterableFields_Id>
  name?: InputMaybe<GameCategoryFilterableFields_Name>
}

export type GameCategoryFilterableFields = {
  id?: InputMaybe<GameCategoryFilterableFields_Id>
  name?: InputMaybe<GameCategoryFilterableFields_Name>
}

export type GameCategoryFilterableFieldsEnumerableNested = {
  AND?: InputMaybe<Array<GameCategoryFilterableFields>>
  NOT?: InputMaybe<Array<GameCategoryFilterableFields>>
  OR?: InputMaybe<Array<GameCategoryFilterableFields>>
  id?: InputMaybe<GameCategoryFilterableFields_Id>
  name?: InputMaybe<GameCategoryFilterableFields_Name>
}

export type GameCategoryFilterableFields_Id = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type GameCategoryFilterableFields_Name = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type GameFilter = {
  AND?: InputMaybe<Array<GameFilterableFieldsEnumerableNested>>
  NOT?: InputMaybe<Array<GameFilterableFieldsEnumerableNested>>
  OR?: InputMaybe<Array<GameFilterableFieldsEnumerableNested>>
  active?: InputMaybe<GameFilterableFields_Active>
  bonusEligible?: InputMaybe<GameFilterableFields_BonusEligible>
  brand?: InputMaybe<BrandFilter>
  categories?: InputMaybe<GameFilterableFields_Categories>
  createdAt?: InputMaybe<GameFilterableFields_CreatedAt>
  demo?: InputMaybe<GameFilterableFields_Demo>
  devices?: InputMaybe<GameFilterableFields_Devices>
  freespinsEligible?: InputMaybe<GameFilterableFields_FreespinsEligible>
  id?: InputMaybe<GameFilterableFields_Id>
  isWagering?: InputMaybe<GameFilterableFields_IsWagering>
  lines?: InputMaybe<GameFilterableFields_Lines>
  name?: InputMaybe<GameFilterableFields_Name>
  remoteName?: InputMaybe<GameFilterableFields_RemoteName>
  tags?: InputMaybe<GameFilterableFields_Tags>
}

export type GameFilterableFields = {
  active?: InputMaybe<GameFilterableFields_Active>
  bonusEligible?: InputMaybe<GameFilterableFields_BonusEligible>
  brand?: InputMaybe<BrandFilter>
  categories?: InputMaybe<GameFilterableFields_Categories>
  createdAt?: InputMaybe<GameFilterableFields_CreatedAt>
  demo?: InputMaybe<GameFilterableFields_Demo>
  devices?: InputMaybe<GameFilterableFields_Devices>
  freespinsEligible?: InputMaybe<GameFilterableFields_FreespinsEligible>
  id?: InputMaybe<GameFilterableFields_Id>
  isWagering?: InputMaybe<GameFilterableFields_IsWagering>
  lines?: InputMaybe<GameFilterableFields_Lines>
  name?: InputMaybe<GameFilterableFields_Name>
  remoteName?: InputMaybe<GameFilterableFields_RemoteName>
  tags?: InputMaybe<GameFilterableFields_Tags>
}

export type GameFilterableFieldsEnumerableNested = {
  AND?: InputMaybe<Array<GameFilterableFields>>
  NOT?: InputMaybe<Array<GameFilterableFields>>
  OR?: InputMaybe<Array<GameFilterableFields>>
  active?: InputMaybe<GameFilterableFields_Active>
  bonusEligible?: InputMaybe<GameFilterableFields_BonusEligible>
  brand?: InputMaybe<BrandFilter>
  categories?: InputMaybe<GameFilterableFields_Categories>
  createdAt?: InputMaybe<GameFilterableFields_CreatedAt>
  demo?: InputMaybe<GameFilterableFields_Demo>
  devices?: InputMaybe<GameFilterableFields_Devices>
  freespinsEligible?: InputMaybe<GameFilterableFields_FreespinsEligible>
  id?: InputMaybe<GameFilterableFields_Id>
  isWagering?: InputMaybe<GameFilterableFields_IsWagering>
  lines?: InputMaybe<GameFilterableFields_Lines>
  name?: InputMaybe<GameFilterableFields_Name>
  remoteName?: InputMaybe<GameFilterableFields_RemoteName>
  tags?: InputMaybe<GameFilterableFields_Tags>
}

export type GameFilterableFields_Active = {
  contains?: InputMaybe<Scalars['Boolean']['input']>
  endsWith?: InputMaybe<Scalars['Boolean']['input']>
  equals?: InputMaybe<Scalars['Boolean']['input']>
  gt?: InputMaybe<Scalars['Boolean']['input']>
  gte?: InputMaybe<Scalars['Boolean']['input']>
  in?: InputMaybe<Array<Scalars['Boolean']['input']>>
  lt?: InputMaybe<Scalars['Boolean']['input']>
  lte?: InputMaybe<Scalars['Boolean']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Boolean']['input']>
  notIn?: InputMaybe<Array<Scalars['Boolean']['input']>>
  startsWith?: InputMaybe<Scalars['Boolean']['input']>
}

export type GameFilterableFields_BonusEligible = {
  contains?: InputMaybe<Scalars['Boolean']['input']>
  endsWith?: InputMaybe<Scalars['Boolean']['input']>
  equals?: InputMaybe<Scalars['Boolean']['input']>
  gt?: InputMaybe<Scalars['Boolean']['input']>
  gte?: InputMaybe<Scalars['Boolean']['input']>
  in?: InputMaybe<Array<Scalars['Boolean']['input']>>
  lt?: InputMaybe<Scalars['Boolean']['input']>
  lte?: InputMaybe<Scalars['Boolean']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Boolean']['input']>
  notIn?: InputMaybe<Array<Scalars['Boolean']['input']>>
  startsWith?: InputMaybe<Scalars['Boolean']['input']>
}

export type GameFilterableFields_Categories = {
  every?: InputMaybe<GameCategoryFilter>
  none?: InputMaybe<GameCategoryFilter>
  some?: InputMaybe<GameCategoryFilter>
}

export type GameFilterableFields_CreatedAt = {
  contains?: InputMaybe<Scalars['DateTime']['input']>
  endsWith?: InputMaybe<Scalars['DateTime']['input']>
  equals?: InputMaybe<Scalars['DateTime']['input']>
  gt?: InputMaybe<Scalars['DateTime']['input']>
  gte?: InputMaybe<Scalars['DateTime']['input']>
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  lt?: InputMaybe<Scalars['DateTime']['input']>
  lte?: InputMaybe<Scalars['DateTime']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['DateTime']['input']>
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  startsWith?: InputMaybe<Scalars['DateTime']['input']>
}

export type GameFilterableFields_Demo = {
  contains?: InputMaybe<Scalars['Boolean']['input']>
  endsWith?: InputMaybe<Scalars['Boolean']['input']>
  equals?: InputMaybe<Scalars['Boolean']['input']>
  gt?: InputMaybe<Scalars['Boolean']['input']>
  gte?: InputMaybe<Scalars['Boolean']['input']>
  in?: InputMaybe<Array<Scalars['Boolean']['input']>>
  lt?: InputMaybe<Scalars['Boolean']['input']>
  lte?: InputMaybe<Scalars['Boolean']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Boolean']['input']>
  notIn?: InputMaybe<Array<Scalars['Boolean']['input']>>
  startsWith?: InputMaybe<Scalars['Boolean']['input']>
}

export type GameFilterableFields_Devices = {
  contains?: InputMaybe<EDeviceType>
  endsWith?: InputMaybe<EDeviceType>
  equals?: InputMaybe<EDeviceType>
  gt?: InputMaybe<EDeviceType>
  gte?: InputMaybe<EDeviceType>
  in?: InputMaybe<Array<EDeviceType>>
  lt?: InputMaybe<EDeviceType>
  lte?: InputMaybe<EDeviceType>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<EDeviceType>
  notIn?: InputMaybe<Array<EDeviceType>>
  startsWith?: InputMaybe<EDeviceType>
}

export type GameFilterableFields_FreespinsEligible = {
  contains?: InputMaybe<Scalars['Boolean']['input']>
  endsWith?: InputMaybe<Scalars['Boolean']['input']>
  equals?: InputMaybe<Scalars['Boolean']['input']>
  gt?: InputMaybe<Scalars['Boolean']['input']>
  gte?: InputMaybe<Scalars['Boolean']['input']>
  in?: InputMaybe<Array<Scalars['Boolean']['input']>>
  lt?: InputMaybe<Scalars['Boolean']['input']>
  lte?: InputMaybe<Scalars['Boolean']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Boolean']['input']>
  notIn?: InputMaybe<Array<Scalars['Boolean']['input']>>
  startsWith?: InputMaybe<Scalars['Boolean']['input']>
}

export type GameFilterableFields_Id = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type GameFilterableFields_IsWagering = {
  contains?: InputMaybe<Scalars['Boolean']['input']>
  endsWith?: InputMaybe<Scalars['Boolean']['input']>
  equals?: InputMaybe<Scalars['Boolean']['input']>
  gt?: InputMaybe<Scalars['Boolean']['input']>
  gte?: InputMaybe<Scalars['Boolean']['input']>
  in?: InputMaybe<Array<Scalars['Boolean']['input']>>
  lt?: InputMaybe<Scalars['Boolean']['input']>
  lte?: InputMaybe<Scalars['Boolean']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Boolean']['input']>
  notIn?: InputMaybe<Array<Scalars['Boolean']['input']>>
  startsWith?: InputMaybe<Scalars['Boolean']['input']>
}

export type GameFilterableFields_Lines = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type GameFilterableFields_Name = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type GameFilterableFields_RemoteName = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type GameFilterableFields_Tags = {
  every?: InputMaybe<GameTagFilter>
  none?: InputMaybe<GameTagFilter>
  some?: InputMaybe<GameTagFilter>
}

export type GameFromBonus = {
  __typename?: 'GameFromBonus'
  id?: Maybe<Scalars['Float']['output']>
  name?: Maybe<Scalars['String']['output']>
  previewImage?: Maybe<Scalars['String']['output']>
}

export type GameHistory = {
  __typename?: 'GameHistory'
  betCount: Scalars['Float']['output']
  endPeriod: Scalars['DateTime']['output']
  endSpin: Scalars['DateTime']['output']
  game: Game
  gameId: Scalars['Float']['output']
  ggr: Scalars['Float']['output']
  id: Scalars['Float']['output']
  maxBet: Scalars['Float']['output']
  maxWin: Scalars['Float']['output']
  startBalance: Scalars['Float']['output']
  startPeriod: Scalars['DateTime']['output']
  startSpin: Scalars['DateTime']['output']
  totalBetAmount: Scalars['Float']['output']
  totalWinAmount: Scalars['Float']['output']
  user: User
  userId: Scalars['Float']['output']
  winCount: Scalars['Float']['output']
}

export type GameHistoryFilter = {
  AND?: InputMaybe<Array<GameHistoryFilterableFieldsEnumerableNested>>
  NOT?: InputMaybe<Array<GameHistoryFilterableFieldsEnumerableNested>>
  OR?: InputMaybe<Array<GameHistoryFilterableFieldsEnumerableNested>>
  betCount?: InputMaybe<GameHistoryFilterableFields_BetCount>
  endPeriod?: InputMaybe<GameHistoryFilterableFields_EndPeriod>
  game?: InputMaybe<GameFilter>
  gameId?: InputMaybe<GameHistoryFilterableFields_GameId>
  ggr?: InputMaybe<GameHistoryFilterableFields_Ggr>
  id?: InputMaybe<GameHistoryFilterableFields_Id>
  maxBet?: InputMaybe<GameHistoryFilterableFields_MaxBet>
  maxWin?: InputMaybe<GameHistoryFilterableFields_MaxWin>
  startBalance?: InputMaybe<GameHistoryFilterableFields_StartBalance>
  startPeriod?: InputMaybe<GameHistoryFilterableFields_StartPeriod>
  totalBetAmount?: InputMaybe<GameHistoryFilterableFields_TotalBetAmount>
  totalWinAmount?: InputMaybe<GameHistoryFilterableFields_TotalWinAmount>
  userId?: InputMaybe<GameHistoryFilterableFields_UserId>
  winCount?: InputMaybe<GameHistoryFilterableFields_WinCount>
}

export type GameHistoryFilterableFields = {
  betCount?: InputMaybe<GameHistoryFilterableFields_BetCount>
  endPeriod?: InputMaybe<GameHistoryFilterableFields_EndPeriod>
  game?: InputMaybe<GameFilter>
  gameId?: InputMaybe<GameHistoryFilterableFields_GameId>
  ggr?: InputMaybe<GameHistoryFilterableFields_Ggr>
  id?: InputMaybe<GameHistoryFilterableFields_Id>
  maxBet?: InputMaybe<GameHistoryFilterableFields_MaxBet>
  maxWin?: InputMaybe<GameHistoryFilterableFields_MaxWin>
  startBalance?: InputMaybe<GameHistoryFilterableFields_StartBalance>
  startPeriod?: InputMaybe<GameHistoryFilterableFields_StartPeriod>
  totalBetAmount?: InputMaybe<GameHistoryFilterableFields_TotalBetAmount>
  totalWinAmount?: InputMaybe<GameHistoryFilterableFields_TotalWinAmount>
  userId?: InputMaybe<GameHistoryFilterableFields_UserId>
  winCount?: InputMaybe<GameHistoryFilterableFields_WinCount>
}

export type GameHistoryFilterableFieldsEnumerableNested = {
  AND?: InputMaybe<Array<GameHistoryFilterableFields>>
  NOT?: InputMaybe<Array<GameHistoryFilterableFields>>
  OR?: InputMaybe<Array<GameHistoryFilterableFields>>
  betCount?: InputMaybe<GameHistoryFilterableFields_BetCount>
  endPeriod?: InputMaybe<GameHistoryFilterableFields_EndPeriod>
  game?: InputMaybe<GameFilter>
  gameId?: InputMaybe<GameHistoryFilterableFields_GameId>
  ggr?: InputMaybe<GameHistoryFilterableFields_Ggr>
  id?: InputMaybe<GameHistoryFilterableFields_Id>
  maxBet?: InputMaybe<GameHistoryFilterableFields_MaxBet>
  maxWin?: InputMaybe<GameHistoryFilterableFields_MaxWin>
  startBalance?: InputMaybe<GameHistoryFilterableFields_StartBalance>
  startPeriod?: InputMaybe<GameHistoryFilterableFields_StartPeriod>
  totalBetAmount?: InputMaybe<GameHistoryFilterableFields_TotalBetAmount>
  totalWinAmount?: InputMaybe<GameHistoryFilterableFields_TotalWinAmount>
  userId?: InputMaybe<GameHistoryFilterableFields_UserId>
  winCount?: InputMaybe<GameHistoryFilterableFields_WinCount>
}

export type GameHistoryFilterableFields_BetCount = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type GameHistoryFilterableFields_EndPeriod = {
  contains?: InputMaybe<Scalars['DateTime']['input']>
  endsWith?: InputMaybe<Scalars['DateTime']['input']>
  equals?: InputMaybe<Scalars['DateTime']['input']>
  gt?: InputMaybe<Scalars['DateTime']['input']>
  gte?: InputMaybe<Scalars['DateTime']['input']>
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  lt?: InputMaybe<Scalars['DateTime']['input']>
  lte?: InputMaybe<Scalars['DateTime']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['DateTime']['input']>
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  startsWith?: InputMaybe<Scalars['DateTime']['input']>
}

export type GameHistoryFilterableFields_GameId = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type GameHistoryFilterableFields_Ggr = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type GameHistoryFilterableFields_Id = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type GameHistoryFilterableFields_MaxBet = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type GameHistoryFilterableFields_MaxWin = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type GameHistoryFilterableFields_StartBalance = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type GameHistoryFilterableFields_StartPeriod = {
  contains?: InputMaybe<Scalars['DateTime']['input']>
  endsWith?: InputMaybe<Scalars['DateTime']['input']>
  equals?: InputMaybe<Scalars['DateTime']['input']>
  gt?: InputMaybe<Scalars['DateTime']['input']>
  gte?: InputMaybe<Scalars['DateTime']['input']>
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  lt?: InputMaybe<Scalars['DateTime']['input']>
  lte?: InputMaybe<Scalars['DateTime']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['DateTime']['input']>
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  startsWith?: InputMaybe<Scalars['DateTime']['input']>
}

export type GameHistoryFilterableFields_TotalBetAmount = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type GameHistoryFilterableFields_TotalWinAmount = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type GameHistoryFilterableFields_UserId = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type GameHistoryFilterableFields_WinCount = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type GameHistoryMetaType = {
  __typename?: 'GameHistoryMetaType'
  skip: Scalars['Float']['output']
  take: Scalars['Float']['output']
  total: Scalars['Float']['output']
}

export type GameHistoryOrderableFields = {
  betCount?: InputMaybe<OrderEnum>
  endPeriod?: InputMaybe<OrderEnum>
  gameId?: InputMaybe<OrderEnum>
  ggr?: InputMaybe<OrderEnum>
  maxBet?: InputMaybe<OrderEnum>
  maxWin?: InputMaybe<OrderEnum>
  startPeriod?: InputMaybe<OrderEnum>
  totalBetAmount?: InputMaybe<OrderEnum>
  totalWinAmoun?: InputMaybe<OrderEnum>
  userId?: InputMaybe<OrderEnum>
  winCount?: InputMaybe<OrderEnum>
}

export type GameMetaType = {
  __typename?: 'GameMetaType'
  skip: Scalars['Float']['output']
  take: Scalars['Float']['output']
  total: Scalars['Float']['output']
}

export type GameOrderableFields = {
  active?: InputMaybe<OrderEnum>
  bonusEligible?: InputMaybe<OrderEnum>
  brandId?: InputMaybe<OrderEnum>
  createdAt?: InputMaybe<OrderEnum>
  demo?: InputMaybe<OrderEnum>
  devices?: InputMaybe<OrderEnum>
  freespinsEligible?: InputMaybe<OrderEnum>
  isWagering?: InputMaybe<OrderEnum>
  lines?: InputMaybe<OrderEnum>
  name?: InputMaybe<OrderEnum>
  previewImage?: InputMaybe<OrderEnum>
  remoteName?: InputMaybe<OrderEnum>
}

export type GameStartUrlEntity = {
  __typename?: 'GameStartUrlEntity'
  game: Game
  html?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
}

export type GameTag = {
  __typename?: 'GameTag'
  createdAt: Scalars['DateTime']['output']
  games: Array<Game>
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type GameTagFilter = {
  AND?: InputMaybe<Array<GameTagFilterableFieldsEnumerableNested>>
  NOT?: InputMaybe<Array<GameTagFilterableFieldsEnumerableNested>>
  OR?: InputMaybe<Array<GameTagFilterableFieldsEnumerableNested>>
  id?: InputMaybe<GameTagFilterableFields_Id>
  name?: InputMaybe<GameTagFilterableFields_Name>
}

export type GameTagFilterableFields = {
  id?: InputMaybe<GameTagFilterableFields_Id>
  name?: InputMaybe<GameTagFilterableFields_Name>
}

export type GameTagFilterableFieldsEnumerableNested = {
  AND?: InputMaybe<Array<GameTagFilterableFields>>
  NOT?: InputMaybe<Array<GameTagFilterableFields>>
  OR?: InputMaybe<Array<GameTagFilterableFields>>
  id?: InputMaybe<GameTagFilterableFields_Id>
  name?: InputMaybe<GameTagFilterableFields_Name>
}

export type GameTagFilterableFields_Id = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type GameTagFilterableFields_Name = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type Gateway = {
  __typename?: 'Gateway'
  chargeBackFeeValue: Scalars['Float']['output']
  countries: Array<GatewayCountry>
  currencies: Array<GatewayCurrency>
  externalId: Scalars['String']['output']
  fee: Scalars['Float']['output']
  feeAdditionalValue: Scalars['Float']['output']
  feeMinimalValue: Scalars['Float']['output']
  gatewayCurrencies: Array<GatewayCurrency>
  id: Scalars['Float']['output']
  isDefault: Scalars['Boolean']['output']
  minDepositsCount: Scalars['Float']['output']
  minDepositsSum: Scalars['Float']['output']
  name: Scalars['String']['output']
  paymentSystem: PaymentSystem
  paymentSystemId: Scalars['Float']['output']
  refundFeeValue: Scalars['Float']['output']
  userGroups: Array<GatewayUserGroup>
  withInvoice: Scalars['Boolean']['output']
  withdrawalEligible: Scalars['Boolean']['output']
}

export type GatewayCountry = {
  __typename?: 'GatewayCountry'
  country: Country
  countryId: Scalars['Float']['output']
  gatewayId: Scalars['Float']['output']
  id: Scalars['Float']['output']
  mode: EContainMode
  target: EPaymentTarget
}

export type GatewayCurrency = {
  __typename?: 'GatewayCurrency'
  currency: CurrencyEntity
  currencyId: Scalars['Float']['output']
  gatewayId: Scalars['Float']['output']
  id: Scalars['Float']['output']
  isDefault: Scalars['Boolean']['output']
  maxDepositValue: Scalars['Float']['output']
  maxWithdrawalValue: Scalars['Float']['output']
  minDepositValue: Scalars['Float']['output']
  minWithdrawalValue: Scalars['Float']['output']
}

export type GatewayUserGroup = {
  __typename?: 'GatewayUserGroup'
  gatewayId: Scalars['Float']['output']
  group: Group
  groupId: Scalars['Float']['output']
  id: Scalars['Float']['output']
  mode: EContainMode
  target: EPaymentTarget
}

export type GetGamesDto = {
  bonusEligible?: InputMaybe<Scalars['Boolean']['input']>
  countryCode?: InputMaybe<Scalars['String']['input']>
  devices: Scalars['String']['input']
  gameBrands?: InputMaybe<Array<Scalars['Float']['input']>>
  gameCategories?: InputMaybe<Array<Scalars['Float']['input']>>
  gameTags?: InputMaybe<Array<Scalars['Float']['input']>>
  isWagering?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['Float']['input']>
  login?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  offset?: InputMaybe<Scalars['Float']['input']>
  orderByCreatedAt?: InputMaybe<Scalars['String']['input']>
  orderByName?: InputMaybe<Scalars['String']['input']>
  provider?: InputMaybe<Scalars['String']['input']>
  remoteName?: InputMaybe<Scalars['String']['input']>
}

export type GetStatisticsDto = {
  currencyId?: InputMaybe<Scalars['Float']['input']>
  dateFrom?: InputMaybe<Scalars['DateTime']['input']>
  dateTo?: InputMaybe<Scalars['DateTime']['input']>
}

export type GetWalletDto = {
  id?: InputMaybe<Scalars['Float']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  userId?: InputMaybe<Scalars['Float']['input']>
}

export type GiftConfig = {
  __typename?: 'GiftConfig'
  activationTtl: Scalars['Float']['output']
  bonusConfigs: Array<BonusConfig>
  comment: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  depCount?: Maybe<Scalars['Float']['output']>
  hide: Scalars['Boolean']['output']
  id: Scalars['Int']['output']
  image?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  promoCodeId?: Maybe<Scalars['Float']['output']>
  promocode?: Maybe<PromocodeConfig>
  source: GiftSource
  ttl: Scalars['Float']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type GiftConfigCountry = {
  __typename?: 'GiftConfigCountry'
  countryId: Scalars['Float']['output']
  createdAt: Scalars['DateTime']['output']
  giftConfigId: Scalars['Float']['output']
  id: Scalars['Int']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type GiftInstance = {
  __typename?: 'GiftInstance'
  activatedAt?: Maybe<Scalars['DateTime']['output']>
  bonuses: Array<BonusInstance>
  config: GiftConfig
  createdAt: Scalars['DateTime']['output']
  expiredActivationAt?: Maybe<Scalars['DateTime']['output']>
  expiredAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['Int']['output']
  status: EGiftStatuses
  updatedAt: Scalars['DateTime']['output']
  userId: Scalars['Float']['output']
}

export enum GiftSource {
  Cashback = 'CASHBACK',
  Cpexchange = 'CPEXCHANGE',
  Freespins = 'FREESPINS',
  Lottery = 'LOTTERY',
  Manual = 'MANUAL',
  Promocode = 'PROMOCODE',
  Registration = 'REGISTRATION',
  Tournament = 'TOURNAMENT',
  Vip = 'VIP'
}

export type Group = {
  __typename?: 'Group'
  createdAt: Scalars['DateTime']['output']
  description: Scalars['String']['output']
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type GroupFilter = {
  AND?: InputMaybe<Array<GroupFilterableFieldsEnumerableNested>>
  NOT?: InputMaybe<Array<GroupFilterableFieldsEnumerableNested>>
  OR?: InputMaybe<Array<GroupFilterableFieldsEnumerableNested>>
  id?: InputMaybe<GroupFilterableFields_Id>
  name?: InputMaybe<GroupFilterableFields_Name>
}

export type GroupFilterableFields = {
  id?: InputMaybe<GroupFilterableFields_Id>
  name?: InputMaybe<GroupFilterableFields_Name>
}

export type GroupFilterableFieldsEnumerableNested = {
  AND?: InputMaybe<Array<GroupFilterableFields>>
  NOT?: InputMaybe<Array<GroupFilterableFields>>
  OR?: InputMaybe<Array<GroupFilterableFields>>
  id?: InputMaybe<GroupFilterableFields_Id>
  name?: InputMaybe<GroupFilterableFields_Name>
}

export type GroupFilterableFields_Id = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type GroupFilterableFields_Name = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type ITransaction = {
  amount: Scalars['Decimal']['output']
  amountInUserCurrency: Scalars['Float']['output']
  createdAt: Scalars['DateTime']['output']
  currencyId: Scalars['Float']['output']
  feeValue: Scalars['Float']['output']
  id: Scalars['String']['output']
  isWagered: Scalars['Boolean']['output']
  paymentCountry?: Maybe<Scalars['String']['output']>
  paymentDetails?: Maybe<Scalars['String']['output']>
  promoCode?: Maybe<Scalars['Float']['output']>
  status: ETransactionStatus
  type: ETransactionCreateType
  updatedAt: Scalars['DateTime']['output']
  userId: Scalars['Float']['output']
  wagerSum: Scalars['Decimal']['output']
  wageredSum: Scalars['Decimal']['output']
}

export type Icon = {
  __typename?: 'Icon'
  icon: Scalars['String']['output']
  theme: EIconTheme
}

export type InTelegramUser = {
  allows_write_to_pm?: InputMaybe<Scalars['Boolean']['input']>
  first_name?: InputMaybe<Scalars['String']['input']>
  id: Scalars['Float']['input']
  is_bot?: InputMaybe<Scalars['Boolean']['input']>
  is_premium?: InputMaybe<Scalars['Boolean']['input']>
  language_code: Scalars['String']['input']
  last_name?: InputMaybe<Scalars['String']['input']>
  username?: InputMaybe<Scalars['String']['input']>
}

export type Language = {
  __typename?: 'Language'
  code: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
  projects: Array<Project>
  updatedAt: Scalars['DateTime']['output']
}

export type Level = {
  __typename?: 'Level'
  level: Scalars['Float']['output']
  userId: Scalars['Float']['output']
}

export type LoginInTelegramDto = {
  initData: Scalars['String']['input']
}

export type LoginResult = {
  __typename?: 'LoginResult'
  accessToken: Scalars['String']['output']
  user: User
}

export type LoginThroughtTelegramDto = {
  user: ThroughtTelegramUser
}

export type LotteryConfigs = {
  __typename?: 'LotteryConfigs'
  createdAt: Scalars['DateTime']['output']
  duration: Scalars['Float']['output']
  id: Scalars['Int']['output']
  prizes: Array<LotteryPrizes>
  updatedAt: Scalars['DateTime']['output']
}

export type LotteryInstances = {
  __typename?: 'LotteryInstances'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['Int']['output']
  isActive: Scalars['Boolean']['output']
  lotteryConfigId: Scalars['Float']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type LotteryPrizes = {
  __typename?: 'LotteryPrizes'
  createdAt: Scalars['DateTime']['output']
  giftConfigId: Scalars['Float']['output']
  id: Scalars['Int']['output']
  lotteryConfigId: Scalars['Float']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type LotteryTickerPrices = {
  __typename?: 'LotteryTickerPrices'
  createdAt: Scalars['DateTime']['output']
  currencyId: Scalars['Float']['output']
  id: Scalars['Int']['output']
  lotteryConfig: LotteryConfigs
  lotteryConfigId: Scalars['Float']['output']
  price: Scalars['Decimal']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type ManualSendTemplateDto = {
  emails: Scalars['String']['input']
  templateId: Scalars['Float']['input']
}

export type Mutation = {
  __typename?: 'Mutation'
  activate: Scalars['String']['output']
  activateGift: GiftInstance
  activatePromocode: Promocode
  activateStandalonePromocode: Promocode
  addFavoriteGame: FavoriteGame
  anonUserVisit: Scalars['String']['output']
  approveTransaction: AdminTransaction
  assignManualBonus: Scalars['Boolean']['output']
  assignManualFreespins: Scalars['Boolean']['output']
  blockUserForAdmin: User
  bulkCreateDocuments: Array<Scalars['String']['output']>
  bulkUpdateGameForAdmin: Array<Game>
  cancelTransaction: Transaction
  cancelUserGiftInstanceForAdmin: Scalars['Boolean']['output']
  changePassword: User
  changePasswordByAdmin: User
  changePhoneCommit: User
  changePhoneRequest: PhoneConfirmationProcess
  convertCompPoints: Scalars['Boolean']['output']
  createBonusConfigForAdmin: BonusConfig
  createBonusConfigRateForAdmin: BonusConfigRate
  createBonusInstanceForAdmin: BonusInstance
  createBrandForAdmin: BrandAdmin
  createCountryForAdmin: Country
  createCryptoWallet: CryptoWallet
  createFreespinParamsForAdmin: FreespinsParams
  createGame: Game
  createGameCategoryForAdmin: GameCategory
  createGameTagForAdmin: GameTag
  createGateway: Gateway
  createGatewayCountry: GatewayCountry
  createGatewayCurrency: GatewayCurrency
  createGatewayUserGroup: GatewayUserGroup
  createGiftConfig: AGiftConfig
  createGroupForAdmin: Group
  createManualTransactionByAdmin: AdminTransaction
  createPaymentSystemForAdmin: PaymentSystem
  createPaymentSystemInput: PaymentSystemInput
  createPaymentSystemMessage: PaymentSystemMessage
  createPaymentSystemOption: PaymentSystemOption
  createPhoneSenderForAdmin: PhoneSender
  createProjectForAdmin: Project
  createPromocodeConfigForAdmin: PromocodeConfig
  createSenderForAdmin: Sender
  createTagForAdmin: Tag
  createTelegramTriggerForAdmin: TelegramTrigger
  createTelegramTriggerInstanceForAdmin: TelegramTriggerInstance
  createTelegramTriggerTemplateForAdmin: TelegramTriggerTemplate
  createTournamentConfigForAdmin: TournamentConfig
  createTournamentImageForAdmin: TournamentImage
  createTournamentInstanceForAdmin: TournamentInstance
  createTournamentPlaceForAdmin: TournamentPlace
  createTournamentPrizeForAdmin: TournamentPrize
  createTournamentTextForAdmin: TournamentText
  createTournamentWinnerForAdmin: TournamentWinner
  createTransaction: Scalars['String']['output']
  createTriggerForAdmin: Trigger
  createTriggerInstanceForAdmin: TriggerInstance
  createTriggerTemplateForAdmin: TriggerTemplate
  deauthorize: Scalars['String']['output']
  declineTransaction: AdminTransaction
  deleteActiveUserPromocode: Scalars['Boolean']['output']
  deleteGameCategoryForAdmin: GameCategory
  deleteGameTagForAdmin: GameTag
  deleteGateway: Gateway
  deleteGatewayCountry: GatewayCountry
  deleteGatewayCurrency: GatewayCurrency
  deleteGatewayUserGroup: GatewayUserGroup
  deleteGroupForAdmin: Group
  deletePaymentSystemForAdmin: PaymentSystem
  deletePaymentSystemInput: PaymentSystemInput
  deletePaymentSystemMessage: PaymentSystemMessage
  deletePaymentSystemOption: PaymentSystemOption
  deletePhoneSenderForAdmin: PhoneSender
  deleteSenderForAdmin: Sender
  deleteTelegramTriggerTemplateForAdmin: TelegramTriggerTemplate
  deleteTriggerForAdmin: TelegramTrigger
  deleteTriggerTemplateForAdmin: TriggerTemplate
  forgiveAllDepositsForAdmin: Scalars['Boolean']['output']
  forgiveDepositForAdmin: AdminTransaction
  logOut: Scalars['String']['output']
  manualSendTriggerForAdmin: Scalars['String']['output']
  removeAllFreespinsForAdmin: Scalars['Boolean']['output']
  removeFavoriteGame: FavoriteGame
  resetWallet: Scalars['Boolean']['output']
  restorePasswordCommit: Scalars['String']['output']
  restorePasswordRequest: Scalars['String']['output']
  sendActivationLink: Scalars['Float']['output']
  signIn: LoginResult
  signInInTelegram: User
  signInThroughtTelegram: User
  signUp: User
  signUpInTelegram: User
  signUpThroughtTelegram: User
  spamSpin: Scalars['String']['output']
  startGame: GameStartUrlEntity
  unblockUserForAdmin: User
  unverifyEmailForAdmin: User
  updateBonusConfigForAdmin: BonusConfig
  updateBonusConfigRateForAdmin: BonusConfigRate
  updateBonusInstanceForAdmin: BonusInstance
  updateBrandForAdmin: BrandAdmin
  updateCountry: User
  updateCountryForAdmin: Country
  updateEmail: User
  updateFreespinParamsForAdmin: FreespinsParams
  updateGameForAdmin: Game
  updateGateway: Gateway
  updateGatewayCurrency: GatewayCurrency
  updateGiftConfig: AGiftConfig
  updatePaymentSystemForAdmin: PaymentSystem
  updatePaymentSystemInput: PaymentSystemInput
  updatePaymentSystemMessage: PaymentSystemMessage
  updatePaymentSystemOption: PaymentSystemOption
  updatePhoneSenderForAdmin: PhoneSender
  updateProjectForAdmin: Project
  updatePromocodeConfigForAdmin: PromocodeConfig
  updateSenderForAdmin: Sender
  updateTagForAdmin: Tag
  updateTelegramTriggerForAdmin: TelegramTrigger
  updateTelegramTriggerTemplateForAdmin: TelegramTriggerTemplate
  updateTournamentConfigForAdmin: TournamentConfig
  updateTournamentImageForAdmin: TournamentImage
  updateTournamentInstanceForAdmin: TournamentInstance
  updateTournamentPlaceForAdmin: TournamentPlace
  updateTournamentPrizeForAdmin: TournamentPrize
  updateTournamentTextForAdmin: TournamentText
  updateTournamentWinnerForAdmin: TournamentWinner
  updateTransactionForAdmin: AdminTransaction
  updateTriggerForAdmin: Trigger
  updateTriggerTemplateForAdmin: TriggerTemplate
  updateUser: User
  updateUserForAdmin: User
  updateWalletForAdmin: Scalars['Boolean']['output']
  verifyEmailForAdmin: User
}

export type MutationActivateArgs = {
  token: Scalars['String']['input']
}

export type MutationActivateGiftArgs = {
  id: Scalars['Float']['input']
}

export type MutationActivatePromocodeArgs = {
  dto: CreatePromocodeInstanceDto
}

export type MutationActivateStandalonePromocodeArgs = {
  dto: CreatePromocodeInstanceDto
}

export type MutationAddFavoriteGameArgs = {
  gameId: Scalars['Float']['input']
}

export type MutationAnonUserVisitArgs = {
  refCamp: Scalars['String']['input']
  referalId: Scalars['String']['input']
}

export type MutationApproveTransactionArgs = {
  id: Scalars['String']['input']
}

export type MutationAssignManualBonusArgs = {
  dto: AssignManualBonusDto
}

export type MutationAssignManualFreespinsArgs = {
  dto: AssignManualFreeSpinsDto
}

export type MutationBlockUserForAdminArgs = {
  id: Scalars['Float']['input']
}

export type MutationBulkCreateDocumentsArgs = {
  dto: BulkCreateDocumentDto
}

export type MutationBulkUpdateGameForAdminArgs = {
  data: Array<UpdateGameByAdminDto>
}

export type MutationCancelTransactionArgs = {
  id: Scalars['String']['input']
}

export type MutationCancelUserGiftInstanceForAdminArgs = {
  userId: Scalars['Float']['input']
}

export type MutationChangePasswordArgs = {
  dto: ChangePasswordDto
}

export type MutationChangePasswordByAdminArgs = {
  dto: ChangePasswordByAdminDto
}

export type MutationChangePhoneCommitArgs = {
  code: Scalars['String']['input']
}

export type MutationChangePhoneRequestArgs = {
  dto: ChangePhoneRequestDto
}

export type MutationConvertCompPointsArgs = {
  amount: Scalars['Float']['input']
}

export type MutationCreateBonusConfigForAdminArgs = {
  dto: ACreateBonusConfigDto
}

export type MutationCreateBonusConfigRateForAdminArgs = {
  dto: CreateBonusConfigRateDto
}

export type MutationCreateBonusInstanceForAdminArgs = {
  dto: CreateBonusInstanceDto
}

export type MutationCreateBrandForAdminArgs = {
  dto: CreateBrandDto
}

export type MutationCreateCountryForAdminArgs = {
  dto: CreateCountryDto
}

export type MutationCreateCryptoWalletArgs = {
  paymentSystemId: Scalars['Float']['input']
}

export type MutationCreateFreespinParamsForAdminArgs = {
  dto: CreateFreespinsParamsDto
}

export type MutationCreateGameArgs = {
  CreateGameDto: CreateGameDto
}

export type MutationCreateGameCategoryForAdminArgs = {
  dto: CreateGameCategoryDto
}

export type MutationCreateGameTagForAdminArgs = {
  dto: CreateGameTagDto
}

export type MutationCreateGatewayArgs = {
  dto: CreateGatewayDto
}

export type MutationCreateGatewayCountryArgs = {
  dto: CreateGatewayCountryDto
}

export type MutationCreateGatewayCurrencyArgs = {
  dto: CreateGatewayCurrencyDto
}

export type MutationCreateGatewayUserGroupArgs = {
  dto: CreateGatewayUserGroupDto
}

export type MutationCreateGiftConfigArgs = {
  dto: CreateGiftConfigDto
}

export type MutationCreateGroupForAdminArgs = {
  dto: CreateGroupDto
}

export type MutationCreateManualTransactionByAdminArgs = {
  dto: CreateManualTransactionsDto
}

export type MutationCreatePaymentSystemForAdminArgs = {
  dto: CreatePaymentSystemDto
}

export type MutationCreatePaymentSystemInputArgs = {
  dto: CreatePaymentSystemInputDto
}

export type MutationCreatePaymentSystemMessageArgs = {
  dto: CreatePaymentSystemMessageDto
}

export type MutationCreatePaymentSystemOptionArgs = {
  dto: CreatePaymentSystemOptionDto
}

export type MutationCreatePhoneSenderForAdminArgs = {
  dto: CreatePhoneSenderDto
}

export type MutationCreateProjectForAdminArgs = {
  dto: CreateProjectDto
}

export type MutationCreatePromocodeConfigForAdminArgs = {
  dto: CreatePromocodeConfigDto
}

export type MutationCreateSenderForAdminArgs = {
  dto: CreateSenderDto
}

export type MutationCreateTagForAdminArgs = {
  dto: CreateTagDto
}

export type MutationCreateTelegramTriggerForAdminArgs = {
  dto: TelegramTriggerCreateDto
}

export type MutationCreateTelegramTriggerInstanceForAdminArgs = {
  dto: CreateTelegramTriggerInstanceDto
}

export type MutationCreateTelegramTriggerTemplateForAdminArgs = {
  dto: TelegramTriggerTemplateCreateDto
}

export type MutationCreateTournamentConfigForAdminArgs = {
  dto: CreateTournamentConfigDto
}

export type MutationCreateTournamentImageForAdminArgs = {
  dto: CreateTournamentImageDto
}

export type MutationCreateTournamentInstanceForAdminArgs = {
  dto: CreateTournamentInstanceto
}

export type MutationCreateTournamentPlaceForAdminArgs = {
  dto: CreateTournamentPlaceDto
}

export type MutationCreateTournamentPrizeForAdminArgs = {
  dto: CreateTournamentPrizeDto
}

export type MutationCreateTournamentTextForAdminArgs = {
  dto: CreateTournamentTextDto
}

export type MutationCreateTournamentWinnerForAdminArgs = {
  dto: CreateTournamentWinnerDto
}

export type MutationCreateTransactionArgs = {
  createTransactionsDto: CreateTransactionNewDto
}

export type MutationCreateTriggerForAdminArgs = {
  dto: TriggerCreateDto
}

export type MutationCreateTriggerInstanceForAdminArgs = {
  dto: CreateTriggerInstanceDto
}

export type MutationCreateTriggerTemplateForAdminArgs = {
  dto: TriggerTemplateCreateDto
}

export type MutationDeauthorizeArgs = {
  id: Scalars['Float']['input']
}

export type MutationDeclineTransactionArgs = {
  id: Scalars['String']['input']
}

export type MutationDeleteGameCategoryForAdminArgs = {
  id: Scalars['Float']['input']
}

export type MutationDeleteGameTagForAdminArgs = {
  id: Scalars['Float']['input']
}

export type MutationDeleteGatewayArgs = {
  id: Scalars['Float']['input']
}

export type MutationDeleteGatewayCountryArgs = {
  id: Scalars['Float']['input']
}

export type MutationDeleteGatewayCurrencyArgs = {
  id: Scalars['Float']['input']
}

export type MutationDeleteGatewayUserGroupArgs = {
  id: Scalars['Float']['input']
}

export type MutationDeleteGroupForAdminArgs = {
  id: Scalars['Float']['input']
}

export type MutationDeletePaymentSystemForAdminArgs = {
  id: Scalars['Float']['input']
}

export type MutationDeletePaymentSystemInputArgs = {
  id: Scalars['Float']['input']
}

export type MutationDeletePaymentSystemMessageArgs = {
  id: Scalars['Float']['input']
}

export type MutationDeletePaymentSystemOptionArgs = {
  id: Scalars['Float']['input']
}

export type MutationDeletePhoneSenderForAdminArgs = {
  id: Scalars['Float']['input']
}

export type MutationDeleteSenderForAdminArgs = {
  id: Scalars['Float']['input']
}

export type MutationDeleteTelegramTriggerTemplateForAdminArgs = {
  id: Scalars['Float']['input']
}

export type MutationDeleteTriggerForAdminArgs = {
  id: Scalars['Float']['input']
}

export type MutationDeleteTriggerTemplateForAdminArgs = {
  id: Scalars['Float']['input']
}

export type MutationForgiveAllDepositsForAdminArgs = {
  userId: Scalars['Float']['input']
}

export type MutationForgiveDepositForAdminArgs = {
  id: Scalars['String']['input']
}

export type MutationManualSendTriggerForAdminArgs = {
  dto: ManualSendTemplateDto
}

export type MutationRemoveFavoriteGameArgs = {
  gameId: Scalars['Float']['input']
}

export type MutationResetWalletArgs = {
  dto: AResetWalletDto
}

export type MutationRestorePasswordCommitArgs = {
  dto: CommitRestorationPasswordDto
}

export type MutationRestorePasswordRequestArgs = {
  email: Scalars['String']['input']
}

export type MutationSignInArgs = {
  dto: SignInDto
}

export type MutationSignInInTelegramArgs = {
  dto: LoginInTelegramDto
}

export type MutationSignInThroughtTelegramArgs = {
  dto: LoginThroughtTelegramDto
}

export type MutationSignUpArgs = {
  dto: RegistrationDto
}

export type MutationSignUpInTelegramArgs = {
  dto: RegisterInTelegramDto
}

export type MutationSignUpThroughtTelegramArgs = {
  dto: RegisterThroughtTelegramDto
}

export type MutationSpamSpinArgs = {
  dto: SpamSpinDto
}

export type MutationStartGameArgs = {
  startGameDto: StartGameDto
}

export type MutationUnblockUserForAdminArgs = {
  id: Scalars['Float']['input']
}

export type MutationUnverifyEmailForAdminArgs = {
  id: Scalars['Float']['input']
}

export type MutationUpdateBonusConfigForAdminArgs = {
  dto: AUpdateBonusConfigDto
}

export type MutationUpdateBonusConfigRateForAdminArgs = {
  dto: UpdateBonusConfigRateDto
}

export type MutationUpdateBonusInstanceForAdminArgs = {
  dto: UpdateBonusInstanceDto
}

export type MutationUpdateBrandForAdminArgs = {
  dto: UpdateBrandDto
}

export type MutationUpdateCountryArgs = {
  countryId: Scalars['Float']['input']
}

export type MutationUpdateCountryForAdminArgs = {
  dto: UpdateCountryDto
}

export type MutationUpdateEmailArgs = {
  email: Scalars['String']['input']
}

export type MutationUpdateFreespinParamsForAdminArgs = {
  dto: UpdateFreespinsParamsDto
}

export type MutationUpdateGameForAdminArgs = {
  dto: UpdateGameByAdminDto
}

export type MutationUpdateGatewayArgs = {
  dto: UpdateGatewayDto
}

export type MutationUpdateGatewayCurrencyArgs = {
  dto: UpdateGatewayCurrencyDto
}

export type MutationUpdateGiftConfigArgs = {
  dto: UpdateGiftConfigDto
}

export type MutationUpdatePaymentSystemForAdminArgs = {
  dto: UpdatePaymentSystemDto
}

export type MutationUpdatePaymentSystemInputArgs = {
  dto: UpdatePaymentSystemInputDto
}

export type MutationUpdatePaymentSystemMessageArgs = {
  dto: UpdatePaymentSystemMessageDto
}

export type MutationUpdatePaymentSystemOptionArgs = {
  dto: UpdatePaymentSystemOptionDto
}

export type MutationUpdatePhoneSenderForAdminArgs = {
  dto: UpdatePhoneSenderDto
}

export type MutationUpdateProjectForAdminArgs = {
  dto: UpdateProjectDto
}

export type MutationUpdatePromocodeConfigForAdminArgs = {
  dto: UpdatePromocodeConfigDto
}

export type MutationUpdateSenderForAdminArgs = {
  dto: UpdateSenderDto
}

export type MutationUpdateTagForAdminArgs = {
  dto: UpdateTagDto
}

export type MutationUpdateTelegramTriggerForAdminArgs = {
  dto: TelegramTriggerUpdateDto
}

export type MutationUpdateTelegramTriggerTemplateForAdminArgs = {
  dto: TelegramTriggerTemplateUpdateDto
}

export type MutationUpdateTournamentConfigForAdminArgs = {
  dto: UpdateTournamentConfigDto
}

export type MutationUpdateTournamentImageForAdminArgs = {
  dto: UpdateTournamentImageDto
}

export type MutationUpdateTournamentInstanceForAdminArgs = {
  dto: UpdateTournamentInstanceto
}

export type MutationUpdateTournamentPlaceForAdminArgs = {
  dto: UpdateTournamentPlaceDto
}

export type MutationUpdateTournamentPrizeForAdminArgs = {
  dto: UpdateTournamentPrizeDto
}

export type MutationUpdateTournamentTextForAdminArgs = {
  dto: UpdateTournamentTextDto
}

export type MutationUpdateTournamentWinnerForAdminArgs = {
  dto: UpdateTournamentWinnerDto
}

export type MutationUpdateTransactionForAdminArgs = {
  dto: UpdateTransactionDto
}

export type MutationUpdateTriggerForAdminArgs = {
  dto: TriggerUpdateDto
}

export type MutationUpdateTriggerTemplateForAdminArgs = {
  dto: TriggerTemplateUpdateDto
}

export type MutationUpdateUserArgs = {
  dto: UpdateByUserDto
}

export type MutationUpdateUserForAdminArgs = {
  dto: UpdateUserByAdminDto
}

export type MutationUpdateWalletForAdminArgs = {
  dto: ACreateWalletDto
}

export type MutationVerifyEmailForAdminArgs = {
  id: Scalars['Float']['input']
}

export enum OrderEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type OrderUserTransactions = {
  createdAt?: InputMaybe<OrderEnum>
}

export type PaginateUserTransactions = {
  skip: Scalars['Float']['input']
  take: Scalars['Float']['input']
}

export type PaginatedBrands = {
  __typename?: 'PaginatedBrands'
  data?: Maybe<Array<BrandAdmin>>
  meta?: Maybe<BrandAdminMetaType>
}

export type PaginatedDocuments = {
  __typename?: 'PaginatedDocuments'
  data?: Maybe<Array<UserDocument>>
  meta?: Maybe<UserDocumentMetaType>
}

export type PaginatedGameHistory = {
  __typename?: 'PaginatedGameHistory'
  data?: Maybe<Array<GameHistory>>
  meta?: Maybe<GameHistoryMetaType>
}

export type PaginatedGames = {
  __typename?: 'PaginatedGames'
  data?: Maybe<Array<Game>>
  meta?: Maybe<GameMetaType>
}

export type PaginatedSpins = {
  __typename?: 'PaginatedSpins'
  data?: Maybe<Array<Spin>>
  meta?: Maybe<SpinMetaType>
}

export type PaginatedTelegramTriggers = {
  __typename?: 'PaginatedTelegramTriggers'
  data?: Maybe<Array<TelegramTrigger>>
  meta?: Maybe<TelegramTriggerMetaType>
}

export type PaginatedTournament = {
  __typename?: 'PaginatedTournament'
  data?: Maybe<Array<TournamentInstance>>
  meta?: Maybe<TournamentInstanceMetaType>
}

export type PaginatedTransactions = {
  __typename?: 'PaginatedTransactions'
  data?: Maybe<Array<AdminTransaction>>
  meta?: Maybe<AdminTransactionMetaType>
}

export type PaginatedTriggers = {
  __typename?: 'PaginatedTriggers'
  data?: Maybe<Array<Trigger>>
  meta?: Maybe<TriggerMetaType>
}

export type PaginatedUserTransactions = {
  __typename?: 'PaginatedUserTransactions'
  data?: Maybe<Array<Transaction>>
  meta?: Maybe<TransactionMetaType>
}

export type PaginatedUsers = {
  __typename?: 'PaginatedUsers'
  data?: Maybe<Array<User>>
  meta?: Maybe<UserMetaType>
}

export type Pagination = {
  skip?: InputMaybe<Scalars['Float']['input']>
  take?: InputMaybe<Scalars['Float']['input']>
}

export type PaymentSystem = {
  __typename?: 'PaymentSystem'
  checkDepositPaymentDetailsForWithdraw: Scalars['Boolean']['output']
  createdAt: Scalars['DateTime']['output']
  defaultDarkIcon?: Maybe<Scalars['String']['output']>
  defaultIcon: Scalars['String']['output']
  defaultWithdrawDarkIcon?: Maybe<Scalars['String']['output']>
  defaultWithdrawIcon?: Maybe<Scalars['String']['output']>
  fillProfileRequired: Scalars['Boolean']['output']
  gateways: Array<Gateway>
  id: Scalars['Float']['output']
  inputs: Array<PaymentSystemInput>
  isActive: Scalars['Boolean']['output']
  messages: Array<PaymentSystemMessage>
  name: Scalars['String']['output']
  projectId: Scalars['Float']['output']
  successDepositForWithdraw: Scalars['Boolean']['output']
  type: EPaymentSystemType
  updatedAt: Scalars['DateTime']['output']
  valueOptions: Array<PaymentSystemOption>
  visibleInFooter: Scalars['Boolean']['output']
}

export type PaymentSystemFilter = {
  AND?: InputMaybe<Array<PaymentSystemFilterableFieldsEnumerableNested>>
  NOT?: InputMaybe<Array<PaymentSystemFilterableFieldsEnumerableNested>>
  OR?: InputMaybe<Array<PaymentSystemFilterableFieldsEnumerableNested>>
  isActive?: InputMaybe<PaymentSystemFilterableFields_IsActive>
  name?: InputMaybe<PaymentSystemFilterableFields_Name>
}

export type PaymentSystemFilterableFields = {
  isActive?: InputMaybe<PaymentSystemFilterableFields_IsActive>
  name?: InputMaybe<PaymentSystemFilterableFields_Name>
}

export type PaymentSystemFilterableFieldsEnumerableNested = {
  AND?: InputMaybe<Array<PaymentSystemFilterableFields>>
  NOT?: InputMaybe<Array<PaymentSystemFilterableFields>>
  OR?: InputMaybe<Array<PaymentSystemFilterableFields>>
  isActive?: InputMaybe<PaymentSystemFilterableFields_IsActive>
  name?: InputMaybe<PaymentSystemFilterableFields_Name>
}

export type PaymentSystemFilterableFields_IsActive = {
  contains?: InputMaybe<Scalars['Boolean']['input']>
  endsWith?: InputMaybe<Scalars['Boolean']['input']>
  equals?: InputMaybe<Scalars['Boolean']['input']>
  gt?: InputMaybe<Scalars['Boolean']['input']>
  gte?: InputMaybe<Scalars['Boolean']['input']>
  in?: InputMaybe<Array<Scalars['Boolean']['input']>>
  lt?: InputMaybe<Scalars['Boolean']['input']>
  lte?: InputMaybe<Scalars['Boolean']['input']>
  not?: InputMaybe<Scalars['Boolean']['input']>
  notIn?: InputMaybe<Array<Scalars['Boolean']['input']>>
  startsWith?: InputMaybe<Scalars['Boolean']['input']>
}

export type PaymentSystemFilterableFields_Name = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type PaymentSystemInput = {
  __typename?: 'PaymentSystemInput'
  id: Scalars['Float']['output']
  label: Scalars['String']['output']
  name: Scalars['String']['output']
  paymentSystemId: Scalars['Float']['output']
  regexp: Scalars['String']['output']
  target: EPaymentTarget
}

export type PaymentSystemMessage = {
  __typename?: 'PaymentSystemMessage'
  id: Scalars['Float']['output']
  languageId: Scalars['Float']['output']
  message: Scalars['String']['output']
  paymentSystemId: Scalars['Float']['output']
  target: EPaymentTarget
}

export type PaymentSystemOption = {
  __typename?: 'PaymentSystemOption'
  currencyId: Scalars['Float']['output']
  id: Scalars['Float']['output']
  paymentSystemId: Scalars['Float']['output']
  value: Scalars['Float']['output']
}

export type PaymentSystemOrderableFields = {
  createdAt?: InputMaybe<OrderEnum>
  isActive?: InputMaybe<OrderEnum>
  name?: InputMaybe<OrderEnum>
}

export type PhoneConfirmationProcess = {
  __typename?: 'PhoneConfirmationProcess'
  phone: Scalars['String']['output']
  timeoutTo: Scalars['Float']['output']
}

export type PhoneSender = {
  __typename?: 'PhoneSender'
  id: Scalars['Float']['output']
  login: Scalars['String']['output']
  password: Scalars['String']['output']
  project: Project
  projectId: Scalars['Float']['output']
}

export type Project = {
  __typename?: 'Project'
  blockedCountries: Array<Country>
  createdAt: Scalars['DateTime']['output']
  domain: Scalars['String']['output']
  id: Scalars['Int']['output']
  isLicensed: Scalars['Boolean']['output']
  languages: Array<Language>
  mirrorDomain: Scalars['String']['output']
  name: Scalars['String']['output']
  postalApiKey: Scalars['String']['output']
  projectCurrencies: Array<ProjectCurrency>
  updatedAt: Scalars['DateTime']['output']
  wagerDep: Scalars['Float']['output']
}

export type ProjectCurrency = {
  __typename?: 'ProjectCurrency'
  currency: CurrencyEntity
  currencyId: Scalars['Float']['output']
  id: Scalars['Float']['output']
  isDefault: Scalars['Boolean']['output']
  projectId: Scalars['Float']['output']
}

export type Promocode = {
  __typename?: 'Promocode'
  config: PromocodeConfig
  configId: Scalars['Float']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['Int']['output']
  isCompleted: Scalars['Boolean']['output']
  transactionId?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
  userId: Scalars['Float']['output']
}

export type PromocodeConfig = {
  __typename?: 'PromocodeConfig'
  comment: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  giftConfig?: Maybe<GiftConfig>
  id: Scalars['Int']['output']
  isActive: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  promocodes?: Maybe<Array<Promocode>>
  ratelimitPerUser?: Maybe<Scalars['Float']['output']>
  ratelimitPeriodPerUser?: Maybe<EPromocodeActivationPeriods>
  stock?: Maybe<Scalars['Float']['output']>
  stockPerUser?: Maybe<Scalars['Float']['output']>
  type: EPromocodeType
  updatedAt: Scalars['DateTime']['output']
}

export type ProviderJackpot = {
  __typename?: 'ProviderJackpot'
  createdAt: Scalars['DateTime']['output']
  currency: CurrencyEntity
  currencyId: Scalars['Float']['output']
  games: Array<Game>
  id: Scalars['Int']['output']
  updatedAt: Scalars['DateTime']['output']
  value: Scalars['Float']['output']
}

export type QueriesUserTransactions = {
  filter?: InputMaybe<FilterUserTransactions>
  order?: InputMaybe<OrderUserTransactions>
  pagination?: InputMaybe<PaginateUserTransactions>
}

export type Query = {
  __typename?: 'Query'
  activeUserBonuses: Array<UserBonus>
  approveDocumentForAdmin: UserDocument
  bonusConfigRateForAdmin: BonusConfigRate
  bonusConfigRatesForAdmin: Array<BonusConfigRate>
  bonusInstances: Array<UserBonusInstance>
  brand: Brand
  brandForAdmin: BrandAdmin
  brands: Array<Brand>
  brandsForAdmin: Array<BrandAdmin>
  bulkDeleteDocumentForAdmin: UserDocument
  checkExistsInTelegram: Scalars['Boolean']['output']
  countries: Array<Country>
  countriesForAdmin: Array<Country>
  country: Country
  currencies: Array<CurrencyEntity>
  currenciesForAdmin: Array<CurrencyEntity>
  currency: CurrencyEntity
  deleteDocumentForAdmin: UserDocument
  depositPaymentSystem: DepositPaymentSystem
  depositPaymentSystems: Array<DepositPaymentSystem>
  documentForAdmin: UserDocument
  documentsForAdmin: PaginatedDocuments
  emailConfirmationTimeout?: Maybe<Scalars['Float']['output']>
  freespinSettingsForAdmin: Array<FreespinSetting>
  game: Game
  gameCategories: Array<GameCategory>
  gameCategoriesForAdmin: Array<GameCategory>
  gameCategory: GameCategory
  gameCategoryForAdmin: GameCategory
  gameForAdmin: Game
  gameHistoryForAdmin: PaginatedGameHistory
  gameSoftGamingDiscrepancy: SoftGamingDiscrepancy
  gameTagForAdmin: GameTag
  gameTagsForAdmin: Array<GameTag>
  games: Array<Game>
  gamesForAdmin: PaginatedGames
  gateway: Gateway
  gateways: Array<Gateway>
  getActiveBonusUserGiftInstance: Array<GiftInstance>
  getActiveUserPromocode?: Maybe<Promocode>
  getFavoriteGames: Array<Game>
  getGeneralStatistics: Statistics
  getLastPlayerGames: Array<Game>
  getTournamentConfigForAdmin: TournamentConfig
  getTournamentConfigsForAdmin: Array<TournamentConfig>
  getTournamentConfigsForUser: Array<TournamentConfig>
  getTournamentInstanceForAdmin: TournamentInstance
  getTournamentInstancesForAdmin: PaginatedTournament
  giftConfigForAdmin: AGiftConfig
  giftConfigs: Array<GiftConfig>
  giftConfigs2: Array<UserGiftConfig>
  giftConfigsForAdmin: Array<AGiftConfig>
  giftInstanceForAdmin: Array<GiftInstance>
  giftInstances: Array<UserGiftInstance>
  group: Group
  groupForAdmin: Group
  groups: Array<Group>
  groupsForAdmin: Array<Group>
  instanceForAdmin: BonusInstance
  jackpot: ProviderJackpot
  jackpots: Array<ProviderJackpot>
  language: Language
  languages: Array<Language>
  lotteryConfig: Level
  lotteryConfigs: Array<Level>
  lotteryInstance: LotteryInstances
  lotteryInstances: Array<LotteryInstances>
  lotteryTicketPrice: LotteryTickerPrices
  lotteryTicketPrices: Array<LotteryTickerPrices>
  paginatedBrandsForAdmin: PaginatedBrands
  paymentSystemForAdmin: PaymentSystem
  paymentSystemsForAdmin: Array<PaymentSystem>
  phoneConfirmationProcess?: Maybe<PhoneConfirmationProcess>
  phoneSenderByProjectForAdmin: PhoneSender
  phoneSenderForAdmin: PhoneSender
  phoneSendersForAdmin: Array<PhoneSender>
  project: Project
  projects: Array<Project>
  projectsForAdmin: Array<Project>
  promocodeConfig: PromocodeConfig
  promocodeConfigs: Array<PromocodeConfig>
  senderForAdmin: Sender
  sendersForAdmin: Array<Sender>
  setMascotGames: Scalars['Boolean']['output']
  spinForAdmin: Spin
  spinsForAdmin: PaginatedSpins
  tag: GameTag
  tagForAdmin: Tag
  tags: Array<GameTag>
  tagsForAdmin: Array<Tag>
  telegramTriggerForAdmin: TelegramTrigger
  telegramTriggerInstanceForAdmin: TelegramTriggerInstance
  telegramTriggerTemplate: TelegramTriggerTemplate
  telegramTriggerTemplatesForAdmin: Array<TelegramTriggerTemplate>
  telegramTriggersForAdmin: PaginatedTelegramTriggers
  telegramTriggersInstancesForAdmin: Array<TelegramTriggerInstance>
  transaction: Transaction
  transactions: PaginatedUserTransactions
  transactionsForAdmin: PaginatedTransactions
  triggerForAdmin: Trigger
  triggerInstanceForAdmin: TriggerInstance
  triggerTemplate: TriggerTemplate
  triggerTemplatesForAdmin: Array<TriggerTemplate>
  triggersForAdmin: PaginatedTriggers
  triggersInstancesForAdmin: Array<TriggerInstance>
  user: User
  userBonuses: Array<UserBonus>
  userForAdmin: User
  usersForAdmin: PaginatedUsers
  wallet: Wallet
  wallets: Array<Wallet>
  withdrawPaymentSystems: Array<WithdrawPaymentSystem>
}

export type QueryApproveDocumentForAdminArgs = {
  dto: ApproveDocumentDto
}

export type QueryBonusConfigRateForAdminArgs = {
  id: Scalars['Float']['input']
}

export type QueryBonusConfigRatesForAdminArgs = {
  bonusConfigId: Scalars['Float']['input']
}

export type QueryBrandArgs = {
  id: Scalars['Float']['input']
}

export type QueryBrandForAdminArgs = {
  id: Scalars['Float']['input']
}

export type QueryBulkDeleteDocumentForAdminArgs = {
  ids: BulkDeleteDocumentDto
}

export type QueryCheckExistsInTelegramArgs = {
  telegramId: Scalars['Float']['input']
}

export type QueryCountryArgs = {
  id: Scalars['Float']['input']
}

export type QueryCurrencyArgs = {
  id: Scalars['Float']['input']
}

export type QueryDeleteDocumentForAdminArgs = {
  id: Scalars['Float']['input']
}

export type QueryDepositPaymentSystemArgs = {
  id: Scalars['Float']['input']
}

export type QueryDocumentForAdminArgs = {
  id: Scalars['Float']['input']
}

export type QueryDocumentsForAdminArgs = {
  filter?: InputMaybe<DocumentFilter>
  order?: InputMaybe<DocumentOrderableFields>
  pagination: Pagination
}

export type QueryFreespinSettingsForAdminArgs = {
  gameId: Scalars['Float']['input']
}

export type QueryGameArgs = {
  id: Scalars['Float']['input']
}

export type QueryGameCategoryArgs = {
  id: Scalars['Float']['input']
}

export type QueryGameCategoryForAdminArgs = {
  id: Scalars['Float']['input']
}

export type QueryGameForAdminArgs = {
  id: Scalars['Float']['input']
}

export type QueryGameHistoryForAdminArgs = {
  filter?: InputMaybe<GameHistoryFilter>
  order?: InputMaybe<GameHistoryOrderableFields>
  pagination: Pagination
}

export type QueryGameTagForAdminArgs = {
  id: Scalars['Float']['input']
}

export type QueryGamesArgs = {
  args: GetGamesDto
}

export type QueryGamesForAdminArgs = {
  filter?: InputMaybe<GameFilter>
  order?: InputMaybe<GameOrderableFields>
  pagination: Pagination
}

export type QueryGatewayArgs = {
  id: Scalars['Float']['input']
}

export type QueryGetGeneralStatisticsArgs = {
  dto: GetStatisticsDto
}

export type QueryGetLastPlayerGamesArgs = {
  take: Scalars['Float']['input']
}

export type QueryGetTournamentConfigForAdminArgs = {
  id: Scalars['Float']['input']
}

export type QueryGetTournamentInstanceForAdminArgs = {
  id: Scalars['Float']['input']
}

export type QueryGetTournamentInstancesForAdminArgs = {
  filter?: InputMaybe<TournamentFilter>
  order?: InputMaybe<TournamentOrderableFields>
  pagination: Pagination
}

export type QueryGiftConfigForAdminArgs = {
  id: Scalars['Float']['input']
}

export type QueryGiftInstanceForAdminArgs = {
  userId: Scalars['Float']['input']
}

export type QueryGroupArgs = {
  groupId: Scalars['Float']['input']
}

export type QueryGroupForAdminArgs = {
  groupId: Scalars['Float']['input']
}

export type QueryInstanceForAdminArgs = {
  id: Scalars['Float']['input']
}

export type QueryJackpotArgs = {
  id: Scalars['Float']['input']
}

export type QueryLanguageArgs = {
  id: Scalars['Float']['input']
}

export type QueryLotteryConfigArgs = {
  id: Scalars['Float']['input']
}

export type QueryLotteryInstanceArgs = {
  id: Scalars['Float']['input']
}

export type QueryLotteryTicketPriceArgs = {
  id: Scalars['Float']['input']
}

export type QueryPaginatedBrandsForAdminArgs = {
  filter?: InputMaybe<BrandFilter>
  order?: InputMaybe<BrandOrderableFields>
  pagination: Pagination
}

export type QueryPaymentSystemForAdminArgs = {
  paymentSystemId: Scalars['Float']['input']
}

export type QueryPaymentSystemsForAdminArgs = {
  filter?: InputMaybe<PaymentSystemFilter>
  order?: InputMaybe<PaymentSystemOrderableFields>
  pagination: Pagination
}

export type QueryPhoneSenderForAdminArgs = {
  id: Scalars['Float']['input']
}

export type QueryProjectArgs = {
  id: Scalars['Float']['input']
}

export type QueryPromocodeConfigArgs = {
  id: Scalars['Float']['input']
}

export type QuerySenderForAdminArgs = {
  id: Scalars['Float']['input']
}

export type QuerySpinForAdminArgs = {
  id: Scalars['Float']['input']
}

export type QuerySpinsForAdminArgs = {
  filter?: InputMaybe<SpinFilter>
  order?: InputMaybe<SpinOrderableFields>
  pagination: Pagination
}

export type QueryTagArgs = {
  id: Scalars['Float']['input']
}

export type QueryTagForAdminArgs = {
  groupId: Scalars['Float']['input']
}

export type QueryTelegramTriggerForAdminArgs = {
  id: Scalars['Float']['input']
}

export type QueryTelegramTriggerInstanceForAdminArgs = {
  id: Scalars['Float']['input']
}

export type QueryTelegramTriggerTemplateArgs = {
  id: Scalars['Float']['input']
}

export type QueryTelegramTriggerTemplatesForAdminArgs = {
  triggerId: Scalars['Float']['input']
}

export type QueryTelegramTriggersForAdminArgs = {
  filter?: InputMaybe<TelegramTriggerFilter>
  order?: InputMaybe<TelegramTriggerOrderableFields>
  pagination: Pagination
}

export type QueryTransactionArgs = {
  id: Scalars['String']['input']
}

export type QueryTransactionsArgs = {
  input: QueriesUserTransactions
}

export type QueryTransactionsForAdminArgs = {
  filter?: InputMaybe<TransactionFilter>
  order?: InputMaybe<TransactionOrderableFields>
  pagination: Pagination
}

export type QueryTriggerForAdminArgs = {
  id: Scalars['Float']['input']
}

export type QueryTriggerInstanceForAdminArgs = {
  id: Scalars['Float']['input']
}

export type QueryTriggerTemplateArgs = {
  id: Scalars['Float']['input']
}

export type QueryTriggerTemplatesForAdminArgs = {
  triggerId: Scalars['Float']['input']
}

export type QueryTriggersForAdminArgs = {
  filter?: InputMaybe<TriggerFilter>
  order?: InputMaybe<TriggerOrderableFields>
  pagination: Pagination
}

export type QueryUserForAdminArgs = {
  id: Scalars['Float']['input']
}

export type QueryUsersForAdminArgs = {
  filter?: InputMaybe<UserFilter>
  order?: InputMaybe<UserOrderableFields>
  pagination: Pagination
}

export type QueryWalletArgs = {
  getWalletDto: GetWalletDto
}

export type QueryWalletsArgs = {
  getWalletDto: GetWalletDto
}

export type RegisterInTelegramDto = {
  currencyId: Scalars['Float']['input']
  user: InTelegramUser
}

export type RegisterThroughtTelegramDto = {
  currencyId: Scalars['Float']['input']
  languageId: Scalars['Float']['input']
  user: ThroughtTelegramUser
}

export type RegistrationDto = {
  currencyId: Scalars['Float']['input']
  email: Scalars['String']['input']
  emailSubscribed?: InputMaybe<Scalars['Boolean']['input']>
  languageId: Scalars['Float']['input']
  password: Scalars['String']['input']
  refCamp?: InputMaybe<Scalars['String']['input']>
  referalId?: InputMaybe<Scalars['String']['input']>
}

export type Sender = {
  __typename?: 'Sender'
  apiKey: Scalars['String']['output']
  description?: Maybe<Scalars['String']['output']>
  domain: Scalars['String']['output']
  from: Scalars['String']['output']
  id: Scalars['Float']['output']
  mirrorDomain: Scalars['String']['output']
  name: Scalars['String']['output']
  project: Project
  projectId: Scalars['Float']['output']
  replyTo: Scalars['String']['output']
}

export type SignInDto = {
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type SoftGamingDiscrepancy = {
  __typename?: 'SoftGamingDiscrepancy'
  brands: Array<SoftGamingNewBrand>
  games: Array<SoftGamingNewGame>
}

export type SoftGamingNewBrand = {
  __typename?: 'SoftGamingNewBrand'
  externalId: Scalars['Float']['output']
  externalName: Scalars['String']['output']
  name: Scalars['String']['output']
  subExternalId?: Maybe<Scalars['Float']['output']>
}

export type SoftGamingNewGame = {
  __typename?: 'SoftGamingNewGame'
  brandId: Scalars['Float']['output']
  freespinsEligible: Scalars['Float']['output']
  name?: Maybe<Scalars['String']['output']>
  remoteName: Scalars['String']['output']
}

export type SpamSpinDto = {
  after: Scalars['DateTime']['input']
  before: Scalars['DateTime']['input']
  count: Scalars['Float']['input']
  userId: Scalars['Float']['input']
}

export type Spin = {
  __typename?: 'Spin'
  amount: Scalars['Float']['output']
  balanceAfter: Scalars['Float']['output']
  balanceBefore: Scalars['Float']['output']
  createdAt: Scalars['DateTime']['output']
  externalId: Scalars['String']['output']
  game: Game
  gameId: Scalars['Float']['output']
  id: Scalars['Int']['output']
  ip?: Maybe<Scalars['String']['output']>
  roundId: Scalars['String']['output']
  type: SpinType
  updatedAt: Scalars['DateTime']['output']
  user: User
  userAgent?: Maybe<Scalars['String']['output']>
  userId: Scalars['Float']['output']
  walletType: EWalletType
}

export type SpinFilter = {
  AND?: InputMaybe<Array<SpinFilterableFieldsEnumerableNested>>
  NOT?: InputMaybe<Array<SpinFilterableFieldsEnumerableNested>>
  OR?: InputMaybe<Array<SpinFilterableFieldsEnumerableNested>>
  amount?: InputMaybe<SpinFilterableFields_Amount>
  balanceAfter?: InputMaybe<SpinFilterableFields_BalanceAfter>
  balanceBefore?: InputMaybe<SpinFilterableFields_BalanceBefore>
  createdAt?: InputMaybe<SpinFilterableFields_CreatedAt>
  externalId?: InputMaybe<SpinFilterableFields_ExternalId>
  game?: InputMaybe<GameFilter>
  id?: InputMaybe<SpinFilterableFields_Id>
  ip?: InputMaybe<SpinFilterableFields_Ip>
  roundId?: InputMaybe<SpinFilterableFields_RoundId>
  type?: InputMaybe<SpinFilterableFields_Type>
  userAgent?: InputMaybe<SpinFilterableFields_UserAgent>
  userId?: InputMaybe<SpinFilterableFields_UserId>
  walletType?: InputMaybe<SpinFilterableFields_WalletType>
}

export type SpinFilterableFields = {
  amount?: InputMaybe<SpinFilterableFields_Amount>
  balanceAfter?: InputMaybe<SpinFilterableFields_BalanceAfter>
  balanceBefore?: InputMaybe<SpinFilterableFields_BalanceBefore>
  createdAt?: InputMaybe<SpinFilterableFields_CreatedAt>
  externalId?: InputMaybe<SpinFilterableFields_ExternalId>
  game?: InputMaybe<GameFilter>
  id?: InputMaybe<SpinFilterableFields_Id>
  ip?: InputMaybe<SpinFilterableFields_Ip>
  roundId?: InputMaybe<SpinFilterableFields_RoundId>
  type?: InputMaybe<SpinFilterableFields_Type>
  userAgent?: InputMaybe<SpinFilterableFields_UserAgent>
  userId?: InputMaybe<SpinFilterableFields_UserId>
  walletType?: InputMaybe<SpinFilterableFields_WalletType>
}

export type SpinFilterableFieldsEnumerableNested = {
  AND?: InputMaybe<Array<SpinFilterableFields>>
  NOT?: InputMaybe<Array<SpinFilterableFields>>
  OR?: InputMaybe<Array<SpinFilterableFields>>
  amount?: InputMaybe<SpinFilterableFields_Amount>
  balanceAfter?: InputMaybe<SpinFilterableFields_BalanceAfter>
  balanceBefore?: InputMaybe<SpinFilterableFields_BalanceBefore>
  createdAt?: InputMaybe<SpinFilterableFields_CreatedAt>
  externalId?: InputMaybe<SpinFilterableFields_ExternalId>
  game?: InputMaybe<GameFilter>
  id?: InputMaybe<SpinFilterableFields_Id>
  ip?: InputMaybe<SpinFilterableFields_Ip>
  roundId?: InputMaybe<SpinFilterableFields_RoundId>
  type?: InputMaybe<SpinFilterableFields_Type>
  userAgent?: InputMaybe<SpinFilterableFields_UserAgent>
  userId?: InputMaybe<SpinFilterableFields_UserId>
  walletType?: InputMaybe<SpinFilterableFields_WalletType>
}

export type SpinFilterableFields_Amount = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type SpinFilterableFields_BalanceAfter = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type SpinFilterableFields_BalanceBefore = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type SpinFilterableFields_CreatedAt = {
  contains?: InputMaybe<Scalars['DateTime']['input']>
  endsWith?: InputMaybe<Scalars['DateTime']['input']>
  equals?: InputMaybe<Scalars['DateTime']['input']>
  gt?: InputMaybe<Scalars['DateTime']['input']>
  gte?: InputMaybe<Scalars['DateTime']['input']>
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  lt?: InputMaybe<Scalars['DateTime']['input']>
  lte?: InputMaybe<Scalars['DateTime']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['DateTime']['input']>
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  startsWith?: InputMaybe<Scalars['DateTime']['input']>
}

export type SpinFilterableFields_ExternalId = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type SpinFilterableFields_Id = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type SpinFilterableFields_Ip = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type SpinFilterableFields_RoundId = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type SpinFilterableFields_Type = {
  contains?: InputMaybe<SpinType>
  endsWith?: InputMaybe<SpinType>
  equals?: InputMaybe<SpinType>
  gt?: InputMaybe<SpinType>
  gte?: InputMaybe<SpinType>
  in?: InputMaybe<Array<SpinType>>
  lt?: InputMaybe<SpinType>
  lte?: InputMaybe<SpinType>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<SpinType>
  notIn?: InputMaybe<Array<SpinType>>
  startsWith?: InputMaybe<SpinType>
}

export type SpinFilterableFields_UserAgent = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type SpinFilterableFields_UserId = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type SpinFilterableFields_WalletType = {
  contains?: InputMaybe<EWalletType>
  endsWith?: InputMaybe<EWalletType>
  equals?: InputMaybe<EWalletType>
  gt?: InputMaybe<EWalletType>
  gte?: InputMaybe<EWalletType>
  in?: InputMaybe<Array<EWalletType>>
  lt?: InputMaybe<EWalletType>
  lte?: InputMaybe<EWalletType>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<EWalletType>
  notIn?: InputMaybe<Array<EWalletType>>
  startsWith?: InputMaybe<EWalletType>
}

export type SpinMetaType = {
  __typename?: 'SpinMetaType'
  skip: Scalars['Float']['output']
  take: Scalars['Float']['output']
  total: Scalars['Float']['output']
}

export type SpinOrderableFields = {
  amount?: InputMaybe<OrderEnum>
  createdAt?: InputMaybe<OrderEnum>
  gameId?: InputMaybe<OrderEnum>
  id?: InputMaybe<OrderEnum>
  roundId?: InputMaybe<OrderEnum>
  type?: InputMaybe<OrderEnum>
  userId?: InputMaybe<OrderEnum>
  walletType?: InputMaybe<OrderEnum>
}

export enum SpinType {
  Bet = 'BET',
  Rollback = 'ROLLBACK',
  Win = 'WIN'
}

export type StartGameDto = {
  gameId: Scalars['Float']['input']
  homeUrl: Scalars['String']['input']
}

export type Statistics = {
  __typename?: 'Statistics'
  countFirstSuccessDeposits: Scalars['Float']['output']
  countSuccessDeposits: Scalars['Float']['output']
  countUniqueGameHistory: Scalars['Float']['output']
  countUniqueSuccessDeposits: Scalars['Float']['output']
  countUsers: Scalars['Float']['output']
  countUsersWithEmailVerified: Scalars['Float']['output']
  countUsersWithPhoneVerified: Scalars['Float']['output']
  sumFirstSuccessDeposits: Scalars['Float']['output']
  sumPendingWithdraws: Scalars['Float']['output']
  sumSuccessDeposits: Scalars['Float']['output']
  sumSuccessWithdraws: Scalars['Float']['output']
}

export type Tag = {
  __typename?: 'Tag'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type TagFilter = {
  AND?: InputMaybe<Array<TagFilterableFieldsEnumerableNested>>
  NOT?: InputMaybe<Array<TagFilterableFieldsEnumerableNested>>
  OR?: InputMaybe<Array<TagFilterableFieldsEnumerableNested>>
  id?: InputMaybe<TagFilterableFields_Id>
  name?: InputMaybe<TagFilterableFields_Name>
}

export type TagFilterableFields = {
  id?: InputMaybe<TagFilterableFields_Id>
  name?: InputMaybe<TagFilterableFields_Name>
}

export type TagFilterableFieldsEnumerableNested = {
  AND?: InputMaybe<Array<TagFilterableFields>>
  NOT?: InputMaybe<Array<TagFilterableFields>>
  OR?: InputMaybe<Array<TagFilterableFields>>
  id?: InputMaybe<TagFilterableFields_Id>
  name?: InputMaybe<TagFilterableFields_Name>
}

export type TagFilterableFields_Id = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type TagFilterableFields_Name = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type TelegramTrigger = {
  __typename?: 'TelegramTrigger'
  id: Scalars['Float']['output']
  isActive: Scalars['Boolean']['output']
  minAfter: Scalars['Float']['output']
  name: Scalars['String']['output']
  project: Project
  projectId: Scalars['Float']['output']
  sendCount: Scalars['Float']['output']
  templates: Array<TelegramTriggerTemplate>
  type: ETriggerType
}

export type TelegramTriggerSendCountArgs = {
  dto: TriggerInstanceFilter
}

export type TelegramTriggerCreateDto = {
  isActive: Scalars['Boolean']['input']
  minAfter: Scalars['Float']['input']
  name: Scalars['String']['input']
  projectId: Scalars['Float']['input']
  type: ETriggerType
}

export type TelegramTriggerFilter = {
  AND?: InputMaybe<Array<TelegramTriggerFilterableFieldsEnumerableNested>>
  NOT?: InputMaybe<Array<TelegramTriggerFilterableFieldsEnumerableNested>>
  OR?: InputMaybe<Array<TelegramTriggerFilterableFieldsEnumerableNested>>
  id?: InputMaybe<TelegramTriggerFilterableFields_Id>
  isActive?: InputMaybe<TelegramTriggerFilterableFields_IsActive>
  minAfter?: InputMaybe<TelegramTriggerFilterableFields_MinAfter>
  name?: InputMaybe<TelegramTriggerFilterableFields_Name>
  type?: InputMaybe<TelegramTriggerFilterableFields_Type>
}

export type TelegramTriggerFilterableFields = {
  id?: InputMaybe<TelegramTriggerFilterableFields_Id>
  isActive?: InputMaybe<TelegramTriggerFilterableFields_IsActive>
  minAfter?: InputMaybe<TelegramTriggerFilterableFields_MinAfter>
  name?: InputMaybe<TelegramTriggerFilterableFields_Name>
  type?: InputMaybe<TelegramTriggerFilterableFields_Type>
}

export type TelegramTriggerFilterableFieldsEnumerableNested = {
  AND?: InputMaybe<Array<TelegramTriggerFilterableFields>>
  NOT?: InputMaybe<Array<TelegramTriggerFilterableFields>>
  OR?: InputMaybe<Array<TelegramTriggerFilterableFields>>
  id?: InputMaybe<TelegramTriggerFilterableFields_Id>
  isActive?: InputMaybe<TelegramTriggerFilterableFields_IsActive>
  minAfter?: InputMaybe<TelegramTriggerFilterableFields_MinAfter>
  name?: InputMaybe<TelegramTriggerFilterableFields_Name>
  type?: InputMaybe<TelegramTriggerFilterableFields_Type>
}

export type TelegramTriggerFilterableFields_Id = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type TelegramTriggerFilterableFields_IsActive = {
  contains?: InputMaybe<Scalars['Boolean']['input']>
  endsWith?: InputMaybe<Scalars['Boolean']['input']>
  equals?: InputMaybe<Scalars['Boolean']['input']>
  gt?: InputMaybe<Scalars['Boolean']['input']>
  gte?: InputMaybe<Scalars['Boolean']['input']>
  in?: InputMaybe<Array<Scalars['Boolean']['input']>>
  lt?: InputMaybe<Scalars['Boolean']['input']>
  lte?: InputMaybe<Scalars['Boolean']['input']>
  not?: InputMaybe<Scalars['Boolean']['input']>
  notIn?: InputMaybe<Array<Scalars['Boolean']['input']>>
  startsWith?: InputMaybe<Scalars['Boolean']['input']>
}

export type TelegramTriggerFilterableFields_MinAfter = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type TelegramTriggerFilterableFields_Name = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type TelegramTriggerFilterableFields_Type = {
  contains?: InputMaybe<ETriggerType>
  endsWith?: InputMaybe<ETriggerType>
  equals?: InputMaybe<ETriggerType>
  gt?: InputMaybe<ETriggerType>
  gte?: InputMaybe<ETriggerType>
  in?: InputMaybe<Array<ETriggerType>>
  lt?: InputMaybe<ETriggerType>
  lte?: InputMaybe<ETriggerType>
  not?: InputMaybe<ETriggerType>
  notIn?: InputMaybe<Array<ETriggerType>>
  startsWith?: InputMaybe<ETriggerType>
}

export type TelegramTriggerInstance = {
  __typename?: 'TelegramTriggerInstance'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['Int']['output']
  plannedAt?: Maybe<Scalars['DateTime']['output']>
  status: TriggerInstanceStatusEnum
  telegramTemplateId: Scalars['Float']['output']
  template: TelegramTriggerTemplate
  updatedAt: Scalars['DateTime']['output']
  user: User
  userId: Scalars['Float']['output']
}

export type TelegramTriggerMetaType = {
  __typename?: 'TelegramTriggerMetaType'
  skip: Scalars['Float']['output']
  take: Scalars['Float']['output']
  total: Scalars['Float']['output']
}

export type TelegramTriggerOrderableFields = {
  createdAt?: InputMaybe<OrderEnum>
  id?: InputMaybe<OrderEnum>
  isActive?: InputMaybe<OrderEnum>
  name?: InputMaybe<OrderEnum>
  type?: InputMaybe<OrderEnum>
}

export type TelegramTriggerTemplate = {
  __typename?: 'TelegramTriggerTemplate'
  buttonText: Scalars['String']['output']
  id: Scalars['Float']['output']
  language: Language
  languageId: Scalars['Float']['output']
  mediaType: Scalars['String']['output']
  mediaUrl: Scalars['String']['output']
  text: Scalars['String']['output']
  trigger: TelegramTrigger
}

export type TelegramTriggerTemplateCreateDto = {
  buttonText: Scalars['String']['input']
  languageId: Scalars['Float']['input']
  mediaType: Scalars['String']['input']
  mediaUrl: Scalars['String']['input']
  text: Scalars['String']['input']
  triggerId: Scalars['Float']['input']
}

export type TelegramTriggerTemplateUpdateDto = {
  buttonText: Scalars['String']['input']
  id: Scalars['Float']['input']
  mediaType: Scalars['String']['input']
  mediaUrl: Scalars['String']['input']
  text: Scalars['String']['input']
}

export type TelegramTriggerUpdateDto = {
  id: Scalars['Float']['input']
  isActive: Scalars['Boolean']['input']
  minAfter: Scalars['Float']['input']
  name: Scalars['String']['input']
  projectId: Scalars['Float']['input']
}

export type ThroughtTelegramUser = {
  auth_date: Scalars['Float']['input']
  first_name: Scalars['String']['input']
  hash: Scalars['String']['input']
  id: Scalars['Float']['input']
  last_name: Scalars['String']['input']
  photo_url: Scalars['String']['input']
  username: Scalars['String']['input']
}

export type TournamentConfig = {
  __typename?: 'TournamentConfig'
  areGamesVisible: Scalars['Boolean']['output']
  createdAt: Scalars['DateTime']['output']
  duration: Scalars['Int']['output']
  gameCategoriesIds: Array<Scalars['Int']['output']>
  gameTagsIds: Array<Scalars['Int']['output']>
  id: Scalars['Int']['output']
  images?: Maybe<Array<TournamentImage>>
  isActive: Scalars['Boolean']['output']
  minBet: Scalars['Float']['output']
  minDepositCount: Scalars['Int']['output']
  pointsFactor: Scalars['Int']['output']
  prizes?: Maybe<Array<TournamentPrize>>
  schedule: Scalars['String']['output']
  startAt: Scalars['DateTime']['output']
  stopAt?: Maybe<Scalars['DateTime']['output']>
  texts?: Maybe<Array<TournamentText>>
  type: TournamentConfigType
  updatedAt: Scalars['DateTime']['output']
  userTagsIds: Array<Scalars['Int']['output']>
}

export enum TournamentConfigType {
  Bet = 'BET',
  MaxQuotient = 'MAX_QUOTIENT',
  QuotientSum = 'QUOTIENT_SUM'
}

export type TournamentFilter = {
  AND?: InputMaybe<Array<TournamentFilterableFieldsEnumerableNested>>
  NOT?: InputMaybe<Array<TournamentFilterableFieldsEnumerableNested>>
  OR?: InputMaybe<Array<TournamentFilterableFieldsEnumerableNested>>
  createdAt?: InputMaybe<TournamentFilterableFields_CreatedAt>
  expiredAt?: InputMaybe<TournamentFilterableFields_ExpiredAt>
  isActive?: InputMaybe<TournamentFilterableFields_IsActive>
}

export type TournamentFilterableFields = {
  createdAt?: InputMaybe<TournamentFilterableFields_CreatedAt>
  expiredAt?: InputMaybe<TournamentFilterableFields_ExpiredAt>
  isActive?: InputMaybe<TournamentFilterableFields_IsActive>
}

export type TournamentFilterableFieldsEnumerableNested = {
  AND?: InputMaybe<Array<TournamentFilterableFields>>
  NOT?: InputMaybe<Array<TournamentFilterableFields>>
  OR?: InputMaybe<Array<TournamentFilterableFields>>
  createdAt?: InputMaybe<TournamentFilterableFields_CreatedAt>
  expiredAt?: InputMaybe<TournamentFilterableFields_ExpiredAt>
  isActive?: InputMaybe<TournamentFilterableFields_IsActive>
}

export type TournamentFilterableFields_CreatedAt = {
  contains?: InputMaybe<Scalars['DateTime']['input']>
  endsWith?: InputMaybe<Scalars['DateTime']['input']>
  equals?: InputMaybe<Scalars['DateTime']['input']>
  gt?: InputMaybe<Scalars['DateTime']['input']>
  gte?: InputMaybe<Scalars['DateTime']['input']>
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  lt?: InputMaybe<Scalars['DateTime']['input']>
  lte?: InputMaybe<Scalars['DateTime']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['DateTime']['input']>
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  startsWith?: InputMaybe<Scalars['DateTime']['input']>
}

export type TournamentFilterableFields_ExpiredAt = {
  contains?: InputMaybe<Scalars['DateTime']['input']>
  endsWith?: InputMaybe<Scalars['DateTime']['input']>
  equals?: InputMaybe<Scalars['DateTime']['input']>
  gt?: InputMaybe<Scalars['DateTime']['input']>
  gte?: InputMaybe<Scalars['DateTime']['input']>
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  lt?: InputMaybe<Scalars['DateTime']['input']>
  lte?: InputMaybe<Scalars['DateTime']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['DateTime']['input']>
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  startsWith?: InputMaybe<Scalars['DateTime']['input']>
}

export type TournamentFilterableFields_IsActive = {
  contains?: InputMaybe<Scalars['Boolean']['input']>
  endsWith?: InputMaybe<Scalars['Boolean']['input']>
  equals?: InputMaybe<Scalars['Boolean']['input']>
  gt?: InputMaybe<Scalars['Boolean']['input']>
  gte?: InputMaybe<Scalars['Boolean']['input']>
  in?: InputMaybe<Array<Scalars['Boolean']['input']>>
  lt?: InputMaybe<Scalars['Boolean']['input']>
  lte?: InputMaybe<Scalars['Boolean']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Boolean']['input']>
  notIn?: InputMaybe<Array<Scalars['Boolean']['input']>>
  startsWith?: InputMaybe<Scalars['Boolean']['input']>
}

export type TournamentImage = {
  __typename?: 'TournamentImage'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['Int']['output']
  size: TournamentImageSizes
  tournamentConfigId: Scalars['Float']['output']
  tournamentId: Scalars['ID']['output']
  updatedAt: Scalars['DateTime']['output']
  url: Scalars['String']['output']
}

export enum TournamentImageSizes {
  ExtraSmall = 'EXTRA_SMALL',
  Large = 'LARGE',
  Medium = 'MEDIUM',
  Small = 'SMALL'
}

export type TournamentInstance = {
  __typename?: 'TournamentInstance'
  config: TournamentConfig
  configId: Scalars['ID']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['Int']['output']
  places: Array<TournamentPlace>
  updatedAt: Scalars['DateTime']['output']
}

export type TournamentInstanceMetaType = {
  __typename?: 'TournamentInstanceMetaType'
  skip: Scalars['Float']['output']
  take: Scalars['Float']['output']
  total: Scalars['Float']['output']
}

export type TournamentOrderableFields = {
  createdAt?: InputMaybe<OrderEnum>
}

export type TournamentPlace = {
  __typename?: 'TournamentPlace'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['Int']['output']
  place: Scalars['Int']['output']
  points: Scalars['Float']['output']
  tournamentId: Scalars['ID']['output']
  updatedAt: Scalars['DateTime']['output']
  userId: Scalars['ID']['output']
}

export type TournamentPrize = {
  __typename?: 'TournamentPrize'
  createdAt: Scalars['DateTime']['output']
  gift: GiftConfig
  giftId: Scalars['ID']['output']
  id: Scalars['Int']['output']
  place: Scalars['Float']['output']
  tournamentId: Scalars['ID']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type TournamentText = {
  __typename?: 'TournamentText'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['Int']['output']
  languageId: Scalars['ID']['output']
  tournamentConfig: TournamentConfig
  tournamentId: Scalars['ID']['output']
  type: TournamentTextTypes
  updatedAt: Scalars['DateTime']['output']
  value: Scalars['String']['output']
}

export enum TournamentTextTypes {
  BannerText = 'BANNER_TEXT',
  Description = 'DESCRIPTION',
  Name = 'NAME',
  PrizeFund = 'PRIZE_FUND'
}

export type TournamentWinner = {
  __typename?: 'TournamentWinner'
  createdAt: Scalars['DateTime']['output']
  giftConfigId: Scalars['Int']['output']
  id: Scalars['Int']['output']
  place: Scalars['Int']['output']
  points: Scalars['Float']['output']
  tournamentId: Scalars['ID']['output']
  updatedAt: Scalars['DateTime']['output']
  userId: Scalars['ID']['output']
}

export type Transaction = ITransaction & {
  __typename?: 'Transaction'
  amount: Scalars['Decimal']['output']
  amountInUserCurrency: Scalars['Float']['output']
  createdAt: Scalars['DateTime']['output']
  currencyId: Scalars['Float']['output']
  feeValue: Scalars['Float']['output']
  id: Scalars['String']['output']
  isWagered: Scalars['Boolean']['output']
  paymentCountry?: Maybe<Scalars['String']['output']>
  paymentDetails?: Maybe<Scalars['String']['output']>
  paymentSystem: UserPaymentSystem
  promoCode?: Maybe<Scalars['Float']['output']>
  status: ETransactionStatus
  type: ETransactionCreateType
  updatedAt: Scalars['DateTime']['output']
  userId: Scalars['Float']['output']
  wagerSum: Scalars['Decimal']['output']
  wageredSum: Scalars['Decimal']['output']
}

export type TransactionFilter = {
  AND?: InputMaybe<Array<TransactionFilterableFieldsEnumerableNested>>
  NOT?: InputMaybe<Array<TransactionFilterableFieldsEnumerableNested>>
  OR?: InputMaybe<Array<TransactionFilterableFieldsEnumerableNested>>
  adminId?: InputMaybe<TransactionFilterableFields_AdminId>
  amount?: InputMaybe<TransactionFilterableFields_Amount>
  amountInUserCurrency?: InputMaybe<TransactionFilterableFields_AmountInUserCurrency>
  comments?: InputMaybe<TransactionFilterableFields_Comments>
  createdAt?: InputMaybe<TransactionFilterableFields_CreatedAt>
  currencyId?: InputMaybe<TransactionFilterableFields_CurrencyId>
  externalId?: InputMaybe<TransactionFilterableFields_ExternalId>
  failMessage?: InputMaybe<TransactionFilterableFields_FailMessage>
  feeValue?: InputMaybe<TransactionFilterableFields_FeeValue>
  gatewayId?: InputMaybe<TransactionFilterableFields_GatewayId>
  id?: InputMaybe<TransactionFilterableFields_Id>
  isWagered?: InputMaybe<TransactionFilterableFields_IsWagered>
  merchantId?: InputMaybe<TransactionFilterableFields_MerchantId>
  paymentCountry?: InputMaybe<TransactionFilterableFields_PaymentCountry>
  paymentDetails?: InputMaybe<TransactionFilterableFields_PaymentDetails>
  processing?: InputMaybe<TransactionFilterableFields_Processing>
  promoCode?: InputMaybe<TransactionFilterableFields_PromoCode>
  relatedTransactionId?: InputMaybe<TransactionFilterableFields_RelatedTransactionId>
  status?: InputMaybe<TransactionFilterableFields_Status>
  type?: InputMaybe<TransactionFilterableFields_Type>
  updatedAt?: InputMaybe<TransactionFilterableFields_UpdatedAt>
  userId?: InputMaybe<TransactionFilterableFields_UserId>
}

export type TransactionFilterableFields = {
  adminId?: InputMaybe<TransactionFilterableFields_AdminId>
  amount?: InputMaybe<TransactionFilterableFields_Amount>
  amountInUserCurrency?: InputMaybe<TransactionFilterableFields_AmountInUserCurrency>
  comments?: InputMaybe<TransactionFilterableFields_Comments>
  createdAt?: InputMaybe<TransactionFilterableFields_CreatedAt>
  currencyId?: InputMaybe<TransactionFilterableFields_CurrencyId>
  externalId?: InputMaybe<TransactionFilterableFields_ExternalId>
  failMessage?: InputMaybe<TransactionFilterableFields_FailMessage>
  feeValue?: InputMaybe<TransactionFilterableFields_FeeValue>
  gatewayId?: InputMaybe<TransactionFilterableFields_GatewayId>
  id?: InputMaybe<TransactionFilterableFields_Id>
  isWagered?: InputMaybe<TransactionFilterableFields_IsWagered>
  merchantId?: InputMaybe<TransactionFilterableFields_MerchantId>
  paymentCountry?: InputMaybe<TransactionFilterableFields_PaymentCountry>
  paymentDetails?: InputMaybe<TransactionFilterableFields_PaymentDetails>
  processing?: InputMaybe<TransactionFilterableFields_Processing>
  promoCode?: InputMaybe<TransactionFilterableFields_PromoCode>
  relatedTransactionId?: InputMaybe<TransactionFilterableFields_RelatedTransactionId>
  status?: InputMaybe<TransactionFilterableFields_Status>
  type?: InputMaybe<TransactionFilterableFields_Type>
  updatedAt?: InputMaybe<TransactionFilterableFields_UpdatedAt>
  userId?: InputMaybe<TransactionFilterableFields_UserId>
}

export type TransactionFilterableFieldsEnumerableNested = {
  AND?: InputMaybe<Array<TransactionFilterableFields>>
  NOT?: InputMaybe<Array<TransactionFilterableFields>>
  OR?: InputMaybe<Array<TransactionFilterableFields>>
  adminId?: InputMaybe<TransactionFilterableFields_AdminId>
  amount?: InputMaybe<TransactionFilterableFields_Amount>
  amountInUserCurrency?: InputMaybe<TransactionFilterableFields_AmountInUserCurrency>
  comments?: InputMaybe<TransactionFilterableFields_Comments>
  createdAt?: InputMaybe<TransactionFilterableFields_CreatedAt>
  currencyId?: InputMaybe<TransactionFilterableFields_CurrencyId>
  externalId?: InputMaybe<TransactionFilterableFields_ExternalId>
  failMessage?: InputMaybe<TransactionFilterableFields_FailMessage>
  feeValue?: InputMaybe<TransactionFilterableFields_FeeValue>
  gatewayId?: InputMaybe<TransactionFilterableFields_GatewayId>
  id?: InputMaybe<TransactionFilterableFields_Id>
  isWagered?: InputMaybe<TransactionFilterableFields_IsWagered>
  merchantId?: InputMaybe<TransactionFilterableFields_MerchantId>
  paymentCountry?: InputMaybe<TransactionFilterableFields_PaymentCountry>
  paymentDetails?: InputMaybe<TransactionFilterableFields_PaymentDetails>
  processing?: InputMaybe<TransactionFilterableFields_Processing>
  promoCode?: InputMaybe<TransactionFilterableFields_PromoCode>
  relatedTransactionId?: InputMaybe<TransactionFilterableFields_RelatedTransactionId>
  status?: InputMaybe<TransactionFilterableFields_Status>
  type?: InputMaybe<TransactionFilterableFields_Type>
  updatedAt?: InputMaybe<TransactionFilterableFields_UpdatedAt>
  userId?: InputMaybe<TransactionFilterableFields_UserId>
}

export type TransactionFilterableFields_AdminId = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type TransactionFilterableFields_Amount = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type TransactionFilterableFields_AmountInUserCurrency = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type TransactionFilterableFields_Comments = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type TransactionFilterableFields_CreatedAt = {
  contains?: InputMaybe<Scalars['DateTime']['input']>
  endsWith?: InputMaybe<Scalars['DateTime']['input']>
  equals?: InputMaybe<Scalars['DateTime']['input']>
  gt?: InputMaybe<Scalars['DateTime']['input']>
  gte?: InputMaybe<Scalars['DateTime']['input']>
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  lt?: InputMaybe<Scalars['DateTime']['input']>
  lte?: InputMaybe<Scalars['DateTime']['input']>
  not?: InputMaybe<Scalars['DateTime']['input']>
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  startsWith?: InputMaybe<Scalars['DateTime']['input']>
}

export type TransactionFilterableFields_CurrencyId = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type TransactionFilterableFields_ExternalId = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type TransactionFilterableFields_FailMessage = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type TransactionFilterableFields_FeeValue = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type TransactionFilterableFields_GatewayId = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type TransactionFilterableFields_Id = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type TransactionFilterableFields_IsWagered = {
  contains?: InputMaybe<Scalars['Boolean']['input']>
  endsWith?: InputMaybe<Scalars['Boolean']['input']>
  equals?: InputMaybe<Scalars['Boolean']['input']>
  gt?: InputMaybe<Scalars['Boolean']['input']>
  gte?: InputMaybe<Scalars['Boolean']['input']>
  in?: InputMaybe<Array<Scalars['Boolean']['input']>>
  lt?: InputMaybe<Scalars['Boolean']['input']>
  lte?: InputMaybe<Scalars['Boolean']['input']>
  not?: InputMaybe<Scalars['Boolean']['input']>
  notIn?: InputMaybe<Array<Scalars['Boolean']['input']>>
  startsWith?: InputMaybe<Scalars['Boolean']['input']>
}

export type TransactionFilterableFields_MerchantId = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type TransactionFilterableFields_PaymentCountry = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type TransactionFilterableFields_PaymentDetails = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type TransactionFilterableFields_Processing = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type TransactionFilterableFields_PromoCode = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type TransactionFilterableFields_RelatedTransactionId = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type TransactionFilterableFields_Status = {
  contains?: InputMaybe<ETransactionStatus>
  endsWith?: InputMaybe<ETransactionStatus>
  equals?: InputMaybe<ETransactionStatus>
  gt?: InputMaybe<ETransactionStatus>
  gte?: InputMaybe<ETransactionStatus>
  in?: InputMaybe<Array<ETransactionStatus>>
  lt?: InputMaybe<ETransactionStatus>
  lte?: InputMaybe<ETransactionStatus>
  not?: InputMaybe<ETransactionStatus>
  notIn?: InputMaybe<Array<ETransactionStatus>>
  startsWith?: InputMaybe<ETransactionStatus>
}

export type TransactionFilterableFields_Type = {
  contains?: InputMaybe<ETransactionCreateType>
  endsWith?: InputMaybe<ETransactionCreateType>
  equals?: InputMaybe<ETransactionCreateType>
  gt?: InputMaybe<ETransactionCreateType>
  gte?: InputMaybe<ETransactionCreateType>
  in?: InputMaybe<Array<ETransactionCreateType>>
  lt?: InputMaybe<ETransactionCreateType>
  lte?: InputMaybe<ETransactionCreateType>
  not?: InputMaybe<ETransactionCreateType>
  notIn?: InputMaybe<Array<ETransactionCreateType>>
  startsWith?: InputMaybe<ETransactionCreateType>
}

export type TransactionFilterableFields_UpdatedAt = {
  contains?: InputMaybe<Scalars['DateTime']['input']>
  endsWith?: InputMaybe<Scalars['DateTime']['input']>
  equals?: InputMaybe<Scalars['DateTime']['input']>
  gt?: InputMaybe<Scalars['DateTime']['input']>
  gte?: InputMaybe<Scalars['DateTime']['input']>
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  lt?: InputMaybe<Scalars['DateTime']['input']>
  lte?: InputMaybe<Scalars['DateTime']['input']>
  not?: InputMaybe<Scalars['DateTime']['input']>
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  startsWith?: InputMaybe<Scalars['DateTime']['input']>
}

export type TransactionFilterableFields_UserId = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type TransactionMetaType = {
  __typename?: 'TransactionMetaType'
  skip: Scalars['Float']['output']
  take: Scalars['Float']['output']
  total: Scalars['Float']['output']
}

export type TransactionOrderableFields = {
  amount?: InputMaybe<OrderEnum>
  amountInUserCurrency?: InputMaybe<OrderEnum>
  createdAt?: InputMaybe<OrderEnum>
  status?: InputMaybe<OrderEnum>
  type?: InputMaybe<OrderEnum>
}

export type Trigger = {
  __typename?: 'Trigger'
  clickCount: Scalars['Float']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['Int']['output']
  isActive: Scalars['Boolean']['output']
  minAfter: Scalars['Float']['output']
  name: Scalars['String']['output']
  openCount: Scalars['Float']['output']
  sendCount: Scalars['Float']['output']
  sender: Sender
  senderId: Scalars['Float']['output']
  templates: Array<TriggerTemplate>
  type: ETriggerType
  updatedAt: Scalars['DateTime']['output']
}

export type TriggerClickCountArgs = {
  dto: TriggerInstanceFilter
}

export type TriggerOpenCountArgs = {
  dto: TriggerInstanceFilter
}

export type TriggerSendCountArgs = {
  dto: TriggerInstanceFilter
}

export type TriggerCreateDto = {
  description?: InputMaybe<Scalars['String']['input']>
  isActive: Scalars['Boolean']['input']
  minAfter: Scalars['Float']['input']
  name: Scalars['String']['input']
  senderId: Scalars['Float']['input']
  type: ETriggerType
}

export type TriggerFilter = {
  AND?: InputMaybe<Array<TriggerFilterableFieldsEnumerableNested>>
  NOT?: InputMaybe<Array<TriggerFilterableFieldsEnumerableNested>>
  OR?: InputMaybe<Array<TriggerFilterableFieldsEnumerableNested>>
  id?: InputMaybe<TriggerFilterableFields_Id>
  isActive?: InputMaybe<TriggerFilterableFields_IsActive>
  minAfter?: InputMaybe<TriggerFilterableFields_MinAfter>
  name?: InputMaybe<TriggerFilterableFields_Name>
  type?: InputMaybe<TriggerFilterableFields_Type>
}

export type TriggerFilterableFields = {
  id?: InputMaybe<TriggerFilterableFields_Id>
  isActive?: InputMaybe<TriggerFilterableFields_IsActive>
  minAfter?: InputMaybe<TriggerFilterableFields_MinAfter>
  name?: InputMaybe<TriggerFilterableFields_Name>
  type?: InputMaybe<TriggerFilterableFields_Type>
}

export type TriggerFilterableFieldsEnumerableNested = {
  AND?: InputMaybe<Array<TriggerFilterableFields>>
  NOT?: InputMaybe<Array<TriggerFilterableFields>>
  OR?: InputMaybe<Array<TriggerFilterableFields>>
  id?: InputMaybe<TriggerFilterableFields_Id>
  isActive?: InputMaybe<TriggerFilterableFields_IsActive>
  minAfter?: InputMaybe<TriggerFilterableFields_MinAfter>
  name?: InputMaybe<TriggerFilterableFields_Name>
  type?: InputMaybe<TriggerFilterableFields_Type>
}

export type TriggerFilterableFields_Id = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type TriggerFilterableFields_IsActive = {
  contains?: InputMaybe<Scalars['Boolean']['input']>
  endsWith?: InputMaybe<Scalars['Boolean']['input']>
  equals?: InputMaybe<Scalars['Boolean']['input']>
  gt?: InputMaybe<Scalars['Boolean']['input']>
  gte?: InputMaybe<Scalars['Boolean']['input']>
  in?: InputMaybe<Array<Scalars['Boolean']['input']>>
  lt?: InputMaybe<Scalars['Boolean']['input']>
  lte?: InputMaybe<Scalars['Boolean']['input']>
  not?: InputMaybe<Scalars['Boolean']['input']>
  notIn?: InputMaybe<Array<Scalars['Boolean']['input']>>
  startsWith?: InputMaybe<Scalars['Boolean']['input']>
}

export type TriggerFilterableFields_MinAfter = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type TriggerFilterableFields_Name = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type TriggerFilterableFields_Type = {
  contains?: InputMaybe<ETriggerType>
  endsWith?: InputMaybe<ETriggerType>
  equals?: InputMaybe<ETriggerType>
  gt?: InputMaybe<ETriggerType>
  gte?: InputMaybe<ETriggerType>
  in?: InputMaybe<Array<ETriggerType>>
  lt?: InputMaybe<ETriggerType>
  lte?: InputMaybe<ETriggerType>
  not?: InputMaybe<ETriggerType>
  notIn?: InputMaybe<Array<ETriggerType>>
  startsWith?: InputMaybe<ETriggerType>
}

export type TriggerInstance = {
  __typename?: 'TriggerInstance'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['Int']['output']
  linkClicked: Scalars['Boolean']['output']
  opened: Scalars['Boolean']['output']
  plannedAt?: Maybe<Scalars['DateTime']['output']>
  status: TriggerInstanceStatusEnum
  template: TriggerTemplate
  templateId: Scalars['Float']['output']
  updatedAt: Scalars['DateTime']['output']
  user: User
  userId: Scalars['Float']['output']
}

export type TriggerInstanceFilter = {
  AND?: InputMaybe<Array<TriggerInstanceFilterableFieldsEnumerableNested>>
  NOT?: InputMaybe<Array<TriggerInstanceFilterableFieldsEnumerableNested>>
  OR?: InputMaybe<Array<TriggerInstanceFilterableFieldsEnumerableNested>>
  createdAt?: InputMaybe<TriggerInstanceFilterableFields_CreatedAt>
  id?: InputMaybe<TriggerInstanceFilterableFields_Id>
}

export type TriggerInstanceFilterableFields = {
  createdAt?: InputMaybe<TriggerInstanceFilterableFields_CreatedAt>
  id?: InputMaybe<TriggerInstanceFilterableFields_Id>
}

export type TriggerInstanceFilterableFieldsEnumerableNested = {
  AND?: InputMaybe<Array<TriggerInstanceFilterableFields>>
  NOT?: InputMaybe<Array<TriggerInstanceFilterableFields>>
  OR?: InputMaybe<Array<TriggerInstanceFilterableFields>>
  createdAt?: InputMaybe<TriggerInstanceFilterableFields_CreatedAt>
  id?: InputMaybe<TriggerInstanceFilterableFields_Id>
}

export type TriggerInstanceFilterableFields_CreatedAt = {
  contains?: InputMaybe<Scalars['DateTime']['input']>
  endsWith?: InputMaybe<Scalars['DateTime']['input']>
  equals?: InputMaybe<Scalars['DateTime']['input']>
  gt?: InputMaybe<Scalars['DateTime']['input']>
  gte?: InputMaybe<Scalars['DateTime']['input']>
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  lt?: InputMaybe<Scalars['DateTime']['input']>
  lte?: InputMaybe<Scalars['DateTime']['input']>
  not?: InputMaybe<Scalars['DateTime']['input']>
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  startsWith?: InputMaybe<Scalars['DateTime']['input']>
}

export type TriggerInstanceFilterableFields_Id = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export enum TriggerInstanceStatusEnum {
  Created = 'Created',
  Failed = 'Failed',
  Planned = 'Planned',
  Sended = 'Sended'
}

export type TriggerMetaType = {
  __typename?: 'TriggerMetaType'
  skip: Scalars['Float']['output']
  take: Scalars['Float']['output']
  total: Scalars['Float']['output']
}

export type TriggerOrderableFields = {
  createdAt?: InputMaybe<OrderEnum>
  id?: InputMaybe<OrderEnum>
  isActive?: InputMaybe<OrderEnum>
  name?: InputMaybe<OrderEnum>
  type?: InputMaybe<OrderEnum>
}

export type TriggerTemplate = {
  __typename?: 'TriggerTemplate'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['Int']['output']
  language: Language
  languageId: Scalars['Float']['output']
  subject: Scalars['String']['output']
  text: Scalars['String']['output']
  trigger: Trigger
  triggerId: Scalars['Float']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type TriggerTemplateCreateDto = {
  languageId: Scalars['Float']['input']
  subject: Scalars['String']['input']
  text: Scalars['String']['input']
  triggerId: Scalars['Float']['input']
}

export type TriggerTemplateUpdateDto = {
  id: Scalars['Float']['input']
  subject: Scalars['String']['input']
  text: Scalars['String']['input']
}

export type TriggerUpdateDto = {
  description?: InputMaybe<Scalars['String']['input']>
  id: Scalars['Float']['input']
  isActive: Scalars['Boolean']['input']
  minAfter: Scalars['Float']['input']
  name: Scalars['String']['input']
  senderId: Scalars['Float']['input']
}

export type UpdateBonusConfigRateDto = {
  bonusConfigId?: InputMaybe<Scalars['Float']['input']>
  currencyId?: InputMaybe<Scalars['Float']['input']>
  fixedValue?: InputMaybe<Scalars['Float']['input']>
  id: Scalars['Float']['input']
  maxBet?: InputMaybe<Scalars['Float']['input']>
  maxValue?: InputMaybe<Scalars['Float']['input']>
  maxWinValue?: InputMaybe<Scalars['Float']['input']>
  minDepValue?: InputMaybe<Scalars['Float']['input']>
}

export type UpdateBonusInstanceDto = {
  configId?: InputMaybe<Scalars['Float']['input']>
  giftId?: InputMaybe<Scalars['Float']['input']>
  id: Scalars['Float']['input']
  wagerBonusSum?: InputMaybe<Scalars['Float']['input']>
  wagered?: InputMaybe<Scalars['Float']['input']>
}

export type UpdateBrandDto = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  allowAllCountries?: InputMaybe<Scalars['Boolean']['input']>
  countryIds?: Array<Scalars['Float']['input']>
  currencyIds?: InputMaybe<Array<Scalars['Float']['input']>>
  darkPreviewImage?: InputMaybe<Scalars['String']['input']>
  externalId?: InputMaybe<Scalars['Float']['input']>
  externalName?: InputMaybe<Scalars['String']['input']>
  hide?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['Float']['input']
  lightPreviewImage?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  provider?: InputMaybe<Scalars['String']['input']>
  subExternalId?: InputMaybe<Scalars['Float']['input']>
}

export type UpdateByUserDto = {
  address: Scalars['String']['input']
  birthday: Scalars['DateTime']['input']
  city?: InputMaybe<Scalars['String']['input']>
  countryId: Scalars['Float']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  sex: EUserSex
  zip: Scalars['String']['input']
}

export type UpdateCountryDto = {
  code: Scalars['String']['input']
  id: Scalars['Float']['input']
  name: Scalars['String']['input']
}

export type UpdateFreespinsParamsDto = {
  bet?: InputMaybe<Scalars['Decimal']['input']>
  betLevel?: InputMaybe<Scalars['Float']['input']>
  bonusConfigId?: InputMaybe<Scalars['Float']['input']>
  count?: InputMaybe<Scalars['Float']['input']>
  currencyId?: InputMaybe<Scalars['Float']['input']>
  denomination?: InputMaybe<Scalars['Decimal']['input']>
  gameId?: InputMaybe<Scalars['Float']['input']>
  id: Scalars['Float']['input']
  provider?: InputMaybe<Scalars['String']['input']>
  ttl?: InputMaybe<Scalars['Float']['input']>
}

export type UpdateGameByAdminDto = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  bonusEligible?: InputMaybe<Scalars['Boolean']['input']>
  brandId?: InputMaybe<Scalars['Float']['input']>
  categoryIds?: InputMaybe<Array<Scalars['Float']['input']>>
  countryIds?: InputMaybe<Array<Scalars['Float']['input']>>
  demo?: InputMaybe<Scalars['Boolean']['input']>
  devices?: InputMaybe<EDeviceType>
  freespinsEligible?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['Float']['input']
  isWagering?: InputMaybe<Scalars['Boolean']['input']>
  lines?: InputMaybe<Scalars['Float']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  percentRate?: InputMaybe<Scalars['Float']['input']>
  previewImage?: InputMaybe<Scalars['String']['input']>
  provider?: InputMaybe<EProvider>
  providerJackpotIds?: InputMaybe<Array<Scalars['Float']['input']>>
  remoteName?: InputMaybe<Scalars['String']['input']>
  tagIds?: InputMaybe<Array<Scalars['Float']['input']>>
}

export type UpdateGatewayCurrencyDto = {
  id: Scalars['Float']['input']
  isDefault: Scalars['Boolean']['input']
  maxDepositValue?: InputMaybe<Scalars['Float']['input']>
  maxWithdrawalValue?: InputMaybe<Scalars['Float']['input']>
  minDepositValue?: InputMaybe<Scalars['Float']['input']>
  minWithdrawalValue?: InputMaybe<Scalars['Float']['input']>
}

export type UpdateGatewayDto = {
  chargeBackFeeValue?: InputMaybe<Scalars['Float']['input']>
  externalId: Scalars['String']['input']
  fee?: InputMaybe<Scalars['Float']['input']>
  feeAdditionalValue?: InputMaybe<Scalars['Float']['input']>
  feeMinimalValue?: InputMaybe<Scalars['Float']['input']>
  id: Scalars['Float']['input']
  isDefault: Scalars['Boolean']['input']
  minDepositsCount: Scalars['Float']['input']
  minDepositsSum: Scalars['Float']['input']
  name: Scalars['String']['input']
  refundFeeValue?: InputMaybe<Scalars['Float']['input']>
  withInvoice?: InputMaybe<Scalars['Boolean']['input']>
  withdrawalEligible: Scalars['Boolean']['input']
}

export type UpdateGiftConfigDto = {
  activationTtl?: InputMaybe<Scalars['Float']['input']>
  comment?: InputMaybe<Scalars['String']['input']>
  countriesIds?: InputMaybe<Array<Scalars['Float']['input']>>
  depCount?: InputMaybe<Scalars['Float']['input']>
  groupIds?: InputMaybe<Array<Scalars['Float']['input']>>
  hide?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['Float']['input']
  image?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  promoCodeId?: InputMaybe<Scalars['Float']['input']>
  refCamp?: InputMaybe<Scalars['String']['input']>
  referalId?: InputMaybe<Scalars['String']['input']>
  source?: InputMaybe<Scalars['String']['input']>
  tagsIds?: InputMaybe<Array<Scalars['Float']['input']>>
  ttl?: InputMaybe<Scalars['Float']['input']>
}

export type UpdatePaymentSystemDto = {
  checkDepositPaymentDetailsForWithdraw?: Scalars['Boolean']['input']
  defaultDarkIcon?: InputMaybe<Scalars['String']['input']>
  defaultIcon: Scalars['String']['input']
  defaultWithdrawDarkIcon?: InputMaybe<Scalars['String']['input']>
  defaultWithdrawIcon?: InputMaybe<Scalars['String']['input']>
  fillProfileRequired?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['Float']['input']
  isActive?: Scalars['Boolean']['input']
  name: Scalars['String']['input']
  paymentSystemInputs: Array<CreatePaymentSystemInputNestedDto>
  paymentSystemMessage: Array<CreatePaymentSystemMessageNestedDto>
  successDepositForWithdraw?: Scalars['Boolean']['input']
  type: EPaymentSystemType
  valueOptions: Array<CreatePaymentSystemOptionNestedDto>
  visibleInFooter?: Scalars['Boolean']['input']
}

export type UpdatePaymentSystemInputDto = {
  id: Scalars['Float']['input']
  label?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  regexp: Scalars['String']['input']
  target?: InputMaybe<EPaymentTarget>
}

export type UpdatePaymentSystemMessageDto = {
  id: Scalars['Float']['input']
  languageId: Scalars['Float']['input']
  message: Scalars['String']['input']
  target: EPaymentTarget
}

export type UpdatePaymentSystemOptionDto = {
  id: Scalars['Float']['input']
  value: Scalars['Float']['input']
}

export type UpdatePhoneSenderDto = {
  id: Scalars['Float']['input']
  login: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type UpdateProjectDto = {
  blockedCountries: Array<Scalars['Float']['input']>
  domain: Scalars['String']['input']
  id: Scalars['Float']['input']
  isLicensed: Scalars['Boolean']['input']
  languages: Array<Scalars['Float']['input']>
  mirrorDomain: Scalars['String']['input']
  name: Scalars['String']['input']
  postalApiKey: Scalars['String']['input']
  wagerDep: Scalars['Float']['input']
}

export type UpdatePromocodeConfigDto = {
  comment?: InputMaybe<Scalars['String']['input']>
  giftConfigId?: InputMaybe<Scalars['Float']['input']>
  id: Scalars['Int']['input']
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  ratelimitPerUser?: InputMaybe<Scalars['Float']['input']>
  ratelimitPeriodPerUser?: InputMaybe<Scalars['String']['input']>
  stock?: InputMaybe<Scalars['Float']['input']>
  stockPerUser?: InputMaybe<Scalars['Float']['input']>
  type?: InputMaybe<Scalars['String']['input']>
}

export type UpdateSenderDto = {
  apiKey: Scalars['String']['input']
  description: Scalars['String']['input']
  domain: Scalars['String']['input']
  from: Scalars['String']['input']
  id: Scalars['Float']['input']
  mirrorDomain: Scalars['String']['input']
  name: Scalars['String']['input']
  replyTo: Scalars['String']['input']
}

export type UpdateTagDto = {
  id: Scalars['Float']['input']
  name: Scalars['String']['input']
}

export type UpdateTournamentConfigDto = {
  areGamesVisible?: InputMaybe<Scalars['Boolean']['input']>
  duration?: InputMaybe<Scalars['Float']['input']>
  gameCategoriesIds?: InputMaybe<Array<Scalars['Float']['input']>>
  gameTagsIds?: InputMaybe<Array<Scalars['Float']['input']>>
  id: Scalars['Float']['input']
  minBet?: InputMaybe<Scalars['Float']['input']>
  minDepositCount?: InputMaybe<Scalars['Float']['input']>
  pointsFactor?: InputMaybe<Scalars['Float']['input']>
  schedule?: InputMaybe<Scalars['String']['input']>
  startAt?: InputMaybe<Scalars['DateTime']['input']>
  stopAt?: InputMaybe<Scalars['DateTime']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  userTagsIds?: InputMaybe<Array<Scalars['Float']['input']>>
}

export type UpdateTournamentImageDto = {
  id: Scalars['Float']['input']
  languageId?: InputMaybe<Scalars['Float']['input']>
  size?: InputMaybe<Scalars['String']['input']>
  tournamentConfigId?: InputMaybe<Scalars['Float']['input']>
  url?: InputMaybe<Scalars['String']['input']>
}

export type UpdateTournamentInstanceto = {
  configId?: InputMaybe<Scalars['Float']['input']>
  expiredAt?: InputMaybe<Scalars['DateTime']['input']>
  id: Scalars['Float']['input']
  isActive?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdateTournamentPlaceDto = {
  id: Scalars['Float']['input']
  place?: InputMaybe<Scalars['Float']['input']>
  points?: InputMaybe<Scalars['Float']['input']>
  tournamentId?: InputMaybe<Scalars['Float']['input']>
  userId?: InputMaybe<Scalars['Float']['input']>
}

export type UpdateTournamentPrizeDto = {
  giftId?: InputMaybe<Scalars['Float']['input']>
  id: Scalars['Float']['input']
  place?: InputMaybe<Scalars['Float']['input']>
  tournamentId?: InputMaybe<Scalars['Float']['input']>
}

export type UpdateTournamentTextDto = {
  id: Scalars['Float']['input']
  languageId?: InputMaybe<Scalars['Float']['input']>
  tournamentId?: InputMaybe<Scalars['Float']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

export type UpdateTournamentWinnerDto = {
  giftConfigId?: InputMaybe<Scalars['Float']['input']>
  id: Scalars['Float']['input']
  place?: InputMaybe<Scalars['Float']['input']>
  points?: InputMaybe<Scalars['Float']['input']>
  tournamentId?: InputMaybe<Scalars['Float']['input']>
  userId?: InputMaybe<Scalars['Float']['input']>
}

export type UpdateTransactionDto = {
  adminId?: InputMaybe<Scalars['String']['input']>
  amount?: InputMaybe<Scalars['Float']['input']>
  comments?: InputMaybe<Scalars['String']['input']>
  failMessage?: InputMaybe<Scalars['String']['input']>
  id: Scalars['String']['input']
  isWagered?: InputMaybe<Scalars['Boolean']['input']>
  merchantId?: InputMaybe<Scalars['Float']['input']>
  paymentDetails?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<ETransactionStatus>
  type?: InputMaybe<ETransactionCreateType>
  wagersum?: InputMaybe<Scalars['Float']['input']>
}

export type UpdateUserByAdminDto = {
  address?: InputMaybe<Scalars['String']['input']>
  birthday?: InputMaybe<Scalars['DateTime']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  countryId?: InputMaybe<Scalars['Float']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  groups?: InputMaybe<Array<Scalars['Float']['input']>>
  id: Scalars['Float']['input']
  lastName?: InputMaybe<Scalars['String']['input']>
  sex?: InputMaybe<EUserSex>
  status: EUserStatus
  tags?: InputMaybe<Array<Scalars['Float']['input']>>
  zip?: InputMaybe<Scalars['String']['input']>
}

export type User = {
  __typename?: 'User'
  address?: Maybe<Scalars['String']['output']>
  birthday?: Maybe<Scalars['DateTime']['output']>
  city?: Maybe<Scalars['String']['output']>
  country?: Maybe<Country>
  countryId?: Maybe<Scalars['Float']['output']>
  createdAt: Scalars['DateTime']['output']
  currency: CurrencyEntity
  currencyId: Scalars['Float']['output']
  docsVerified: Scalars['Boolean']['output']
  email: Scalars['String']['output']
  emailSubscribed: Scalars['Boolean']['output']
  emailVerified: Scalars['Boolean']['output']
  firstName?: Maybe<Scalars['String']['output']>
  groups: Array<Group>
  hasDeposit: Scalars['Boolean']['output']
  id: Scalars['Float']['output']
  language: Language
  languageId: Scalars['Float']['output']
  lastName?: Maybe<Scalars['String']['output']>
  messengerVerified: Scalars['Boolean']['output']
  phone?: Maybe<Scalars['String']['output']>
  phoneVerified: Scalars['Boolean']['output']
  project: Project
  projectId: Scalars['Float']['output']
  refCamp?: Maybe<Scalars['String']['output']>
  referalId?: Maybe<Scalars['String']['output']>
  sex?: Maybe<EUserSex>
  state?: Maybe<Scalars['String']['output']>
  status: EUserStatus
  tags: Array<Tag>
  updatedAt: Scalars['DateTime']['output']
  wallets: Array<Wallet>
  withdrawableAmount: Scalars['Float']['output']
  zip?: Maybe<Scalars['String']['output']>
}

export type UserBonus = {
  __typename?: 'UserBonus'
  game?: Maybe<GameFromBonus>
  gameId?: Maybe<Scalars['Float']['output']>
  id: Scalars['Float']['output']
  image?: Maybe<Scalars['String']['output']>
  ttl: Scalars['DateTime']['output']
  type: BonusTypes
  value?: Maybe<Scalars['Float']['output']>
  valueType: EBonusValueType
  wager?: Maybe<Scalars['Float']['output']>
  wagerSum?: Maybe<Scalars['Float']['output']>
  wagered?: Maybe<Scalars['Float']['output']>
}

export type UserBonusConfig = {
  __typename?: 'UserBonusConfig'
  freespinParams?: Maybe<UserFreespinParams>
  rate?: Maybe<UserConfigRate>
  type: BonusTypes
  value: Scalars['Float']['output']
  valueType: BonusValueType
  wager: Scalars['Float']['output']
}

export type UserBonusInstance = {
  __typename?: 'UserBonusInstance'
  bonusConfig: UserBonusConfig
  expiredAt?: Maybe<Scalars['DateTime']['output']>
  giftId: Scalars['Float']['output']
  id: Scalars['Float']['output']
  wagerBonusSum: Scalars['Float']['output']
  wagered: Scalars['Float']['output']
}

export type UserConfigRate = {
  __typename?: 'UserConfigRate'
  currencyId: Scalars['Float']['output']
  fixedValue?: Maybe<Scalars['Float']['output']>
  maxBet?: Maybe<Scalars['Float']['output']>
  maxBetValue?: Maybe<Scalars['Float']['output']>
  maxWinValue?: Maybe<Scalars['Float']['output']>
}

export type UserDocument = {
  __typename?: 'UserDocument'
  createdAt: Scalars['DateTime']['output']
  fileId: Scalars['Float']['output']
  id: Scalars['Float']['output']
  isAccepted: Scalars['Boolean']['output']
  type: EDocumentType
  updatedAt: Scalars['DateTime']['output']
  user: User
  userId: Scalars['Float']['output']
}

export type UserDocumentMetaType = {
  __typename?: 'UserDocumentMetaType'
  skip: Scalars['Float']['output']
  take: Scalars['Float']['output']
  total: Scalars['Float']['output']
}

export type UserFilter = {
  AND?: InputMaybe<Array<UserFilterableFieldsEnumerableNested>>
  NOT?: InputMaybe<Array<UserFilterableFieldsEnumerableNested>>
  OR?: InputMaybe<Array<UserFilterableFieldsEnumerableNested>>
  adress?: InputMaybe<UserFilterableFields_Adress>
  birthday?: InputMaybe<UserFilterableFields_Birthday>
  city?: InputMaybe<UserFilterableFields_City>
  countryId?: InputMaybe<UserFilterableFields_CountryId>
  createdAt?: InputMaybe<UserFilterableFields_CreatedAt>
  currencyId?: InputMaybe<UserFilterableFields_CurrencyId>
  email?: InputMaybe<UserFilterableFields_Email>
  emailVerified?: InputMaybe<UserFilterableFields_EmailVerified>
  firstName?: InputMaybe<UserFilterableFields_FirstName>
  groups?: InputMaybe<UserFilterableFields_Groups>
  id?: InputMaybe<UserFilterableFields_Id>
  languageId?: InputMaybe<UserFilterableFields_LanguageId>
  lastName?: InputMaybe<UserFilterableFields_LastName>
  messengerVerified?: InputMaybe<UserFilterableFields_MessengerVerified>
  phone?: InputMaybe<UserFilterableFields_Phone>
  phoneVerified?: InputMaybe<UserFilterableFields_PhoneVerified>
  referalId?: InputMaybe<UserFilterableFields_ReferalId>
  state?: InputMaybe<UserFilterableFields_State>
  tags?: InputMaybe<UserFilterableFields_Tags>
  updatedAt?: InputMaybe<UserFilterableFields_UpdatedAt>
  zip?: InputMaybe<UserFilterableFields_Zip>
}

export type UserFilterableFields = {
  adress?: InputMaybe<UserFilterableFields_Adress>
  birthday?: InputMaybe<UserFilterableFields_Birthday>
  city?: InputMaybe<UserFilterableFields_City>
  countryId?: InputMaybe<UserFilterableFields_CountryId>
  createdAt?: InputMaybe<UserFilterableFields_CreatedAt>
  currencyId?: InputMaybe<UserFilterableFields_CurrencyId>
  email?: InputMaybe<UserFilterableFields_Email>
  emailVerified?: InputMaybe<UserFilterableFields_EmailVerified>
  firstName?: InputMaybe<UserFilterableFields_FirstName>
  groups?: InputMaybe<UserFilterableFields_Groups>
  id?: InputMaybe<UserFilterableFields_Id>
  languageId?: InputMaybe<UserFilterableFields_LanguageId>
  lastName?: InputMaybe<UserFilterableFields_LastName>
  messengerVerified?: InputMaybe<UserFilterableFields_MessengerVerified>
  phone?: InputMaybe<UserFilterableFields_Phone>
  phoneVerified?: InputMaybe<UserFilterableFields_PhoneVerified>
  referalId?: InputMaybe<UserFilterableFields_ReferalId>
  state?: InputMaybe<UserFilterableFields_State>
  tags?: InputMaybe<UserFilterableFields_Tags>
  updatedAt?: InputMaybe<UserFilterableFields_UpdatedAt>
  zip?: InputMaybe<UserFilterableFields_Zip>
}

export type UserFilterableFieldsEnumerableNested = {
  AND?: InputMaybe<Array<UserFilterableFields>>
  NOT?: InputMaybe<Array<UserFilterableFields>>
  OR?: InputMaybe<Array<UserFilterableFields>>
  adress?: InputMaybe<UserFilterableFields_Adress>
  birthday?: InputMaybe<UserFilterableFields_Birthday>
  city?: InputMaybe<UserFilterableFields_City>
  countryId?: InputMaybe<UserFilterableFields_CountryId>
  createdAt?: InputMaybe<UserFilterableFields_CreatedAt>
  currencyId?: InputMaybe<UserFilterableFields_CurrencyId>
  email?: InputMaybe<UserFilterableFields_Email>
  emailVerified?: InputMaybe<UserFilterableFields_EmailVerified>
  firstName?: InputMaybe<UserFilterableFields_FirstName>
  groups?: InputMaybe<UserFilterableFields_Groups>
  id?: InputMaybe<UserFilterableFields_Id>
  languageId?: InputMaybe<UserFilterableFields_LanguageId>
  lastName?: InputMaybe<UserFilterableFields_LastName>
  messengerVerified?: InputMaybe<UserFilterableFields_MessengerVerified>
  phone?: InputMaybe<UserFilterableFields_Phone>
  phoneVerified?: InputMaybe<UserFilterableFields_PhoneVerified>
  referalId?: InputMaybe<UserFilterableFields_ReferalId>
  state?: InputMaybe<UserFilterableFields_State>
  tags?: InputMaybe<UserFilterableFields_Tags>
  updatedAt?: InputMaybe<UserFilterableFields_UpdatedAt>
  zip?: InputMaybe<UserFilterableFields_Zip>
}

export type UserFilterableFields_Adress = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type UserFilterableFields_Birthday = {
  contains?: InputMaybe<Scalars['DateTime']['input']>
  endsWith?: InputMaybe<Scalars['DateTime']['input']>
  equals?: InputMaybe<Scalars['DateTime']['input']>
  gt?: InputMaybe<Scalars['DateTime']['input']>
  gte?: InputMaybe<Scalars['DateTime']['input']>
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  lt?: InputMaybe<Scalars['DateTime']['input']>
  lte?: InputMaybe<Scalars['DateTime']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['DateTime']['input']>
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  startsWith?: InputMaybe<Scalars['DateTime']['input']>
}

export type UserFilterableFields_City = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type UserFilterableFields_CountryId = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type UserFilterableFields_CreatedAt = {
  contains?: InputMaybe<Scalars['DateTime']['input']>
  endsWith?: InputMaybe<Scalars['DateTime']['input']>
  equals?: InputMaybe<Scalars['DateTime']['input']>
  gt?: InputMaybe<Scalars['DateTime']['input']>
  gte?: InputMaybe<Scalars['DateTime']['input']>
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  lt?: InputMaybe<Scalars['DateTime']['input']>
  lte?: InputMaybe<Scalars['DateTime']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['DateTime']['input']>
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  startsWith?: InputMaybe<Scalars['DateTime']['input']>
}

export type UserFilterableFields_CurrencyId = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type UserFilterableFields_Email = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type UserFilterableFields_EmailVerified = {
  contains?: InputMaybe<Scalars['Boolean']['input']>
  endsWith?: InputMaybe<Scalars['Boolean']['input']>
  equals?: InputMaybe<Scalars['Boolean']['input']>
  gt?: InputMaybe<Scalars['Boolean']['input']>
  gte?: InputMaybe<Scalars['Boolean']['input']>
  in?: InputMaybe<Array<Scalars['Boolean']['input']>>
  lt?: InputMaybe<Scalars['Boolean']['input']>
  lte?: InputMaybe<Scalars['Boolean']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Boolean']['input']>
  notIn?: InputMaybe<Array<Scalars['Boolean']['input']>>
  startsWith?: InputMaybe<Scalars['Boolean']['input']>
}

export type UserFilterableFields_FirstName = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type UserFilterableFields_Groups = {
  every?: InputMaybe<GroupFilter>
  none?: InputMaybe<GroupFilter>
  some?: InputMaybe<GroupFilter>
}

export type UserFilterableFields_Id = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type UserFilterableFields_LanguageId = {
  contains?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type UserFilterableFields_LastName = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type UserFilterableFields_MessengerVerified = {
  contains?: InputMaybe<Scalars['Boolean']['input']>
  endsWith?: InputMaybe<Scalars['Boolean']['input']>
  equals?: InputMaybe<Scalars['Boolean']['input']>
  gt?: InputMaybe<Scalars['Boolean']['input']>
  gte?: InputMaybe<Scalars['Boolean']['input']>
  in?: InputMaybe<Array<Scalars['Boolean']['input']>>
  lt?: InputMaybe<Scalars['Boolean']['input']>
  lte?: InputMaybe<Scalars['Boolean']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Boolean']['input']>
  notIn?: InputMaybe<Array<Scalars['Boolean']['input']>>
  startsWith?: InputMaybe<Scalars['Boolean']['input']>
}

export type UserFilterableFields_Phone = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type UserFilterableFields_PhoneVerified = {
  contains?: InputMaybe<Scalars['Boolean']['input']>
  endsWith?: InputMaybe<Scalars['Boolean']['input']>
  equals?: InputMaybe<Scalars['Boolean']['input']>
  gt?: InputMaybe<Scalars['Boolean']['input']>
  gte?: InputMaybe<Scalars['Boolean']['input']>
  in?: InputMaybe<Array<Scalars['Boolean']['input']>>
  lt?: InputMaybe<Scalars['Boolean']['input']>
  lte?: InputMaybe<Scalars['Boolean']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['Boolean']['input']>
  notIn?: InputMaybe<Array<Scalars['Boolean']['input']>>
  startsWith?: InputMaybe<Scalars['Boolean']['input']>
}

export type UserFilterableFields_ReferalId = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type UserFilterableFields_State = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type UserFilterableFields_Tags = {
  every?: InputMaybe<TagFilter>
  none?: InputMaybe<TagFilter>
  some?: InputMaybe<TagFilter>
}

export type UserFilterableFields_UpdatedAt = {
  contains?: InputMaybe<Scalars['DateTime']['input']>
  endsWith?: InputMaybe<Scalars['DateTime']['input']>
  equals?: InputMaybe<Scalars['DateTime']['input']>
  gt?: InputMaybe<Scalars['DateTime']['input']>
  gte?: InputMaybe<Scalars['DateTime']['input']>
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  lt?: InputMaybe<Scalars['DateTime']['input']>
  lte?: InputMaybe<Scalars['DateTime']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['DateTime']['input']>
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  startsWith?: InputMaybe<Scalars['DateTime']['input']>
}

export type UserFilterableFields_Zip = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<EValueFieldCase>
  not?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type UserFreespinParams = {
  __typename?: 'UserFreespinParams'
  count: Scalars['Float']['output']
  currency: CurrencyEntity
  game: GameFromBonus
  gameId: Scalars['Float']['output']
}

export type UserGiftConfig = {
  __typename?: 'UserGiftConfig'
  bonusConfigs: Array<UserBonusConfig>
  id: Scalars['Float']['output']
  image?: Maybe<Scalars['String']['output']>
  source: GiftSource
}

export type UserGiftInstance = {
  __typename?: 'UserGiftInstance'
  bonusInstances: Array<UserBonusInstance>
  expiredAt: Scalars['String']['output']
  giftConfig: UserGiftConfig
  id: Scalars['Float']['output']
  status: Scalars['String']['output']
}

export type UserGroup = {
  __typename?: 'UserGroup'
  createdAt: Scalars['DateTime']['output']
  giftConfigId: Scalars['Float']['output']
  id: Scalars['Int']['output']
  updatedAt: Scalars['DateTime']['output']
  userGroupId: Scalars['Float']['output']
}

export type UserMetaType = {
  __typename?: 'UserMetaType'
  skip: Scalars['Float']['output']
  take: Scalars['Float']['output']
  total: Scalars['Float']['output']
}

export type UserOrderableFields = {
  birthday?: InputMaybe<OrderEnum>
  city?: InputMaybe<OrderEnum>
  createdAt?: InputMaybe<OrderEnum>
  email?: InputMaybe<OrderEnum>
  emailVerified?: InputMaybe<OrderEnum>
  firstName?: InputMaybe<OrderEnum>
  id?: InputMaybe<OrderEnum>
  lastName?: InputMaybe<OrderEnum>
  phoneVerified?: InputMaybe<OrderEnum>
}

export type UserPaymentSystem = {
  __typename?: 'UserPaymentSystem'
  id: Scalars['Float']['output']
  name: Scalars['String']['output']
}

export type UserTag = {
  __typename?: 'UserTag'
  createdAt: Scalars['DateTime']['output']
  giftConfigId: Scalars['Float']['output']
  id: Scalars['Int']['output']
  updatedAt: Scalars['DateTime']['output']
  userTagId: Scalars['Float']['output']
}

export type Wallet = {
  __typename?: 'Wallet'
  id?: Maybe<Scalars['Int']['output']>
  type: EWalletType
  userId: Scalars['Float']['output']
  value: Scalars['Decimal']['output']
}

export type WithdrawPaymentSystem = {
  __typename?: 'WithdrawPaymentSystem'
  checkDepositPaymentDetailsForWithdraw: Scalars['Boolean']['output']
  currency?: Maybe<WithdrawPaymentSystemCurrency>
  fillProfileRequired: Scalars['Boolean']['output']
  icons?: Maybe<Array<Icon>>
  id: Scalars['Float']['output']
  inputs: Array<PaymentSystemInput>
  message?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  type: EPaymentSystemType
}

export type WithdrawPaymentSystemCurrency = {
  __typename?: 'WithdrawPaymentSystemCurrency'
  code: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['Int']['output']
  maxValue: Scalars['Float']['output']
  minValue: Scalars['Float']['output']
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type SignUpMutationVariables = Exact<{
  dto: RegistrationDto
}>

export type SignUpMutation = {
  __typename?: 'Mutation'
  signUp: {
    __typename?: 'User'
    id: number
    createdAt: any
    updatedAt: any
    email: string
    firstName?: string | null
    lastName?: string | null
    birthday?: any | null
    sex?: EUserSex | null
    status: EUserStatus
    countryId?: number | null
    state?: string | null
    city?: string | null
    zip?: string | null
    languageId: number
    currencyId: number
    referalId?: string | null
    projectId: number
    phone?: string | null
    phoneVerified: boolean
    emailVerified: boolean
    messengerVerified: boolean
  }
}

export type SignInMutationVariables = Exact<{
  dto: SignInDto
}>

export type SignInMutation = {
  __typename?: 'Mutation'
  signIn: {
    __typename?: 'LoginResult'
    accessToken: string
    user: {
      __typename?: 'User'
      id: number
      createdAt: any
      updatedAt: any
      email: string
      firstName?: string | null
      withdrawableAmount: number
      lastName?: string | null
      birthday?: any | null
      sex?: EUserSex | null
      status: EUserStatus
      address?: string | null
      countryId?: number | null
      state?: string | null
      city?: string | null
      zip?: string | null
      languageId: number
      currencyId: number
      referalId?: string | null
      projectId: number
      phone?: string | null
      phoneVerified: boolean
      emailVerified: boolean
      messengerVerified: boolean
      docsVerified: boolean
      hasDeposit: boolean
      emailSubscribed: boolean
      wallets: Array<{
        __typename?: 'Wallet'
        id?: number | null
        userId: number
        type: EWalletType
        value: any
      }>
      language: {
        __typename?: 'Language'
        id: number
        createdAt: any
        updatedAt: any
        name: string
        code: string
      }
      currency: {
        __typename?: 'CurrencyEntity'
        id: number
        createdAt: any
        updatedAt: any
        name: string
        code: string
      }
      country?: {
        __typename?: 'Country'
        id: number
        createdAt: any
        updatedAt: any
        name: string
        code: string
      } | null
      groups: Array<{
        __typename?: 'Group'
        id: number
        createdAt: any
        updatedAt: any
        name: string
        description: string
      }>
      tags: Array<{
        __typename?: 'Tag'
        id: number
        createdAt: any
        updatedAt: any
        name: string
      }>
    }
  }
}

export type SignInInTelegramMutationVariables = Exact<{
  dto: LoginInTelegramDto
}>

export type SignInInTelegramMutation = {
  __typename?: 'Mutation'
  signInInTelegram: {
    __typename?: 'User'
    id: number
    createdAt: any
    updatedAt: any
    email: string
    firstName?: string | null
    lastName?: string | null
    birthday?: any | null
    sex?: EUserSex | null
    status: EUserStatus
    countryId?: number | null
    state?: string | null
    city?: string | null
    zip?: string | null
    languageId: number
    currencyId: number
    referalId?: string | null
    projectId: number
    phone?: string | null
    phoneVerified: boolean
    emailVerified: boolean
    messengerVerified: boolean
  }
}

export type GetWalletsQueryVariables = Exact<{
  getWalletDto: GetWalletDto
}>

export type GetWalletsQuery = {
  __typename?: 'Query'
  wallets: Array<{
    __typename?: 'Wallet'
    id?: number | null
    userId: number
    type: EWalletType
    value: any
  }>
}

export type UpdateUserMutationVariables = Exact<{
  dto: UpdateByUserDto
}>

export type UpdateUserMutation = {
  __typename?: 'Mutation'
  updateUser: {
    __typename?: 'User'
    id: number
    email: string
    firstName?: string | null
    lastName?: string | null
    birthday?: any | null
    sex?: EUserSex | null
    address?: string | null
    countryId?: number | null
    state?: string | null
    city?: string | null
    zip?: string | null
    languageId: number
    currencyId: number
    referalId?: string | null
    projectId: number
    phone?: string | null
  }
}

export type UserQueryVariables = Exact<{ [key: string]: never }>

export type UserQuery = {
  __typename?: 'Query'
  user: {
    __typename?: 'User'
    id: number
    createdAt: any
    updatedAt: any
    email: string
    firstName?: string | null
    lastName?: string | null
    withdrawableAmount: number
    birthday?: any | null
    sex?: EUserSex | null
    status: EUserStatus
    address?: string | null
    countryId?: number | null
    state?: string | null
    city?: string | null
    zip?: string | null
    languageId: number
    currencyId: number
    referalId?: string | null
    projectId: number
    phone?: string | null
    phoneVerified: boolean
    emailVerified: boolean
    messengerVerified: boolean
    docsVerified: boolean
    hasDeposit: boolean
    emailSubscribed: boolean
    wallets: Array<{
      __typename?: 'Wallet'
      id?: number | null
      userId: number
      type: EWalletType
      value: any
    }>
    language: {
      __typename?: 'Language'
      id: number
      createdAt: any
      updatedAt: any
      name: string
      code: string
    }
    currency: {
      __typename?: 'CurrencyEntity'
      id: number
      createdAt: any
      updatedAt: any
      name: string
      code: string
    }
    country?: {
      __typename?: 'Country'
      id: number
      createdAt: any
      updatedAt: any
      name: string
      code: string
    } | null
    groups: Array<{
      __typename?: 'Group'
      id: number
      createdAt: any
      updatedAt: any
      name: string
      description: string
    }>
    tags: Array<{
      __typename?: 'Tag'
      id: number
      createdAt: any
      updatedAt: any
      name: string
    }>
  }
}

export type RestorePasswordRequestMutationVariables = Exact<{
  email: Scalars['String']['input']
}>

export type RestorePasswordRequestMutation = {
  __typename?: 'Mutation'
  restorePasswordRequest: string
}

export type RestorePasswordCommitMutationVariables = Exact<{
  dto: CommitRestorationPasswordDto
}>

export type RestorePasswordCommitMutation = {
  __typename?: 'Mutation'
  restorePasswordCommit: string
}

export type LogOutMutationVariables = Exact<{ [key: string]: never }>

export type LogOutMutation = { __typename?: 'Mutation'; logOut: string }

export type ChangePhoneRequestMutationVariables = Exact<{
  dto: ChangePhoneRequestDto
}>

export type ChangePhoneRequestMutation = {
  __typename?: 'Mutation'
  changePhoneRequest: {
    __typename?: 'PhoneConfirmationProcess'
    phone: string
    timeoutTo: number
  }
}

export type ChangePhoneCommitMutationVariables = Exact<{
  code: Scalars['String']['input']
}>

export type ChangePhoneCommitMutation = {
  __typename?: 'Mutation'
  changePhoneCommit: { __typename?: 'User'; id: number }
}

export type PhoneConfirmationProcessQueryVariables = Exact<{
  [key: string]: never
}>

export type PhoneConfirmationProcessQuery = {
  __typename?: 'Query'
  phoneConfirmationProcess?: {
    __typename?: 'PhoneConfirmationProcess'
    phone: string
    timeoutTo: number
  } | null
}

export type UpdateEmailMutationVariables = Exact<{
  email: Scalars['String']['input']
}>

export type UpdateEmailMutation = {
  __typename?: 'Mutation'
  updateEmail: { __typename?: 'User'; id: number }
}

export type AnonUserVisitMutationVariables = Exact<{
  referalId: Scalars['String']['input']
  refCamp: Scalars['String']['input']
}>

export type AnonUserVisitMutation = {
  __typename?: 'Mutation'
  anonUserVisit: string
}

export type GiftConfigsQueryVariables = Exact<{ [key: string]: never }>

export type GiftConfigsQuery = {
  __typename?: 'Query'
  giftConfigs: Array<{
    __typename?: 'GiftConfig'
    id: number
    createdAt: any
    updatedAt: any
    ttl: number
    activationTtl: number
    promoCodeId?: number | null
    comment: string
    source: GiftSource
    hide: boolean
    name: string
    depCount?: number | null
    image?: string | null
    bonusConfigs: Array<{
      __typename?: 'BonusConfig'
      id: number
      createdAt: any
      updatedAt: any
      type: BonusTypes
      value: number
      wager: number
      onlyCashWagering: boolean
      gamesIds: Array<number>
      freespinsParams?: Array<{
        __typename?: 'FreespinsParams'
        id: number
        createdAt: any
        updatedAt: any
        count: number
        currencyId: number
        gameId: number
        denomination: number
        bet: number
        betLevel: number
      }> | null
      bonuses?: Array<{
        __typename?: 'BonusInstance'
        id: number
        createdAt: any
        updatedAt: any
        wagered: number
        giftId: number
        configId: number
        config: {
          __typename?: 'BonusConfig'
          id: number
          createdAt: any
          updatedAt: any
          type: BonusTypes
          value: number
          wager: number
          onlyCashWagering: boolean
          gamesIds: Array<number>
        }
      }> | null
      rates?: Array<{
        __typename?: 'BonusConfigRate'
        id: number
        createdAt: any
        updatedAt: any
        currencyId: number
        maxValue?: number | null
        maxWinValue?: number | null
        minDepValue?: number | null
        maxBet?: number | null
        bonusConfigId: number
      }> | null
    }>
    promocode?: {
      __typename?: 'PromocodeConfig'
      id: number
      createdAt: any
      updatedAt: any
      name: string
      type: EPromocodeType
      stock?: number | null
      stockPerUser?: number | null
      ratelimitPerUser?: number | null
      ratelimitPeriodPerUser?: EPromocodeActivationPeriods | null
      isActive: boolean
      comment: string
      promocodes?: Array<{
        __typename?: 'Promocode'
        id: number
        createdAt: any
        updatedAt: any
        isCompleted: boolean
        transactionId?: string | null
        userId: number
        configId: number
      }> | null
    } | null
  }>
}

export type ActiveUserBonusesQueryVariables = Exact<{ [key: string]: never }>

export type ActiveUserBonusesQuery = {
  __typename?: 'Query'
  activeUserBonuses: Array<{
    __typename?: 'UserBonus'
    id: number
    value?: number | null
    valueType: EBonusValueType
    ttl: any
    image?: string | null
    wager?: number | null
    wagerSum?: number | null
    wagered?: number | null
    type: BonusTypes
    game?: {
      __typename?: 'GameFromBonus'
      id?: number | null
      name?: string | null
      previewImage?: string | null
    } | null
  }>
}

export type ActivateGiftMutationVariables = Exact<{
  activateGiftId: Scalars['Float']['input']
}>

export type ActivateGiftMutation = {
  __typename?: 'Mutation'
  activateGift: {
    __typename?: 'GiftInstance'
    id: number
    createdAt: any
    updatedAt: any
    userId: number
    status: EGiftStatuses
    expiredActivationAt?: any | null
    expiredAt?: any | null
    activatedAt?: any | null
    config: {
      __typename?: 'GiftConfig'
      id: number
      createdAt: any
      updatedAt: any
      ttl: number
      activationTtl: number
      promoCodeId?: number | null
      comment: string
      source: GiftSource
      hide: boolean
      name: string
      depCount?: number | null
      image?: string | null
      bonusConfigs: Array<{
        __typename?: 'BonusConfig'
        id: number
        createdAt: any
        updatedAt: any
        type: BonusTypes
        value: number
        wager: number
        onlyCashWagering: boolean
        gamesIds: Array<number>
        freespinsParams?: Array<{
          __typename?: 'FreespinsParams'
          id: number
          createdAt: any
          updatedAt: any
          count: number
          currencyId: number
          gameId: number
          denomination: number
          bet: number
          betLevel: number
        }> | null
        bonuses?: Array<{
          __typename?: 'BonusInstance'
          id: number
          createdAt: any
          updatedAt: any
          wagered: number
          giftId: number
          configId: number
        }> | null
        rates?: Array<{
          __typename?: 'BonusConfigRate'
          id: number
          createdAt: any
          updatedAt: any
          currencyId: number
          bonusConfigId: number
          maxValue?: number | null
          maxWinValue?: number | null
          minDepValue?: number | null
          maxBet?: number | null
        }> | null
      }>
    }
    bonuses: Array<{
      __typename?: 'BonusInstance'
      id: number
      createdAt: any
      updatedAt: any
      wagered: number
      giftId: number
      configId: number
      config: {
        __typename?: 'BonusConfig'
        id: number
        createdAt: any
        updatedAt: any
        type: BonusTypes
        value: number
        wager: number
        onlyCashWagering: boolean
        gamesIds: Array<number>
      }
    }>
  }
}

export type ActivatePromocodeMutationVariables = Exact<{
  dto: CreatePromocodeInstanceDto
}>

export type ActivatePromocodeMutation = {
  __typename?: 'Mutation'
  activatePromocode: { __typename?: 'Promocode'; id: number }
}

export type ConvertCompointsMutationVariables = Exact<{
  amount: Scalars['Float']['input']
}>

export type ConvertCompointsMutation = {
  __typename?: 'Mutation'
  convertCompPoints: boolean
}

export type GetActiveUserPromocodeQueryVariables = Exact<{
  [key: string]: never
}>

export type GetActiveUserPromocodeQuery = {
  __typename?: 'Query'
  getActiveUserPromocode?: {
    __typename?: 'Promocode'
    configId: number
    createdAt: any
    id: number
    isCompleted: boolean
    transactionId?: string | null
    updatedAt: any
    userId: number
    config: {
      __typename?: 'PromocodeConfig'
      comment: string
      createdAt: any
      id: number
      isActive: boolean
      name: string
      ratelimitPerUser?: number | null
      ratelimitPeriodPerUser?: EPromocodeActivationPeriods | null
      stock?: number | null
      type: EPromocodeType
      updatedAt: any
    }
  } | null
}

export type DeleteActiveUserPromocodeMutationVariables = Exact<{
  [key: string]: never
}>

export type DeleteActiveUserPromocodeMutation = {
  __typename?: 'Mutation'
  deleteActiveUserPromocode: boolean
}

export type ActivateStandalonePromocodeMutationVariables = Exact<{
  dto: CreatePromocodeInstanceDto
}>

export type ActivateStandalonePromocodeMutation = {
  __typename?: 'Mutation'
  activateStandalonePromocode: { __typename?: 'Promocode'; id: number }
}

export type GetGamesQueryVariables = Exact<{
  dto: GetGamesDto
}>

export type GetGamesQuery = {
  __typename?: 'Query'
  games: Array<{
    __typename?: 'Game'
    id: number
    createdAt: any
    updatedAt: any
    name: string
    previewImage: string
    brand: { __typename?: 'Brand'; name: string }
  }>
}

export type GetGameCategoriesQueryVariables = Exact<{ [key: string]: never }>

export type GetGameCategoriesQuery = {
  __typename?: 'Query'
  gameCategories: Array<{
    __typename?: 'GameCategory'
    id: number
    createdAt: any
    updatedAt: any
    name: string
    games: Array<{
      __typename?: 'Game'
      id: number
      bonusEligible: boolean
      createdAt: any
      updatedAt: any
      name: string
    }>
  }>
}

export type GetBrandsQueryVariables = Exact<{ [key: string]: never }>

export type GetBrandsQuery = {
  __typename?: 'Query'
  brands: Array<{
    __typename?: 'Brand'
    id: number
    name: string
    darkPreviewImage?: string | null
    lightPreviewImage?: string | null
    provider: EProvider
    active: boolean
    currencies: Array<{
      __typename?: 'CurrencyEntity'
      id: number
      name: string
    }>
  }>
}

export type GetTagsQueryVariables = Exact<{ [key: string]: never }>

export type GetTagsQuery = {
  __typename?: 'Query'
  tags: Array<{
    __typename?: 'GameTag'
    id: number
    createdAt: any
    updatedAt: any
    name: string
    games: Array<{
      __typename?: 'Game'
      id: number
      bonusEligible: boolean
      createdAt: any
      updatedAt: any
      name: string
    }>
  }>
}

export type StartGameMutationVariables = Exact<{
  dto: StartGameDto
}>

export type StartGameMutation = {
  __typename?: 'Mutation'
  startGame: {
    __typename?: 'GameStartUrlEntity'
    url?: string | null
    html?: string | null
    game: {
      __typename?: 'Game'
      name: string
      id: number
      brand: { __typename?: 'Brand'; name: string }
    }
  }
}

export type GetLastPlayerGamesQueryVariables = Exact<{
  count: Scalars['Float']['input']
}>

export type GetLastPlayerGamesQuery = {
  __typename?: 'Query'
  getLastPlayerGames: Array<{
    __typename?: 'Game'
    id: number
    createdAt: any
    updatedAt: any
    name: string
    devices: EDeviceType
    previewImage: string
    demo: boolean
    brand: { __typename?: 'Brand'; name: string }
    countries: Array<{ __typename?: 'Country'; name: string }>
    getGameTags: Array<{ __typename?: 'GameTag'; name: string }>
    gameCategories: Array<{ __typename?: 'GameCategory'; name: string }>
  }>
}

export type AddFavoriteGameMutationVariables = Exact<{
  gameId: Scalars['Float']['input']
}>

export type AddFavoriteGameMutation = {
  __typename?: 'Mutation'
  addFavoriteGame: {
    __typename?: 'FavoriteGame'
    id: number
    createdAt: any
    updatedAt: any
    userId: number
    gameId: number
  }
}

export type RemoveFavoriteGameMutationVariables = Exact<{
  gameId: Scalars['Float']['input']
}>

export type RemoveFavoriteGameMutation = {
  __typename?: 'Mutation'
  removeFavoriteGame: {
    __typename?: 'FavoriteGame'
    id: number
    createdAt: any
    updatedAt: any
    userId: number
    gameId: number
  }
}

export type GetFavoriteGamesQueryVariables = Exact<{ [key: string]: never }>

export type GetFavoriteGamesQuery = {
  __typename?: 'Query'
  getFavoriteGames: Array<{
    __typename?: 'Game'
    id: number
    name: string
    createdAt: any
    updatedAt: any
    remoteName: string
    freespinsEligible: boolean
    bonusEligible: boolean
    isWagering: boolean
    previewImage: string
    demo: boolean
    active: boolean
    devices: EDeviceType
    percentRate: any
    getProviderJackpots: Array<{
      __typename?: 'ProviderJackpot'
      id: number
      createdAt: any
      updatedAt: any
      currencyId: number
      value: number
    }>
    getGameTags: Array<{
      __typename?: 'GameTag'
      id: number
      createdAt: any
      updatedAt: any
      name: string
    }>
    brand: {
      __typename?: 'Brand'
      id: number
      createdAt: any
      updatedAt: any
      name: string
      active: boolean
      provider: EProvider
      darkPreviewImage?: string | null
      lightPreviewImage?: string | null
    }
    gameCategories: Array<{
      __typename?: 'GameCategory'
      id: number
      createdAt: any
      updatedAt: any
      name: string
    }>
  }>
}

export type CreateTransactionMutationVariables = Exact<{
  createTransactionsDto: CreateTransactionNewDto
}>

export type CreateTransactionMutation = {
  __typename?: 'Mutation'
  createTransaction: string
}

export type DepositPaymentSystemsQueryVariables = Exact<{
  [key: string]: never
}>

export type DepositPaymentSystemsQuery = {
  __typename?: 'Query'
  depositPaymentSystems: Array<{
    __typename?: 'DepositPaymentSystem'
    fillProfileRequired: boolean
    id: number
    type: EPaymentSystemType
    name: string
    message?: string | null
    currency?: {
      __typename?: 'DepositPaymentSystemCurrency'
      code: string
      createdAt: any
      id: number
      minValue: number
      maxValue: number
      name: string
      updatedAt: any
      paymentSystemId: number
      options?: Array<{
        __typename?: 'PaymentSystemOption'
        value: number
        paymentSystemId: number
        id: number
        currencyId: number
      }> | null
    } | null
    icons?: Array<{
      __typename?: 'Icon'
      theme: EIconTheme
      icon: string
    }> | null
    inputs: Array<{
      __typename?: 'PaymentSystemInput'
      name: string
      label: string
      paymentSystemId: number
      target: EPaymentTarget
      regexp: string
    }>
  }>
}

export type CreateCryptoWalletMutationVariables = Exact<{
  paymentSystemId: Scalars['Float']['input']
}>

export type CreateCryptoWalletMutation = {
  __typename?: 'Mutation'
  createCryptoWallet: {
    __typename?: 'CryptoWallet'
    addressWallet: string
    tag?: string | null
    qrCodeImage: string
  }
}

export type WithdrawPaymentSystemsQueryVariables = Exact<{
  [key: string]: never
}>

export type WithdrawPaymentSystemsQuery = {
  __typename?: 'Query'
  withdrawPaymentSystems: Array<{
    __typename?: 'WithdrawPaymentSystem'
    fillProfileRequired: boolean
    checkDepositPaymentDetailsForWithdraw: boolean
    id: number
    message?: string | null
    name: string
    type: EPaymentSystemType
    icons?: Array<{
      __typename?: 'Icon'
      icon: string
      theme: EIconTheme
    }> | null
    currency?: {
      __typename?: 'WithdrawPaymentSystemCurrency'
      code: string
      createdAt: any
      id: number
      maxValue: number
      minValue: number
      name: string
      updatedAt: any
    } | null
  }>
}

export type TransactionsQueryVariables = Exact<{
  input: QueriesUserTransactions
}>

export type TransactionsQuery = {
  __typename?: 'Query'
  transactions: {
    __typename?: 'PaginatedUserTransactions'
    meta?: {
      __typename?: 'TransactionMetaType'
      total: number
      take: number
      skip: number
    } | null
    data?: Array<{
      __typename?: 'Transaction'
      id: string
      status: ETransactionStatus
      type: ETransactionCreateType
      amount: any
      currencyId: number
      paymentDetails?: string | null
      userId: number
      promoCode?: number | null
      paymentCountry?: string | null
      feeValue: number
      createdAt: any
      updatedAt: any
      isWagered: boolean
      wageredSum: any
      wagerSum: any
      paymentSystem: { __typename?: 'UserPaymentSystem'; name: string }
    }> | null
  }
}

export type CancelTransactionMutationVariables = Exact<{
  cancelTransactionId: Scalars['String']['input']
}>

export type CancelTransactionMutation = {
  __typename?: 'Mutation'
  cancelTransaction: { __typename?: 'Transaction'; id: string }
}

export type GetProjectsQueryVariables = Exact<{ [key: string]: never }>

export type GetProjectsQuery = {
  __typename?: 'Query'
  projects: Array<{ __typename?: 'Project'; id: number; name: string }>
}

export type GetProjectQueryVariables = Exact<{
  id: Scalars['Float']['input']
}>

export type GetProjectQuery = {
  __typename?: 'Query'
  project: {
    __typename?: 'Project'
    id: number
    name: string
    languages: Array<{ __typename?: 'Language'; id: number; name: string }>
  }
}

export type GetLanguagesQueryVariables = Exact<{ [key: string]: never }>

export type GetLanguagesQuery = {
  __typename?: 'Query'
  languages: Array<{
    __typename?: 'Language'
    id: number
    name: string
    code: string
  }>
}

export type GetLanguageQueryVariables = Exact<{
  id: Scalars['Float']['input']
}>

export type GetLanguageQuery = {
  __typename?: 'Query'
  language: {
    __typename?: 'Language'
    id: number
    name: string
    code: string
    projects: Array<{ __typename?: 'Project'; id: number; name: string }>
  }
}

export type GetCountriesQueryVariables = Exact<{ [key: string]: never }>

export type GetCountriesQuery = {
  __typename?: 'Query'
  countries: Array<{ __typename?: 'Country'; id: number; name: string }>
}

export type GetCountryQueryVariables = Exact<{
  id: Scalars['Float']['input']
}>

export type GetCountryQuery = {
  __typename?: 'Query'
  country: {
    __typename?: 'Country'
    id: number
    name: string
    projects: Array<{ __typename?: 'Project'; id: number; name: string }>
  }
}

export type ActivateMutationVariables = Exact<{
  token: Scalars['String']['input']
}>

export type ActivateMutation = { __typename?: 'Mutation'; activate: string }

export type SendActivationLinkMutationVariables = Exact<{
  [key: string]: never
}>

export type SendActivationLinkMutation = {
  __typename?: 'Mutation'
  sendActivationLink: number
}

export type EmailConfirmationTimeoutQueryVariables = Exact<{
  [key: string]: never
}>

export type EmailConfirmationTimeoutQuery = {
  __typename?: 'Query'
  emailConfirmationTimeout?: number | null
}

export type BulkCreateDocumentsMutationVariables = Exact<{
  dto: BulkCreateDocumentDto
}>

export type BulkCreateDocumentsMutation = {
  __typename?: 'Mutation'
  bulkCreateDocuments: Array<string>
}

export const SignUpDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'signUp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dto' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RegistrationDto' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'signUp' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dto' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dto' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sex' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'languageId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currencyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'referalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phoneVerified' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailVerified' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'messengerVerified' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<SignUpMutation, SignUpMutationVariables>
export const SignInDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'signIn' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dto' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SignInDto' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'signIn' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dto' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dto' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'wallets' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userId' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'withdrawableAmount' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'birthday' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'sex' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'countryId' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'languageId' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currencyId' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'referalId' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projectId' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneVerified' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailVerified' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messengerVerified' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'docsVerified' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasDeposit' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailSubscribed' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'language' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'updatedAt' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'code' }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currency' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'updatedAt' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'code' }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'updatedAt' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'code' }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groups' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'updatedAt' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'updatedAt' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'accessToken' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<SignInMutation, SignInMutationVariables>
export const SignInInTelegramDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SignInInTelegram' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dto' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoginInTelegramDto' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'signInInTelegram' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dto' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dto' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sex' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'languageId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currencyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'referalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phoneVerified' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailVerified' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'messengerVerified' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  SignInInTelegramMutation,
  SignInInTelegramMutationVariables
>
export const GetWalletsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetWallets' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'getWalletDto' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetWalletDto' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wallets' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'getWalletDto' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'getWalletDto' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetWalletsQuery, GetWalletsQueryVariables>
export const UpdateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dto' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateByUserDto' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dto' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dto' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sex' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'languageId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currencyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'referalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>
export const UserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'user' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'wallets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'withdrawableAmount' }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sex' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'languageId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currencyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'referalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phoneVerified' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailVerified' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'messengerVerified' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'docsVerified' }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hasDeposit' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailSubscribed' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'language' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currency' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'country' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UserQuery, UserQueryVariables>
export const RestorePasswordRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'restorePasswordRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' }
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'restorePasswordRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  RestorePasswordRequestMutation,
  RestorePasswordRequestMutationVariables
>
export const RestorePasswordCommitDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'restorePasswordCommit' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dto' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CommitRestorationPasswordDto' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'restorePasswordCommit' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dto' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dto' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  RestorePasswordCommitMutation,
  RestorePasswordCommitMutationVariables
>
export const LogOutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'logOut' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'logOut' } }]
      }
    }
  ]
} as unknown as DocumentNode<LogOutMutation, LogOutMutationVariables>
export const ChangePhoneRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangePhoneRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dto' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ChangePhoneRequestDto' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changePhoneRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dto' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dto' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timeoutTo' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ChangePhoneRequestMutation,
  ChangePhoneRequestMutationVariables
>
export const ChangePhoneCommitDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangePhoneCommit' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changePhoneCommit' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'code' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ChangePhoneCommitMutation,
  ChangePhoneCommitMutationVariables
>
export const PhoneConfirmationProcessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PhoneConfirmationProcess' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneConfirmationProcess' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timeoutTo' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  PhoneConfirmationProcessQuery,
  PhoneConfirmationProcessQueryVariables
>
export const UpdateEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' }
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UpdateEmailMutation, UpdateEmailMutationVariables>
export const AnonUserVisitDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AnonUserVisit' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'referalId' }
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'refCamp' }
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'anonUserVisit' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'referalId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'referalId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'refCamp' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'refCamp' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AnonUserVisitMutation,
  AnonUserVisitMutationVariables
>
export const GiftConfigsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GiftConfigs' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftConfigs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ttl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activationTtl' }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'promoCodeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bonusConfigs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'wager' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'onlyCashWagering' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gamesIds' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freespinsParams' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'updatedAt' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currencyId' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'gameId' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'denomination' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bet' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'betLevel' }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bonuses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'updatedAt' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'wagered' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'giftId' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'configId' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'config' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'createdAt' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedAt' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wager' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'onlyCashWagering'
                                    }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'gamesIds' }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rates' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'updatedAt' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currencyId' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'maxValue' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'maxWinValue' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'minDepValue' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'maxBet' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bonusConfigId' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'promocode' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stock' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stockPerUser' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ratelimitPerUser' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ratelimitPeriodPerUser' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isActive' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comment' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'promocodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'updatedAt' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isCompleted' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'transactionId' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userId' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'configId' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hide' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'depCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GiftConfigsQuery, GiftConfigsQueryVariables>
export const ActiveUserBonusesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ActiveUserBonuses' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeUserBonuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'valueType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ttl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                { kind: 'Field', name: { kind: 'Name', value: 'wager' } },
                { kind: 'Field', name: { kind: 'Name', value: 'wagerSum' } },
                { kind: 'Field', name: { kind: 'Name', value: 'wagered' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'game' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'previewImage' }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ActiveUserBonusesQuery,
  ActiveUserBonusesQueryVariables
>
export const ActivateGiftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ActivateGift' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'activateGiftId' }
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activateGift' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'activateGiftId' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'expiredActivationAt' }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'config' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'ttl' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activationTtl' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'promoCodeId' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comment' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'source' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bonusConfigs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'updatedAt' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'wager' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'onlyCashWagering' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'gamesIds' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'freespinsParams' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'createdAt' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedAt' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'currencyId' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'gameId' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'denomination'
                                    }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bet' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'betLevel' }
                                  }
                                ]
                              }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bonuses' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'createdAt' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedAt' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'wagered' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'giftId' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'configId' }
                                  }
                                ]
                              }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rates' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'createdAt' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedAt' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'currencyId' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'bonusConfigId'
                                    }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'maxValue' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'maxWinValue' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'minDepValue' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'maxBet' }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'hide' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'depCount' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bonuses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'wagered' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'giftId' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'configId' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'config' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'updatedAt' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'wager' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'onlyCashWagering' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'gamesIds' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'activatedAt' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ActivateGiftMutation,
  ActivateGiftMutationVariables
>
export const ActivatePromocodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ActivatePromocode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dto' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreatePromocodeInstanceDto' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activatePromocode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dto' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dto' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ActivatePromocodeMutation,
  ActivatePromocodeMutationVariables
>
export const ConvertCompointsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConvertCompoints' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'amount' }
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'convertCompPoints' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'amount' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'amount' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ConvertCompointsMutation,
  ConvertCompointsMutationVariables
>
export const GetActiveUserPromocodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetActiveUserPromocode' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getActiveUserPromocode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'config' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comment' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isActive' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ratelimitPerUser' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ratelimitPeriodPerUser' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'stock' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'configId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompleted' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactionId' }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetActiveUserPromocodeQuery,
  GetActiveUserPromocodeQueryVariables
>
export const DeleteActiveUserPromocodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteActiveUserPromocode' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteActiveUserPromocode' }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  DeleteActiveUserPromocodeMutation,
  DeleteActiveUserPromocodeMutationVariables
>
export const ActivateStandalonePromocodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ActivateStandalonePromocode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dto' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreatePromocodeInstanceDto' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activateStandalonePromocode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dto' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dto' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ActivateStandalonePromocodeMutation,
  ActivateStandalonePromocodeMutationVariables
>
export const GetGamesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetGames' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dto' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetGamesDto' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'games' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dto' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previewImage' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'brand' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetGamesQuery, GetGamesQueryVariables>
export const GetGameCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetGameCategories' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gameCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'games' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bonusEligible' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetGameCategoriesQuery,
  GetGameCategoriesQueryVariables
>
export const GetBrandsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBrands' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brands' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'darkPreviewImage' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lightPreviewImage' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currencies' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetBrandsQuery, GetBrandsQueryVariables>
export const GetTagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTags' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'games' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bonusEligible' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetTagsQuery, GetTagsQueryVariables>
export const StartGameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'StartGame' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dto' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StartGameDto' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'startGame' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startGameDto' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dto' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'game' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'brand' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' }
                            }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<StartGameMutation, StartGameMutationVariables>
export const GetLastPlayerGamesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLastPlayerGames' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'count' }
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getLastPlayerGames' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'count' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'brand' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'devices' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previewImage' }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'demo' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'countries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'getGameTags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'gameCategories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetLastPlayerGamesQuery,
  GetLastPlayerGamesQueryVariables
>
export const AddFavoriteGameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddFavoriteGame' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'gameId' }
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addFavoriteGame' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'gameId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'gameId' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gameId' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AddFavoriteGameMutation,
  AddFavoriteGameMutationVariables
>
export const RemoveFavoriteGameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveFavoriteGame' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'gameId' }
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeFavoriteGame' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'gameId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'gameId' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gameId' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  RemoveFavoriteGameMutation,
  RemoveFavoriteGameMutationVariables
>
export const GetFavoriteGamesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFavoriteGames' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getFavoriteGames' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'remoteName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'freespinsEligible' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bonusEligible' }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isWagering' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previewImage' }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'demo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'devices' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percentRate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'getProviderJackpots' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currencyId' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'getGameTags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'brand' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'provider' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'darkPreviewImage' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lightPreviewImage' }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'gameCategories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetFavoriteGamesQuery,
  GetFavoriteGamesQueryVariables
>
export const CreateTransactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateTransaction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createTransactionsDto' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateTransactionNewDto' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTransaction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createTransactionsDto' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'createTransactionsDto' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CreateTransactionMutation,
  CreateTransactionMutationVariables
>
export const DepositPaymentSystemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DepositPaymentSystems' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'depositPaymentSystems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fillProfileRequired' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currency' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minValue' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'maxValue' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'options' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'paymentSystemId' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currencyId' }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paymentSystemId' }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'icons' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'theme' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paymentSystemId' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'target' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'regexp' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  DepositPaymentSystemsQuery,
  DepositPaymentSystemsQueryVariables
>
export const CreateCryptoWalletDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCryptoWallet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paymentSystemId' }
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCryptoWallet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentSystemId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paymentSystemId' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addressWallet' }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'tag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'qrCodeImage' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CreateCryptoWalletMutation,
  CreateCryptoWalletMutationVariables
>
export const WithdrawPaymentSystemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'WithdrawPaymentSystems' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'withdrawPaymentSystems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fillProfileRequired' }
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'checkDepositPaymentDetailsForWithdraw'
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'icons' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'theme' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currency' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'maxValue' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minValue' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  WithdrawPaymentSystemsQuery,
  WithdrawPaymentSystemsQueryVariables
>
export const TransactionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Transactions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'QueriesUserTransactions' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'take' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'skip' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currencyId' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paymentDetails' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'promoCode' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paymentCountry' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'feeValue' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isWagered' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'wageredSum' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'wagerSum' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paymentSystem' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<TransactionsQuery, TransactionsQueryVariables>
export const CancelTransactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelTransaction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cancelTransactionId' }
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelTransaction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cancelTransactionId' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CancelTransactionMutation,
  CancelTransactionMutationVariables
>
export const GetProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProjects' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetProjectsQuery, GetProjectsQueryVariables>
export const GetProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'languages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetProjectQuery, GetProjectQueryVariables>
export const GetLanguagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLanguages' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'languages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetLanguagesQuery, GetLanguagesQueryVariables>
export const GetLanguageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLanguage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'language' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetLanguageQuery, GetLanguageQueryVariables>
export const GetCountriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCountries' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetCountriesQuery, GetCountriesQueryVariables>
export const GetCountryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCountry' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetCountryQuery, GetCountryQueryVariables>
export const ActivateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Activate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' }
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ActivateMutation, ActivateMutationVariables>
export const SendActivationLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'sendActivationLink' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sendActivationLink' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  SendActivationLinkMutation,
  SendActivationLinkMutationVariables
>
export const EmailConfirmationTimeoutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'emailConfirmationTimeout' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailConfirmationTimeout' }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  EmailConfirmationTimeoutQuery,
  EmailConfirmationTimeoutQueryVariables
>
export const BulkCreateDocumentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BulkCreateDocuments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dto' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BulkCreateDocumentDto' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkCreateDocuments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dto' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dto' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  BulkCreateDocumentsMutation,
  BulkCreateDocumentsMutationVariables
>
