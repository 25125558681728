import { gql } from '@apollo/client'
import { mutate, query } from '@/graphql/base-api'

import { type PagesParams } from '@models/pages/model'
import {
  type PhoneConfirmationProcess,
  type RegistrationDto,
  type SignInDto,
  type UpdateByUserDto,
  type User,
  type Wallet
} from '@/__generated__/graphql'

export async function signUp({
  email,
  password,
  currencyId,
  languageId,
  emailSubscribed,
  refCamp,
  referalId
}: RegistrationDto) {
  try {
    const response = await mutate<{ signUp: User }>({
      mutation: gql`
        mutation signUp($dto: RegistrationDto!) {
          signUp(dto: $dto) {
            id
            createdAt
            updatedAt
            email
            firstName
            lastName
            birthday
            sex
            status
            countryId
            state
            city
            zip
            languageId
            currencyId
            referalId
            projectId
            phone
            phoneVerified
            emailVerified
            messengerVerified
          }
        }
      `,
      variables: {
        dto: {
          email,
          password,
          currencyId,
          languageId,
          emailSubscribed,
          refCamp,
          referalId
        }
      }
    })

    return response.signUp
  } catch (error) {
    console.error('Error creating user:', error)
    throw error
  }
}

export const signIn = async ({ email, password }: SignInDto) => {
  const response = await mutate<{
    signIn: {
      user: User
      accessToken: string
    }
  }>({
    mutation: gql(`
      mutation signIn($dto: SignInDto!) {
        signIn(dto: $dto) {
          user {
            id
            wallets {
              id
              userId
              type
              value
            }
            createdAt
            updatedAt
            email
            firstName
            withdrawableAmount
            lastName
            birthday
            sex
            status
            address
            countryId
            state
            city
            zip
            languageId
            currencyId
            referalId
            projectId
            phone
            phoneVerified
            emailVerified
            messengerVerified
            docsVerified
            hasDeposit
            emailSubscribed
            language {
              id
              createdAt
              updatedAt
              name
              code
          
            }
            currency {
              id
              createdAt
              updatedAt
              name
              code
            }
            country {
              id
              createdAt
              updatedAt
              name
              code
             
            }
           
            groups {
              id
              createdAt
              updatedAt
              name
              description
            }
            tags {
              id
              createdAt
              updatedAt
              name
            }
          }
          accessToken
        }
      }
    `),
    variables: {
      dto: {
        email,
        password
      }
    }
  })

  return response.signIn.user
}

export async function signInInTelegram(initData: string) {
  const query = {
    mutation: gql`
      mutation SignInInTelegram($dto: LoginInTelegramDto!) {
        signInInTelegram(dto: $dto) {
          id
          createdAt
          updatedAt
          email
          firstName
          lastName
          birthday
          sex
          status
          countryId
          state
          city
          zip
          languageId
          currencyId
          referalId
          projectId
          phone
          phoneVerified
          emailVerified
          messengerVerified
        }
      }
    `,
    variables: { dto: { initData } }
  }
  const result = await mutate<{ signInInTelegram: User }>(query)

  return result.signInInTelegram
}

export async function getWallets({
  userId,
  cookie,
  headers
}: { userId: number } & PagesParams): Promise<Wallet[]> {
  try {
    const response = await query<{ wallets: Wallet[] }>({
      query: gql`
        query GetWallets($getWalletDto: GetWalletDto!) {
          wallets(getWalletDto: $getWalletDto) {
            id
            userId
            type
            value
          }
        }
      `,
      variables: {
        getWalletDto: {
          userId
        }
      },
      context: {
        headers: {
          ...headers,
          cookie
        }
      }
    })

    return response.wallets
  } catch (error) {
    console.error('Error getting wallet by ID:', error)
    throw error
  }
}

export async function updateUser(user: UpdateByUserDto): Promise<User> {
  try {
    const response = await mutate<{ updateUser: User }>({
      mutation: gql`
        mutation updateUser($dto: UpdateByUserDto!) {
          updateUser(dto: $dto) {
            id
            email
            firstName
            lastName
            birthday
            sex
            address
            countryId
            state
            city
            zip
            languageId
            currencyId
            referalId
            projectId
            phone
          }
        }
      `,
      variables: {
        dto: user
      }
    })

    return response.updateUser
  } catch (error) {
    console.error('Error updating user:', error)
    throw error
  }
}

export const checkAuth = async (params?: PagesParams) => {
  try {
    const response = await query<{ user: User }>({
      query: gql`
        query user {
          user {
            id
            wallets {
              id
              userId
              type
              value
            }
            createdAt
            updatedAt
            email
            firstName
            lastName
            withdrawableAmount
            birthday
            sex
            status
            address
            countryId
            state
            city
            zip
            languageId
            currencyId
            referalId
            projectId
            phone
            phoneVerified
            emailVerified
            messengerVerified
            docsVerified
            hasDeposit
            emailSubscribed
            language {
              id
              createdAt
              updatedAt
              name
              code
            }
            currency {
              id
              createdAt
              updatedAt
              name
              code
            }
            country {
              id
              createdAt
              updatedAt
              name
              code
            }

            groups {
              id
              createdAt
              updatedAt
              name
              description
            }
            tags {
              id
              createdAt
              updatedAt
              name
            }
          }
        }
      `,
      context: {
        headers: {
          cookie: params?.cookie
        }
      }
    })

    return response.user
  } catch (error) {
    console.error('Error checking auth:', error)
    throw error
  }
}

export const restorePassword = async (email: string) => {
  const response = await mutate<{
    restorePasswordRequest: string
  }>({
    mutation: gql`
      mutation restorePasswordRequest($email: String!) {
        restorePasswordRequest(email: $email)
      }
    `,
    variables: {
      email
    }
  })

  return response.restorePasswordRequest
}

export const restorePasswordCommit = async ({
  password,
  token
}: {
  password: string
  token: string
}) => {
  const response = await mutate<{
    restorePasswordCommit: string
  }>({
    mutation: gql`
      mutation restorePasswordCommit($dto: CommitRestorationPasswordDto!) {
        restorePasswordCommit(dto: $dto)
      }
    `,
    variables: {
      dto: {
        token,
        password
      }
    }
  })

  return response.restorePasswordCommit
}

export const logout = async () => {
  const response = await mutate<{
    logOut: string
  }>({
    mutation: gql`
      mutation logOut {
        logOut
      }
    `
  })

  return response.logOut
}

export const changePhoneNumber = async (phone: string) => {
  const response = await mutate<{ changePhoneRequest: unknown }>({
    mutation: gql`
      mutation ChangePhoneRequest($dto: ChangePhoneRequestDto!) {
        changePhoneRequest(dto: $dto) {
          phone
          timeoutTo
        }
      }
    `,
    variables: {
      dto: {
        phone
      }
    }
  })

  return response.changePhoneRequest
}

export const changePhoneSendCode = async (code: string) => {
  const response = await mutate<{ changePhoneCommit: unknown }>({
    mutation: gql`
      mutation ChangePhoneCommit($code: String!) {
        changePhoneCommit(code: $code) {
          id
        }
      }
    `,
    variables: {
      code
    }
  })

  return response.changePhoneCommit
}

export const phoneConfirmationProcess = async () => {
  const response = await query<{
    phoneConfirmationProcess: PhoneConfirmationProcess
  }>({
    query: gql`
      query PhoneConfirmationProcess {
        phoneConfirmationProcess {
          phone
          timeoutTo
        }
      }
    `
  })

  return response.phoneConfirmationProcess
}

export const updateEmail = async (email: string) => {
  const response = await mutate<{
    updateEmail: User
  }>({
    mutation: gql`
      mutation UpdateEmail($email: String!) {
        updateEmail(email: $email) {
          id
        }
      }
    `,
    variables: {
      email
    }
  })

  return response.updateEmail
}

export const anonUserVisit = async ({
  referalId,
  refCamp
}: {
  referalId: string
  refCamp: string
}) => {
  await query({
    query: gql`
      mutation AnonUserVisit($referalId: String!, $refCamp: String!) {
        anonUserVisit(referalId: $referalId, refCamp: $refCamp)
      }
    `,
    variables: {
      referalId,
      refCamp
    }
  })
}
