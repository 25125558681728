import { type ReactNode } from 'react'

export const Trophy = (): ReactNode => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6266 13.7267L21.2133 19.7334L15.9999 15.4267L10.7866 19.7334L12.3733 13.84L7.61328 9.87337L13.7333 9.53337L15.9999 3.8667L18.2666 9.53337L24.3866 9.87337L19.6266 13.7267ZM18.2666 23.1334H17.1333V18.9334L15.9999 17.8L14.8666 18.9334V23.1334H13.7333C12.4866 23.1334 11.4666 24.1534 11.4666 25.4V26.5334H20.5333V25.4C20.5333 24.1534 19.5246 23.1334 18.2666 23.1334Z"
        fill="#95FE2C"
      />
    </svg>
  )
}
