import { gql, type QueryOptions } from '@apollo/client'
import { mutate, query } from '../base-api'
import {
  type Country,
  type CreateDocumentDto,
  type CurrencyEntity,
  type Language,
  type Project
} from '@/__generated__/graphql'

export async function getProjects(): Promise<Project[]> {
  const response: QueryOptions = {
    query: gql`
      query GetProjects {
        projects {
          id
          name
        }
      }
    `
  }

  const result = await query<{ projects: Project[] }>(response)

  return result.projects
}

export async function getProjectById(projectId: number): Promise<Project> {
  const response: QueryOptions = {
    query: gql`
      query GetProject($id: Float!) {
        project(id: $id) {
          id
          name
          languages {
            id
            name
          }
        }
      }
    `,
    variables: { id: projectId }
  }

  const result = await query<{ project: Project }>(response)

  return result.project
}

export async function getLanguages(): Promise<Language[]> {
  const response: QueryOptions = {
    query: gql`
      query GetLanguages {
        languages {
          id
          name
          code
        }
      }
    `
  }

  const result = await query<{ languages: Language[] }>(response)

  return result.languages
}

export async function getLanguageById(languageId: number): Promise<Language> {
  const response: QueryOptions = {
    query: gql`
      query GetLanguage($id: Float!) {
        language(id: $id) {
          id
          name
          code
          projects {
            id
            name
          }
        }
      }
    `,
    variables: { id: languageId }
  }

  const result = await query<{ language: Language }>(response)

  return result.language
}

export async function getCountries(): Promise<Country[]> {
  const response: QueryOptions = {
    query: gql`
      query GetCountries {
        countries {
          id
          name
        }
      }
    `
  }

  const result = await query<{ countries: Country[] }>(response)

  return result.countries
}

export async function getCountryById(countryId: number): Promise<Country> {
  const response: QueryOptions = {
    query: gql`
      query GetCountry($id: Float!) {
        country(id: $id) {
          id
          name
          projects {
            id
            name
          }
        }
      }
    `,
    variables: { id: countryId }
  }

  const result = await query<{ country: Country }>(response)

  return result.country
}

export async function getCurrencies(): Promise<CurrencyEntity[]> {
  const response: QueryOptions = {
    query: gql`
      query {
        currencies {
          id
          name
        }
      }
    `
  }

  const result = await query<{ currencies: CurrencyEntity[] }>(response)

  return result.currencies
}

export async function verifyEmail(token: string) {
  const query = {
    mutation: gql`
      mutation Activate($token: String!) {
        activate(token: $token)
      }
    `,
    variables: { token }
  }

  return await mutate(query)
}

export async function sendActivationLink() {
  const query = {
    mutation: gql`
      mutation sendActivationLink {
        sendActivationLink
      }
    `
  }

  const result = await mutate<{ sendActivationLink: number }>(query)

  return result.sendActivationLink
}

export async function emailConfirmationTimeout() {
  const request = {
    query: gql`
      query emailConfirmationTimeout {
        emailConfirmationTimeout
      }
    `
  }

  const result = await query<{ emailConfirmationTimeout: number }>(request)

  return result.emailConfirmationTimeout
}

export const createDocuments = async (data: CreateDocumentDto[]) => {
  const request = {
    mutation: gql`
      mutation BulkCreateDocuments($dto: BulkCreateDocumentDto!) {
        bulkCreateDocuments(dto: $dto)
      }
    `,
    variables: { dto: { data } }
  }

  await mutate(request)
}
