import { type ReactNode } from 'react'

import { NavigationMobile } from '@/layouts/footer/navigation-mobile'
import { Box, useTheme } from '@mui/material'
import { License } from '@/layouts/footer/license'
import { NavigationDesktop } from '@/layouts/footer/navigation-desktop'
import { DESKTOP_LAYOUT_BREAKPOINT } from '@/config/common'
import { Container } from '@/layouts/container'

export const Footer = (): ReactNode => {
  const theme = useTheme()

  return (
    <>
      <Container
        component="footer"
        sx={{
          mt: '12px',
          [theme.breakpoints.up(DESKTOP_LAYOUT_BREAKPOINT)]: {
            maxWidth: '1200px',
            mx: 'auto'
          }
        }}
      >
        <Box
          sx={{
            p: '12px',
            background: theme.colors.color400,
            [theme.breakpoints.up(DESKTOP_LAYOUT_BREAKPOINT)]: {
              display: 'flex'
            }
          }}
        >
          <NavigationDesktop />
          <Box>
            {/* <Box */}
            {/*  sx={{ */}
            {/*    display: 'grid', */}
            {/*    gridTemplateColumns: `repeat(${LANGUAGES.length}, 1fr)`, */}
            {/*    gap: '4px', */}
            {/*    [theme.breakpoints.up(DESKTOP_LAYOUT_BREAKPOINT)]: { */}
            {/*      width: '400px' */}
            {/*    } */}
            {/*  }} */}
            {/* > */}
            {/*  {LANGUAGES.map((lang) => ( */}
            {/*    <LangButton language={lang} key={lang.locale} /> */}
            {/*  ))} */}
            {/* </Box> */}
            <NavigationMobile />
            {/* <Box */}
            {/*  sx={{ */}
            {/*    mt: '12px', */}
            {/*    borderRadius: '4px', */}
            {/*    display: 'flex', */}
            {/*    height: '100px', */}
            {/*    justifyContent: 'center', */}
            {/*    alignItems: 'center', */}
            {/*    background: theme.colors.color300, */}
            {/*    color: theme.colors.text3 */}
            {/*  }} */}
            {/* > */}
            {/*  PARTNER */}
            {/* </Box> */}
          </Box>
        </Box>
      </Container>
      <License />
    </>
  )
}
