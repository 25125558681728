import '@shared/styles/global.css'
import { appWithTranslation, useTranslation } from 'next-i18next'
import { CacheProvider } from '@emotion/react'
import { Provider as EffectorProvider } from 'effector-react'
import { fork, type Scope, serialize } from 'effector'
import { GlobalStyles, ThemeProvider } from '@mui/material'
import { type IAppProps } from '@/types/app'
import { Content } from '@/layouts/content'

import '@models/init'
import 'swiper/css'

import createEmotionCache from '@/styles/theme/ssr'
import { theme } from '@/styles/theme'
import { typography } from '@/styles/theme/foundations/typography'
import { coloristics } from '@/styles/theme/foundations/coloristics'
import { type ReactNode, useEffect } from 'react'
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev'
import { ToastContainer } from 'react-toastify'
import { useRouter } from 'next/router'
import { notify } from '@/config/notify'
import { verifyEmail } from '@/graphql'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { TelegramAuth } from '@/providers/telegram.provider'
import { ApolloProvider } from '@apollo/client'
import { getApolloClient } from '@/graphql/base-api'
import { REF_CAMP_KEY, REFERAL_ID_KEY } from '@/config/common'
import { anonUserVisit } from '@/graphql/auth/auth.api'

let clientScope: Scope | undefined
const clientSideEmotionCache = createEmotionCache()

loadDevMessages()
loadErrorMessages()

const App = ({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache
}: IAppProps): ReactNode => {
  const { t } = useTranslation('common')
  const scope = fork({
    values: {
      ...(clientScope !== undefined && serialize(clientScope)),
      ...pageProps?.initialState
    }
  })

  if (typeof window !== 'undefined') clientScope = scope

  const router = useRouter()

  useEffect(() => {
    if (router.query.verifyEmail) {
      verifyEmail(String(router.query.verifyEmail))
        .then(() => {
          notify.success({ description: t('emailVerified') })
        })
        .catch(() => {
          notify.error({ description: t('emailVerificationFailed') })
        })
    }

    if (router.query.refCamp && router.query.referalId) {
      const isAlreadyVisited = !!localStorage.getItem(REFERAL_ID_KEY)

      if (!isAlreadyVisited && router.query.refCamp && router.query.referalId) {
        void anonUserVisit({
          referalId: router.query.referalId.toString(),
          refCamp: router.query.refCamp.toString()
        }).catch(() => undefined)
      }

      localStorage.setItem(REF_CAMP_KEY, router.query.refCamp.toString())
      localStorage.setItem(REFERAL_ID_KEY, router.query.referalId.toString())

      const url = new URL(window.location.href)

      url.searchParams.delete('refCamp')
      url.searchParams.delete('referalId')
      history.replaceState(history.state, '', url.href)
    }
  }, [
    router.query.refCamp,
    router.query.referalId,
    router.query.verifyEmail,
    t
  ])

  useEffect(() => {
    if (window.innerWidth < 1200) {
      window.Tawk_API?.hideWidget?.()
    }

    // observer для случая когда скрывается скролл и страница скачет
    const bodyElement = document.body
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        const hasVerScroll = bodyElement.scrollHeight > bodyElement.clientHeight

        if (
          hasVerScroll &&
          mutation.type === 'attributes' &&
          mutation.attributeName === 'style'
        ) {
          const overflowValue = window.getComputedStyle(bodyElement).overflow
          if (overflowValue === 'hidden') {
            bodyElement.style.paddingRight = '16px'
          } else {
            bodyElement.style.paddingRight = ''
          }
        }
      })
    })

    observer.observe(bodyElement, { attributes: true })

    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <ApolloProvider client={getApolloClient()}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <EffectorProvider value={scope}>
          <CacheProvider value={emotionCache}>
            <ThemeProvider theme={theme}>
              <TelegramAuth />
              <GlobalStyles
                styles={{
                  body: {
                    margin: 0,
                    background: coloristics.colors.color1000
                  },
                  '*': {
                    boxSizing: 'border-box',
                    fontFamily: typography.fontFamily,
                    color: '#FFFFFF'
                  }
                }}
              />
              <Content>
                <Component {...pageProps} />
              </Content>
              <ToastContainer limit={3} />
            </ThemeProvider>
          </CacheProvider>
        </EffectorProvider>
      </LocalizationProvider>
    </ApolloProvider>
  )
}

export default appWithTranslation(App)
