import { sample } from 'effector'
import {
  bonusesPageSSREvent,
  dashboardPageSSREvent,
  gamePageSSREvent,
  gamesPageSSREvent,
  homePageSSREvent,
  profilePageSSREvent,
  tournamentPageSSREvent,
  tournamentsPageSSREvent
} from '@models/pages/model'
import {
  changeGamesPageFilter,
  getBrandsFx,
  getCategoriesFx,
  getFavoriteGamesFx,
  getGamesForMainPage,
  getGamesFx,
  getGameTagsFx,
  getLastPlayedGamesFx,
  startGameFx
} from '@models/games/model'
import {
  checkAuthFx,
  getCountriesFx,
  getCurrenciesFx,
  getLanguagesFx
} from '@models/user/model'

import { getTransactionsFx } from '@models/payments/model'
import { getTournamentFx, getTournamentsFx } from '@models/tournaments/model'
import {
  getActiveUserBonusesFx,
  getActiveUserPromocodeFx,
  getUserBonusesFx
} from '@models/bonuses/model'
import { setCookie, setHeaders } from '@models/app/model'

sample({
  clock: [
    bonusesPageSSREvent,
    dashboardPageSSREvent,
    gamesPageSSREvent,
    homePageSSREvent,
    profilePageSSREvent,
    tournamentPageSSREvent,
    tournamentsPageSSREvent
  ],
  target: [checkAuthFx, setHeaders, setCookie]
})

sample({
  clock: gamePageSSREvent,
  fn: ({ headers, cookie, userAgent }) => ({ headers, cookie, userAgent }),
  target: [checkAuthFx]
})

sample({
  clock: homePageSSREvent,
  target: [
    getGamesFx,
    getCurrenciesFx,
    getCountriesFx,
    getBrandsFx,
    getLanguagesFx,
    getGamesForMainPage,
    getFavoriteGamesFx
  ]
})

sample({
  clock: gamesPageSSREvent,
  target: [
    getBrandsFx,
    getCategoriesFx,
    getGameTagsFx,
    getCurrenciesFx,
    getLanguagesFx,
    getFavoriteGamesFx
  ]
})

sample({
  clock: gamesPageSSREvent,
  fn: () => ({ limit: 50 }),
  target: [changeGamesPageFilter]
})

sample({
  clock: gamePageSSREvent,
  target: [getGamesFx, getCurrenciesFx, getLanguagesFx]
})

sample({
  clock: gamePageSSREvent,
  target: [startGameFx, getLanguagesFx]
})

sample({
  clock: profilePageSSREvent,
  target: [getCountriesFx, getCurrenciesFx, getLanguagesFx, getTransactionsFx]
})

sample({
  clock: bonusesPageSSREvent,
  target: [
    getCurrenciesFx,
    getLanguagesFx,
    getUserBonusesFx,
    getActiveUserBonusesFx,
    getActiveUserPromocodeFx
  ]
})

sample({
  clock: dashboardPageSSREvent,
  target: [
    getCurrenciesFx,
    getTransactionsFx,
    getLanguagesFx,
    getLastPlayedGamesFx,
    getActiveUserBonusesFx
  ]
})

sample({
  clock: tournamentsPageSSREvent,
  target: [getCurrenciesFx, getTournamentsFx, getLanguagesFx]
})

sample({
  clock: tournamentPageSSREvent,
  target: [getCurrenciesFx, getLanguagesFx]
})

sample({
  clock: tournamentPageSSREvent,
  target: [getTournamentFx, getLanguagesFx]
})
