import { gql } from '@apollo/client'
import { query } from '@/graphql/base-api'
import { type TournamentInstance } from '@/__generated__/graphql'

export async function getTournaments() {
  const request = {
    query: gql``
  }

  const result = await query<{ tournamentInstances: TournamentInstance[] }>(
    request
  )

  return result.tournamentInstances
}

export async function getTournament({
  tournamentInstanceId
}: {
  tournamentInstanceId: number
}) {
  const request = {
    query: gql``,
    variables: { tournamentInstanceId }
  }

  const result = await query<{ tournamentInstance: TournamentInstance }>(
    request
  )

  return result.tournamentInstance
}
