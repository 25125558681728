import {
  Box,
  MenuItem,
  Select,
  type SelectChangeEvent,
  type SxProps,
  TextField,
  type TextFieldProps,
  Typography,
  useTheme
} from '@mui/material'
import { type ChangeEventHandler, type ReactNode } from 'react'

interface InputProps {
  label?: string
  placeholder?: string
  value: string
  inputOnChange?: ChangeEventHandler<HTMLInputElement>
  selectOnChange?: (event: SelectChangeEvent<string>, child: ReactNode) => void
  selectItems?: Array<{ value: string; label: string }>
  type: 'input' | 'select'
  sx?: SxProps
  startAdornment?: ReactNode
  endAdornment?: ReactNode
  disabled?: boolean
  InputProps?: TextFieldProps['InputProps']
  error?: boolean
}

export const Input = ({
  label,
  placeholder,
  value,
  inputOnChange,
  selectOnChange,
  endAdornment,
  selectItems,
  type = 'input',
  sx,
  startAdornment,
  disabled,
  InputProps,
  error
}: InputProps) => {
  const theme = useTheme()

  return (
    <Box sx={sx}>
      {label && (
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '12px',
            mt: '12px',
            color: theme.colors.text3
          }}
        >
          {label}
        </Typography>
      )}
      {type === 'input' ? (
        <TextField
          error={error}
          value={value}
          onChange={inputOnChange}
          disabled={disabled}
          sx={{
            input: {
              zIndex: 1
            },
            fieldset: {
              background: theme.colors.color400
            },
            '&:hover fieldset': {
              background: `${theme.colors.color400}!important`
            }
          }}
          fullWidth
          placeholder={placeholder}
          InputProps={{
            startAdornment,
            endAdornment,
            ...InputProps
          }}
        />
      ) : (
        <Select
          error={error}
          value={value}
          onChange={selectOnChange}
          placeholder={placeholder}
          fullWidth
          disabled={disabled}
          sx={{
            input: {
              zIndex: 1
            },
            fieldset: {
              background: theme.colors.color400
            },
            '&:hover fieldset': {
              background: `${theme.colors.color400}!important`
            }
          }}
        >
          {selectItems?.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      )}
    </Box>
  )
}
