import { Input } from '@/content/profile/input'
import { Box } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { useUnit } from 'effector-react'
import { $user } from '@models/user/model'
import dayjs from 'dayjs'

export const BirthdayInput = ({
  value,
  error,
  onChange
}: {
  value: [number, number, number]
  error: boolean
  onChange: (value: [number, number, number]) => void
}) => {
  const { t } = useTranslation(['profile', 'common'])
  const profile = useUnit($user)
  const isProfileFilled = !!profile?.firstName

  const birthday = value

  const monthes = [
    {
      value: '0',
      label: t('January', { ns: 'common' })
    },
    {
      value: '1',
      label: t('February', { ns: 'common' })
    },
    {
      value: '2',
      label: t('March', { ns: 'common' })
    },
    {
      value: '3',
      label: t('April', { ns: 'common' })
    },
    {
      value: '4',
      label: t('May', { ns: 'common' })
    },
    {
      value: '5',
      label: t('June', { ns: 'common' })
    },
    {
      value: '6',
      label: t('July', { ns: 'common' })
    },
    {
      value: '7',
      label: t('August', { ns: 'common' })
    },
    {
      value: '8',
      label: t('September', { ns: 'common' })
    },
    {
      value: '9',
      label: t('October', { ns: 'common' })
    },
    {
      value: '10',
      label: t('November', { ns: 'common' })
    },
    {
      value: '11',
      label: t('December', { ns: 'common' })
    }
  ]

  return (
    <Box sx={{ display: 'flex', gap: '4px' }}>
      <Input
        error={error}
        placeholder={t('day', { ns: 'common' })}
        value={birthday[0].toString()}
        disabled={isProfileFilled}
        inputOnChange={(event) => {
          if (!isNaN(Number(event.target.value))) {
            onChange([Number(event.target.value), birthday[1], birthday[2]])
          }
        }}
        type="input"
      />
      <Input
        error={error}
        sx={{ width: '100%' }}
        type="select"
        selectItems={monthes}
        placeholder={t('month', { ns: 'common' })}
        value={birthday[1].toString()}
        disabled={isProfileFilled}
        selectOnChange={(event) => {
          if (!isNaN(Number(event.target.value))) {
            onChange([birthday[0], Number(event.target.value), birthday[2]])
          }
        }}
      />
      <Input
        error={error}
        selectItems={Array.from({ length: 100 }, (_, i) => ({
          value: `${Number(dayjs().year()) - 116 + i}`,
          label: `${Number(dayjs().year()) - 116 + i}`
        }))}
        placeholder={t('year', { ns: 'common' })}
        value={birthday[2].toString()}
        disabled={isProfileFilled}
        selectOnChange={(event) => {
          if (!isNaN(Number(event.target.value))) {
            onChange([birthday[0], birthday[1], Number(event.target.value)])
          }
        }}
        type="select"
      />
    </Box>
  )
}
