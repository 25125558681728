import { type CSSProperties, type ReactNode } from 'react'

export const SearchIcon = ({
  style,
  color
}: {
  style?: CSSProperties
  color?: string
}): ReactNode => {
  return (
    <svg
      style={style}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.125 2.25C8.41793 2.25 9.65791 2.76361 10.5721 3.67785C11.4864 4.59209 12 5.83207 12 7.125C12 8.3325 11.5575 9.4425 10.83 10.2975L11.0325 10.5H11.625L15.375 14.25L14.25 15.375L10.5 11.625V11.0325L10.2975 10.83C9.4425 11.5575 8.3325 12 7.125 12C5.83207 12 4.59209 11.4864 3.67785 10.5721C2.76361 9.65791 2.25 8.41793 2.25 7.125C2.25 5.83207 2.76361 4.59209 3.67785 3.67785C4.59209 2.76361 5.83207 2.25 7.125 2.25ZM7.125 3.75C5.25 3.75 3.75 5.25 3.75 7.125C3.75 9 5.25 10.5 7.125 10.5C9 10.5 10.5 9 10.5 7.125C10.5 5.25 9 3.75 7.125 3.75Z"
        fill={color ?? '#71767A'}
      />
    </svg>
  )
}
