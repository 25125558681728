import {
  Box,
  Grid,
  IconButton,
  keyframes,
  Typography,
  useTheme
} from '@mui/material'
import {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import Image from 'next/image'

import { PlayButton } from '@assets/icons/svg/play-button'
import { useRouter } from 'next/router'
import { getBackToPath } from '@/helpers/get-back-to-path'
import { closeSearchGamesModal } from '@models/search-modal/model'
import { useUnit } from 'effector-react'
import isTouch from '@/helpers/is-touch'
import { type Game } from '@/__generated__/graphql'
import { $user, $wallets } from '@models/user/model'
import { Heart } from '@assets/icons/svg/heart'
import {
  $favoriteGames,
  addFavoriteGameFx,
  removeFavoriteGameFx
} from '@models/games/model'
import debounce from 'lodash.debounce'

const pulsate = keyframes`
  0% {
    transform: scale(0.6);
    opacity: 1;
    box-shadow: inset 0 0 25px 3px rgba(255, 255, 255, 0.75), 0 0 25px 10px rgba(255, 255, 255, 0.75);
  }
  100% {
    transform: scale(1);
    opacity: 0;
    box-shadow: none;
  }
`

export const GameCard = ({
  id,
  name,
  previewImage,
  brand,
  bonusEligible,
  maxWidth
}: Game & { maxWidth?: number }): ReactNode => {
  const theme = useTheme()
  const [isHover, setIsHover] = useState(false)
  const router = useRouter()
  const closeSearchGamesModalFunction = useUnit(closeSearchGamesModal)
  const gameUrl = `/game/${id}?backTo=${getBackToPath()}`
  const user = useUnit($user)
  const wallets = useUnit($wallets)
  const favoriteGames = useUnit($favoriteGames)
  const addGameToFavorite = useUnit(addFavoriteGameFx)
  const removeGameFromFavorite = useUnit(removeFavoriteGameFx)
  const deboucedAdd = useCallback(debounce(addGameToFavorite, 500), [
    addGameToFavorite
  ])
  const deboucedRemove = useCallback(debounce(removeGameFromFavorite, 500), [
    removeGameFromFavorite
  ])

  const [isFavorite, setIsFavorite] = useState(
    favoriteGames.some((favoriteGame) => favoriteGame.id === id)
  )

  const bonusBalance = useMemo(
    () => wallets.find((wallet) => wallet.type === 'BONUS')?.value,
    [wallets]
  )

  const isUnavailableWithActiveBonus = bonusBalance > 0 && bonusEligible

  const onClickGame = () => {
    router.push(user?.email ? gameUrl : '/?register').catch((e) => {
      console.error(e)
    })
    closeSearchGamesModalFunction()
  }

  const onClickAdd = async (gameId: number) => {
    setIsFavorite(true)
    await deboucedAdd(gameId)
  }

  const onClickRemove = async (gameId: number) => {
    setIsFavorite(false)
    await deboucedRemove(gameId)
  }

  useEffect(() => {
    setIsFavorite(favoriteGames.some((favoriteGame) => favoriteGame.id === id))
  }, [favoriteGames, id])

  return (
    <Grid
      {...(isTouch()
        ? {
            onClick: isUnavailableWithActiveBonus ? undefined : onClickGame
          }
        : {})}
      item
      xs={1}
      sx={
        maxWidth
          ? {
              maxWidth: `${maxWidth}px`
            }
          : {}
      }
      onMouseEnter={
        isTouch()
          ? undefined
          : () => {
              setIsHover(true)
            }
      }
      onMouseLeave={
        isTouch()
          ? undefined
          : () => {
              setIsHover(false)
            }
      }
    >
      <Box
        sx={{
          width: '100%',
          aspectRatio: 1,
          position: 'relative'
        }}
      >
        <Image
          src={previewImage}
          alt={name}
          fill
          sizes="(max-width: 768px) 100vw, 33vw"
        />
        {!isUnavailableWithActiveBonus && isHover && (
          <Box
            onClick={isUnavailableWithActiveBonus ? undefined : onClickGame}
            className="play_button"
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#00000085',
              cursor: 'pointer',
              '*': {
                shapeRendering: 'auto'
              }
            }}
          >
            <PlayButton />
            <Box
              sx={{
                position: 'absolute',
                width: '64%',
                height: '64%',
                animation: `${pulsate} 2s 0s`,
                WebkitAnimationIterationCount: 'infinite',
                animationIterationCount: 'infinite',
                WebkitAnimationTimingFunction: 'steps(1000, end)',
                animationTimingFunction: 'steps(1000, end)',
                opacity: 1,
                borderRadius: '50%',
                border: '2px solid #B7FE60',
                top: '16%',
                left: '18%',
                background: 'rgba(183,254,96,0.27)'
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                width: '64%',
                height: '64%',
                opacity: 0,
                animation: `${pulsate} 2s 1s`,
                animationIterationCount: 'infinite',
                WebkitAnimationTimingFunction: 'steps(1000, end)',
                animationTimingFunction: 'steps(1000, end)',
                borderRadius: '50%',
                border: '2px solid #B7FE60',
                top: '16%',
                left: '18%',
                background: 'rgba(183,254,96,0.27)'
              }}
            />
          </Box>
        )}
        {isUnavailableWithActiveBonus && (
          <>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                background: '#000',
                opacity: 0.7,
                position: 'absolute',
                top: 0
              }}
            />
            <Box
              style={{
                maxWidth: '100%',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
              }}
            >
              <Typography
                whiteSpace="nowrap"
                textAlign="center"
                fontWeight={600}
                color="#fff"
                sx={{
                  cursor: 'default',
                  [theme.breakpoints.down(600)]: {
                    fontSize: '11px'
                  }
                }}
              >
                BONUS IS NOT
              </Typography>
              <Typography
                textAlign="center"
                fontWeight={600}
                color="#fff"
                sx={{
                  cursor: 'default',
                  [theme.breakpoints.down(600)]: {
                    fontSize: '11px'
                  }
                }}
              >
                PLAYABLE
              </Typography>
            </Box>
          </>
        )}
      </Box>
      <Box
        sx={{
          background: theme.colors.color300,
          p: '8px 12px',
          display: 'flex',
          [theme.breakpoints.down(790)]: {
            p: '5px'
          }
        }}
      >
        <Box
          sx={{
            width: 'calc(100% - 26px)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <Typography
            color={
              !isUnavailableWithActiveBonus && isHover
                ? theme.colors.primary500
                : theme.colors.text2
            }
            fontSize="16px"
            fontWeight={500}
            sx={{
              cursor: 'default',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              [theme.breakpoints.down(1000)]: {
                fontSize: '14px'
              },
              [theme.breakpoints.down(790)]: {
                fontSize: '12px'
              },
              [theme.breakpoints.down(700)]: {
                fontSize: '12px'
              },
              [theme.breakpoints.down(636)]: {
                fontSize: '12px'
              }
            }}
          >
            {name}
          </Typography>
          <Typography
            color={
              !isUnavailableWithActiveBonus && isHover
                ? theme.colors.text1
                : theme.colors.text3
            }
            fontSize="12px"
            fontWeight={500}
            sx={{
              cursor: 'default',
              [theme.breakpoints.down(1000)]: {
                fontSize: '12px'
              },
              [theme.breakpoints.down(790)]: {
                maxWidth: '110px',
                fontSize: '10px'
              }
            }}
          >
            {brand?.name}
          </Typography>
        </Box>
        {user?.email && (
          <IconButton
            sx={{
              p: 0,
              background: 'none',
              '&:hover': { background: 'none' },
              ml: 'auto'
            }}
            onClick={async () => {
              isFavorite ? await onClickRemove(id) : await onClickAdd(id)
            }}
          >
            <Heart active={isFavorite} />
          </IconButton>
        )}
      </Box>
    </Grid>
  )
}
