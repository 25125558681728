import {
  ApolloClient,
  ApolloLink,
  type ApolloQueryResult,
  createHttpLink,
  type FetchResult,
  InMemoryCache,
  type MutationOptions,
  type QueryOptions,
  split
} from '@apollo/client'
import process from 'process'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { generateCookiesString } from '@/config/common'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { API_URL_INSIDE, API_URL_OUTSIDE } from '@/config/env'
import { type SubscriptionOptions } from '@apollo/client/core/watchQueryOptions'

export const isDevelopment = process.env.NODE_ENV === 'development'

const errorLink = onError(
  ({ networkError, operation, graphQLErrors, forward, ...rest }) => {
    if (graphQLErrors) {
      // graphQLErrors.forEach(({ message, locations, path, ...rest }) => {
      //   console.log(
      //     `[GraphQL error]: Message: ${message}, Location: ${locations?.toString()}, Path: ${path?.toString()}`
      //   )
      // })
    }
    if (networkError)
      console.log(`[Network error]: ${networkError?.toString()}`)

    forward(operation)
  }
)

const httpLink = createHttpLink({
  uri: isDevelopment
    ? 'http://localhost:3000/api/graphql'
    : typeof window !== 'undefined'
      ? '/api/graphql'
      : 'http://' + API_URL_INSIDE + '/graphql',
  fetch,
  credentials: 'include',
  ...(typeof window === 'undefined'
    ? {
        headers: {
          projectId: '8'
        }
      }
    : {})
})

const wsLink = new GraphQLWsLink(
  createClient({
    url: 'wss://' + API_URL_OUTSIDE + '/notification/graphql'
  })
)

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)

const convertCookies = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      Cookie: headers?.cookie ? generateCookiesString(headers.cookie) : ''
    }
  }
})

const link = ApolloLink.from([
  errorLink,
  convertCookies.concat(typeof window === 'undefined' ? httpLink : splitLink)
])

const isServer = typeof window === 'undefined'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
const windowApolloState = !isServer && window.__NEXT_DATA__.apolloState

let CLIENT: ApolloClient<unknown>

export function getApolloClient(forceNew?: boolean) {
  if (!CLIENT || forceNew) {
    CLIENT = new ApolloClient({
      ssrMode: true,
      cache: new InMemoryCache().restore(windowApolloState || {}),
      link,
      credentials: 'include',
      defaultOptions: {
        query: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all'
        },
        watchQuery: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all'
        },
        mutate: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all'
        }
      }
    })
  }

  return CLIENT
}

export const apolloClient = getApolloClient()

export async function query<T>(query: QueryOptions): Promise<T> {
  const result: ApolloQueryResult<T> = await apolloClient.query(query)

  if (result.data) {
    return result.data
  } else {
    throw new Error(
      result.errors?.map((error) => error.message).join('\n') ?? 'smthWrong'
    )
  }
}

export async function mutate<T>(mutation: MutationOptions): Promise<T> {
  const result: FetchResult<T> = await apolloClient.mutate(mutation)

  if (result.data) {
    return result.data
  } else {
    throw new Error(
      result.errors?.map((error) => error.message).join('\n') ?? 'smthWrong'
    )
  }
}

export async function subscribe(subscription: SubscriptionOptions) {
  return apolloClient.subscribe({
    ...subscription,
    context: {
      headers: {
        cookies: window.document.cookie
      }
    }
  })
}
