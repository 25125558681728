export const BellOff = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8333 9.82508C15.4167 9.93341 15 10.0001 14.5833 10.0001C12.0583 10.0001 10 7.94175 10 5.41675C10 4.19175 10.4833 3.08341 11.25 2.25841C10.9583 1.90008 10.5083 1.66675 10 1.66675C9.08333 1.66675 8.33333 2.41675 8.33333 3.33341V3.57508C5.85833 4.30841 4.16667 6.58341 4.16667 9.16675V14.1667L2.5 15.8334V16.6667H17.5V15.8334L15.8333 14.1667V9.82508ZM10 19.1667C10.925 19.1667 11.6667 18.4251 11.6667 17.5001H8.33333C8.33333 18.4251 9.08333 19.1667 10 19.1667Z"
        fill="#BCC1CC"
      />
      <path
        d="M14.5837 8.33333C16.192 8.33333 17.5003 7.025 17.5003 5.41667C17.5003 3.80833 16.192 2.5 14.5837 2.5C12.9753 2.5 11.667 3.80833 11.667 5.41667C11.667 7.025 12.9753 8.33333 14.5837 8.33333Z"
        fill="#71767A"
      />
    </svg>
  )
}
