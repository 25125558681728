import { createEvent, createStore } from 'effector'

export const openForgotPasswordModal = createEvent()
export const closeForgotPasswordModal = createEvent()

export const $isForgotPasswordModalOpened = createStore(false)

$isForgotPasswordModalOpened
  .on(openForgotPasswordModal, () => true)
  .reset(closeForgotPasswordModal)
