import {
  Box,
  Button,
  Dialog,
  IconButton,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material'
import { PlayNetwork } from '@assets/icons/svg/play-network'
import { CloseIcon } from 'next/dist/client/components/react-dev-overlay/internal/icons/CloseIcon'
import Image from 'next/image'
import { useUnit } from 'effector-react'
import { $brands } from '@models/games/model'
import { useTheme } from '@mui/material/styles'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { type ChangeEvent, useEffect, useState } from 'react'
import { SearchIcon } from '@assets/icons/svg/search-icon'
import { type Brand } from '@/__generated__/graphql'

export const BrandsModal = ({
  isOpened,
  setIsOpened
}: {
  isOpened: boolean
  setIsOpened: (v: boolean) => void
}) => {
  const { t } = useTranslation('home')
  const router = useRouter()
  const theme = useTheme()
  const brands = useUnit($brands)
  const withText = useMediaQuery(theme.breakpoints.up(950))

  const [showedBrands, setShowedBrands] = useState<Brand[]>([])

  useEffect(() => {
    setShowedBrands(brands)
  }, [brands])

  const findBrand = (event: ChangeEvent<HTMLInputElement>) => {
    setShowedBrands(
      brands.filter((brand) =>
        brand.name.toLowerCase().includes(event.target.value)
      )
    )
  }

  const onClose = () => {
    setIsOpened(false)
    setTimeout(() => {
      setShowedBrands(brands)
    }, 200)
  }

  return (
    <Dialog
      open={isOpened}
      onClose={onClose}
      sx={{
        zIndex: 5000,
        '.MuiDialog-container': {
          alignItems: 'flex-start'
        }
      }}
      PaperProps={{
        sx: {
          mt: '60px',
          maxWidth: '100vw'
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <PlayNetwork />
        <Typography sx={{ textTransform: 'uppercase' }}>
          {t('providers')}
        </Typography>
        <IconButton sx={{ ml: 'auto' }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <TextField
        InputProps={{ startAdornment: <SearchIcon /> }}
        placeholder={t('searchForProvider')}
        onChange={findBrand}
        sx={{ mt: '12px' }}
      />
      <Box
        sx={{
          mt: '12px',
          display: 'grid',
          gridTemplateColumns: 'repeat(5, 1fr)',
          gap: '8px',
          width: '80vw',
          maxWidth: '1200px',
          [theme.breakpoints.down(1200)]: {
            gridTemplateColumns: 'repeat(4, 1fr)'
          },
          [theme.breakpoints.down(950)]: {
            gridTemplateColumns: 'repeat(3, 1fr)'
          },
          [theme.breakpoints.down(950)]: {
            gridTemplateColumns: 'repeat(4, 1fr)'
          },
          [theme.breakpoints.down(610)]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
            maxWidth: '100%'
          }
        }}
      >
        {showedBrands.map(({ name, id, darkPreviewImage }) => (
          <Button
            sx={{
              borderRadius: '8px',
              padding: '4px',
              justifyContent: 'flex-start',
              gap: '5px',
              '&:hover': {
                '& p': {
                  color: '#fffffe'
                }
              },
              ...(!withText && {
                p: 0,
                position: 'relative'
              })
            }}
            key={id}
            onClick={async () =>
              await router.push({
                query: { brands: id },
                pathname: '/games'
              })
            }
          >
            <Box
              sx={{
                width: withText ? '100px' : '100%',
                height: '32px',
                position: 'relative',
                p: '0 4px'
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  position: 'relative'
                }}
              >
                <Image
                  src={darkPreviewImage ?? '/gray.png'}
                  alt={name}
                  fill={!withText}
                  width={withText ? 80 : undefined}
                  height={withText ? 32 : undefined}
                  style={{
                    borderRadius: '4px',
                    objectFit: darkPreviewImage ? 'contain' : 'cover'
                  }}
                />
              </Box>
            </Box>
            {withText && (
              <Typography
                fontSize={14}
                color={theme.colors.text3}
                margin="0 auto 0 0"
              >
                {name}
              </Typography>
            )}
          </Button>
        ))}
      </Box>
    </Dialog>
  )
}
