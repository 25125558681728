import { Box, Button, useTheme } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import { type ReactNode, useRef, useState } from 'react'
import { Container } from '@/layouts/container'
import { PlayNetwork } from '@assets/icons/svg/play-network'
import { ContainedArrow } from '@assets/icons/svg/contained-arrow'

import { BrandsModal } from '@/content/home/category-links/modal'
import { type Category } from '@/pages'
import { useRouter } from 'next/router'

export const CategoryLinks = ({
  categories
}: {
  categories: Category[]
}): ReactNode => {
  const theme = useTheme()
  const { t } = useTranslation('home')
  const [isBrandsModalOpened, setIsBrandsModalOpened] = useState<boolean>(false)
  const router = useRouter()
  const providersButtonRef = useRef<HTMLButtonElement | null>(null)

  return (
    <Container
      sx={{
        mb: '10px',
        [theme.breakpoints.up(1000)]: {
          display: 'grid',
          gridTemplateColumns: 'auto 200px',
          alignItems: 'center',
          gap: '10px'
        }
      }}
    >
      <Box
        component={Swiper}
        sx={{
          width: '100%',
          padding: '16px 0 16px',
          [theme.breakpoints.up(768)]: {
            display: 'none'
          }
        }}
        slidesPerView={'auto'}
        spaceBetween={10}
      >
        {categories.map(({ tKey, icon, serverId }) => (
          <SwiperSlide style={{ width: 'fit-content' }} key={tKey}>
            <Button
              onClick={async () =>
                await router.push(`/games?filter=categories:${serverId}`)
              }
              sx={{
                borderRadius: '4px',
                textWrap: 'nowrap',
                '&:hover': {
                  '& svg': {
                    fill: '#95FE2C'
                  },
                  '& path': {
                    fill: '#95FE2C'
                  }
                }
              }}
              startIcon={icon}
            >
              {t(tKey)}
            </Button>
          </SwiperSlide>
        ))}
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(5, 1fr)',
          gap: '10px',
          padding: '16px 0 16px',
          [theme.breakpoints.down(768)]: {
            display: 'none'
          }
        }}
      >
        {categories.map(({ tKey, icon, serverId }) => (
          <Button
            onClick={async () =>
              await router.push(`/games?filter=categories:${serverId}`)
            }
            sx={{
              borderRadius: '4px',
              textWrap: 'nowrap',
              '&:hover': {
                '& svg': {
                  fill: '#95FE2C'
                },
                '& path': {
                  fill: '#95FE2C'
                }
              }
            }}
            startIcon={icon}
            key={tKey}
          >
            {t(tKey)}
          </Button>
        ))}
      </Box>
      <Button
        ref={providersButtonRef}
        sx={{
          borderRadius: '4px',
          width: '100%',
          justifyContent: 'space-between'
        }}
        startIcon={<PlayNetwork />}
        endIcon={<ContainedArrow rotate={'90deg'} />}
        onClick={() => {
          setIsBrandsModalOpened((prev) => !prev)
        }}
      >
        {t('providers')}
      </Button>
      <BrandsModal
        isOpened={isBrandsModalOpened}
        setIsOpened={setIsBrandsModalOpened}
      />
    </Container>
  )
}
