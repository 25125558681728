import { Button, Typography } from '@mui/material'
import Link from 'next/link'
import { type MenuItem } from '@/layouts/menu/index'
import { useTheme } from '@mui/material/styles'

type MenuButtonProps = MenuItem & {
  variant: 'mobile' | 'desktop_main' | 'desktop_second'
}

export const MenuButton = ({
  icon,
  title,
  path,
  onClick,
  type,
  endIcon,
  variant
}: MenuButtonProps) => {
  const theme = useTheme()

  return (
    <Button
      {...(path !== undefined ? { component: Link, href: path } : {})}
      {...(onClick !== undefined ? { onClick } : {})}
      sx={{
        justifyContent: 'flex-start',
        gap: '4px',
        borderRadius: '4px',
        height: type === 'primary' ? '40px' : '32px',
        ...(variant === 'desktop_main' && {
          background: 'none',
          flexDirection: 'column',
          height: '55px',
          width: '62px',
          px: '2px'
        }),
        ...(variant === 'desktop_second' && {
          background: theme.colors.color400,
          padding: '2px 5px',
          height: '28px'
        })
      }}
      fullWidth
    >
      {icon}
      <Typography
        sx={{
          ...(variant === 'desktop_main' && {
            fontSize: '12px',
            textTransform: 'none'
          }),
          ...(variant === 'desktop_second' && {
            color: theme.colors.color200,
            fontSize: '12px',
            textTransform: 'none'
          })
        }}
      >
        {title}
      </Typography>
      {endIcon}
    </Button>
  )
}
