import { createTheme } from '@mui/material'
import { coloristics } from '@/styles/theme/foundations/coloristics'
import { components } from '@/styles/theme/foundations/components'
import { breakpoints } from '@/styles/theme/foundations/breakpoints'
import { shadows } from '@/styles/theme/foundations/shadows'
import { typography } from '@/styles/theme/foundations/typography'

export const theme = createTheme({
  ...coloristics,
  components,
  breakpoints,
  typography,
  shape: {
    borderRadius: 16
  },
  shadows
})
