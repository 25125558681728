export const Popular = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 7H23C25.7614 7 28 9.23858 28 12V12.0645C31.3354 12.5242 33.9041 15.3861 33.9041 18.8478C33.9041 19.0012 33.8991 19.1535 33.8891 19.3044H33.9037C33.9037 26.8683 27.772 33 20.2081 33C16.1519 33 12.5075 31.2367 9.99976 28.4348H11.0507L11.0777 28.4348C16.1203 28.4348 20.2081 24.347 20.2081 19.3044H20.2234C20.2135 19.1535 20.2085 19.0012 20.2085 18.8478C20.2085 15.4251 22.7196 12.5889 26 12.081V12C26 10.3431 24.6569 9 23 9H22V7ZM8.01158 17.1362L8.48842 16.8638C9.42314 16.3296 10 15.3356 10 14.259L10 11L11.0555 11.9047C12.6071 13.2346 13.5 15.2017 13.5 17.2452C13.5 18.3217 12.9231 19.3296 11.9884 19.8638L11.5116 20.1362C10.5769 20.6704 10 21.6644 10 22.741L10 26L8.94446 25.0953C7.39294 23.7654 6.5 21.7983 6.5 19.7548C6.5 18.6783 7.07686 17.6704 8.01158 17.1362ZM17.9722 30.5334C24.1706 29.0497 29.0496 24.1707 30.5334 17.9722C30.8365 18.9273 31 19.9446 31 21C31 26.5229 26.5228 31 21 31C19.9445 31 18.9273 30.8365 17.9722 30.5334Z"
        fill="#71767A"
      />
    </svg>
  )
}
