import { Dialog, TextField, Typography } from '@mui/material'

import { TitleWithBackAndClose } from '@components/modals/title-with-back-and-close'

import {
  $isForgotPasswordModalOpened,
  closeForgotPasswordModal
} from '@components/modals/forgot-password/model'

import { useUnit } from 'effector-react'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import { restorePassword } from '@/graphql/auth/auth.api'
import { LoadingButton } from '@components/loading-button'
import { notify } from '@/config/notify'

export const ForgotPassword = () => {
  const { t } = useTranslation('common')
  const isOpened = useUnit($isForgotPasswordModalOpened)
  const closeForgetPasswordFunction = useUnit(closeForgotPasswordModal)

  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  const onClickRestore = async () => {
    try {
      setLoading(true)
      const result = await restorePassword(email)

      if (result === 'ok') {
        notify.success({ description: t('checkEmail') })
        closeForgetPasswordFunction()
      }
    } catch (e) {
      if (e instanceof Error) {
        notify.error({ description: e.message })
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog
      open={isOpened}
      sx={{
        zIndex: 5000
      }}
      PaperProps={{
        sx: {
          minWidth: '375px'
        }
      }}
    >
      <TitleWithBackAndClose
        title={t('restorePassword')}
        onClickBack={closeForgetPasswordFunction}
        onClickClose={closeForgetPasswordFunction}
      />
      <Typography sx={{ fontWeight: 400, fontSize: '12px', mt: '12px' }}>
        {t('email')}
      </Typography>
      <TextField
        value={email}
        onChange={(event) => {
          setEmail(event.target.value)
        }}
      />
      <LoadingButton
        sx={{ borderRadius: '4px', mt: '12px' }}
        onClick={onClickRestore}
        loading={loading}
        color="secondary"
      >
        {t('restorePassword')}
      </LoadingButton>
    </Dialog>
  )
}
