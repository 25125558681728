import { type ReactNode } from 'react'

export const FireGreen = (): ReactNode => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.5017 7.05252L23.6001 7.20005L23.8293 7.65841C23.9416 7.88308 24.0001 8.13082 24.0001 8.38201C24.0001 9.27563 23.2757 10 22.3821 10H22.2417C21.4035 10 20.6818 9.40841 20.5174 8.58647L20.4873 8.43583C20.4296 8.14741 20.4213 7.85128 20.4629 7.56011L20.4952 7.33417C20.6768 6.06345 21.6604 5.05667 22.9266 4.84564L23.2001 4.80005C22.9477 5.5572 23.059 6.38846 23.5017 7.05252ZM22.0393 14.2257C22.2998 14.4825 22.5661 14.7452 22.7922 15.04C22.7922 15.04 23.4642 16 23.7162 16.552L23.8722 16.864C25.0002 19.6 24.2322 22.792 22.0362 24.784C20.0682 26.56 17.3202 27.028 14.7522 26.7041C12.3402 26.404 10.0962 24.916 8.80016 22.84C8.44016 22.2041 8.11616 21.484 7.94816 20.764C7.74416 20.164 7.67216 19.564 7.60016 18.964C7.44416 16.36 8.53616 13.6 10.5402 11.968C9.62816 13.936 9.84416 16.396 11.2002 18.112C11.2482 18.196 11.2962 18.256 11.3682 18.316C11.5962 18.508 11.8842 18.58 12.1602 18.46C12.4002 18.364 12.5802 18.124 12.5802 17.86C12.5802 17.704 12.5322 17.584 12.4842 17.464C11.0202 13.684 12.2442 9.28005 15.3522 6.78405C16.2042 6.10005 17.2002 5.47605 18.3402 5.20005C17.2002 7.42005 17.5962 10.312 19.3842 12.064C19.8063 12.4796 20.2719 12.8226 20.7392 13.1669C21.1221 13.4489 21.5061 13.7318 21.8682 14.056C21.9246 14.1125 21.9818 14.1689 22.0393 14.2257ZM17.6802 23.32C18.1122 23.2001 18.6642 22.888 19.0002 22.6C19.7202 21.94 20.1522 20.86 20.1162 19.876C20.1162 19.696 20.0922 19.528 20.0442 19.36C19.8415 18.3002 19.1581 17.706 18.4508 17.0909C18.0689 16.7589 17.6801 16.4207 17.3562 16C17.0682 15.64 16.8282 15.184 16.6002 14.728C16.2762 15.556 16.2522 16.312 16.3962 17.2C16.4419 17.4708 16.5072 17.7302 16.5715 17.9858C16.7267 18.6026 16.8764 19.1973 16.7322 19.876C16.4802 20.944 15.6282 22 14.2002 22.3361C14.9922 23.128 16.3362 23.8 17.6802 23.32Z"
        fill="#95FE2C"
      />
    </svg>
  )
}
