import { Box } from '@mui/material'
import { type ReactNode, useEffect, useState } from 'react'
import { Header } from '@/layouts/header'
import {
  MOBILE_BOTTOM_BAR_HEIGHT,
  MobileBottomBar
} from '@/layouts/mobile-bottom-bar'
import { Footer } from '@/layouts/footer'
import { SiteMenu } from '@/layouts/menu'
import { useTheme } from '@mui/material/styles'
import { DESKTOP_LAYOUT_BREAKPOINT } from '@/config/common'

import { Modals } from '@components/modals'
import { useRouter } from 'next/router'
import { RouterLoader } from '@components/router-loader'
import { useUnit } from 'effector-react'
import { $tournaments, getTournamentsFx } from '@models/tournaments/model'
import { $endSession, $userAgent, appStarted } from '@models/app/model'
import { UAParser } from 'ua-parser-js'
import { MobileGameHeader } from '@/layouts/mobile-game-header'
import { openNewPasswordModal } from '@components/modals/new-password/model'
import {
  $depositPaymentSystems,
  $withdrawPaymentSystems,
  getDepositPaymentSystemsFx,
  getWithdrawPaymentSystemsFx
} from '@models/payments/model'
import { $user, setWithdrawableAmount } from '@models/user/model'
import {
  getObserverBalanceChange,
  getTransactionCompletedObserver
} from '@/graphql/socket/socket.api'
import {
  setBalanceChangeMessage,
  setTestNotification
} from '@models/socket/model'
import { isDevelopment } from '@/graphql/base-api'
import { openRegisterModal } from '@components/modals/registration-modal/model'
import { setIsSuccessDepositModalOpen } from '@components/modals/success-deposit-modal/model'
import {
  ETransactionCreateType,
  ETransactionStatus
} from '@/__generated__/graphql'
import { closePaymentModal } from '@components/modals/payment-modal/model'
import { isInIframe } from '@/helpers/inInIframe'

const PAGES_WITH_FOOTER = ['/']
export const GAME_PAGES = ['/game/[gameId]']

export const Content = ({ children }: { children: ReactNode }): ReactNode => {
  const theme = useTheme()
  const { pathname } = useRouter()
  const tournaments = useUnit($tournaments)
  const getTournaments = useUnit(getTournamentsFx)
  const userAgent = useUnit($userAgent)
  const parser = new UAParser(userAgent)
  const openNewPasswordModalFunction = useUnit(openNewPasswordModal)
  const router = useRouter()
  const depositPaymentSystems = useUnit($depositPaymentSystems)
  const withdrawPaymentSystems = useUnit($withdrawPaymentSystems)
  const getDepositPaymentSystemsFxFunction = useUnit(getDepositPaymentSystemsFx)
  const getWithdrawPaymentSystemsFxFunction = useUnit(
    getWithdrawPaymentSystemsFx
  )
  const closePaymentModalFunction = useUnit(closePaymentModal)
  const user = useUnit($user)
  const setBalanceChangeMessageFunction = useUnit(setBalanceChangeMessage)
  const setWithdrawableAmountFunction = useUnit(setWithdrawableAmount)
  const setTestNotificationFunction = useUnit(setTestNotification)
  const isAuthenticated = !!user
  const openRegisterModalFunction = useUnit(openRegisterModal)
  const setSuccessDepositModalOpenFunction = useUnit(
    setIsSuccessDepositModalOpen
  )
  const endSession = useUnit($endSession)
  const appStartedEvent = useUnit(appStarted)
  const [isHideContent, setIsHideContent] = useState(true)

  const isMobile = parser.getDevice().type === 'mobile'

  useEffect(() => {
    if (depositPaymentSystems.length === 0 && user?.id) {
      getDepositPaymentSystemsFxFunction().catch(console.error)
    }
    if (withdrawPaymentSystems.length === 0 && user?.id) {
      getWithdrawPaymentSystemsFxFunction().catch(console.error)
    }
  }, [getTournaments, tournaments.length, user?.id])

  useEffect(() => {
    if (router.query.passwordrestoration) {
      openNewPasswordModalFunction()
    }

    if (router.query.registration !== undefined) {
      router.push('/', '/', { shallow: true }).catch(console.error)
      openRegisterModalFunction()
    }
  }, [
    openNewPasswordModalFunction,
    openRegisterModalFunction,
    router.query.passwordrestoration,
    router.query.registration
  ])

  useEffect(() => {
    if (!isDevelopment && isAuthenticated) {
      getObserverBalanceChange()
        .then((observer) => {
          observer.subscribe(({ data }) => {
            if (data) {
              setBalanceChangeMessageFunction(data.balanceChanged)
            }
          })
        })
        .catch(console.error)

      getTransactionCompletedObserver()
        .then((observer) => {
          observer.subscribe(({ data }) => {
            if (
              data?.transactionCompleted?.type ===
                ETransactionCreateType.Deposit &&
              data?.transactionCompleted?.status === ETransactionStatus.Success
            ) {
              closePaymentModalFunction()
              setSuccessDepositModalOpenFunction(true)
            }

            setWithdrawableAmountFunction(
              data?.transactionCompleted?.withdrawableAmount ?? 0
            )
          })
        })
        .catch(console.error)
    }
  }, [
    isAuthenticated,
    setBalanceChangeMessageFunction,
    setTestNotificationFunction
  ])

  useEffect(() => {
    appStartedEvent()

    const inIframe = isInIframe()

    // TODO сделали временно пока не приходит endSession
    if (!inIframe) {
      setIsHideContent(false)
    }

    const url = new URL(document.location as unknown as URL)
    const params = new URLSearchParams(url.search)
    const endSessionParam = params.get('endSession')

    console.log('url', url)
    console.log('params', params)
    console.log('endSession', endSessionParam)

    if (endSession || inIframe) {
      params.delete('endSession')

      const paramsString = params.toString()
      const pathname =
        paramsString.length > 0
          ? `${url.pathname}?${paramsString}`
          : url.pathname
      const newUrl = new URL(pathname, url.origin)

      if (isInIframe()) {
        window.parent.location.href = newUrl.toString()
      } else {
        window.location.href = newUrl.toString()
      }
    }
  }, [])

  return isHideContent ? (
    <Box />
  ) : (
    <Box
      component="main"
      minHeight="100%"
      minWidth="375px"
      height="100%"
      overflow="hidden"
      sx={{
        pt: '60px',
        pb: GAME_PAGES.includes(pathname) ? 0 : MOBILE_BOTTOM_BAR_HEIGHT,
        [theme.breakpoints.up(DESKTOP_LAYOUT_BREAKPOINT)]: {
          pb: 0
        }
      }}
    >
      <RouterLoader />
      {GAME_PAGES.includes(pathname) && isMobile ? (
        <MobileGameHeader />
      ) : (
        <Header />
      )}
      <Box
        sx={{
          height: '100%'
        }}
      >
        {children}
        {PAGES_WITH_FOOTER.includes(pathname) && <Footer />}
      </Box>
      {!GAME_PAGES.includes(pathname) && <MobileBottomBar />}

      <SiteMenu />
      {/* <Tournament /> */}
      <Modals />
    </Box>
  )
}
